import React from "react";
import { useOutletContext } from "react-router-dom";
import Card from "../../../Card";
import SimpleJobDonut from "../../SimpleJobDonut";

import { ForecastSnapshotViewContextType } from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

import Forecast from "../../../../pages/Forecast/classes/Forecast";
import CapacityBarIndicator from "../CapacityBarIndicator";

export default function ForecastCountSummary({ isStatic = false}) {
  const contextValue = useOutletContext();

  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = contextValue;
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
    } = contextValue;
  }

  let totalInput = forecastData?.getTotalInput();
  let totalLikelyJobs = forecastData?.getTotalLikely();
  if (isStatic) {
    totalInput = staticForecastData?.numInputJobs;
    totalLikelyJobs = staticForecastData?.numEstimatedJobs; // TODO: double check this
  }

  let futureJobs = 0;
  if (totalInput > totalLikelyJobs) {
    futureJobs = totalInput - totalLikelyJobs;
  }

  // Round to 2 decimal places
  let weeksCapacity = forecastData?.getWeeksOfCapacity();
  if (isStatic) {
    weeksCapacity = staticForecastData?.weeksOfCapacity;
  }
  weeksCapacity = Math.round(weeksCapacity * 100) / 100;

  return (
    <div className="flex">
      <Card>
        <div className="flex items-center gap-6">
          {/* Stats */}
          <div className="flex flex-col items-stretch justify-between gap-3">
            {/* Job counts */}
            <div className="flex gap-6">
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">
                  Planned Jobs
                </div>
                <div className="text-2xl font-semibold text-center">
                  {formatNumber(Math.min(totalInput, totalLikelyJobs))}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">
                  Future Jobs
                </div>
                <div className="text-2xl font-semibold text-center">
                  {formatNumber(futureJobs)}
                </div>
              </div>
            </div>

            {/* Weeks Capacity */}
            <div className="flex flex-col items-center">
              <div className="w-full text-xl font-medium text-center whitespace-nowrap">
                {weeksCapacity} Weeks Capacity
              </div>
              <div className="w-full px-2 py-1">
                <CapacityBarIndicator isStatic={isStatic} />
              </div>
            </div>
          </div>

          {/* Donut */}
          <SimpleJobDonut
            size="sm"
            numerator={totalInput}
            denominator={totalLikelyJobs}
          />
        </div>
      </Card>
    </div>
  );
}

function formatNumber(n) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(n);
}
