import React, { useEffect } from "react";
import SearchBar from "../../SearchBar";
import $ from "jquery";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectFieldList({
  schema,
  allowNoField = false,
  onSelect = () => {},
}) {
  var keys = Object.keys(schema);

  const [searchText, setSearchText] = React.useState("");

  function handleSearchChange(e) {
    setSearchText(e.target.value.toLowerCase());
  }

  useEffect(() => {
    /**
     * Keyboard navigation for line items
     */
    $(".line-item").on("keydown", function (e) {
      if ($(this).is(":focus")) {
        if (e.key === "ArrowDown" || e.key === "ArrowRight") {
          $(this).nextAll(".line-item").first().trigger("focus");
        }
        if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
          $(this).prevAll(".line-item").first().trigger("focus");
        }
        if (e.key === "Enter") {
          $(this).trigger("click");
        }
      }
    });
  }, [searchText]);

  return (
    <div>
      <SearchBar onChange={handleSearchChange} />
      <div className="border-t mt-3">
        {allowNoField && (
          <LineItem
            k="null"
            v="No Field"
            searchText={searchText}
            onClick={onSelect}
          />
        )}
        {keys.map((key, index) => {
          return (
            <React.Fragment key={index}>
              <LineItem
                k={key}
                v={schema[key]}
                searchText={searchText}
                onClick={onSelect}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

function LineItem({ k, v, indent = 0, searchText = "", onClick = () => {} }) {
  // var handleClick = (path) => onClick([k, ...path]);

  function doesSearchMatch(text) {
    return text.toLowerCase().includes(searchText);
  }

  // Check if any subkeys (or their subkeys) match search text
  function checkSubKeys(subKeys, object) {
    var subKeysMatchSearch = false;
    subKeys.forEach((key) => {
      // If key is for an object, check its subkeys
      if (typeof object[key] === "object" && object[key] !== null) {
        subKeysMatchSearch =
          subKeysMatchSearch ||
          checkSubKeys(Object.keys(object[key]), object[key]);
      }

      // If key is for an array, check its subkeys
      if (Array.isArray(object[key]) && object[key].length > 0) {
        subKeysMatchSearch =
          subKeysMatchSearch ||
          checkSubKeys(Object.keys(object[key][0]), object[key][0]);
      }

      // If key is for value, check key
      if (doesSearchMatch(key)) {
        subKeysMatchSearch = true;
      }

      // Could short circuit here
    });

    return subKeysMatchSearch;
  }

  // Check v's type: Array, Object, Number, String, Date, etc.
  // If Array, map over it and return a LineItem for each element
  // If Object, return a LineItem for each key/value pair
  // If Number, String, Date, etc., return a LineItem with the key and value

  if (k === "null") {
    if (searchText !== "") return null;
    return (
      <LineItemRender
        k={k}
        t="null"
        indent={indent}
        onClick={() => onClick([{ key: "null", type: "null" }])}
      />
    );
  } else if (Array.isArray(v)) {
    // If no objects in array, say so
    if (v.length === 0) {
      if (searchText !== "") return null;

      return (
        <>
          <LineItemRender k={k} t="array" cantFocus indent={indent} />
          <LineItemRender
            k=""
            t="No objects in array"
            cantFocus
            indent={indent + 1}
          />
        </>
      );
    } else if (typeof v[0] !== "object") {
      if (!doesSearchMatch(k)) return null;

      return (
        <>
          <LineItemRender k={k} t="array" cantFocus indent={indent} />
          <LineItemRender
            onClick={(path) =>
              onClick([{ key: k, type: "arrayItem" }, ...path])
            }
            k="(array item)"
            t={typeof v[0]}
            indent={indent + 1}
          />
        </>
      );
    }

    // Get first object in array and use its keys
    var firstObject = v[0];
    var subKeys = Object.keys(firstObject);

    if (!checkSubKeys(subKeys, firstObject)) {
      return null;
    }

    return (
      <>
        <LineItemRender k={k} t="array" cantFocus indent={indent} />
        {subKeys.map((key, index) => {
          return (
            <React.Fragment key={index}>
              <LineItem
                k={key}
                v={firstObject[key]}
                indent={indent + 1}
                searchText={searchText}
                onClick={(path) =>
                  onClick([{ key: k, type: "arrayObj" }, ...path])
                }
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  if (typeof v === "object" && v !== null) {
    var subKeys = Object.keys(v);

    if (!checkSubKeys(subKeys, v)) {
      return null;
    }

    return (
      <>
        <LineItemRender k={k} t="object" cantFocus indent={indent} />
        {subKeys.map((key, index) => {
          return (
            <React.Fragment key={index}>
              <LineItem
                k={key}
                v={v[key]}
                indent={indent + 1}
                searchText={searchText}
                onClick={(path) => onClick([{ key: k, type: "obj" }, ...path])}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  }

  // Check if search text is in key
  if (!doesSearchMatch(k)) {
    return null;
  }

  var type = typeof v;
  if (!v) type = "null";

  return (
    <LineItemRender
      k={k}
      v={v}
      t={type}
      indent={indent}
      onClick={(path) => onClick([{ key: k, type: "field" }, ...path])}
    />
  );
}

function LineItemRender({
  k,
  v = "",
  t,
  indent = 0,
  cantFocus = false,
  onClick = () => {},
}) {
  return (
    <div
      tabIndex={cantFocus ? undefined : 0}
      className={classNames(
        "flex items-center gap-4 h-9 py-1 px-4.5 border-b text-sm text-gray-900 group focus:bg-primary-rose focus:outline-0",
        cantFocus ? "" : "line-item cursor-pointer hover:bg-gray-100"
      )}
      onClick={() => onClick([])}
    >
      <div
        className="font-medium group-focus:text-white"
        style={{ paddingLeft: indent * 36 + 12 }}
      >
        {k === "null" ? (
          "No Field"
        ) : (
          <>
            {k ? k + ": " : ""}
            <span className="text-gray-400 group-focus:text-white">[{t}]</span>
          </>
        )}
      </div>
      <div className="group-focus:text-white">{v}</div>
    </div>
  );
}
