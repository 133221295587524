import { useCallback } from 'react';
import UserManager from './UserManager'

// export const debouncedRequestCompanySearch = useCallback(debounce(requestCompanySearch, 500), []);

/**
 * Sort the connections by status and then by name
 * @param {array} connections - list of connections
 * @returns - a sorted list of connections, sorted by status and then by name
 */
export function sortConnections(connections) {
    // Check if connections is an array
    if (!Array.isArray(connections)) {
        return connections;
    }
    // sort connections by connection.status, possible values are ['Active', 'Pending Request', 'Incoming Request', 'Request Rejected']
    // sort in this order: 'Incoming Request', 'Pending Request', 'Active', 'Request Rejected'
    return connections.sort((a, b) => {
        let statusOrder = ['Incoming Request', 'Pending Request', 'Active', 'Request Rejected']
        return statusOrder.indexOf(a.status.value) - statusOrder.indexOf(b.status.value)
    });
}

/**
 * Search for companies based on the search string and company type
 * @param {string} searchString - the string to search for
 * @param {string} companyType - the type of company to search for
 * @param {function} setSearchResults - function to set the search results
 * @param {array} connectionRequestCompanies - list of companies that are already in the list about to be sent
 * @param {array} idsToExclude - list of ids to exclude from the search results
 */
export function requestCompanySearch(searchString, companyType, setSearchResults, connectionRequestCompanies = [], idsToExclude = []) {
    // make a request to the backend to search for companies
    // use the searchValue to search for companies
    // update the searchResults with the results
    UserManager.makeAuthenticatedRequest(
        '/api/company/company-search?companyType=' + companyType + '&searchString=' + searchString,
        'GET'
    )
        .then((res) => {
            if (res.data.status === "ok") {
                // exclude the companies that are already in the list
                let filteredResults = res.data.companies.filter((item) => !connectionRequestCompanies.some((i) => i._id === item._id))
                filteredResults = filteredResults.filter((item) => !idsToExclude.includes(item._id))
                setSearchResults(filteredResults);
            } else {
                console.log(res.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
}

/**
 * Add a company to the connectionRequestCompanies list and remove it from the search results
 * @param {object} company - the company to add to the connectionRequestCompanies list
 * @param {array} searchResults - the search results list
 * @param {function} setSearchResults - function to set the search results
 * @param {array} connectionRequestCompanies - list of companies that are already in the list about to be sent
 * @param {function} setConnectionRequestCompanies - function to set the connectionRequestCompanies list
 */
export function addCompanySearchResult(company, searchResults, setSearchResults, connectionRequestCompanies = [], setConnectionRequestCompanies = () => { }) {
    if (!company) {
        return
    }
    // add the company to the connectionRequestCompanies list, if its not already there
    // use the id to check if the company is already in the list
    if (!connectionRequestCompanies.some((item) => item._id === company._id)) {
        setConnectionRequestCompanies([...connectionRequestCompanies, company])
    }
    // remove the company from the search results
    setSearchResults(searchResults.filter((item) => item._id !== company._id))
}

/**
 * Send a connection request to a company
 * @param {string} companyId - the id of the company to send a connection request to
 */
async function sendConnectionRequest(companyId) {
    await UserManager.makeAuthenticatedRequest(
        '/api/company/connect-request',
        'POST',
        {
            companyId: companyId
        }
    )
        .then((res) => {
            if (res.data.status === "ok") {
            } else {
                console.log(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        });
}

/**
 * Send an invite to a user to create a company
 * @param {string} companyType - the type of company to create
 * @param {string} companyName - the name of the company to create
 * @param {string} email - the email of the user to invite
 */
async function sendCreateCompanyInvite(companyType, companyName, email) {
    await UserManager.makeAuthenticatedRequest(
        '/api/company/create-company-invite',
        'POST',
        {
            companyType: companyType,
            companyName: companyName,
            email: email
        }
    )
        .then((res) => {
            if (res.data.status === "ok") {
            } else {
                console.log(res.data)
            }
        })
        .catch((err) => {
            console.log(err)
        });
}

/**
 * Send invites to users and connection requests to companies
 * @param {array} connectionRequestCompanies - list of companies to send connection requests to
 * @param {array} invites - list of invites to send
 * @param {object} user - the user sending the invites
 */
export function sendInvites(connectionRequestCompanies, invites, user) {
    // for each connectionRequestCompany in the connectionRequestCompanies list, send a connection request
    connectionRequestCompanies.forEach((connectionRequestCompany) => {
        sendConnectionRequest(connectionRequestCompany._id)
    })

    // for each invite in the invites list, send an invite
    invites.forEach((invite) => {
        let inviteCompanyType = user.company.type === 'distributor' ? 'installer' : 'distributor'
        sendCreateCompanyInvite(
            inviteCompanyType,
            invite.companyName,
            invite.email
        )
    })
}