import React, { useState, useEffect } from "react";
import Modal from "../../Modal";
import Input from "../../input/Input";
import Button from "../../input/Button";
import Dropdown from "../../input/Dropdown";

export default function AddMaterialModal({
  open: ex_open,
  setOpen: ex_setOpen,
  groups,
  handleNewMaterial = () => {},
}) {
  const [open, setOpen] = useState(ex_open);
  const [material, setMaterial] = useState({
    name: "",
    description: "",
    customFields: [],
    group: null,
    brand: "",
    model: "",
  });

  useEffect(() => {
    if (ex_open !== open) {
      setOpen(ex_open);
      setMaterial({
        name: "",
        description: "",
        customFields: [],
        group: null,
        brand: "",
        model: "",
      });
    }
  }, [ex_open]);

  function handleAdd() {
    if (validate(material)) {
      handleNewMaterial(material);
    }
  }

  return (
    <Modal open={open} setOpen={ex_setOpen}>
      <div className="">
        {/* Content */}
        <div className="text-gray-900 font-medium text-sm flex flex-col gap-6">
          {/* Header */}
          <div>
            <div className="font-semibold text-base">Material</div>
            <div className="text-gray-500 font-normal">
              Add Material Group and Material to add jobs.
            </div>
          </div>

          {/* Name */}
          <Input
            value={material.name}
            label="Material Name"
            placeholder="V Sun 420"
            type="text"
            onChange={(value) => {
              setMaterial({ ...material, name: value });
            }}
          />

          {/* Group */}
          <div>
            <Dropdown
              label="Material Group"
              justifyLeft
              wide
              highlightOnEmpty
              options={[
                groups.map((g) => ({
                  label: g.name,
                  value: g._id,
                })),
              ]}
              selectedValue={material.group}
              onSelected={(item) => {
                var _id = item.value;
                var group = groups.find((g) => g._id == _id);
                setMaterial({
                  ...material,
                  group: _id,
                  customFields: group.customFields.map((f) => ({
                    name: f.name,
                    value: null,
                  })),
                });
              }}
            />
          </div>

          {/* Line */}
          <div className="h-0 w-full border-t"></div>

          {/* Brand, Model, Description */}
          <div className="flex flex-wrap">
            <div className="basis-1/2 pr-2">
              <Input
                value={material.brand}
                label="Brand"
                placeholder="V Sun"
                type="text"
                highlightOnEmpty
                onChange={(value) => {
                  setMaterial({ ...material, brand: value });
                }}
              />
            </div>
            <div className="basis-1/2 pl-2">
              <Input
                value={material.model}
                label="Model"
                placeholder="123-420-VSUN"
                type="text"
                highlightOnEmpty
                onChange={(value) => {
                  setMaterial({ ...material, model: value });
                }}
              />
            </div>
            <div className="basis-full pt-6">
              <Input
                value={material.description}
                label="Description"
                type="textarea"
                onChange={(value) => {
                  setMaterial({ ...material, description: value });
                }}
              />
            </div>
          </div>

          {/* Line */}
          <div className="h-0 w-full border-t"></div>

          {/* Custom Fields */}
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <div>Custom Fields</div>
              <div>
                {material.group && (
                  <div className="text-gray-500 font-normal">
                    For{" "}
                    <span className="text-primary-green-600">
                      {groups.find((g) => g._id === material.group).name}
                    </span>{" "}
                    materials
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-wrap gap-x-3 gap-y-6">
              {material.customFields.length > 0 ? (
                material.customFields.map((field, index) => (
                  <div key={index} className="flex gap-2 items-center">
                    <div>{field.name}:</div>
                    <div>
                      <Input
                        value={field.value || ""}
                        onChange={(value) => {
                          var newCustomFields = [...material.customFields];
                          newCustomFields[index].value = value;
                          setMaterial({
                            ...material,
                            customFields: newCustomFields,
                          });
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-gray-500 font-normal">
                  No custom fields.
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Footer */}
        <div className="flex justify-end">
          <Button
            variant="primary-green"
            className="mt-6"
            onClick={handleAdd}
            disabled={!validate(material)}
          >
            Add Material
          </Button>

          <Button
            variant="secondary"
            className="mt-6 ml-4"
            onClick={() => {
              ex_setOpen(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function validate(material) {
  // Must have brand, model, and group
  return material.brand && material.model && material.group;
}
