import React, { useEffect, useState } from "react";

/**
 * Tracks user inputs and returns the last `amount` inputs.
 * 
 * E.g. `useLastInputs(3)` returns the last 3 inputs such as `["a", "b", "c", "ArrowUp"]`
 * 
 * @param amount - number of inputs to track
 * @returns - array of the last `amount` inputs
 */
export default function useLastInputs(amount: number): string[] {
  const [inputs, setInputs] = useState<string[]>([]);

  /**
   * Track the keydown event in inputs.
   *
   * @param e - KeyboardEvent
   */
  function handleKeyDown(e: KeyboardEvent) {
    const newInputs = [...inputs, e.key];
    if (newInputs.length > amount) {
      newInputs.shift();
    }
    setInputs(newInputs);
  }

  // Add event listener on mount, remove on unmount
  // Dependency array includes inputs to update on input change so handleKeyDown can use the latest inputs.
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputs]);

  return inputs;
}
