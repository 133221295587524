import { BoltIcon } from "@heroicons/react/20/solid";
import React from "react";
import Button from "../../input/Button";
import moment from "moment";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MarketInfoCard({ market }) {
  // Generate last 6 mo bar chart
  var last6MonthsBars = <></>;
  if (market.stats.last6Months) {
    // Get largest
    var largest = market.stats.last6Months.reduce((a, b) => Math.max(a, b));

    last6MonthsBars = market.stats.last6Months.map((month, i) => {
      var percent = (100.0 * month) / largest;

      return (
        <div
          key={i}
          className={`w-2 rounded bg-primary-rose`}
          style={{ height: `${percent}%`, minHeight: "4px" }}
        ></div>
      );
    });
  }

  return (
    <div className="shadow rounded-lg bg-white flex flex-col">
      {/* Header */}
      <Link
        to={`${market._id}?edit=false`}
        className="border-b rounded-t-lg py-5 px-6 bg-gray-50 flex items-center justify-between gap-4 hover:bg-gray-200 hover:cursor-pointer"
      >
        {/* Title */}
        <div className="flex flex-col text-base">
          <div className="font-semibold text-gray-900">{market.name}</div>
          <div className="font-normal text-gray-500 capitalize">
            {formatWatts(market.stats.wattageYTD || 0)} YTD
          </div>
        </div>

        {/* Active Icon */}
        <div>
          <BoltIcon
            className={classNames(
              "w-5 h-5 mx-10",
              market.status === "active"
                ? "fill-primary-green"
                : "fill-gray-400"
            )}
          />
        </div>

        {/* Button */}
        <div>
          <Link to={`${market._id}?edit=true`}>
            <Button
              variant="secondary"
            >
              Edit
            </Button>
          </Link>
        </div>
      </Link>

      {/* Total Installs */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">
          Total Installs
        </div>
        <div className="font-normal text-gray-900 basis-1/2">
          {formatNumber(market.stats.totalInstalls || 0)}
        </div>
      </div>

      {/* Total Pipeline */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">
          Total Pipeline
        </div>
        <div className="font-normal text-gray-900 basis-1/2">
          {formatNumber(market.stats.totalPipeline || 0)}
        </div>
      </div>

      {/* Created Date */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">Created Date</div>
        <div className="font-normal text-gray-900 basis-1/2">
          {market.createdAt && formatDate(market.createdAt)}
        </div>
      </div>

      {/* Last 6 Months */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">Last 6 Months</div>
        <div className="font-normal text-gray-900 basis-1/2 flex gap-1 items-end -my-2">
          {last6MonthsBars}
        </div>
      </div>
    </div>
  );
}

function formatDate(date) {
  if (!date) return null;

  return moment(date).format("MM / DD / YY");
}

function formatNumber(number) {
  return number.toLocaleString();
}

function formatWatts(watts) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(watts) + "W";
}
