import React, { Component } from 'react'
import FloCard from '../components/deprecated/FloCard'
import Spinner from '../components/Spinner'
import { Row, Col } from 'react-bootstrap'
import RightBar from '../components/deprecated/RightBar'
import '../styles/IssuesPage.css'

export default class IssuesPage extends Component {
  render() {
    return (
      <>
        <Col className="pt-3 pb-3">

          {/* All Issues */}
          <Row className='pb-3'>
            <div className='flo-title'>Issues</div>

            <div className='pt-3'>
              <Row>

                {/* Issue Count by Week */}
                <Col xs md={8}>
                  <FloCard>
                    <FloCard.Title>Issue Count By Week</FloCard.Title>
                    <FloCard.Body>
                      <Spinner />
                    </FloCard.Body>
                  </FloCard>
                </Col>

                {/* Next 20 Weeks */}
                <Col xs md={4}>
                  <FloCard>
                    <FloCard.Title>Next 20 Weeks</FloCard.Title>
                    <FloCard.Body>
                      <Spinner />
                    </FloCard.Body>
                  </FloCard>
                </Col>
              </Row>
            </div>
          </Row>

          {/* Issues in Market */}
          <Row>
            <div className='flo-title'>Issues in <span className='flo-highlight'>Market Name</span></div>

            <div className='pt-3'>
              <Row>

                {/* Left Column */}
                <Col>
                  <Row>

                    {/* Market Summary */}
                    <Col xs={12} className="mb-3">
                      <FloCard>
                        <FloCard.Title>Market Summary</FloCard.Title>
                        <FloCard.Body>
                          <Spinner />
                        </FloCard.Body>
                      </FloCard>
                    </Col>

                    {/* Issue List */}
                    <Col xs={12} className="mb-3">
                      <FloCard>
                        <FloCard.Title>Issue List</FloCard.Title>
                        <FloCard.Body>
                          <Spinner />
                        </FloCard.Body>
                      </FloCard>
                    </Col>
                  </Row>
                </Col>

                {/* Right Column */}
                <Col>
                  <Row>

                    {/* Labor Forecast */}
                    <Col xs={12} className="mb-3">
                      <FloCard>
                        <FloCard.Title>Labor Forecast</FloCard.Title>
                        <FloCard.Body>
                          <Spinner />
                        </FloCard.Body>
                      </FloCard>
                    </Col>

                    {/* Material Forecast */}
                    <Col xs={12} className="mb-3">
                      <FloCard>
                        <FloCard.Title>Material Forecast</FloCard.Title>
                        <FloCard.Body>
                          <Spinner />
                        </FloCard.Body>
                      </FloCard>
                    </Col>

                    {/* Issue Details */}
                    <Col xs={12} className="mb-3">
                      <FloCard>
                        <FloCard.Title>Issue Details</FloCard.Title>
                        <FloCard.Body>
                          <Spinner />
                        </FloCard.Body>
                      </FloCard>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Row>
        </Col>

        {/* Side Bar */}
        <RightBar>
          <div className='flo-title'>Coming Soon</div>

          <div className="flo-header pt-3">This Week</div>
          <div className='pt-2'>
            <FloCard variant="good">
              <FloCard.Body>
                <div className='center'>
                  Filler
                </div>
              </FloCard.Body>
            </FloCard>
          </div>

          <div className="flo-header pt-3">Next Week</div>
          <div className='pt-2'>
            <FloCard variant="bad">
              <FloCard.Body>
                <div className='center'>
                  Filler
                </div>
              </FloCard.Body>
            </FloCard>
          </div>
          <div className='pt-2'>
            <FloCard variant="bad">
              <FloCard.Body>
                <div className='center'>
                  Filler
                </div>
              </FloCard.Body>
            </FloCard>
          </div>
        </RightBar>
      </>
    )
  }
}
