import React, { useState, useEffect } from 'react'
import InputTable from '../../components/input/InputTable'
import UserManager from '../../tools/UserManager'
import SearchBar from '../../components/SearchBar'
import Card from '../../components/Card'
import Button from '../../components/input/Button'
import Input from '../../components/input/Input'
import Dropdown from '../../components/input/Dropdown'
import Modal from '../../components/Modal'
import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  getTableColumns,
  getLocations,
  deleteLocation,
  updateLocation,
  addLocation,
  sortLocations,
  validateZip,
  stateOptions
} from '../../tools/locationManagement'


/**
 * Locations Management Page for admins to manage locations
 * @returns - a table of locations that the company has created, allowing the user to add, edit, and delete locations
 */
export default function ManageLocationsPage() {

  // get current logged in user
  const [currentUser, setCurrentUser] = useState({})

  // locations state
  const [locations, setLocations] = useState([])

  // error state
  const [error, setError] = useState('')

  // modal error state
  const [modalError, setModalError] = useState('')

  // state for add location modal
  const [showAddLocationModal, setShowAddLocationModal] = useState(false)

  // new location states
  const [newLocationName, setNewLocationName] = useState('')
  const [newLocationAddress1, setNewLocationAddress1] = useState('')
  const [newLocationAddress2, setNewLocationAddress2] = useState('')
  const [newLocationCity, setNewLocationCity] = useState('')
  const [newLocationState, setNewLocationState] = useState('')
  const [newLocationZip, setNewLocationZip] = useState('')

  // get current user on page load
  useEffect(() => {
    UserManager.getUser()
      .then((user) => {
        setCurrentUser(user.userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // on page load, get all locations for the company and add them to the table
  useEffect(() => {
    getLocations(setError).then((locations) => {
      // use locations.map to add each location to the table
      setLocations(locations.map(location => {
        // if the location already exists in the table, don't add it again
        if (locations.some(prevLocation => prevLocation.id === location._id)) {
          return location
        }
        return {
          id: location._id,
          name: { value: location.name, className: '' },
          address: { value: location.address.line1, className: '' },
          address2: { value: location.address.line2, className: '' },
          city: { value: location.address.city, className: '' },
          state: { value: location.address.state, className: '' },
          zip: { value: location.address.postalCode, className: '' },
          rowStatus: 'readonly',
          menuOptions: [
            { label: 'Delete', value: 'deleteLocation', onSelected: (item) => { deleteLocation(item, setError, setLocations) } },
            { label: 'Edit', value: 'edit' }
          ]
        }
      }))
    })
  }, [])

  // on 'locations' change:
  // - enforce unique location names
  // - make sure menuOptions exist for each location
  useEffect(() => {
    // if a location with duplicate name exists, set error
    const locationNames = locations.map(location => location.name)
    const uniqueLocationNames = new Set(locationNames)
    if (locationNames.length !== uniqueLocationNames.size) {
      setError('Location names must be unique.')
    } else {
      setError('')
    }
  }, [locations])


  /**
   * Check a location name for duplicates in the locations array
   * @param {string} name - the name of the location to check
   * @returns - true if the name is a duplicate, false otherwise
   */
  function checkDuplicateLocationName(name) {
    if (locations.some(location => location.name.value === name)) {
      setModalError('Location names must be unique.')
      return true
    }
    return false
  }


  /**
   * Clear all new location fields
   */
  function clearNewLocationFields() {
    setNewLocationName('')
    setNewLocationAddress1('')
    setNewLocationAddress2('')
    setNewLocationCity('')
    setNewLocationState('')
    setNewLocationZip('')
  }

  /**
   * Check if all required fields are filled in the add location modal
   * @returns - true if all required fields are filled, false otherwise
   */
  function requiredFieldsFilled() {
    if (!(newLocationName && newLocationAddress1 && newLocationCity && newLocationState && newLocationZip)) {
      setModalError('Missing required fields.')
      return false
    }
    return true
  }

  /**
   * Add a new location to the locations array, and to the database
   * @returns - true if the location was added successfully, false otherwise
   */
  async function addModalLocation() {
    var success = false

    var locationData = {
      name: { value: newLocationName, className: '' },
      address: { value: newLocationAddress1, className: '' },
      address2: { value: newLocationAddress2, className: '' },
      city: { value: newLocationCity, className: '' },
      state: { value: newLocationState, className: '' },
      zip: { value: newLocationZip, className: '' },
    }

    var newLocation = await addLocation(locationData, setModalError)
    if (newLocation) {
      // add new location to locations with newLocation as the id
      setLocations((prevLocations) => {
        return sortLocations([...prevLocations, {
          ...locationData,
          id: newLocation,
          rowStatus: 'readonly',
          menuOptions: [
            { label: 'Delete', value: 'deleteLocation', onSelected: (item) => { deleteLocation(item, setError, setLocations) } },
            { label: 'Edit', value: 'edit' }
          ]
        }])
      })

      setError('')
      setModalError('')
      clearNewLocationFields()
      success = true
    }

    return success
  }


  return (
    <div>
      <Modal
        open={showAddLocationModal}
        setOpen={(prop) => {
          clearNewLocationFields()
          setError('')
          setModalError('')
          setShowAddLocationModal(prop)
        }}
      >
        <div className='flex flex-col items-center justify-center'>
          <div className='flex flex-row items-center justify-end w-full gap-3'>
            {modalError && <div className='text-red-500'>{modalError}</div>}
            <XMarkIcon
              className='w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-300'
              onClick={() => {
                // clear all new location fields
                clearNewLocationFields()
                setError('')
                setModalError('')
                setShowAddLocationModal(false)
              }}
            />
          </div>

          <div className='flex flex-col w-full gap-6 mb-6'>
            <div className='flex flex-col items-start justify-center w-full'>
              <div className='text-base font-semibold leading-6 text-left align-middle'>Add Location</div>
              <div className='text-sm font-normal leading-5 text-left text-gray-500 align-middle'>Add a warehouse to manage demand by location</div>
            </div>
            <div>
              <Input
                type='text'
                label='Warehouse Name'
                placeholder='Name'
                value={newLocationName}
                onChange={(value) => setNewLocationName(value)}
              />
            </div>
            <div className='flex flex-row w-full gap-2'>
              <div className='w-full'>
                <Input
                  label='Address Line 1'
                  placeholder='Address Line 1'
                  value={newLocationAddress1}
                  onChange={(value) => setNewLocationAddress1(value)}
                />
              </div>
              <div className='w-full'>
                <Input
                  label='Address Line 2'
                  placeholder='Address Line 2'
                  value={newLocationAddress2}
                  onChange={(value) => setNewLocationAddress2(value)}
                />
              </div>
            </div>
            {/* break line */}
            <div className='flex flex-row items-center justify-center w-full gap-2'>
              <div className='w-full'>
                <Input
                  label='City'
                  placeholder='City'
                  value={newLocationCity}
                  onChange={(value) => setNewLocationCity(value)}
                />
              </div>
              <div className='w-full'>
                <Dropdown
                  label='State'
                  options={[stateOptions]}
                  placeholder='State'
                  selectedValue={newLocationState}
                  wide
                  onSelected={(item) => {
                    console.log(item)
                    setNewLocationState(item.value)
                  }}
                />
              </div>
              <div className='w-full'>
                <Input
                  label='Zip'
                  placeholder='Zip'
                  value={newLocationZip}
                  onChange={(value) => setNewLocationZip(value)}
                />
              </div>
            </div>
          </div>

          <div className='flex flex-row items-center justify-end w-full gap-2'>
            <Button
              variant='primary'
              onClick={() => {
                if (!checkDuplicateLocationName(newLocationName) && requiredFieldsFilled() && validateZip(newLocationZip, setModalError)) {
                  if (addModalLocation()) {
                    setShowAddLocationModal(false)
                  }
                }
              }}
            >
              Save
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                if (!checkDuplicateLocationName(newLocationName) && requiredFieldsFilled() && validateZip(newLocationZip, setModalError)) {
                  addModalLocation()
                }
              }}
            >
              Save and New
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                // clear all new location fields
                clearNewLocationFields()
                setError('')
                setShowAddLocationModal(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      <div className='flex flex-col gap-2 mt-4'>
        <Card title="Manage Locations">
          <div className='flex flex-col'>
            <div className='flex flex-row items-center justify-between'>
              {/* keep this here, will uncomment when searching is implemented */}
              {/* <div className='flex flex-row items-center gap-3'>
              <div className='w-64'>
                <SearchBar />
              </div>
              <div>
                {error && <div className='text-red-500'>{error}</div>}
              </div>
            </div> */}
              {/* keep this here, will uncomment when filtering and sorting is implemented */}
              {/* <div className='flex flex-row items-center gap-4'>
                <FilterSort />
            </div> */}
            </div>
            <div>
              <InputTable
                tableData={locations}
                setTableData={setLocations}
                columns={getTableColumns(setError)}
                addButtonText={"Add Location"}
                addButtonClicked={() => { setShowAddLocationModal(true) }}
                outerAddButton={currentUser.company?.role === 'admin'}
                innerAddButton={currentUser.company?.role === 'admin'}
                height={'calc(100vh - 195px)'}
                useOnBlur={false}
                onSaveRow={(newRow) => {
                  if (newRow.rowStatus === 'new') {
                    return addLocation(newRow, setError)
                  } else if (newRow.rowStatus === 'editing') {
                    return updateLocation(newRow, setError)
                  }
                }}
                error={error}
                setError={setError}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
