import React from "react";

const rowExample = {
  id: 1,
  label: "Label",
  color: "bg-primary-rose",
  barVal: 50,
  colVals: [1, 2, 3],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Bar({ percent, fill = "bg-gray-200" }) {
  return <div className={`${fill} h-5 rounded-md`} style={{ width: percent + "%"}}></div>;
}

export default function BarChartTable({
  barLbl = "Last 90 Days",
  tableLbls = ["L30", "L7", "+/-"],
  rows = [rowExample, { ...rowExample, id: 2 }, { ...rowExample, id: 3 }],
  selectedRowId = -1,
  maxBarVal,
  lastColColorFunc = (val) => "",
}) {
  if (!maxBarVal) maxBarVal = Math.max(...rows.map((r) => r.barVal));

  return (
    <div className="grid grid-cols-12 text-lg leading-7 text-gray-900 font-semibold">
      <div className="col-span-6 font-medium text-gray-500 py-1 mb-1">
        {barLbl}
      </div>
      <div className="col-span-2 pb-1 border-b font-medium text-gray-500 py-1 mb-1">
        {tableLbls[0]}
      </div>
      <div className="col-span-2 pb-1 border-b font-medium text-gray-500 py-1 mb-1">
        {tableLbls[1]}
      </div>
      <div className="col-span-2 pb-1 border-b font-medium text-gray-500 py-1 mb-1">
        {tableLbls[2]}
      </div>
      {rows.map((row, i) => (
        <React.Fragment key={i}>
          <div className="col-span-1 flex flex-col justify-center">
            {row.label}
          </div>
          <div className="col-span-5 flex flex-col justify-center px-3">
            <Bar
              percent={(row.barVal / maxBarVal) * 100}
              fill={
                selectedRowId !== null
                  ? selectedRowId === row.id
                    ? row.color
                    : "bg-gray-300"
                  : "bg-gray-500"
              }
            />
          </div>
          <div className="col-span-2 py-1">{row.colVals[0] ?? "-"}</div>
          <div className="col-span-2 py-1">{row.colVals[1] ?? "-"}</div>
          <div
            className={classNames(
              "col-span-2 py-1",
              row.colVals[2] ? lastColColorFunc(row.colVals[2]) : ""
            )}
          >
            {row.colVals[2] ?? "-"}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
