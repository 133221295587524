import Card from "../Card";

export default function RadioCard({ label, description, icon, checked, checkable=true }) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    var checkBox = checkable ? (
        // if checked, display filled in dot, else display empty dot
        checked ? (
            <div className="flex items-center justify-center w-6 h-6 bg-white border-2 rounded-full border-primary-rose dark:bg-gray-700">
                <div className="w-3 h-3 rounded-full bg-primary-rose" />
            </div>
        ) : (
            <div className="w-6 h-6 bg-white border-2 border-gray-200 rounded-full dark:bg-gray-700 dark:border-gray-500" />
        )
    ) : null;


    return (
        <>
            <Card
                className={classNames(
                    "flex-col items-stretch justify-start w-80 min-h-[240px] dark:!bg-gray-700 bg-white h-full pb-12 hidden md:flex",
                    checkable ? "dark:hover:!bg-gray-600 hover:bg-gray-100 cursor-pointer" : ""
                )}
            >
                <div className="flex flex-row justify-end">
                    {checkBox}
                </div>


                <div className="flex mx-2.5 py-4 justify-center items-center">
                    {icon}
                </div>

                <div className="flex flex-col items-center justify-center px-4">
                    <div className="">
                        <div className="text-xl font-semibold leading-7 text-gray-900 align-middle dark:text-gray-50">
                            {label}
                        </div>
                    </div>

                    <div className="flex mx-1.5 content-center justify-center">
                        <div className="text-base font-normal leading-6 text-gray-500 align-middle text-wrap dark:text-gray-200">
                            {description}
                        </div>
                    </div>

                </div>
            </Card>

            <Card
                className={classNames(
                    "flex flex-row items-stretch justify-start w-84 min-h-[86px] h-full md:hidden !p-2 gap-3 dark:!bg-gray-700",
                    checkable ? "hover:bg-gray-100 dark:hover:!bg-gray-600 cursor-pointer" : ""
                )}
            >
                <div className="flex flex-col justify-center p-3">
                    {checkBox}
                </div>

                <div className="flex flex-row gap-2">
                    <div className="flex items-center justify-center py-4">
                        {icon}
                    </div>

                    <div className="flex flex-col items-start justify-center">
                        <div className="">
                            <div className="font-semibold leading-7 text-gray-900 text-l">
                                {label}
                            </div>
                        </div>

                        <div className="flex content-center justify-center">
                            <div className="text-sm font-normal leading-5 text-gray-500 align-left text-wrap">
                                {description}
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}