import React, { useEffect, useState } from "react";
import Modal from "../../Modal";
import Input from "../../input/Input";
import Button from "../../input/Button";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import $ from "jquery";

export default function AddMaterialGroupModal({
  open: ex_open,
  setOpen: ex_setOpen,
  handleNewMaterialGroup = () => {},
}) {
  const [open, setOpen] = useState(ex_open);
  const [materialGroup, setMaterialGroup] = useState({
    name: "",
    description: "",
    customFields: [],
  });

  useEffect(() => {
    if (ex_open !== open) {
      setOpen(ex_open);
      setMaterialGroup({
        name: "",
        description: "",
        customFields: [],
      });
    }
  }, [ex_open]);

  function handleAdd() {
    if (validate(materialGroup)) {
      handleNewMaterialGroup(materialGroup);
    }
  }

  return (
    <Modal open={open} setOpen={ex_setOpen}>
      <div id="add-material-group-modal">
        {/* Content */}
        <div className="flex flex-col gap-6 text-sm font-medium text-gray-900">
          {/* Header */}
          <div>
            <div className="text-base font-semibold">Material Group</div>
            <div className="font-normal text-gray-500">
              Add a Material Group to categorize Materials.
            </div>
          </div>

          {/* Name */}
          <div>
            <Input
              label="Material Group Name"
              placeholder="Panels"
              type="text"
              value={materialGroup.name}
              highlightOnEmpty
              onChange={(value) => {
                setMaterialGroup({ ...materialGroup, name: value });
              }}
            />
          </div>

          {/* Description */}
          <div>
            <Input
              label="Material Group Description"
              placeholder="The panels that make up the solar array."
              type="textarea"
              value={materialGroup.description}
              onChange={(value) => {
                setMaterialGroup({ ...materialGroup, description: value });
              }}
            />
          </div>

          {/* Line */}
          <div className="w-full h-0 border-t"></div>

          {/* Custom Fields */}
          <div>
            <div className="text-base font-semibold">Custom Fields</div>
            <div className="font-normal text-gray-500">
              Add custom fields to track additional information about Materials.
            </div>

            {/* Custom Fields List */}
            <div className="flex flex-col gap-2 mt-4">
              {materialGroup.customFields.map((field, index) => (
                <div key={index} className="flex flex-row items-center gap-2">
                  <Input
                    placeholder=""
                    type="text"
                    value={field.name}
                    highlightOnEmpty
                    onChange={(value) => {
                      var newCustomFields = [...materialGroup.customFields];
                      newCustomFields[index].name = value;
                      setMaterialGroup({
                        ...materialGroup,
                        customFields: newCustomFields,
                      });
                    }}
                  />

                  {/* Remove Custom Field */}
                  <MinusCircleIcon
                    onClick={() => {
                      var newCustomFields = [...materialGroup.customFields];
                      newCustomFields.splice(index, 1);
                      setMaterialGroup({
                        ...materialGroup,
                        customFields: newCustomFields,
                      });
                    }}
                    className="w-5 h-5 cursor-pointer text-primary-rose"
                  />
                </div>
              ))}

              {/* Add Custom Field */}
              <div className="pt-2">
                <Button
                  variant="primary-green"
                  onClick={() => {
                    setMaterialGroup({
                      ...materialGroup,
                      customFields: [
                        ...materialGroup.customFields,
                        { name: "" },
                      ],
                    });
                    

                  // Focus on last input. Need to wait for DOM to update. Might be a better way to do this.
                  setTimeout(() => {
                    $("#add-material-group-modal input:last-child").trigger('focus');
                  }, 0);
                  }
                }
                >
                  Add Custom Field
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end gap-4 mt-6">
          <Button variant="primary-green" onClick={handleAdd} disabled={!validate(materialGroup)}>
            Add Material Group
          </Button>

          <Button
            variant="secondary"
            onClick={() => {
              ex_setOpen(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function validate(group) {
  // Has name
  if (!group.name) {
    return false;
  }

  // Each field has a name
  for (var i = 0; i < group.customFields.length; i++) {
    if (!group.customFields[i].name) {
      return false;
    }
  }

  // Has custom fields with unique names
  var customFieldNames = group.customFields.map((f) => f.name);
  if (customFieldNames.length !== new Set(customFieldNames).size) {
    return false;
  }

  return true;
}
