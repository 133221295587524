import React, { useContext, useState, useEffect } from "react";
import Modal from "../Modal";
import Button from "../input/Button";
import Dropdown from "../input/Dropdown";
import { UserIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import Input from "../input/Input";
import MultiSelectListbox from "../input/MultiSelectListbox.tsx";
import MaterialForecastContext from "../../contexts/forecast/material/MaterialForecastContext";
import { useOutletContext } from "react-router-dom";

import Forecast from "../../pages/Forecast/classes/Forecast";

import pluralize from "pluralize";
import moment from "moment";
import UserManager from "../../tools/UserManager";
import Alert from "../feedback/Alert";


const sampleDistributors = [
  {
    companyType: "distributor",
    connectionDate: "2024-05-23T18:28:40.573Z",
    id: "664e4b8103b4d115fc6596ba",
    name: "Shane Test Distributor",
    status: "active",
  },
  {
    companyType: "distributor",
    connectionDate: "2024-05-30T16:00:42.051Z",
    id: "66589d2debb6afd6be1c93be",
    name: "Final Mile Solar",
    status: "active",
  },
  {
    companyType: "distributor",
    connectionDate: "2024-05-23T18:28:40.573Z",
    id: "664e4b8103b4d115fc6596bb",
    name: "Shane Test Distributor 2",
    status: "active",
  },
  {
    companyType: "distributor",
    connectionDate: "2024-05-30T16:00:42.051Z",
    id: "66589d2debb6afd6be1c93bb",
    name: "Final Mile Solar 2",
    status: "active",
  }
]


/**
 * Modal for submitting forecast.
 * Has an input for the review date and a button to submit the forecast.
 * Submits the current forecast for the selected market to the distributor.
 * (Sharing with distributor is not implemented yet)
 * 
 * Used on the Forecast page.
 */
export default function SubmitForecastModal({
  open,
  setOpen,
  contextValue = {},
}) {
  /**
   * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
   */
  const {
    selectedMarket,
    rawForecastData,
    forecastData,
    onJobCountInput,
    materials,
    materialGroups,
    lastForecastDate,
  } = contextValue;

  const [reviewDate, setReviewDate] = useState("");

  // distributors state
  const [distributors, setDistributors] = useState([])

  // selected distributor ids for the multi select listbox
  const [selectedDistributorIds, setSelectedDistributorIds] = useState([])

  const [alert, setAlert] = useState({
    showing: false,
    title: "",
    setShowing: () => { },
    severity: "good",
  });

  const setAlertTo = (severity, title) => {
    setAlert({
      ...alert,
      showing: true,
      severity,
      title,
      setShowing: (v) => setAlert({ ...alert, showing: v }),
    });
  };

  // Const for total jobs text
  const sumInput = forecastData?.getTotalInput();
  const lastWeekWithInput = forecastData?.weeks.reduce((acc, week, index) => {
    // If there is no input for this week, return the last week with input
    return week.inputCount > 0 ? index + 1 : acc;
  }, 0);

  // Const for material forecasted text
  const material = forecastData?.weeks.reduce((acc, week) => {
    for (let group of materialGroups) {
      if (!(group._id in acc)) {
        acc[group._id] = 0;
      }

      acc[group._id] += week.materials[group._id]?.total ?? 0;
    }
    return acc;
  }, {});

  // For date input
  const today = moment().format("YYYY-MM-DD");

  /**
   * on page load, get all connected distributors.
   */
  useEffect(() => {
    getDistributors()
  }, [])

  /**
   * Get all connected distributors.
   */
  function getDistributors() {
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list",
      "GET"
    ).then((res) => {
      setDistributors(
        res.data.connections.map((connection) => {
          if (connection.status === "active" || connection.status === "pending_request") {
            return connection;
          }
        }).filter((connection) => connection)
      );
    });
  }

  /**
   * Submit the forecast to the distributor.
   * This sends a POST request to the /api/forecast/submit endpoint.
   * Updates alert state based on the response.
   */
  async function handleSubmit() {
    try {
      var res = await UserManager.makeAuthenticatedRequest(
        "/api/forecast/submit",
        "POST",
        {
          marketId: selectedMarket._id,
          requestedReviewDate: reviewDate,
          distributorIds: selectedDistributorIds,
        }
      );

      if (res.data.status === "ok") {
        setAlertTo("good", "Forecast Submitted");
      } else {
        setAlertTo("bad", "Error Submitting Forecast");
        console.error(res.data);
      }
    } catch (error) {
      setAlertTo("bad", "Error Submitting Forecast");
      console.error(error);
    }

    setSelectedDistributorIds([]);
    setOpen(false);
  }

  function close() {
    setSelectedDistributorIds([]);
    setOpen(false);
    setReviewDate("");
  }

  /**
   * Handle distributor selection.
   * @param {string[]} distributorIds
   */
  function handleDistributorSelected(distributorIds) {
    setSelectedDistributorIds(distributorIds)
  }


  // Generate distributors options
  var distributorsOptions = distributors.map((d) => {
    return {
      label: d.name,
      value: d.id,
    };
  })


  return (
    <>
      <Alert {...alert} />
      <Modal open={open} setOpen={close}>
        <div className="flex flex-col">
          {/* Content */}
          <div className="flex flex-col divide-y">
            {/* Top Section */}
            <div className="flex flex-col gap-6 pb-6">
              <div className="flex flex-col">
                <div className="text-base font-semibold">Submit Forecast</div>
                <div className="text-sm text-gray-500">
                  Save a snapshot of your current forecast and submit it to your connected distributors.
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-medium">
                  Select Distributors
                </div>
                <div className="flex items-center justify-start text-gray-500 dark:text-gray-400">
                  <MultiSelectListbox
                    itemType="Distributor"
                    options={distributorsOptions}
                    selectedOptionsValues={selectedDistributorIds}
                    onChange={(values) => {
                      handleDistributorSelected(values)
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Bottom Section */}
            <div className="grid pt-6 lg:grid-rows-2 lg:grid-flow-col gap-x-12 gap-y-4">
              <div className="flex flex-col gap-1">
                <Input
                  type="date"
                  label="Requested Review Date"
                  min={today}
                  value={reviewDate}
                  onChange={setReviewDate}
                />
              </div>
              <div></div>
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium">Total Jobs</div>
                <div className="flex items-center pl-4 font-normal leading-5 text-gray-500 dark:text-gray-300 grow">
                  {sumInput} over the next{" "}
                  {pluralize("week", lastWeekWithInput, true)}
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-sm font-medium">Material Forecasted</div>
                <div className="flex flex-col pl-4 font-normal leading-5 text-gray-500 dark:text-gray-300 grow">
                  {material &&
                    Object.entries(material).map(([key, rawVal], i) => {
                      const group = materialGroups.find(
                        (group) => group._id === key
                      );

                      let value = rawVal ? Math.ceil(rawVal) : 0;

                      return (
                        <p key={i}>
                          {pluralize(group?.name, value, false)}: {value}
                        </p>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="relative flex justify-end gap-3 px-4 py-3 -mx-4 bg-gray-100 rounded-b-lg dark:bg-gray-700 sm:-mx-6 -bottom-4 sm:-bottom-6">
            <Button variant="secondary" onClick={close}>
              Cancel
            </Button>
            <Button variant="primary-green" onClick={handleSubmit}>
              Submit Forecast
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
