import React, { useState } from "react";
import Card from "../../components/Card";
import {
  ArrowsPointingOutIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import BucketsDiagram from "../../components/BucketsDiagram";
import BarChartTable from "../../components/BarChartTable";
import { ResponsiveContainer, LineChart, Line, XAxis } from "recharts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DataCard({
  title,
  barTableData,
  lineChartData,
  selectedMarket,
  lastColColorFunc,
}) {
  return (
    <Card className="grow p-6">
      {/* Title */}
      <div className="flex justify-between items-center mb-3">
        <div className="text-lg leading-7 font-semibold text-gray-900">
          {title}
        </div>
        <div>
          <ArrowsPointingOutIcon className="h-5 w-5 stroke-2 text-gray-300" />
        </div>
      </div>

      {/* Bar Chart Table */}
      <BarChartTable
        selectedRowId={selectedMarket}
        rows={barTableData}
        lastColColorFunc={lastColColorFunc}
      />

      {/* Line Chart */}
      <div className="relative w-full h-[200px]">
        <div className="absolute inset-0">
          <ResponsiveContainer height={200} width="99%">
            <LineChart data={lineChartData.data}>
              {/* <XAxis dataKey="week" domain={[0,5]} /> */}
              {lineChartData.markets.map((market, index) => (
                <Line
                  key={market.id}
                  connectNulls
                  type="monotone"
                  dataKey={market.id}
                  name={market.name}
                  className={
                    selectedMarket !== null
                      ? selectedMarket === market.id
                        ? market.color
                        : "text-gray-300"
                      : market.color
                  }
                  stroke="currentColor"
                  strokeWidth={3}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Card>
  );
}

function MarketCard({ market, onSelected = (m) => {} }) {
  return (
    <div key={market.name} className="flex rounded-md justify-center">
      {/* Job Count and Selectable */}
      <div
        onClick={() => onSelected(market.id)}
        className={classNames(
          market.bgColor,
          "flex flex-col w-14 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-bold text-white pt-1 group relative cursor-pointer"
        )}
      >
        <span className="leading-5">{market.shortName}</span>
        <span className="text-lg leading-7">{market.jobsInPipeline}</span>
        <div className="absolute inset-0 rounded-l-md opacity-20 bg-slate-900 hidden group-hover:block"></div>
      </div>

      {/* Link to Jobs */}
      <Link
        to={`/app/pipeline/jobs?market=${market.id}`}
        className="flex items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white px-2 hover:bg-gray-200 cursor-pointer"
      >
        <ChevronRightIcon className="h-5 w-5 stroke-[3] text-gray-500" />
      </Link>
    </div>
  );
}

export default function PipelineMarketsPage() {
  const [stages, setStages] = useState([
    {
      id: 1,
      name: "Booked",
      shortDescription: "Sold Deals",
      active: true,
      type: "exit",
      description: "Jobs that have been booked through the sales team",
      created: "2021-01-01",
    },
    {
      id: 2,
      name: "Design",
      shortDescription: "In Design",
      active: true,
      type: "exit",
      description: "Projects that are being designed",
      created: "2021-01-01",
    },
    {
      id: 3,
      name: "Permit",
      shortDescription: "Obtaining Permits",
      active: true,
      type: "exit",
      description:
        "Plans submitted for permit. Waiting for permit to be issued.",
      created: "2021-01-01",
    },
    {
      id: 4,
      name: "Schedule",
      shortDescription: "Need to Schedule",
      active: true,
      type: "exit",
      description: "Jobs with permits that need to be scheduled",
      created: "2021-01-01",
    },
    {
      id: 5,
      name: "Install",
      shortDescription: "Waiting for Install",
      active: true,
      type: "exit",
      description: "Scheduled jobs that are waiting for install",
      created: "2021-01-01",
    },
  ]);
  const [markets, setMarkets] = useState([
    {
      id: 1,
      name: "Colorado",
      shortName: "CO",
      bgColor: "bg-primary-rose",
      textColor: "text-primary-rose",
      jobsInPipeline: 210,
    },
    {
      id: 2,
      name: "Arizona",
      shortName: "AZ",
      bgColor: "bg-secondary-orange",
      textColor: "text-secondary-orange",
      jobsInPipeline: 182,
    },
    {
      id: 3,
      name: "Utah",
      shortName: "UT",
      bgColor: "bg-secondary-blue",
      textColor: "text-secondary-blue",
      jobsInPipeline: 95,
    },
    {
      id: 4,
      name: "Idaho",
      shortName: "ID",
      bgColor: "bg-secondary-green",
      textColor: "text-secondary-green",
      jobsInPipeline: 89,
    },
  ]);
  const [selectedMarket, setSelectedMarket] = useState(null);

  var lineChartData = {
    markets: markets.map((market) => ({
      id: market.id,
      name: market.name,
      color: market.textColor,
    })),
    data: [],
  };
  for (var i = 0; i < 5; i++) {
    var obj = {
      week: i + 1,
    };

    // for each market
    for (var j = 0; j < markets.length; j++) {
      obj[markets[j].id] = Math.random() * 100;
    }

    lineChartData.data.push(obj);
  }

  return (
    <div className="flex flex-col gap-6">
      {/* List of Markets */}
      <div className="flex gap-8 justify-center flex-wrap">
        {/* Header */}
        <div className="flex flex-col items-end gap-2.5">
          <div className="flex flex-col justify-center text-lg leading-7 font-semibold text-gray-900 h-[52px]">
            <div>Jobs in Pipeline</div>
          </div>
          <div className="text-gray-400 text-sm leading-5 font-bold flex flex-col gap-3">
            {stages.map((stage, index) => (
              <div className="h-6 flex flex-col justify-center" key={index}>
                <span>
                  S{index + 1} - {stage.name}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* List of Jobs */}
        {markets.map((market) => {
          return (
            <div key={market.id} className="flex flex-col justify-center">
              <MarketCard
                market={market}
                onSelected={(m) =>
                  setSelectedMarket(m != selectedMarket ? m : null)
                }
              />
              <BucketsDiagram
                disabled
                values={[
                  {
                    width: 60,
                  },
                  {
                    width: 20,
                  },
                  {
                    width: 135,
                  },
                  {
                    width: 30,
                  },
                  {
                    width: 50,
                  },
                ]}
                height={24}
                connWidth={6}
                connHeight={12}
                borderRadius={3}
                fill={
                  selectedMarket === market.id
                    ? "fill-gray-500"
                    : "fill-gray-300"
                }
              />
            </div>
          );
        })}
      </div>

      {/* Details */}
      <div className="flex flex-wrap gap-4 justify-center">
        {/* Cycle Time */}
        <DataCard
          title="Cycle Time"
          selectedMarket={selectedMarket}
          barTableData={markets.map((market) => ({
            id: market.id,
            label: market.shortName,
            color: market.bgColor,
            colVals: [
              market.jobsInPipeline,
              100,
              (market.jobsInPipeline - 100 > 0 ? "+" : "") +
                (market.jobsInPipeline - 100),
            ],
            barVal: market.id,
          }))}
          lineChartData={lineChartData}
          lastColColorFunc={(val) =>
            val <= 0 ? "text-green-600" : "text-red-600"
          }
        />

        {/* Conversion */}
        <DataCard
          title="Conversion"
          selectedMarket={selectedMarket}
          barTableData={markets.map((market) => ({
            id: market.id,
            label: market.shortName,
            color: market.bgColor,
            colVals: [
              market.jobsInPipeline,
              100,
              (market.jobsInPipeline - 100 > 0 ? "+" : "") +
                (market.jobsInPipeline - 100),
            ],
            barVal: market.id,
          }))}
          lineChartData={lineChartData}
          lastColColorFunc={(val) =>
            val >= 0 ? "text-green-600" : "text-red-600"
          }
        />

        {/* Weekly Jobs */}
        <DataCard
          title="Weekly Jobs"
          selectedMarket={selectedMarket}
          barTableData={markets.map((market) => ({
            id: market.id,
            label: market.shortName,
            color: market.bgColor,
            colVals: [
              market.jobsInPipeline,
              100,
              (market.jobsInPipeline - 100 > 0 ? "+" : "") +
                (market.jobsInPipeline - 100),
            ],
            barVal: market.id,
          }))}
          lineChartData={lineChartData}
          lastColColorFunc={(val) =>
            val >= 0 ? "text-green-600" : "text-red-600"
          }
        />
      </div>
    </div>
  );
}
