import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GridView from "../../components/Pipeline/Jobs/Views/GridView";
import ListView from "../../components/Pipeline/Jobs/Views/ListView";
import SearchBar from "../../components/SearchBar";
import Dropdown from "../../components/input/Dropdown";
import GridListToggle from "../../components/input/GridListToggle.tsx";
import Button from "../../components/input/Button";
import InputTable from "../../components/input/InputTable";
import Modal from "../../components/Modal";
import JobPipelineContext from "../../contexts/pipeline/jobs/JobPipelineContext";
import UserManager from "../../tools/UserManager";
import RowsView from "../../components/Pipeline/Jobs/Views/RowsView.tsx";
import MultiSelectListbox from "../../components/input/MultiSelectListbox.tsx";
import TopBar from "../../components/nav/TopBar";

/**
 * Page to allow a distributor to view shared installers' pipelines
 * @returns - Page content
 */
export default function DistributorPipelineViewPage() {
  const [viewState, setViewState] = useState("grid");
  const [selectedStageIndex, setSelectedStageIndex] = useState(-1);
  const [selectedMarketIds, setSelectedMarketIds] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
  });

  const [installers, setInstallers] = useState(null);

  const [selectedInstaller, setSelectedInstaller] = useState(null);

  const [markets, setMarkets] = useState(null);

  const [stages, setStages] = useState(null);

  const [jobs, setJobs] = useState(null);

  const [search, setSearch] = useState("");


  // TODO: is this used?
  const [dateFilter] = useState(
    // Start of last 12 months (e.g. If today is 11/10/2023, should be 11/1/2022 inclusive)
    moment.utc().subtract(12, "months").startOf("month").toISOString()
  );

  const [cycleTimeStats, setCycleTimeStats] = useState(null);
  const [conversionStats, setConversionStats] = useState(null);
  const [installStats, setInstallStats] = useState(null);
  const [soldStats, setSoldStats] = useState(null);

  const [combinedForecast, setCombinedForecast] = useState(null);

  // Get all installers that have shared their pipeline with the current distributor
  useEffect(() => {
    // get installers
    UserManager.makeAuthenticatedRequest("/api/pipeline/get-sharing-installers", "GET")
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { installers: sharingInstallers } = res.data;
          setInstallers(sharingInstallers);
          // Set default installer to first in list
          if (sharingInstallers.length) {
            setSelectedInstaller(sharingInstallers[0]._id);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Get stages, markets, and jobs for selected installer
  useEffect(() => {
    if (selectedInstaller) {
      var marketQuery = `?installerId=${selectedInstaller}`;

      // Get markets
      UserManager.makeAuthenticatedRequest(`/api/markets/distributor/find${marketQuery}`, "GET")
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            var { markets } = res.data;
            setMarkets(markets);
            let newSelectedMarketIds = markets.map((m) => m._id);
            setSelectedMarketIds(newSelectedMarketIds);

            // Get jobs
            retrieveJobs(
              pagination.currentPage,
              selectedStageIndex !== -1 && stages
                ? stages[selectedStageIndex]._id
                : null,
              newSelectedMarketIds,
              search
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedInstaller]);

  // Get current forecast on market change
  useEffect(() => {
    if (selectedInstaller && selectedMarketIds.length > 0) {
      getCurrentForecasts(selectedMarketIds, selectedInstaller)
        .then((res) => {
          setCombinedForecast(res.data.combined);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedMarketIds, selectedInstaller]);

  /**
   * Change pages of job results
   * @param page - Page number to retrieve
   */
  function handlePageChange(page) {
    setPagination({
      ...pagination,
      currentPage: page,
    });

    retrieveJobs(
      page,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      selectedMarketIds,
      search
    );
  }

  /**
   * Filter jobs based on search input
   * @param search - Search input
   */
  function handleSearch(search) {
    // TODO: debounce?

    search = search.target.value;

    // Go back to page 1 and update search
    setPagination({
      ...pagination,
      currentPage: 1,
    });
    setSearch(search);

    retrieveJobs(
      1,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      selectedMarketIds,
      search
    );
  }

  /**
   * Set selected stage
   * @param i - index of selected stage
   * @param toggle - if true, toggle selected stage
   */
  function handleSetSelectedStage(i, toggle = false) {
    if (toggle) {
      i = i === selectedStageIndex ? -1 : i;
    }

    // Update selected stage
    setSelectedStageIndex(i);

    // Filter jobs based on selected stage
    retrieveJobs(
      pagination.currentPage,
      i !== -1 && stages ? stages[i]._id : null,
      selectedMarketIds,
      search,
      dateFilter,
      -1
    );

    // TODO:  reset month select
  }

  /**
   * Change selected installer
   * @param installerId - ID of the selected installer
   */
  function handleSetSelectedInstaller(installerId) {
    setSelectedInstaller(installerId);
  }

  /**
   * Retrieve jobs based on filters
   * @param page - Page number
   * @param stageId - ID of the selected stage
   * @param marketIds - List of selected market IDs
   * @param search - Search input
   * @param date - Date filter
   */
  function retrieveJobs(page, stageId, marketIds, search, date = dateFilter) {
    // TODO: URI encode searches?

    var jobQuery =
      `?installerId=${selectedInstaller}` +
      `&page=${page || pagination.currentPage}` +
      `&pageSize=${pagination.itemsPerPage}` +
      "&excludeCompleted=true" +
      `&onlyActive=true` +
      `${stageId ? `&stage=${stageId}` : ""}` +
      `${marketIds && marketIds.length ? `&markets=${marketIds}` : ""}` +
      `${search ? `&search=${search}` : ""}`;

    // Query jobs
    UserManager.makeAuthenticatedRequest(`/api/jobs/distributor/find${jobQuery}`, "GET")
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { count: jobCount, page: currPage, jobs: jobList } = res.data;

          // Update jobs
          setJobs(jobList);

          // Update pagination
          setPagination({
            ...pagination,
            currentPage: currPage,
            totalItems: jobCount,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // Query stages
    var stageQuery =
      `?installerId=${selectedInstaller}` +
      `&includeStats=true` +
      `${marketIds && marketIds.length ? `&markets=${marketIds}` : ""}` +
      `${search ? `&search=${search}` : ""}` +
      `${date ? `&date=${date}` : ""}`;
    // TODO: figure out why the date filter made conversion rate show up. was 0 without date
    UserManager.makeAuthenticatedRequest(`/api/stages/distributor/find${stageQuery}`, "GET")
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { stages, stats } = res.data;

          // Weave stats into stages
          for (var i = 0; i < stages.length; i++) {
            stages[i] = {
              ...stages[i],
              ...stats[i],
            };
          }
          setStages(stages);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // Query pipeline stats (cycle time, conversion rate, installs per month, sold per month)
    var query =
      `?` +
      `installerId=${selectedInstaller}` +
      `${marketIds && marketIds.length ? `&market=${marketIds}` : ""}` +
      `${search ? `&search=${search}` : ""}` +
      `${stageId ? `&stage=${stageId}` : ""}` +
      `${date ? `&date=${date}` : ""}`;
    UserManager.makeAuthenticatedRequest(
      `/api/jobs/distributor/pipeline-stats${query}`,
      "GET"
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { cycleTimes, conversionRates, installsPerMo, soldPerMo } =
            res.data;

          // Cycle time
          var {
            avgCycleTime,
            jobCount: cycleTimeJobCount,
            minCycleTime,
            maxCycleTime,
            stdevCycleTime,
            summedCycleTime,
            byMonth: cycleTimeByMonth,
            byMarket: cycleTimeByMarket,
            last30: last30CycleTime,
            last90: last90CycleTime,
          } = cycleTimes;

          // Sort cycle time by month
          cycleTimeByMonth.sort((a, b) => {
            // Sort by year first then month
            if (a._id.year < b._id.year) return -1;
            if (a._id.year > b._id.year) return 1;
            return a._id.month - b._id.month;
          });

          // Update cycle time stats
          setCycleTimeStats({
            avgCycleTime,
            minCycleTime,
            maxCycleTime,
            stdevCycleTime,
            summedCycleTime,
            jobCount: cycleTimeJobCount,
            byMonth: cycleTimeByMonth,
            byMarket: cycleTimeByMarket,
            last30: last30CycleTime,
            last90: last90CycleTime,
          });

          // Conversion rate
          var {
            conversionRate: conversionRateStat,
            completedJobs,
            jobCount: convJobCount,
            cutoffDate,
            byMonth: convByMonth,
            byMarket: conByMarket,
          } = conversionRates;

          // Sort conversion rate by month
          convByMonth.sort((a, b) => {
            // Sort by year first then month
            if (a._id.year < b._id.year) return -1;
            if (a._id.year > b._id.year) return 1;
            return a._id.month - b._id.month;
          });

          // Update conversion rate stats
          setConversionStats({
            conversionRate: conversionRateStat,
            completedJobs,
            jobCount: convJobCount,
            cutoffDate,
            byMonth: convByMonth,
            byMarket: conByMarket,
          });

          // Installs per month
          var {
            avgInstallsPerMo,
            installsByMo,
            last30: last30Installs,
            last90: last90Installs,
          } = installsPerMo;

          // Presorted

          // Update installs per month stats
          setInstallStats({
            avgInstallsPerMo,
            installsByMo,
            last30: last30Installs,
            last90: last90Installs,
          });

          // Sold per month
          var {
            avgSoldPerMo,
            soldByMo,
            last30: last30Sold,
            last90: last90Sold,
          } = soldPerMo;

          // Presorted

          // Update sold per month stats
          setSoldStats({
            avgSoldPerMo,
            soldByMo,
            last30: last30Sold,
            last90: last90Sold,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Retrieve jobs based on selected markets
   * @param marketIds - List of selected market IDs
   */
  function handleMarketSelected(marketIds) {
    setSelectedMarketIds(marketIds);

    retrieveJobs(
      pagination.currentPage,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      marketIds,
      search,
      dateFilter,
      -1
    );

    // TODO: Reset month select
  }


  // Generate markets options
  var allMarketsOption = { label: "All Markets", value: null };
  var marketsOptions = markets
    ? [
      [allMarketsOption],
      markets.map((m) => {
        return {
          label: m.name,
          value: m._id,
        };
      }),
    ]
    : [[allMarketsOption]];

  var content = {
    grid: (
      <GridView
        cycleTimeStats={cycleTimeStats}
        conversionStats={conversionStats}
        installStats={installStats}
        soldStats={soldStats}
        companyType="distributor"
        installerId={selectedInstaller}
      />
    ),
    columns: <ListView />,
    rows: <RowsView
      companyType="distributor"
      installerId={selectedInstaller}
    />,
    list: <RowsView
      compact
      companyType="distributor"
      installerId={selectedInstaller}
    />
  }[viewState]

  let contextValue = {
    stages,
    selectedStageIndex,
    markets,
    selectedMarketIds,
    setSelectedStageIndex: handleSetSelectedStage,
    setSelectedMarketId: handleMarketSelected,
    jobPagination: pagination,
    jobs,
    formattedJobs: formatJobsForTable(jobs, stages, markets),
    setJobListPage: handlePageChange,

    cycleTimeStats,
    conversionStats,
    installStats,
    soldStats,

    combinedForecast,

    setViewState
  };

  return (
    <div className="flex flex-col max-h-screen dark:text-white grow">
      {/* Top Bar */}
      <TopBar>Installer Pipeline</TopBar>

      {/* Body */}
      <div className="flex flex-col flex-1 space-y-2">
        <JobPipelineContext.Provider value={contextValue}>
          {/* Actions */}
          <div className="flex flex-wrap items-center gap-4 p-2 mx-2 my-3">
            <div>
              {/* Installer Select Dropdown */}
              <Dropdown
                options={[
                  installers
                    ? installers.map((i) => ({
                      label: i.name,
                      value: i._id,
                    }))
                    : [],
                ]}
                placeholder="Select an Installer"
                bold
                justifyLeft
                onSelected={(item) => {
                  handleSetSelectedInstaller(item.value);
                }}
                selectedValue={selectedInstaller}
              />
            </div>
            <div>
              <MultiSelectListbox
                itemType="Market"
                options={marketsOptions[1]}
                selectedOptionsValues={selectedMarketIds}
                onChange={(values) => {
                  handleMarketSelected(values);
                }}
              />
            </div>
            <div className="mr-auto sm:mr-0">
              <Dropdown
                options={
                  stages
                    ? [
                      [
                        {
                          label: "All Stages",
                          value: -1,
                        },
                      ],
                      stages.slice(1).map((s, i) => ({
                        label: s.name,
                        value: i + 1,
                      })),
                    ]
                    : [
                      [
                        {
                          label: "All Stages",
                          value: -1,
                        },
                      ],
                    ]
                }
                placeholder="Select a Stage"
                bold
                justifyLeft
                onSelected={(item) => {
                  handleSetSelectedStage(item.value);
                }}
                selectedValue={selectedStageIndex}
              />
            </div>

            <div className="flex flex-wrap items-center gap-4 grow">
              <div className="flex grow">
                <div className="shrink">
                  <SearchBar value={search} onChange={handleSearch} />
                </div>
              </div>

              <div className="flex flex-row items-center justify-center gap-4">
                <GridListToggle
                  options={["grid", "rows", "list", "columns"]}
                  onChange={(selected) => {
                    setViewState(selected);
                  }}
                />
              </div>
            </div>

            {/* TODO: implement add job */}
            {/* <div>
          <button className="px-4 py-2 text-sm font-semibold text-white rounded-md bg-primary-green">
            Add Job
          </button>
        </div> */}
          </div>

          {installers && !installers.length ? (
            <div className="flex items-center justify-center h-64 text-lg text-gray-500">
              No installer pipelines have been shared
            </div>
          ) : (
            <div className="overflow-auto">{content}</div>
          )}
        </JobPipelineContext.Provider>
      </div>
    </div>
  );
}

/**
 * Format jobs for table display
 * @param jobs - List of jobs
 * @param stages - List of stages
 * @param markets - List of markets
 * @returns - Formatted jobs
 */
function formatJobsForTable(jobs, stages, markets) {
  if (!stages) return null;

  return jobs?.map((j, i) => {
    // Build city state string
    var cityState = null;
    if (j.address?.city || j.address?.state) {
      cityState = `${j.address?.city}${j.address?.city ? ", " : ""}${j.address?.state
        }`;
    }

    // Order job stages based on stages order
    var jobStages = [];
    for (let k = 0; k < stages.length; k++) {
      let stage = stages[k];
      // var jobStage = j.stages.find((s) => s.stage === stage._id); // DEPRECATED
      // jobStage.name = stage.name;
      let stageDate = j.stageMap[stage._id];
      jobStages.push({
        stage: stage._id,
        date: stageDate,
        name: stage.name,
      });
    }

    // TODO: rework for stage map

    // Get the stage after the last stage with a date
    // If all stages have dates, TODO: figure out
    var stage = null;
    if (jobStages.length) {
      stage = jobStages[0]; // Default is first stage
      // TODO: switch this
      for (let k = jobStages.length - 1; k >= 0; k--) {
        // If stage has a date, set stage to successor (i.e. stage without a date)
        if (jobStages[k].date) {
          // If last stage has a date, set stage to completed
          if (k === jobStages.length - 1) {
            stage = {
              name: (
                <CheckIcon className="w-4 h-4 stroke-2 text-primary-green" />
              ),
              completedDate: jobStages[k].date,
            };
            break;
          }
          stage = jobStages[k + 1];
          stage.started = jobStages[k].date;
          break;
        }
      }
    }

    // Get market
    var market = markets?.find((m) => m._id === j.market);
    // TODO: handle null markets

    // Get days since created
    // Get first stage date
    var firstStageDate = null;
    for (let _stage in stages) {
      if (j.stageMap[_stage._id]) {
        firstStageDate = j.stageMap[_stage._id];
        break;
      }
    }

    // Calculate days since sold (if complete use completed date instead of today)
    let soldDate = j.stageMap[stages[0]._id]
      ? moment.utc(j.stageMap[stages[0]._id])
      : null;
    var endDate = stage.hasOwnProperty("completedDate")
      ? moment.utc(stage.completedDate)
      : moment.utc();
    var daysSinceCreated = endDate.diff(firstStageDate || soldDate, "days");

    // Get days in stage
    if (stage && stage.started) {
      var stageDate = moment.utc(stage.started);
      var daysInStage = endDate.diff(stageDate, "days");
    }

    // Return formatted job
    var formattedJob = {
      cityState: cityState,
      name: j.name,
      systemSize: formatKW(j.systemSize),
      stage: stage?.name,
      stageAndDays:
        stage?.name && daysInStage
          ? `${stage.name} | ${daysInStage} / ${daysSinceCreated || "-"} Day${daysSinceCreated != 1 ? "s" : ""
          }`
          : stage?.name || "",
      market: market?.name,
      daysSinceCreated: daysSinceCreated || null,
      daysInStage: daysInStage || null,
      importedId: j.importedId,
      cycleTime: Math.round(j.cycleTime),
      stage1Date: j.date1 ? moment.utc(j.date1).format("M/D/YY") : "",
      closeDate: j.closeDate ? moment.utc(j.closeDate).format("M/D/YY") : "",
      completed: j.completed ? true : false,
      completedIcon: j.completed ? (
        <CheckIcon className="w-4 h-4 stroke-2 text-primary-green" />
      ) : (
        <XMarkIcon className="w-4 h-4 text-red-500 stroke-2" />
      ),
      installDate: moment.utc(stage?.completedDate).format("M/D/YY"),
      soldDate: j.stageMap[stages[0]._id]
        ? moment.utc(j.stageMap[stages[0]._id]).format("M/D/YY")
        : "-",
      link: {
        href: `/app/jobs/${j._id}`,
        target: "_blank",
      },
    };

    // Add time in each stage (for each stage, time since previous stage)
    for (let k = 1; k < jobStages.length; k++) {
      let stage = jobStages[k];
      var prevStage = jobStages[k - 1];

      // If has previous stage, calculate time in stage
      if (prevStage.date) {
        var date = stage.date || moment.utc().startOf("day");

        stage.timeInStage = moment(date)
          .utc()
          .startOf("day")
          .diff(moment.utc(prevStage.date).startOf("day"), "days");
      } else {
        stage.timeInStage = null;
      }

      formattedJob[stage.stage] = stage.timeInStage;
    }

    return formattedJob;
  });
}

/**
 * Format kilowatts
 * @param num - Number of kilowatts
 * @returns - Formatted kilowatts
 */
function formatKW(num) {
  return (
    Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(num) + "W"
  );
}

/**
 * Get current forecasts for selected markets
 * @param marketIds - List of selected market IDs
 * @param installerId - ID of the selected installer
 * @returns - Current forecasts
 */
async function getCurrentForecasts(marketIds, installerId) {
  let url = "/api/forecast/distributor/get-by-markets";
  url += marketIds ? `?markets=${marketIds}` : "";
  url += installerId ? `&installerId=${installerId}` : "";

  try {
    var res = await UserManager.makeAuthenticatedRequest(url);
  } catch (err) {
    console.error(err);
  }
  return res;
}
