import React, { useEffect } from "react";
import Modal from "../Modal";
import Button from "../input/Button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleOptions = [
  {
    label: "State",
    value: "address.state",
    sample: "Utah",
  },
  {
    label: "Postal Code",
    value: "address.postalCode",
    sample: 12345,
  },
  {
    label: "Panel Type",
    value: "panelType",
    sample: "SolarEdge",
  },
  {
    label: "Panel Quantity",
    value: "panelQuantity",
    sample: 20,
  },
];

export default function DetailFieldSelectModal({
  open,
  setOpen,
  onAddField,
  options = [],
}) {
  const [selectedField, setSelectedField] = React.useState(null);

  useEffect(() => {
    // Set selected field to null when the modal is opened
    if (open) {
      setSelectedField(null);
    }
  }, [open]);

  function handleCancel() {
    setOpen(false);
  }

  function handleAddField() {
    setOpen(false);
    onAddField(selectedField);
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex flex-col gap-6">
        {/* Title */}
        <div>
          <h1 className="font-semibold">Add Field to Display</h1>
          <h2 className="text-sm text-gray-500">
            This will temporarily add a field to the Forecast Details table
          </h2>
        </div>

        {/* Field Select */}
        <ul className="text-gray-500 divide-y">
          {options && options.length
            ? options.map((o, i) => (
                <ListItem
                  key={i}
                  option={o}
                  selectedField={selectedField}
                  setSelectedField={setSelectedField}
                />
              ))
            : "No available fields"}
        </ul>

        {/* Buttons */}
        <div className="flex justify-end gap-3 border-t pt-3 -mb-3 -mx-6 px-4">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={!selectedField}
            variant="primary-green"
            onClick={handleAddField}
          >
            Add Field
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function ListItem({ option, selectedField, setSelectedField = () => {} }) {
  // Check if the option is selected
  const isSelected = selectedField && selectedField.value === option.value;

  return (
    <li
      className={classNames(
        "py-2 px-3 cursor-pointer flex justify-between items-center",
        isSelected ? "bg-primary-green" : "hover:bg-gray-100"
      )}
      onClick={() => {
        setSelectedField(option);
      }}
    >
      <div className={isSelected ? "text-white" : "text-gray-900"}>
        {option.label}
      </div>

      <div className={isSelected ? "text-white opacity-70" : "text-gray-500"}>
        {option.sample || ""}
      </div>
    </li>
  );
}
