import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SubNav from "../components/nav/SubNav";
import TopBar from "../components/nav/TopBar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PipelinePage() {
  const [subNavTabs, setSubNavTabs] = useState([
    // { name: "Markets", href: "markets", current: true },
    { name: "Jobs", href: "jobs", current: true },
  ]);

  const location = useLocation();

  useEffect(() => {
    // Get the current tab from the URL
    const currentTab = location.pathname.split("/")[3];

    // Get name by href
    const currentTabName = subNavTabs.find((tab) => tab.href == currentTab)?.name ?? subNavTabs[0].name;

    // Set tab
    selectTab(currentTabName);
  }, [location]);

  /**
   * Selects a tab by name. This adjusts subNavTabs.
   * @param {string} tabName Name of tab to select
   */
  function selectTab(tabName) {
    var temp = [...subNavTabs];
    temp.forEach((tab) => {
      tab.current = tab.name == tabName ? true : false;
    });
    setSubNavTabs(temp);
  }

  return (
    <>
      <div className="flex flex-col max-h-screen dark:text-white grow">
        {/* Top Bar */}
        <TopBar>Pipeline</TopBar>

        {/* Body */}
        <div className="flex flex-col flex-1 space-y-2">
          {/* Sub Nav */}
          <div className="px-6 pt-3">
            <SubNav
              tabs={subNavTabs}
              onSelected={(tabName) => {
                selectTab(tabName);
              }}
            />
          </div>

          <Outlet />
        </div>
      </div>
    </>
  );
}
