import React from "react";

import pluralize from "pluralize";
import useTheme from "../../hooks/styling/useTheme";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleJobDonut({
  numerator = 0,
  denominator = 0,
  size = "lg",
}) {

  var percent = denominator ? (numerator / denominator) * 100 : 0;

  let formattedNumerator = formatNumber(numerator);
  let formattedDenominator = formatNumber(denominator);

  let outerSizeClass = size === "sm" ? "h-24 w-24" : "h-32 w-32";
  let innerSizeClass = size === "sm" ? "h-14 w-14" : "h-20 w-20";

  return (
    <div
      className={classNames(
        outerSizeClass,
        "rounded-full flex items-center justify-center"
      )}
      style={{
        backgroundImage: `conic-gradient(#1CB7BE ${percent}%, #D9D9D9 0)`,
      }}
    >
      <div
        className={classNames(
          innerSizeClass,
          "bg-white dark:bg-gray-700 rounded-full flex flex-col justify-center items-center"
        )}
      >
        <div className="text-base font-bold text-center whitespace-nowrap">
          {formattedNumerator}
          {size === "sm" ? "" : " " + pluralize("Job", numerator, false)}
        </div>
        <div className="-mt-1 text-xs font-normal whitespace-nowrap">
          of {formattedDenominator || "0"}
        </div>
      </div>
    </div>
  );
}

function formatNumber(n) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(n);
}
