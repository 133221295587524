import {
  CalendarIcon,
  HomeIcon,
  MapIcon,
  QueueListIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Badge from "src/components/Badge.tsx";
import Card from "src/components/Card";
import SubNav from "src/components/nav/SubNav";
import TopBar from "src/components/nav/TopBar";
import {
  ForecastSnapshotViewContextType,
  StageType,
} from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext";
import UserManager from "src/tools/UserManager";
import { ForecastType } from "../ForecastHistoryPage";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";

type Props = {};

type Params = {
  id?: string;
};

/**
 * Displays a submitted forecast snapshot.
 * Looks like the dynamic forecast page but is static.
 */
export default function ForecastSnapshotViewPage({}: Props): JSX.Element {
  // Get snapshot ID from URL
  const params: Params = useParams<Params>();

  // Navigation and location const from react-router-dom
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const [forecast, setForecast] = useState<ForecastType>(null);
  const [market, setMarket] = useState<MarketType>(null);
  const [stages, setStages] = useState<StageType[]>([]);
  const [submitter, setSubmitter] = useState<SubmitterType>(null);
  const [materialGroups, setMaterialGroups] = useState<MaterialGroupType[]>([]);
  const [materials, setMaterials] = useState<MaterialType[]>([]);

  const [tabs, setTabs] = useState([
    {
      name: "Job Forecast",
      icon: <HomeIcon />,
      href: "job",
      current: true,
    },
    {
      name: "Material Forecast",
      icon: <QueueListIcon />,
      href: "material",
      current: false,
    },
  ]);

  // Get snapshot data
  useEffect(() => {
    getSnapshot(params.id)
      .then((res) => {
        // Set values on success
        setForecast(res.forecast);
        setMarket(res.market);
        setStages(res.stages);
        setSubmitter(res.submitter);
        setMaterialGroups(res.materialGroups);
        setMaterials(res.materials);
      })
      .catch((error) => {
        console.error("Error getting snapshot:", error);
        navigate(".."); // Go back to the forecast history page
      });
  }, [params.id]);

  /**
   * Adjust tabs based on the current location
   */
  useEffect(() => {
    setTabs((oldTabs) => {
      var newTabs = oldTabs.map((oldTab) => {
        if (location.pathname.includes(oldTab.href)) {
          return { ...oldTab, current: true };
        }
        return { ...oldTab, current: false };
      });
      return newTabs;
    });
  }, [location]);

  // Handle tab selection
  const handleTabSelected = (tabName: string, tabHref: string) => {
    setTabs((prev) =>
      prev.map((t) => {
        return { ...t, current: t.name === tabName };
      })
    );
  };

  const submitterName = submitter
    ? `${submitter.firstName} ${submitter.lastName}`
    : null;

  const contextValue: ForecastSnapshotViewContextType = {
    forecast,
    market,
    stages,
    submitter,
    materialGroups,
    materials,
  };

  return (
    <div className="flex flex-col gap-2">
      <TopBar>
        <div className="flex gap-3 grow">
          <h1>Forecast Snapshot</h1>
          <Badge>Past Forecast</Badge>
          <button
            className="ml-auto"
            onClick={() => {
              navigate(".."); // Up to Forecast History Page
            }}
          >
            <XMarkIcon className="w-6 h-6 stroke-2" />
          </button>
        </div>
      </TopBar>

      {/* Body */}
      <div className="inset-0 flex flex-col flex-1 gap-y-4">
        <div className="flex items-center gap-6 px-6 pt-2">
          {/* Actions */}
          <div className="flex">
            <SubNav
              tabs={tabs}
              onSelected={handleTabSelected}
              navClasses="!space-x-0 border border-gray-300 bg-gray-300 dark:bg-gray-500 gap-0.5"
              currentTabClasses="bg-white dark:bg-gray-800 !border-b-[3px]"
              tabClasses="!whitespace-nowrap border-b-[0px] bg-gray-200 dark:bg-gray-600"
            />
          </div>
          {/* Forecast Info */}
          <div className="flex">
            <Card>
              <div className="flex flex-row gap-5">
                {/* Week */}
                <div className="flex gap-2 ">
                  <CalendarIcon className="w-6 h-6 text-gray-500 stroke-2 dark:text-gray-400" />
                  <div className="flex items-center gap-2 font-medium text-primary-green">
                    Week of{" "}
                    {forecast ? (
                      formatDate(forecast.week, "MMM D, YYYY")
                    ) : (
                      <SkeletonBar width={100} />
                    )}
                  </div>
                </div>
                {/* Market */}
                <div className="flex gap-2">
                  <MapIcon className="w-6 h-6 text-gray-500 stroke-2 dark:text-gray-400" />
                  <div className="flex items-center gap-2 ">
                    {market ? market.name : <SkeletonBar width={70} />}
                  </div>
                </div>
                {/* Submitter */}
                <div className="flex gap-2">
                  <UserIcon className="w-6 h-6 text-gray-500 stroke-2 dark:text-gray-400" />
                  <div className="flex items-center gap-2 ">
                    Submitted by {submitterName ?? <SkeletonBar width={60} />}{" "}
                    on{" "}
                    {forecast ? (
                      formatDate(forecast.submittedAt)
                    ) : (
                      <SkeletonBar />
                    )}
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        {/* Main */}
        {/* Main */}
        <div className="relative flex justify-start overflow-x-auto">
          <Outlet context={contextValue} />
        </div>
      </div>
    </div>
  );
}

/**
 * Response from the snapshot API endpoint.
 */
type SnapshotResponse = {
  status: string;
  forecast: ForecastType;
  market: MarketType;
  stages: StageType[];
  submitter: SubmitterType;
  materialGroups: MaterialGroupType[];
  materials: MaterialType[];
};

type MaterialType = {
  _id: string;
  name: string;
  model: string;
  brand: string;
  isDefaultMaterial: boolean;
  materialGroup: string; // ID of the material group
};

type MaterialGroupType = {
  _id: string;
  name: string;
};

type SubmitterType = {
  _id: string;
  company: { type: string; name: string };
  firstName: string;
  lastName: string;
  email: string;
};

/**
 * Retrieves a snapshot from the API.
 * @param id - ID of the snapshot to get
 * @returns - Snapshot data
 */
async function getSnapshot(id: string): Promise<SnapshotResponse> {
  var res = await UserManager.makeAuthenticatedRequest(
    "/api/forecast/snapshot/" + id
  );

  if (res.data.status !== "ok") {
    throw new Error(res.data.message);
  }

  return res.data;
}

/**
 * Turns a date string into a formatted date string.
 * If invalid date, returns the original string.
 *
 * @param date - String representation of a date
 * @param format - Moment.js format string
 * @returns - Date formatted according to the format string
 */
function formatDate(date: string, format = "M/D/YY"): string {
  if (!date) return "";

  var m = moment(date);

  // Check if the date is valid
  if (!m.isValid()) return date;

  return m.format(format);
}

/**
 * A loading skeleton bar.
 * Can take a width and height prop.
 */
function SkeletonBar({ width = 40, height = 20 }) {
  return (
    <div
      style={{ width: width, height: height }}
      className="bg-gray-300 rounded-lg dark:bg-gray-500 animate-pulse"
    />
  );
}
