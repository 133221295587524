import { FolderPlusIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "../../components/input/Button";
import Dropdown from "../../components/input/Dropdown";
import GridListToggle from "../../components/input/GridListToggle.tsx";
import Input from "../../components/input/Input";
import MaterialInfoCard from "../../components/OperationsSetup/MaterialsPage/MaterialInfoCard";
import Modal from "../../components/Modal";
import AddMaterialGroupModal from "../../components/OperationsSetup/MaterialsPage/AddMaterialGroupModal";
import SearchBar from "../../components/SearchBar";
import SimpleBarCard from "../../components/SimpleBarCard";
import SimpleCard from "../../components/SimpleCard";
import UserManager from "../../tools/UserManager";
import AddMaterialModal from "../../components/OperationsSetup/MaterialsPage/AddMaterialModal";
import Spinner from "../../components/Spinner";
import pluralize from "pluralize";
import Table from "../../components/Table";
import Card from "../../components/Card";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleMaterial = {
  panels: [
    {
      _id: 1,
      name: "V Sun 420",
      brand: "V Sun",
      watts: "420",
      model: "123-420-VSUN",
      jobs: [6, 5, 12, 4, 5, 6, 3, 5],
      createdAt: "2021-01-01",
      active: true,
    },
    {
      id: 2,
      name: "V Sun 320",
      brand: "V Sun",
      watts: "320",
      model: "123-320-VSUN",
      jobs: [1, 2, 3, 4, 5, 6, 7, 8],
      createdAt: "2021-01-01",
      active: false,
    },
  ],
  inverters: [
    {
      id: 3,
      name: "IQ7+",
      brand: "Enphase",
      model: "IQ7+",
      jobs: [1, 2, 3, 4, 5, 6, 7, 8],
      createdAt: "2021-01-01",
      active: true,
    },
  ],
  racking: [],
};

export default function MaterialsPage() {
  const [isGridView, setIsGridView] = useState(true);

  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [showAddMaterialGroupModal, setShowAddMaterialGroupModal] =
    useState(false);

  const [materials, setMaterials] = useState({});
  const [materialGroups, setMaterialGroups] = useState([]);
  const [selectedMaterialGroup, setSelectedMaterialGroup] = useState(null);

  const [loading, setLoading] = useState(false);

  // --- Use Effect --- //
  useEffect(() => {
    getMaterials();
  }, []);

  // ----- Functions ----- //

  function getMaterials() {
    setLoading(true);

    // Get the material groups & materials from the database
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/materials/get-materials-in-groups",
      "GET"
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "error") {
          alert("Error getting materials");
        } else if (status === "ok") {
          setMaterials(res.data.materials);
          setMaterialGroups(res.data.groups);
        }
      })
      .catch((err) => {
        alert("Error getting materials");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAddMaterial() {
    setShowAddMaterialModal(true);
  }

  function handleCreateMaterial(newMaterial) {
    // Add the material to the database
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/materials/add-material",
      "POST",
      newMaterial
    )
      .then((res) => {
        var status = res.data.status;

        if (status === "error") {
          alert("Error creating material.");
        } else if (status === "ok") {
          // Record change locally

          // Get group name
          var groupName = materialGroups.find(
            (group) => group._id === newMaterial.group
          ).name;

          // Record change locally
          materials[groupName].push(res.data.material);
          setShowAddMaterialModal(false);
        }
      })
      .catch((err) => {
        alert("Error creating material");
      });
  }

  function handleAddMaterialGroupBtn() {
    setShowAddMaterialGroupModal(true);
  }

  function handleCreateMaterialGroup(materialGroup) {
    // Add the material group to the database
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/materials/add-group",
      "POST",
      materialGroup
    )
      .then((res) => {
        var status = res.data.status;

        if (status === "error") {
          alert(
            "Error creating material group. There can be no duplicate material group names. Please try again."
          );
        } else if (status === "ok") {
          // Record change locally
          materials[materialGroup.name] = [];
          setShowAddMaterialGroupModal(false);
          setMaterialGroups([...materialGroups, res.data.materialGroup]);
        }
      })
      .catch((err) => {
        alert("Error creating material group");
      });
  }

  function handleNewDefaultMaterial(materialId) {
    getMaterials();
  }

  // ----- End Functions ----- //

  var content = isGridView ? (
    <GridView
      material={materials}
      materialGroups={materialGroups}
      selectedMaterialGroup={selectedMaterialGroup}
      setSelectedMaterialGroup={setSelectedMaterialGroup}
      handleAddMaterialGroup={handleAddMaterialGroupBtn}
      handleAddMaterial={handleAddMaterial}
      onNewDefaultMaterial={handleNewDefaultMaterial}
    />
  ) : (
    <ListView
      materials={materials}
      selectedMaterialGroup={selectedMaterialGroup}
      setSelectedMaterialGroup={setSelectedMaterialGroup}
    />
  );

  // If no material groups, set content accordingly
  if (Object.keys(materials).length === 0) {
    content = (
      <div className="flex flex-col justify-center items-center mt-[20vh] lg:mt-[30vh]">
        <FolderPlusIcon className="w-12 h-12 text-gray-400" />
        <div className="text-sm font-semibold text-gray-900">
          No Material Groups Found
        </div>
        <div className="text-sm font-normal text-gray-500">
          Add a material group to get started.
        </div>
        <div>
          <Button
            variant="primary-green"
            className="mt-6"
            onClick={handleAddMaterialGroupBtn}
          >
            Add Material Group
          </Button>
        </div>
      </div>
    );
  }

  // Check loading
  if (loading) {
    content = (
      <div className="relative flex items-center min-h-[400px]">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* Actions */}
      <div className="flex flex-wrap items-center gap-4 p-2">
        <div className="text-lg font-semibold">Materials</div>
        {/* // TODO: implement dropdown functionality */}
        {/* <div className="basis-full lg:basis-0">
          <Dropdown bare bold justifyLeft />
        </div> */}

        {/* // TODO: implement searchbar functionality */}
        {/* <div className="shrink">
          <SearchBar />
        </div> */}

        <div className="ml-auto">
          <GridListToggle
            onChange={(selected) => {
              setIsGridView(selected === "grid");
            }}
          />
        </div>

        <div>
          <Button onClick={handleAddMaterial} variant="primary-green">
            Add Material
          </Button>
        </div>

        <div>
          <Button variant="primary-green" onClick={handleAddMaterialGroupBtn}>
            Add Material Group
          </Button>
        </div>
      </div>

      <div className="overflow-auto">{content}</div>

      {/* Modals */}
      {/* Add material modal */}
      <AddMaterialModal
        open={showAddMaterialModal}
        setOpen={setShowAddMaterialModal}
        groups={materialGroups}
        handleNewMaterial={handleCreateMaterial}
      />

      {/* Add Material Group Modal */}
      <AddMaterialGroupModal
        open={showAddMaterialGroupModal}
        setOpen={setShowAddMaterialGroupModal}
        handleNewMaterialGroup={handleCreateMaterialGroup}
      />
    </>
  );
}

function GridView({
  material,
  materialGroups,
  selectedMaterialGroup,
  setSelectedMaterialGroup,
  handleAddMaterialGroup,
  handleAddMaterial,
  onNewDefaultMaterial,
}) {
  var materialCards = <></>;
  var materialGroupCards = <></>;

  const navigate = useNavigate();

  Object.entries(material).forEach(([key, value]) => {
    // Calculate the number of brands and number of models
    var brands = new Set();
    for (var i = 0; i < value.length; i++) {
      brands.add(value[i].brand);
    }

    // Create the group card for each group
    materialGroupCards = (
      <>
        {materialGroupCards}
        <div
          className="flex flex-col items-center justify-center cursor-pointer"
          onClick={() => {
            if (selectedMaterialGroup === key) {
              setSelectedMaterialGroup(null);
            } else {
              setSelectedMaterialGroup(key);
            }
          }}
        >
          <SimpleCard
            text={pluralize.plural(key)}
            subtext={`${brands.size} Brand${brands.size !== 1 ? "s" : ""}, ${
              value.length
            } Model${value.length !== 1 ? "s" : ""}`}
            grayed={
              selectedMaterialGroup !== key && selectedMaterialGroup !== null
            }
            onEllipsisClick={(e) => {
              e.stopPropagation();

              // Get ID from groups
              var groupID = materialGroups.find(
                (group) => group.name === key
              )._id;
              navigate("/app/operations-setup/material/group/" + groupID);
            }}
          />
        </div>
      </>
    );

    // If the group is selected or no groups are selected, show the materials
    if (selectedMaterialGroup === key || selectedMaterialGroup === null) {
      for (var i = 0; i < value.length; i++) {
        materialCards = (
          <>
            {materialCards}
            <MaterialInfoCard
              material={value[i]}
              group={key}
              onNewDefaultMaterial={onNewDefaultMaterial}
            />
          </>
        );
      }
    }
  });

  // If no materials, show message
  if (
    Object.keys(material).length === 0 ||
    Object.keys(material).reduce((a, b) => a + material[b].length, 0) === 0
  ) {
    materialCards = (
      <div className="grow flex flex-col justify-center items-center mt-[20vh] lg:mt-[30vh]">
        <FolderPlusIcon className="w-12 h-12 text-gray-400" />
        <div className="text-sm font-semibold text-gray-900">
          No Material Found
        </div>
        <div className="text-sm font-normal text-gray-500">
          Add material to get started.
        </div>
        <div>
          <Button
            variant="primary-green"
            className="mt-6"
            onClick={handleAddMaterial}
          >
            Add Material
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex gap-8 w-100">
      {/* Left Side */}
      <div className="">
        {/* Top Bar */}
        <div className="flex items-center mb-6">
          {/* Title */}
          <div className="text-base font-normal text-gray-500">
            Material Group
          </div>
          {/* Bar */}
          <div className="h-0 ml-3 ring-1 ring-gray-300 grow"></div>
          {/* + */}
          <PlusCircleIcon
            className="h-6 w-6 -ml-[1px] text-primary-green cursor-pointer"
            onClick={handleAddMaterialGroup}
          />
        </div>

        {/* Groups */}
        <div className="flex flex-col gap-6">{materialGroupCards}</div>
      </div>

      {/* Right Side */}
      <div className="grow">
        {/* Top Bar */}
        <div className="flex items-center mb-6">
          {/* Title */}
          <div className="text-base font-normal text-gray-500">Material</div>
          {/* Bar */}
          <div className="h-0 ml-3 ring-1 ring-gray-300 grow"></div>
          {/* + */}
          <PlusCircleIcon
            className="h-6 w-6 -ml-[1px] text-primary-green cursor-pointer"
            onClick={handleAddMaterial}
          />
        </div>
        <div>
          <div className="flex flex-wrap gap-6">{materialCards}</div>
        </div>
      </div>
    </div>
  );
}

function ListView({
  materials,
  selectedMaterialGroup,
  setSelectedMaterialGroup,
}) {
  var materialGroupCards = <></>;
  var maxCount = 0;

  // TODO: check for empty materials

  // Find the max count of materials in a group
  Object.entries(materials).forEach(([key, value]) => {
    if (value.length > maxCount) {
      maxCount = value.length;
    }
  });

  // Make simple bar card for each material group
  Object.entries(materials).forEach(([key, value]) => {
    materialGroupCards = (
      <>
        {materialGroupCards}
        <SimpleBarCard
          className="w-[180px] capitalize"
          title={key}
          value={`${value.length}`}
          grayed={
            selectedMaterialGroup !== key && selectedMaterialGroup !== null
          }
          percent={maxCount != 0 ? (value.length / maxCount) * 100 : 0}
          onClick={() => {
            if (selectedMaterialGroup === key) {
              setSelectedMaterialGroup(null);
            } else {
              setSelectedMaterialGroup(key);
            }
          }}
        />
      </>
    );
  });

  // Flatten materials into a list
  var flatMaterials = [];
  Object.entries(materials).forEach(([key, value]) => {
    for (var i = 0; i < value.length; i++) {
      if (selectedMaterialGroup === key || selectedMaterialGroup === null) {
        flatMaterials.push({
          ...value[i],
          group: key,
          href: `${value[i]._id}`,
          active: value[i].active ? "Yes" : "No",
          createdAt: formatDate(value[i].createdAt),
        });
      }
    }
  });

  const fields = [
    {
      label: "Name",
      field: "name",
    },
    {
      label: "Type",
      field: "group",
    },
    {
      label: "Active",
      field: "active",
    },
    {
      label: "Brand",
      field: "brand",
    },
    {
      label: "Model",
      field: "model",
    },
    {
      label: "Created Date",
      field: "createdAt",
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      {/* Material Groups */}
      <div className="flex flex-wrap justify-center gap-4">
        {materialGroupCards}
      </div>

      {/* Materials */}
      <div className="w-full">
        <Card>
          <div className="-m-4">
            <Table fields={fields} noSelect data={flatMaterials} />
          </div>
        </Card>
      </div>
    </div>
  );
}

function formatDate(date) {
  if (!date) return "";

  return moment(date).format("MM/DD/YY");
}
