/**
 * ProgressDots component to display progress through a series of steps
 * 
 * @param {Object[]} steps - array of objects with name and status
 */
export default function ProgressDots({
    steps
}) {
    return (
        <nav className="flex items-center justify-center" aria-label="Progress">
            <ol role="list" className="flex items-center space-x-5">
                {steps.map((step) => (
                    <li key={step.name}>
                        {step.status === 'complete' ? (
                            <a className="block h-2.5 w-2.5 rounded-full bg-primary-rose-600 ">
                                <span className="sr-only">{step.name}</span>
                            </a>
                        ) : step.status === 'current' ? (
                            <a className="relative flex items-center justify-center" aria-current="step">
                                <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
                                    <span className="w-full h-full rounded-full bg-primary-rose-300 dark:bg-primary-rose-500" />
                                </span>
                                <span className="relative block h-2.5 w-2.5 rounded-full bg-primary-rose-600" aria-hidden="true" />
                                <span className="sr-only">{step.name}</span>
                            </a>
                        ) : (
                            <a className="block h-2.5 w-2.5 rounded-full bg-gray-300 dark:bg-gray-600">
                                <span className="sr-only">{step.name}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
