import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import pluralize from "pluralize";
import { Dialog, Popover, Transition } from "@headlessui/react";

import moment from "moment";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import TableHandle from "./Forecast/material/TableHandle";
import MaterialForecastContext from "../contexts/forecast/material/MaterialForecastContext";
import { useOutletContext } from "react-router-dom";

import { ForecastSnapshotViewContextType } from "../contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Row({
  item: material,
  isUnassigned = false,
  weeksData = [],
  matGroup = {},
  isStatic = false,
}) {
  const outletContextValue = useOutletContext();

  // If static, get context value and build cells
  if (isStatic) {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
      materialGroups: matGroups,
      materials,
    } = outletContextValue;

    var cells = Array(20).fill({});

    staticForecastData.toaForecast.forEach((toa, i) => {
      let matForecast =
        staticForecastData.toaMaterialForecast[material.materialGroup][
          material._id
        ];

      cells[i] = {
        forecast: null,
        fulfilled: null,
        estimatedUpperBound: null, // TODO: do we need this for snapshot?
        estimated: matForecast?.[i] || null,
        weekJobPotential: toa,
        weekJobPotentialWoConv: staticForecastData.toaForecastWoConversion[i],
        week: moment(staticForecastData.week).utc().format("M/D"), // TODO: check
        weekSpan:
          moment(staticForecastData.week)
            .utc()
            .add(i, "weeks")
            .format("M/D/Y") +
          " - " +
          moment(staticForecastData.week)
            .utc()
            .add(i, "weeks")
            .add(6, "days")
            .format("M/D/Y"),
        material: material,
        group: null, // TODO: add
        inputJobCount: staticForecastData.inputForecast[i],
        jobsNeededForInputLikely: null, // TODO: do we need this for snapshot?
      };
    });

    return (
      <tr className={material._id === "total" ? "font-bold" : ""}>
        {cells.map((cell, i) => {
          return (
            <Cell isStatic={isStatic} key={i} cell={cell} matGroup={matGroup} material={material} />
          );
        })}
      </tr>
    );
  }

  // Start with 20 empty cells
  var cells = Array(20).fill({ forecast: null, fulfilled: null });
  for (let i = 0; i < weeksData.length; i++) {
    let weekData = weeksData[i];

    if (!weekData.materials) {
      weekData.materials = {};
    }

    let group = weekData.materials[material.materialGroup];

    let qty = group ? group[material._id] : null;

    // Get materials from week data
    let matCount = weekData.materials[material.materialGroup]
      ? weekData.materials[material.materialGroup][material._id]
      : null;
    let matWoConvCount = weekData.materialsWoConv[material.materialGroup]
      ? weekData.materialsWoConv[material.materialGroup][material._id]
      : null;
      
    cells[i] = {
      forecast: null,
      fulfilled: null,
      estimatedUpperBound: matWoConvCount,
      weekJobPotential: weekData.potential,
      weekJobPotentialWoConv: weekData.potentialWoConv,
      // calculated: weekData.input
      //   ? Math.ceil(weekData.input * material.avgPerJob)
      //   : null,
      estimated: matCount,
      matWoConvCount: matWoConvCount,
      week: weekData.label,
      weekSpan:
        moment(weekData.startOfWeek).utc().format("M/D/Y") +
        " - " +
        moment(weekData.startOfWeek).utc().add(6, "days").format("M/D/Y"),
      material: material,
      group: group,

      inputJobCount: weekData.input,
      jobsNeededForInputLikely: weekData.neededForInputLikely,
    };
  }

  return (
    <tr className={material._id === "total" ? "font-bold" : ""}>
      {cells.map((cell, i) => {
        return (
          <Cell isStatic={isStatic} key={i} cell={cell} matGroup={matGroup} material={material} />
        );
      })}
    </tr>
  );
}

function Cell({ cell, matGroup, material, isStatic = false}) {
  const [selected, setSelected] = useState(false);

  var isFulfilled = cell.fulfilled >= cell.forecast;

  function handleInput(e) {
    // console.log(e.target.value);
    // TODO: implement
  }

  const estimatedUpperBoundDescription = `As the job input for this week is ${cell.inputJobCount}, an estimate of ${cell.jobsNeededForInputLikely} active jobs is used to calculate the number of materials expected for ${cell.inputJobCount} jobs. This upper bound is the material for those ${cell.jobsNeededForInputLikely} active jobs.`;

  const calculatedDescription = `As the job input for this week is ${cell.inputJobCount}, an estimate of ${cell.jobsNeededForInputLikely} active jobs is used to calculate the number of materials expected for ${cell.inputJobCount} jobs. A portion of each of these ${cell.jobsNeededForInputLikely} active job's material is used. The portion is based on the likelihood of the job completing. The sum of these portions is the number of materials expected for ${cell.inputJobCount} jobs. In short, this estimate is a subset of the upper bound based on the likelihood of the jobs completing.`;

  const unassignedDescription = `Unassigned materials are used when all material for the active pipeline has been assigned already. The number of unassigned materials is based off the number of average materials per job for the material group. For ${pluralize(
    matGroup.name,
    2,
    false
  )}, the average per job is roughly ${Math.round(matGroup.avgPerJob)}.`;

  // Value to show in cell
  let value = cell.estimated ?? "";
  if (value !== "") {
    value = Math.ceil(value);
  }

  // TODO: improve unassigned description

  return (
    <td
      className={classNames(
        "p-0 border relative",
        selected && "z-10",
        "w-[5%] max-w-min"
      )}
      onFocus={() => setSelected(true)}
      onBlur={() => setSelected(false)}
    >
      {/* TODO: implement */}
      {isFulfilled || true ? (
        <input
          className="w-full px-0 py-2 text-center border-0"
          value={value}
          size={1}
          placeholder="-"
          onChange={handleInput}
        />
      ) : (
        <div className="w-full py-2 text-center text-white bg-primary-rose">
          {cell.fulfilled} / {cell.forecast}
        </div>
      )}
      <Transition show={selected} as={Fragment}>
        <div
          className={classNames(
            "absolute z-10",
            "left-1/2 transform -translate-x-1/2 bottom-full mb-2 font-normal"
          )}
        >
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                "bg-white shadow-lg p-3 rounded-lg border border-gray-300 flex flex-col gap-2 text-sm"
              )}
            >
              <div>
                <div className="font-medium">Week of {cell.week}</div>
                <div className="text-xs text-gray-400 whitespace-nowrap">{cell.weekSpan}</div>
              </div>

              <div>
                <div className="font-medium">
                  {pluralize(matGroup?.name, 1, false)}
                </div>
                <div className="text-xs text-gray-400">
                  {cell.material?.name}
                </div>
              </div>

              <table>
                <tbody>
                  {/* // TODO: manual material count override? */}
                  {/* <tr>
                    <td className="text-gray-500 whitespace-nowrap">Input</td>
                    <td className="pl-2 text-right">{cell.input ?? "-"}</td>
                  </tr> */}
                  <tr>
                    <td className="text-gray-500 whitespace-nowrap">
                      Fulfilled
                    </td>
                    <td className="pl-2 text-right">{cell.fulfilled ?? "-"}</td>
                  </tr>
                  {cell.material._id !== "unassigned" && !isStatic && (
                    <tr>
                      <td className="flex items-center gap-1 text-gray-500 whitespace-nowrap">
                        Estimated Upper Bound
                        <InformationCircleIcon
                          className="w-4 h-4 hover:text-gray-600"
                          title={estimatedUpperBoundDescription}
                        />
                      </td>
                      <td className="pl-2 text-right">
                        {cell.matWoConvCount !== null &&
                        !isNaN(cell.matWoConvCount)
                          ? Math.ceil(cell.matWoConvCount)
                          : "-"}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="flex items-center gap-1 text-gray-500 whitespace-nowrap">
                      Estimated
                      {!isStatic && (<InformationCircleIcon
                        title={
                          cell.material._id !== "unassigned"
                            ? calculatedDescription
                            : unassignedDescription
                        }
                        className="w-4 h-4 hover:text-gray-600"
                      />)}
                    </td>
                    <td className="pl-2 text-right">
                      {cell.estimated ? Math.ceil(cell.estimated) : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </td>
  );
}

export default function MaterialGroupTable({
  materialGroup,
  showWeeks = true,
  noLabels = false,
  noGap = false,
  weeksData = null,
  isStatic = false,
}) {
  const outletContextValue = useOutletContext();

  if (isStatic) {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
      materialGroups: matGroups,
      materials,
    } = outletContextValue;
  }

  // Get material forecast context
  const { xWeekForecast, handleXWeekForecastChange } = useContext(
    MaterialForecastContext
  );

  var { name, field1, field2, items } = materialGroup || {
    name: "",
    field1: { key: "field1", label: "" },
    field2: { key: "field2", label: "" },
    items: [],
  };

  // Weeks

  // Go through items to calculate if each month is fulfilled
  var weekFulfilment = Array(20).fill("unanswered"); // TODO: implement (this is just a placeholder)
  // Unanswered, fulfilled, unfulfilled
  // TODO: implement (this is just a placeholder)
  // weekFulfilment = Array(20).fill(true);

  var labels = (
    <div className="flex flex-col w-[140px] flex-none">
      {/* Material Group Name */}
      <div className="text-base font-medium pt-[18px]">
        {pluralize(name, 2, false)}
      </div>

      {/* Field 2  |  Field 1 */}
      <table className="table-auto text-sm font-medium mt-[17px]">
        <thead className="font-bold">
          <tr>
            <th>{field2.label}</th>
            <th>{field1.label}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) => {
            // Check if field2 is different from previous item
            if (i == 0 || item[field2.key] !== items[i - 1][field2.key]) {
              var showBorder = true;
            }

            // If field1 is unassigned, add extra space
            if (item[field1.key]?.toLowerCase() === "unassigned") {
              var extraSpace = true;
            }

            return (
              <tr
                key={i}
                className={classNames(
                  "h-11",
                  showBorder ? "border-t border-gray-300" : "",
                  extraSpace ? "relative top-2" : "" // TODO: fix this spacing
                )}
              >
                <td>{item[field2.key]}</td>
                <td>{item[field1.key]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  // If no data, return empty
  if (!weeksData && !isStatic) {
    return null;
  }

  let hasNonSpecialItems = items?.some(
    (item) => !["unassigned", "default", "total"].includes(item._id)
  );

  return (
    <div className="flex gap-2.5">
      {/* Labels */}
      {!noLabels && labels}

      {/* Tables */}
      <div className="relative flex flex-col gap-1 grow">
        <div>
          {/* Weeks & Fulfilment */}
          <table className="w-full text-sm font-medium text-center text-gray-500">
            <thead>
              {/* Months */}
              {showWeeks && (
                <tr>
                  {!isStatic ? (
                    weeksData.map((week, i) => {
                      return (
                        <td key={i} className="w-[5%]">
                          {i === 0 ? "this wk" : `${i} wk`}
                        </td>
                      );
                    })
                  ) : (
                    staticForecastData.toaForecast.map((toa, i) => {
                      return (
                        <td key={i} className="w-[5%]">
                          {i === 0 ? moment(staticForecastData.week).utc().format("M/D") : `+${i} wk`}
                        </td>
                      );
                    })
                  )}
                </tr>
              )}
            </thead>
          </table>
        </div>

        <div className="flex flex-col gap-2.5 relative pb-1">
          {/* Fulfilment Icons */}
          <table className="w-full text-center">
            <tbody>
              <tr>
                {weekFulfilment.map((fulfilledStatus, i) => {
                  let isFulfilled = fulfilledStatus === "fulfilled";
                  return (
                    <td
                      key={i}
                      className={classNames(
                        "h-3 border",
                        fulfilledStatus === "unanswered"
                          ? "bg-gray-300"
                          : isFulfilled
                          ? "bg-primary-green"
                          : "bg-primary-rose"
                      )}
                    ></td>
                  );
                })}
              </tr>
              <tr>
                {weekFulfilment.map((fulfilledStatus, i) => {
                  let isFulfilled = fulfilledStatus === "fulfilled";
                  return (
                    <td key={i} className="p-0 bg-white border h-7">
                      <div className="flex items-stretch h-full divide-x justify-evenly">
                        <div className="flex items-center justify-center grow">
                          <CheckIcon
                            className={classNames(
                              "h-4 w-4 stroke-2",
                              isFulfilled
                                ? "text-primary-green"
                                : "text-gray-300"
                            )}
                          />
                        </div>
                        <div className="flex items-center justify-center grow">
                          <XMarkIcon
                            className={classNames(
                              "h-4 w-4 stroke-2",
                              fulfilledStatus === "unanswered"
                                ? "text-gray-300"
                                : isFulfilled
                                ? "text-primary-green"
                                : "text-primary-rose"
                            )}
                          />
                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>

          {/* Inputs */}
          {hasNonSpecialItems && (
            <table className="w-full">
              <tbody className="space-y-4">
                {items.map((item, i) => {
                  return !["unassigned", "default", "total"].includes(
                    item._id?.toLowerCase()
                  ) ? (
                    <Row
                      key={i}
                      item={item}
                      weeksData={weeksData}
                      matGroup={materialGroup}
                      isStatic={isStatic}
                    />
                  ) : null;
                })}
              </tbody>
            </table>
          )}

          {/* Default and Unassigned */}
          <table className="w-full">
            <tbody className="space-y-4">
              {items.map((item, i) => {
                return ["default", "unassigned"].includes(
                  item._id?.toLowerCase()
                ) ? (
                  <Row
                    key={i}
                    item={item}
                    weeksData={weeksData}
                    matGroup={materialGroup}
                    isStatic={isStatic}
                  />
                ) : null;
              })}
            </tbody>
          </table>

          {/* Totals */}
          <table className="w-full">
            <tbody>
              <Row
                item={{
                  _id: "total",
                  name: "Total",
                  materialGroup: materialGroup._id,
                }}
                weeksData={weeksData}
                matGroup={materialGroup}
                isStatic={isStatic}
              />
            </tbody>
          </table>

          <TableHandle
            numSlots={21}
            slot={xWeekForecast}
            onSlotChange={handleXWeekForecastChange}
          />
        </div>
      </div>
    </div>
  );
}
