import React, { useEffect, useState } from "react";
import Button from "./Button";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Input from "./Input";
import { XMarkIcon } from "@heroicons/react/24/outline";

/**
 * Component for inputting a list of values.
 * Handles adding, updating, and deleting values.
 * 
 * @param {Array} props.values - Array of values
 * @param {Function} props.setValues - Function to set values
 * @returns {JSX.Element}
 */
export default function ListInput({
  values: prop_values = [],
  setValues: prop_setValues = () => {},
}) {
  const [values, setValues] = useState(prop_values);

  // Update when prop_values changes
  useEffect(() => {
    setValues(prop_values);
  }, [prop_values]);

  return (
    <div className="flex flex-col gap-2">
      {values.map((value, index) => (
        <Row
          key={index}
          value={value}
          onChange={(newValue) => {
            // Update value at index
            const newValues = [...values];
            newValues[index] = newValue;
            setValues(newValues);
            prop_setValues(newValues);
          }}
          onDelete={() => {
            // Remove value at index
            const newValues = [...values];
            newValues.splice(index, 1);
            setValues(newValues);
            prop_setValues(newValues);
          }}
        />
      ))}
      <button
        className="flex items-center gap-1 cursor-pointer text-primary-green hover:text-primary-green-700"
        onClick={() => {
          setValues([...values, ""]);
          prop_setValues([...values, ""]);
        }}
      >
        <PlusCircleIcon className="w-5 h-5" /> Add Value
      </button>
    </div>
  );
}

function Row({ value, onChange = () => {}, onDelete = () => {} }) {
  return (
    <div className="flex items-center gap-2">
      <Input placeholder="Value" value={value} onChange={onChange} />
      <button
        className="cursor-pointer text-primary-rose hover:text-primary-rose-700"
        onClick={onDelete}
      >
        <XMarkIcon className="w-5 h-5 stroke-2" />
      </button>
    </div>
  );
}
