import { Link } from "react-router-dom";
import React from "react";

const tabsExample = [
  { name: "My Account", href: "#", current: false },
  { name: "Company", href: "#", current: false },
  { name: "Team Members", href: "#", current: true },
  { name: "Billing", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubNav({
  tabs,
  onSelected,
  navClasses = "",
  currentTabClasses = "",
  tabClasses = "",
}) {
  tabs = tabs || [];

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-primary-green focus:outline-none focus:ring-primary-green sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name}
          onChange={(e) => {
            // TODO: fix. This is not working.
            onSelected(e.target.value);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav
            className={classNames("-mb-px flex space-x-8", navClasses)}
            aria-label="Tabs"
          >
            {tabs.map((tab) => {
              return (
                <Link
                  key={tab.name}
                  to={tab.href || "#"}
                  className={classNames(
                    tab.current
                      ? "border-primary-green text-primary-green font-bold"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 font-medium",
                    "whitespace-nowrap border-b-2 py-2 px-1 text-sm min-w-[140px] text-center",
                    tab.current && currentTabClasses,
                    tabClasses
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={(e) => {
                    onSelected(tab.name, tab.href);
                  }}
                >
                  <div className="flex items-center justify-center gap-4 px-2">
                    {tab.icon && (
                      <div>
                        {React.cloneElement(tab.icon, {
                          className: classNames(
                            "h-6 w-6 stroke-2",
                            tab.current ? "text-primary-green" : "dark:text-gray-400"
                          ),
                        })}
                      </div>
                    )}
                    <div
                      className={classNames(
                        tab.icon ? "text-left text-gray-500 dark:text-gray-400" : "text-center"
                      )}
                    >
                      <div
                        className={
                          (tab.icon &&
                            tab.current &&
                            "text-gray-700 dark:text-white font-medium") ||
                          ""
                        }
                      >
                        {tab.name}
                      </div>
                      <div className="text-sm font-medium">{tab.subtext}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
}
