import React from 'react'
import useKonamiCode from 'src/hooks/easterEggs/useKonamiCode.tsx'
import blackCursor from 'src/assets/cursors/blackCursor.svg';
import whiteCursor from 'src/assets/cursors/whiteCursor.svg';
import useTheme from 'src/hooks/styling/useTheme';

type Props = {
  children: React.ReactNode
}

/**
 * Handles the Easter eggs for the site. Currently adjusts styling based on the Easter egg(s) activated.
 * 
 * Current Easter eggs:
 * - Konami code: changes the cursor to a TOA logo cursor
 * 
 * Easter egg ideas:
 * - Snake: renders a snake game on the page
 * - Pacman: renders a pacman game on the page
 * 
 * 
 * @param {Props} props - Children to render
 * @returns 
 */
export default function EasterEggs({
  children
}: Props) {

  const hasKonamiCode : boolean = useKonamiCode();
  const darkMode : boolean = useTheme() === 'dark';

  let style = {
    ...(hasKonamiCode && {
      cursor: `url(${darkMode ? whiteCursor : blackCursor}) 15.6 8, auto`,
    })
  };

  return (
    <div style={style}>
      {children}
    </div>
  )
}