import { useEffect, useState } from "react";
import Button from "./Button";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}


/**
 * Component for bulk email input
 * type emails and press enter to add them to the list
 * emails in the list will be displayed as a list of buttons inside the input
 * 
 * @param {string} label - label that goes above the input field
 * @param {string} type - type of input field, default is "textarea"
 * @param {string} placeholder - placeholder text for the input field
 * @param {string} className - additional classes to apply to the input field
 * @param {array} emails - current list of emails displayed as buttons inside the input field
 * @param {function} setEmails - function to set the list of emails
 * 
 * @returns - a component for bulk email input, emails are typed, and if valid, added to the list of emails as buttons inside the input field
 */
export default function EmailInput({
    label,
    type = "textarea",
    placeholder = "Enter emails to invite",
    className = "",
    emails,
    setEmails,
}) {

    /**
    * Validate email address using regex
    * there are many regexes for email validation, this one is simple and should work for most cases
    *
    * @param {string} email - email address to validate
    * @returns {boolean} - true if email is valid, false otherwise
    */
    function validateEmail(email) {
        // source for email regex: https://www.abstractapi.com/guides/email-validation-regex-javascript
        var re = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
        return re.test(email);
    }

    /**
     * Parse emails from input field, validate them and add them to the list of emails to display
     * also call the onChange function to update the parent component
     *
     * @param {Event} e - event object
     * @param {boolean} pasted - true if the event is a paste event
     */
    function parseEmails(e, pasted=false) {
        e.preventDefault();
        let text = '';
        if (pasted) {
            text = e.clipboardData.getData('text');
        } else {
            text = e.target.value;
        }
        const invalidEmails = [];
        const newEmails = [];
        text.split(/[\s,]+/).forEach((email) => {
            email = email.trim();
            if (emails.includes(email.toLowerCase()) || !validateEmail(email)) {
                if (!validateEmail(email)) {
                    invalidEmails.push(email);
                }
                return;
            }
            newEmails.push(email.toLowerCase());
        });
        setEmails([...emails, ...newEmails]);
        e.target.value = invalidEmails.join(' ');
    }

    // email input field
    var emailInput = (
        <div
            // value={value}
            name={type}
            id={type}
            rows="3"
            className={classNames(
                "block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm bg-white dark:bg-gray-700 min-h-28 max-h-80 overflow-y-auto",
                "ring-1 ring-inset focus:ring-2 focus:ring-inset",
                "focus:ring-primary-green sm:text-sm sm:leading-6 placeholder:text-gray-400",
                // value || !highlightOnEmpty ? "ring-gray-300" : "ring-secondary-orange",
                className
            )}
            placeholder={placeholder}
        >
            <ul className='flex flex-row flex-wrap gap-2'>
                {emails.map((email, index) => (
                    <li key={index} className="email">
                        {/* email button with x to remove it */}
                        <Button
                            className="!text-gray-700 !bg-gray-200 flex flex-row align-middle gap-1 !pr-1 !pl-2 !cursor-default"
                        >
                            <div className="truncate">
                                {email}
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 cursor-pointer hover:text-gray-400"
                                onClick={() => {
                                    setEmails(emails.filter((email) => email !== emails[index]));
                                }}
                            >
                                <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
                            </svg>
                        </Button>
                    </li>
                ))}
                {/* input field to type emails */}
                <input
                    type="text"
                    placeholder={placeholder}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            parseEmails(e);
                        }
                        if (e.key === 'Backspace' && e.target.value === '' && emails.length > 0) {
                            let newEmailsList = emails.slice(0, emails.length - 1);
                            setEmails(newEmailsList);
                            // TODO: maybe first highlight the x button of the last email, and then if backspace is pressed again, remove the email
                        }
                    }}
                    onPaste={(e) => {
                        parseEmails(e, true);
                    }}
                    className="px-1 py-1.5 border-0 focus:ring-0 dark:bg-gray-700 placeholder:text-gray-400 text-gray-900 dark:text-gray-200"
                />
            </ul>

        </div>
    );


    return (
        <div className="">
            {label && (
                <label
                    htmlFor={type}
                    className="block mb-2 text-sm font-medium text-gray-900"
                >
                    {label}
                </label>
            )}
            <div className="">{emailInput}</div>
        </div>
    );
}