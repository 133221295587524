import Job from "./Job.js";
import moment from "moment";

/**
 * Represents a week in the forecast.
 * @class
 */
export default class Week {
  // ------------------ //
  // --- Properties --- //
  // ------------------ //

  /**
   * The date of the week.
   * @type {moment.Moment}
   */
  date;

  /**
   * The list of jobs for the week.
   * @type {Job[]}
   */
  jobs = [];

  /**
   * The number of jobs for the week as input by the installer.
   * @type {number}
   */
  inputCount = 0;

  /**
   * The number of jobs for the week based on TOA estimates and conversion rates.
   * @type {number}
   */
  toaCount = 0;

  /**
   * The number of jobs for the week based on TOA estimates and without using conversion rates.
   * @type {number}
   */
  toaCountWoConv = 0;

  /**
   * The goal number of jobs for the week.
   * @type {number}
   */
  goal = 0;

  /**
   * All material groups that could be used in the week.
   * @type {MaterialGroupModel[]}
   */
  materialGroups = [];

  /**
   * The number of jobs slotted into this week.
   * @type {number}
   */
  get jobCount() {
    return this.jobs.length;
  }

  /**
   * The number of jobs slotted into this week that are likely to convert to installs.
   * @type {number}
   */
  get likelyJobCount() {
    return Math.ceil(
      this.jobs.reduce((acc, job) => acc + job.likelihoodOfInstall, 0)
    );
  }

  /**
   * The materials required for the week with conversion rates applied.
   * For example: if 1 job needs 10 panels and is 80% likely to convert to an install, the value would be 8.
   *
   * @return {{_materialGroupId_:{_materialId_:number}}} - The materials required for the week
   */
  get materials() {
    return this.#getMaterials(true);
  }

  /**
   * The materials required for the week without applying conversion rates.
   *
   * @return {{_materialGroupId_:{_materialId_:number}}} - The materials required for the week
   */
  get materialsWoConv() {
    return this.#getMaterials(false);
  }

  // ------------------- //
  // --- Constructor --- //
  // ------------------- //

  /**
   * Creates a new instance of the Week class.
   * @param {moment.Moment} date - The date of the week.
   */
  constructor(date, materialGroups = []) {
    this.date = date;
    this.materialGroups = materialGroups;
  }

  // --------------- //
  // --- Methods --- //
  // --------------- //

  /**
   * The materials required for the week conditionally applying conversion rates.
   * With conversion rates for example: if 1 job needs 10 panels and is 80% likely to convert to an install, the value would be 8.
   *
   * @param {boolean} applyConv - Whether or not to apply conversion rates to the materials
   * @returns {{_materialGroupId_:{_materialId_:number}} - The materials required for the week
   */
  #getMaterials(applyConv) {
    let materials = {};
    this.jobs.forEach((job) => {
      // Go through groups
      for (let materialGroupId in job.materials) {
        let { material: mat, quantity: qty } = job.materials[materialGroupId];

        // If group not in materials, add it
        if (!(materialGroupId in materials)) {
          materials[materialGroupId] = {};
        }

        // If material not in group, add it
        if (!(mat in materials[materialGroupId])) {
          materials[materialGroupId][mat] = 0;
        }

        // Add quantity to material
        materials[materialGroupId][mat] +=
          qty * (applyConv ? job.likelihoodOfInstall : 1);

        // Add quantity to total
        if (!("total" in materials[materialGroupId])) {
          materials[materialGroupId]["total"] = 0;
        }
        materials[materialGroupId]["total"] +=
          qty * (applyConv ? job.likelihoodOfInstall : 1);
      }
    });

    // --- Unassigned materials --- //

    // Used in remainder of jobs (inputCount - jobCount)

    // If none, return
    if (this.inputCount <= this.likelyJobCount) {
      return materials;
    }

    // Get remainder of jobs
    let remainder = this.inputCount - this.likelyJobCount;

    // For each material group, add remainder * avg material quantity
    for (let materialGroup of this.materialGroups) {
      let mgId = materialGroup._id;
      let avgQty = materialGroup.avgPerJob || 0;
      if (!(mgId in materials)) {
        materials[mgId] = {};
      }
      materials[mgId]["unassigned"] = avgQty * remainder;

      // Add to total
      if (!("total" in materials[mgId])) {
        materials[mgId]["total"] = 0;
      }
      materials[mgId]["total"] += avgQty * remainder;
    }

    return materials;
  }

  /**
   * Adds the given jobs to the week
   *
   * @param {Job[]} jobs The jobs to add to the week
   */
  addJobs(jobs) {
    this.jobs.push(...jobs);
  }

  /**
   * Removes the given jobs from the week
   *
   * @param {Job[]} jobs The jobs to remove from the week
   */
  removeJobs(jobs) {
    this.jobs = this.jobs.filter((job) => !jobs.includes(job));
  }
}
