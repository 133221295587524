import {
  AdjustmentsVerticalIcon,
  Cog6ToothIcon,
  FunnelIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { Link, Navigate, useParams, useSearchParams } from "react-router-dom";
import SimpleJobDonut from "../../components/Forecast/SimpleJobDonut";
import ForecastInput from "../../components/Forecast/ForecastInput";

import pluralize from "pluralize";
import Table from "../../components/Table";
import Card from "../../components/Card";
import moment from "moment";
import Modal from "../../components/Modal";
import SlideOver from "../../components/SlideOver";
import Dropdown from "../../components/input/Dropdown";

import {
  Reorder,
  useMotionValue,
  useDragControls,
  motion,
} from "framer-motion";
import DraggableList from "../../components/DraggableList";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import UserManager from "../../tools/UserManager";
import DetailFieldSelectModal from "../../components/Forecast/DetailFieldSelectModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function WeekForecastDetails() {
  let [searchParams, setSearchParams] = useSearchParams();

  let week = searchParams.get("week");
  week = moment(week, "YYYY-MM-DD");
  let marketId = searchParams.get("market");

  let weekIndex = week.diff(moment().utc().startOf("week"), "weeks");

  // TODO: handle bad week date

  const [forecastData, setForecastData] = React.useState();

  const [materials, setMaterials] = React.useState();
  const [materialGroups, setMaterialGroups] = React.useState();

  const [showFiltersModal, setShowFiltersModal] = React.useState(false);
  const [showFunnelModal, setShowFunnelModal] = React.useState(false);
  const [showSettingsModal, setShowSettingsModal] = React.useState(false);

  const [showAddFieldModal, setShowAddFieldModal] = React.useState(false);

  const [stages, setStages] = React.useState();
  const [market, setMarket] = React.useState();

  const [additionalFieldsValues, setAdditionalFieldsValues] = React.useState(
    []
  );
  const [additionalFieldsContent, setAdditionalFieldsContent] = React.useState(
    {} // Map of value to content to display
  );
  const [additionalFieldsLabels, setAdditionalFieldsLabels] = React.useState(
    {}
  ); // Map of value to label

  const [sortBy, setSortBy] = React.useState("importedId");
  const [sortDir, setSortDir] = React.useState("asc");

  const sortOptions = [
    [
      {
        label: "Job ID",
        value: "importedId",
      },
      {
        label: "Job Name",
        value: "jobName",
      },
      {
        label: "Size",
        value: "systemSize",
      },
      {
        label: "Stage",
        value: "stage",
      },
      {
        label: "Likelihood to Close",
        value: "convRate",
      },
      {
        label: "Estimated Date",
        value: "estimatedDate",
      },
    ],
  ];

  const tableFields = [
    {
      label: "Job ID",
      field: "importedId",
    },
    {
      label: "Job",
      field: "name",
    },
    {
      label: "Size",
      field: "systemSizeFormatted",
    },
    {
      label: "Stage",
      field: "stage",
    },
    {
      label: "Likelihood to Close",
      field: "likelihoodToClose",
    },
    {
      label: "Estimated Date",
      field: "estimatedDate",
    },
  ];

  // Get forecast data
  useEffect(() => {
    // Check null market id
    if (!marketId) {
      return;
    }

    // setForecastData(null); // TODO: figure out how to reset data
    getForecastData(marketId).then((data) => {
      var { forecastData, stages, market } = data;
      setForecastData(forecastData);
      setStages(stages);
      setMarket(market);
    });
  }, [searchParams]);

  // Get material groups
  useEffect(() => {
    getMaterialGroups()
      .then((data) => {
        let { materialGroups, materials } = data;

        setMaterialGroups(materialGroups);
        setMaterials(materials);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  function handleFiltersClick() {
    setShowFiltersModal(true);
  }

  function handleFunnelClick() {
    setShowFunnelModal(true);
    console.error("TODO: implement funnel click");
  }

  function handleSettingsClick() {
    setShowSettingsModal(true);
    console.error("TODO: implement settings click");
  }

  function formatData(data) {
    let weekData = data[weekIndex];

    let formattedData = weekData.jobs.map((j) => {
      let stage = stages.find((s) => s._id === j.currentStage);
      let stageName = stage ? stage.name : "-";
      let estimatedDate = j.estimatedInstallDate
        ? moment(j.estimatedInstallDate).utc().format("M/D/YY")
        : "-";
      let convRate = stage ? stage.convRate : "-";

      let formatted = {
        importedId: j.importedId,
        name: j.name,
        systemSize: j.systemSize,
        systemSizeFormatted: formatWattage(j.systemSize),
        stage: stageName,
        likelihoodToClose: <BasicProgressBar value={convRate * 100} />,
        convRate: convRate,
        estimatedDate: estimatedDate,
        href: `/app/jobs/${j._id}`,
      };

      // Add additional fields
      for (let value of additionalFieldsValues) {
        // Address
        if (value.startsWith("address.")) {
          formatted[value] = j.address[value.split(".")[1]] || "-";
        }

        // Contact
        else if (value.startsWith("contact.")) {
          formatted[value] = j.contact[value.split(".")[1]] || "-";
        }

        // Stages
        else if (value.startsWith("stage.")) {
          let stageId = value.split(".")[1];
          let stageDate = j.stageMap[stageId];
          if (stageDate) {
            stageDate = moment(stageDate).format("M/D/YY");
          }
          formatted[value] = stageDate || "-";
        }

        // Material groups
        else {
          let [groupId, toDisplay] = value.split("."); // Group ID is id of material group, toDisplay is either "type" or "quantity"

          let matAndQty = j.materialMap[groupId];

          if (!matAndQty) continue;

          // Display type or quantity
          if (toDisplay === "type") {
            if (matAndQty.material) {
              // Find material
              let materialObj = materials.find(
                (m) => m._id === matAndQty.material
              );

              // Display material name, brand, or model
              formatted[value] =
                materialObj?.name ??
                materialObj?.brand ??
                materialObj?.model ??
                "-";
            } else {
              // If no material, display default
              // TODO: handle default
              formatted[value] = "Default";
            }
          } else if (toDisplay === "quantity") {
            formatted[value] = matAndQty.quantity;
          }
        }
      }

      return formatted;
    });

    // Sort

    formattedData.sort((a, b) => {
      // Get the properties to compare from objects a and b
      let aProp = a[sortBy];
      let bProp = b[sortBy];

      let ret = 0;

      // If date, sort dates properly
      if (sortBy === "estimatedDate" || sortBy.startsWith("stage.")) {
        aProp = moment(aProp, "M/D/YY").format("YYYYMMDD");
        bProp = moment(bProp, "M/D/YY").format("YYYYMMDD");
      }

      // If the property is a string, use localeCompare for sorting
      if (typeof aProp === "string") {
        ret = aProp.localeCompare(bProp);
      } else {
        // If the property is not a string, subtract b from a for sorting
        try {
          ret = aProp - bProp;
        } catch (err) {
          ret = 0;
        }
      }

      // If the sort direction is descending, multiply the result by -1 to reverse the sort order
      if (sortDir === "desc") {
        ret *= -1;
      }

      // Return the result of the comparison
      return ret;
    });

    return formattedData;
  }

  function handleSortSelected(option) {
    setSortBy(option.value);
  }

  function handleSortDirSelected(option) {
    setSortDir(option.value);
  }

  function handleWeekClick(data, index) {
    setSearchParams({
      week: data.startOfWeek.format("YYYY-MM-DD"),
      market: marketId,
    });
  }

  function handleAddField(field) {
    setAdditionalFieldsValues((oldValues) => [...oldValues, field.value]);
    setAdditionalFieldsContent((oldContent) => {
      return {
        ...oldContent,
        [field.value]: <LineItem name={field.label} sample={field.sample} />,
      };
    });
    setAdditionalFieldsLabels((oldLabels) => {
      return {
        ...oldLabels,
        [field.value]: field.label,
      };
    });
  }

  /**
   * Based on all options and selected options, return options for the add field modal
   * @returns {Array} - Array of options for the add field modal
   */
  function getAddFieldOptions() {
    let allOptions = [];

    // Get job for sampling
    let sampleJob = forecastData ? forecastData[weekIndex].jobs[0] : {};

    // Add material groups
    if (materialGroups) {
      materialGroups.forEach((group) => {
        let typeOpt = {
          label: `${pluralize(group.name, 1, false)} Type`,
          value: `${group._id}.type`,
        };
        let qtyOpt = {
          label: `${pluralize(group.name, 1, false)} Qty`,
          value: `${group._id}.quantity`,
        };

        // Add samples
        // Go through each of sampleJob's materials
        // If group matches, add sample
        if (sampleJob && sampleJob.materialMap) {
          for (const [groupId, materialAndQty] of Object.entries(
            sampleJob.materialMap
          )) {
            let materialObj = materials.find(
              (m) => m._id === materialAndQty.material
            );

            if (!materialObj) continue;

            if (groupId === group._id) {
              typeOpt.sample = materialObj.name;
              qtyOpt.sample = materialAndQty.quantity;
            }
          }
        }

        // Add to options
        allOptions.push(typeOpt);
        allOptions.push(qtyOpt);
      });
    }

    // Stage fields
    if (stages) {
      for (let stage of stages) {
        allOptions.push({
          label: `${stage.name} Stage`,
          value: `stage.${stage._id}`,
          sample:
            sampleJob && sampleJob.stageMap[stage._id]
              ? moment(sampleJob.stageMap[stage._id]).format("M/D/YY")
              : "-",
        });
      }
    }

    // Address fields
    allOptions.push({
      label: "Street",
      value: "address.street",
      sample: sampleJob?.address?.line1,
    });

    allOptions.push({
      label: "City",
      value: "address.city",
      sample: sampleJob?.address?.city,
    });

    allOptions.push({
      label: "State",
      value: "address.state",
      sample: sampleJob?.address?.state,
    });

    allOptions.push({
      label: "Postal Code",
      value: "address.postalCode",
      sample: sampleJob?.address?.postalCode,
    });

    // Contact fields
    allOptions.push({
      label: "Name",
      value: "contact.name",
      sample: sampleJob?.contact?.name,
    });

    allOptions.push({
      label: "Phone",
      value: "contact.phone",
      sample: sampleJob?.contact?.phone,
    });

    allOptions.push({
      label: "Email",
      value: "contact.email",
      sample: sampleJob?.contact?.email,
    });

    // Filter out already added fields
    allOptions = allOptions.filter((option) => {
      return !additionalFieldsValues.includes(option.value);
    });

    return allOptions;
  }

  /**
   * Retrieves the table fields for the WeekForecastDetails component.
   * Additional fields are added dynamically based on the values in `additionalFieldsValues` array.
   * @returns {[{lable: string,field: string}]} An array of table fields, each containing a label and a field.
   */
  function getTableFields() {
    let fields = [...tableFields];

    // Add additional fields
    for (let value of additionalFieldsValues) {
      fields.push({
        label: additionalFieldsLabels[value],
        field: value,
      });
    }

    return fields;
  }

  /**
   * Retrieves the sort options for the week forecast details.
   * Appends sort options based on the additional fields.
   *
   * @returns {Array<Array<Object>>} The sort options for the week forecast details.
   */
  function getSortOptions() {
    let options = [[...sortOptions[0]], []];

    // Add additional fields
    for (let value of additionalFieldsValues) {
      options[1].push({
        label: additionalFieldsLabels[value],
        value: value,
      });
    }

    return options;
  }

  return (
    <>
      <div className="flex flex-col">
        {/* Top Bar */}
        <div className="inset-0 h-14 p-2 bg-gray-50 border-b hidden lg:flex items-center">
          <div className="pl-6 text-xl leading-7 font-semibold">
            Forecast Details - {market ? market.name : "No Market Selected"}
          </div>
          <div className="ml-auto mr-2">
            <Link
              to={`/app/forecast/job${market ? "?market=" + market._id : ""}`}
            >
              <XMarkIcon className="h-5 w-5 text-gray-900 stroke-2" />
            </Link>
          </div>
        </div>

        {/* Body */}
        <div className="flex p-8">
          <div className="flex flex-col grow gap-6">
            {/* Donut and Weeks */}
            <div className="flex justify-between gap-16 px-8">
              <div>
                <SimpleJobDonut
                  numerator={
                    forecastData ? forecastData[weekIndex].potential : 0
                  }
                  denominator={
                    forecastData ? forecastData[weekIndex].potentialWoConv : 0
                  }
                />
              </div>

              <div className="grow flex flex-col">
                <div className="font-semibold">Job Forecast</div>

                {/* Week Details */}
                {forecastData && (
                  <ForecastInput
                    disabled
                    data={forecastData}
                    showWeeks
                    borderedWeek={weekIndex}
                    onToaFieldClick={handleWeekClick}
                  />
                )}
              </div>
            </div>
            {/* Bottom Section */}
            <Card>
              <div className="flex flex-col grow px-4 py-2">
                {/* Table Header */}
                <div className="flex justify-between items-center">
                  {/* Title */}
                  <div className="flex items-center gap-14">
                    {/* Jobs */}
                    <div>
                      <div className="text-lg font-bold">
                        {pluralize(
                          "Total Job",
                          forecastData
                            ? forecastData[weekIndex].potentialWoConv || 0
                            : 0,
                          true
                        )}
                      </div>
                      <div className="text-sm font-normal">
                        {forecastData
                          ? forecastData[weekIndex].potential || 0
                          : 0}{" "}
                        estimated in week {week.format("M/D")}
                      </div>
                    </div>
                  </div>

                  {/* Filter/Sort/Settings */}
                  <div className="flex gap-5">
                    <AdjustmentsVerticalIcon
                      onClick={handleFiltersClick}
                      className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
                    />
                    {/* <FunnelIcon
                      onClick={handleFunnelClick}
                      className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
                    />
                    <Cog6ToothIcon
                      onClick={handleSettingsClick}
                      className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
                    /> */}
                  </div>
                </div>

                {/* Table */}
                <div>
                  <Table
                    data={forecastData ? formatData(forecastData) : []}
                    noPadding
                    fields={getTableFields()}
                    noSelect
                    thinFirstColumn
                  />
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>

      {/* Slid Over */}

      <SlideOver
        open={showFiltersModal}
        setOpen={setShowFiltersModal}
        title="Detail View Filters"
      >
        <div className="flex flex-col gap-4">
          {/* Sort */}
          <div className="flex flex-col gap-2">
            <div>Sort By</div>
            <Dropdown
              wide
              options={getSortOptions()}
              justifyLeft
              selectedValue={sortBy}
              onSelected={handleSortSelected}
            />
            <Dropdown
              wide
              options={[
                [
                  {
                    label: "Ascending",
                    value: "asc",
                  },
                  {
                    label: "Descending",
                    value: "desc",
                  },
                ],
              ]}
              justifyLeft
              selectedValue={sortDir}
              onSelected={handleSortDirSelected}
            />
          </div>

          {/* Additional Fields */}
          <div className="flex flex-col gap-2">
            <div>Field Layout</div>
            <div className="flex flex-col">
              <div className="grid grid-cols-2 ml-11 pb-4 pt-1 text-sm font-semibold">
                <div>Field</div>
                <div>Sample</div>
              </div>
              <DraggableList
                values={additionalFieldsValues}
                setValueOrder={(values) => setAdditionalFieldsValues(values)}
                content={additionalFieldsContent}
              />
              <div className="flex justify-end border-y py-2">
                <div
                  className="flex gap-2 items-center cursor-pointer text-primary-green text-sm hover:text-primary-green-600"
                  onClick={() => setShowAddFieldModal(true)}
                >
                  <div>Add Field</div>
                  <PlusCircleIcon className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SlideOver>

      <DetailFieldSelectModal
        open={showAddFieldModal}
        setOpen={setShowAddFieldModal}
        options={getAddFieldOptions()}
        onAddField={handleAddField}
      />
    </>
  );
}

function BasicProgressBar({ value }) {
  var color = "bg-primary-green";

  // if (value < 50) {
  //   color = "bg-yellow-400";
  // }

  // if (value < 25) {
  //   color = "bg-red-600";
  // }

  return (
    <div className="flex items-center gap-2">
      <div>{value ? Math.round(value) : 0}%</div>
      <div className="bg-gray-200 grow max-w-[120px] rounded">
        <div
          className={classNames("rounded h-3", color)}
          style={{ width: (value || 0) + "%" }}
        ></div>
      </div>
    </div>
  );
}

function LineItem({ name, sample }) {
  return (
    <div className="grow grid grid-cols-2 py-1 text-sm">
      <div className="font-medium  text-gray-900">{name}</div>
      <div className="font-normal text-gray-500">{sample}</div>
    </div>
  );
}

async function getForecastData(marketId) {
  try {
    var res = await UserManager.makeAuthenticatedRequest(
      `/api/forecast/get?market=${marketId}&includeJobs=true`,
      "GET"
    );
  } catch (err) {
    console.error(err);
  }

  if (res.data.status === "ok") {
    // --- Set forecast data --- //

    // Start with empty 20 week forecast
    var tempForecastData = [];
    for (let i = 0; i < 20; i++) {
      tempForecastData.push({
        label: moment().utc().add(i, "weeks").startOf("week").format("M/D"),
        input: null,
        goal: null,
        potential: null,
        potentialWoConv: null, // Without conversion
        startOfWeek: moment().utc().add(i, "weeks").startOf("week"),
        week: moment().utc().add(i, "weeks").startOf("week").format("M/D"),
        jobs: [],
      });
    }

    // Go through each stage forecast and add to forecast data
    for (let stageId of Object.keys(res.data.stageForecasts)) {
      var stageForecast = res.data.stageForecasts[stageId];

      // Go through each week in stage forecast
      for (let week of Object.keys(stageForecast.weekCounts)) {
        // Match on week
        var weekMatch = tempForecastData.find((item) => {
          return item.startOfWeek.toISOString() === week;
        });

        // If no match, continue
        if (!weekMatch) continue;

        // Add to forecast data
        weekMatch.potential += stageForecast.weekCountsWConv[week];
        weekMatch.potentialWoConv += stageForecast.weekCounts[week];
        weekMatch.jobs.push(...stageForecast.weekJobs[week]);
      }
    }

    // Make sure potential is integer via ceiling
    for (let item of tempForecastData) {
      item.potential = Math.ceil(item.potential);
    }

    let stagesWithStats = res.data.stages.map((s, i) => {
      // Skip first stage
      if (i === 0) return s;
      let stageForecast = res.data.stageForecasts[s._id.toString()];

      return {
        ...s,
        activeInStage: stageForecast?.activeInStage ?? 0,
        avgTimeToInstall: stageForecast?.avgTimeToInstall,
        convRate: stageForecast?.convRate ?? 0,
      };
    });

    return {
      forecastData: tempForecastData,
      stages: stagesWithStats,
      market: res.data.market,
    };
  }
}

/**
 * Retrieves the material groups from the server.
 * @returns {Array} An array of material groups.
 */
async function getMaterialGroups() {
  try {
    var res = await UserManager.makeAuthenticatedRequest(
      "/api/materials/get-with-groups",
      "GET"
    );
  } catch (err) {
    console.error(err);
  }

  if (res.data.status === "ok") {
    return {
      materialGroups: res.data.groups,
      materials: res.data.materials,
    };
  }
}

/**
 * Formats the wattage value.
 *
 * @param {number} watts - The wattage value to be formatted.
 * @returns {string} The formatted wattage value with the unit "W".
 */
function formatWattage(watts) {
  if (!watts) return 0 + "W";
  return (
    Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(watts) + "W"
  );
}
