import React, { useEffect } from "react";
import { motion, useAnimate, useMotionValue } from "framer-motion";
import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

export default function TableHandle({
  slot: prop_slot = 0,
  numSlots = 21, // 21 slots will have values from 0 to 20
  onSlotChange = (newSlot) => {},
}) {
  const [scope, animate] = useAnimate();
  const x = useMotionValue(0);

  const [slot, setSlot] = React.useState(prop_slot);

  const [width, setWidth] = React.useState(0);

  // Set width on parent resize
  const [resizeObserver, setResizeObserver] = React.useState(
    new ResizeObserver(() => {
      setWidth(scope.current?.offsetParent.clientWidth);
    })
  );

  // Update slot when initialSlot changes
  useEffect(() => {
    animateToSlot(prop_slot);
    setSlot(prop_slot);
  }, [prop_slot, scope.current]);

  // Set x on width change
  useEffect(() => {
    if (scope.current && width > 0) {
      x.set((slot / (numSlots - 1)) * width - scope.current.offsetWidth / 2);
    }
  }, [width]);

  // Set resize observer
  useEffect(() => {
    resizeObserver.observe(scope.current?.offsetParent);

    return () => {
      if (scope.current) {
        resizeObserver.unobserve(scope.current.offsetParent);
      }
    };
  }, [scope.current]);

  /**
   * Updates slot to land in based on drag end
   */
  function handleDragEnd(event, info) {
    if (scope.current) {
      let xPos = x.get();

      // Get snap position
      let snapSlot = Math.round((xPos / width) * (numSlots - 1));

      // Set slot
      setSlot(snapSlot);
      onSlotChange(snapSlot);

      animateToSlot(snapSlot);
    }
  }

  /**
   * Animates the slider to the specified slot
   * @param {number} toSlot - Slot to animate to
   */
  function animateToSlot(toSlot) {
    if (scope.current) {
      let snapX = (toSlot / (numSlots - 1)) * width;
      animate(scope.current, {
        x: snapX - scope.current.offsetWidth / 2,
        // transition: { type: "spring", stiffness: 500, damping: 30 },
        transition: { type: "inertia", velocity: 100 },
      });
    }
  }

  return (
    <div className="absolute inset-0 pointer-events-none">
      <motion.div
        className="pointer-events-auto w-1 h-full bg-primary-green relative cursor-ew-resize"
        drag="x"
        dragConstraints={{
          left: 0,
          right: scope.current?.offsetParent.clientWidth,
        }}
        dragElastic={0}
        dragMomentum={false}
        onDragEnd={handleDragEnd}
        ref={scope}
        style={{ x }}
      >
        <div className="absolute top-[calc(100%-2px)] left-1/2 -translate-x-1/2 bg-primary-green w-4 h-4 rounded-full flex items-center justify-center">
          <div className="text-[7px] font-extrabold">
            <ChevronUpDownIcon className="h-4 w-4 text-white rotate-90 stroke-[3px]" />
          </div>
        </div>
      </motion.div>
    </div>
  );
}
