import React from 'react'

/**
 * TODO: not implemented
 */
export default function LocationsActivityPage() {
  return (
    <div>LocationsActivityPage</div>
  )
}
