import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import React from "react";
import Card from "./Card";
import Sparkline from "./charts/Sparkline";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from "recharts";
import classNames from "../tools/classNames";
import MiniDiffStat from "./stats/MiniDiffStats.tsx";
import ToaBarChart from "./BarChart.js";

var mainInfoExample = {
  title: "Title",
  value: "Value",
};

var subInfoExample = <>Example</>;

var sideInfoExample = [
  {
    title: "Title",
    value: "Value",
  },
  {
    title: "Title",
    value: "Value",
  },
];

export default function InfoCard({
  mainInfo = { title: "", value: "" },
  sideInfo,
  subInfo,
  showExpand,
  onExpand = () => { },
  graph,
  wide = false,
  children = <></>,
}) {
  var wideChartHeight = 100; // TODO:  solve this
  var minHeight_wideChart = 170; // TODO:  solve this
  // var wideChartHeight = "100%";

  return (
    <div
      className={classNames(
        "group shadow rounded-lg bg-white dark:bg-gray-800 dark:text-white flex h-full",
        showExpand
          ? "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-500"
          : ""
      )}
      onClick={() => onExpand()}
    >
      <div className="flex flex-col grow">
        <div className={classNames("flex px-6 py-5", wide ? "pb-0" : "")}>
          {/* Main Info */}
          <div className={classNames("flex flex-col whitespace-nowrap grow")}>
            {/* Title */}
            <div
              className={classNames(wide ? "flex gap-2 items-baseline" : "")}
            >
              <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
                {mainInfo && mainInfo.title}
              </div>
              {/* Value */}
              <div
                className={classNames(
                  "text-2xl font-semibold",
                  wide ? "order-first" : ""
                )}
              >
                {mainInfo && mainInfo.value}
              </div>
            </div>
            {/* Sub Info */}
            {subInfo && (
              <div className="pt-1 text-xs text-gray-500 dark:text-gray-300">
                <MiniDiffStat {...subInfo} />
              </div>
            )}
          </div>
          {/* Side Info */}
          {sideInfo && (
            <>
              <div
                className={classNames(
                  "flex flex-col justify-center pl-4 pr-2 ml-4 space-y-2",
                  wide ? "" : "border-l"
                )}
              >
                {sideInfo.map((info, index) => (
                  <div
                    key={index}
                    className={classNames(
                      "flex",
                      wide ? "flex-row gap-2 items-baseline" : "flex-col"
                    )}
                  >
                    {/* Title */}
                    <div className="text-xs font-medium text-gray-400 dark:gray-300">
                      {info.title}
                    </div>
                    {/* Value */}
                    <div className="text-base font-semibold text-gray-600 dark:gray-300">
                      {info.value}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        {/* Wide Graph */}
        {wide && graph && (
          // Note: relative->absolute wrapper is to force size of responsive container to shrink fast when resizing window.
          //       Animations are slow when shrinking recharts and it affected the flex layout.
          // Note: ResponsiveContainer width is 99% b/c there is a bug where it won't shrink if it's 100%
          <div
            className="relative mb-4 ml-4 grow"
            // style={{ height: wideChartHeight }}
            style={{ minHeight: minHeight_wideChart }}
          >
            <div className="absolute inset-0">

              <ToaBarChart {...graph} height="100%" hideTooltip xAxisSettings={{ ...graph.xAxisSettings, stroke: "#9CA3AF" }} />
            </div>
          </div>
        )}
        {/* Sparkline */}
        {!wide && graph && (
          <div
            className={classNames(
              "bg-gray-50 dark:bg-gray-900/50 rounded-b-lg p-4 grow",
              showExpand
                ? "group-hover:bg-gray-200 dark:group-hover:bg-gray-600"
                : ""
            )}
          >
            <Sparkline
              data={graph.data}
              dataKey={graph.dataKey}
              line={graph.line}
            />
          </div>
        )}
      </div>

      {/* Right Side Extra */}
      {wide && (
        <div className="px-3 py-2 my-3 border-l basis-1/4">{children}</div>
      )}
    </div>
  );
}

