import React, { Component } from 'react'
import { Col } from 'react-bootstrap'

export default class DistributorPage extends Component {
    render() {
        return (
            <Col className="pt-3 pb-3">
                <div className='flo-title'>Distributor</div>
            </Col>
        )
    }
}
