import { createContext } from "react";

const JobPipelineContext = createContext({
  // TODO: implement context
  // TODO: typedef
  selectedMarketIds: null,
  setSelectedMarketId: null,
  markets: null,
  stages: null,
  selectedStageIndex: null,
  search: null,
  setViewState: (state) => { }
});


export default JobPipelineContext;
