import React from 'react'

/**
 * TODO: not implemented
 */
export default function ConnectionsActivityPage() {
  return (
    <div>ConnectionsActivityPage</div>
  )
}
