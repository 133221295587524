import {
  CodeBracketIcon,
  Cog6ToothIcon,
  ComputerDesktopIcon,
} from "@heroicons/react/24/outline";
import React from "react";

export default function ComingSoon() {
  return (
    <div className="flex flex-col items-center justify-center h-screen gap-2">
      {/* Icon */}
      <div className="relative flex items-center justify-center">
        <Cog6ToothIcon className="w-20 h-20 text-gray-500 stroke-1 dark:text-gray-200" aria-hidden="true" />
      </div>

      {/* Text */}
      <div className="text-xl font-semibold text-gray-700 dark:text-white">
        Additional Support Needed
      </div>

      {/* Subtext */}
      <div className="text-gray-500 dark:text-gray-400">
        Please reach out to Team TOA: <a className="text-primary-rose-400 hover:text-primary-rose-700" href="mailto:support@toa.energy">support@toa.energy</a>
      </div>
    </div>
  );
}
