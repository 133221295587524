import React from "react";
import classNames from "src/tools/classNames";

type Props = {
  children: JSX.Element | JSX.Element[] | string;
  variant?: "green" | "rose";
};

export default function Badge({ children, variant = "green" }: Props) {
  var bg = "bg-primary-green";
  var text = "text-white";

  switch (variant) {
    case "green":
    default:
      bg = "bg-primary-green";
      text = "text-white";
      break;
    case "rose":
      bg = "bg-primary-rose";
      text = "text-white";
      break;
  }

  return (
    <div className="flex">
      <div
        className={classNames(
          bg,
          text,
          "rounded-full text-sm font-semibold px-2 py-1"
        )}
      >
        {children}
      </div>
    </div>
  );
}
