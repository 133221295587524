import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserManager from "../../tools/UserManager";
import Spinner from "../../components/Spinner";
import Card from "../../components/Card";
import Input from "../../components/input/Input";
import toa_semi_bold from "../../assets/logos/lightMode/toa_semi-bold.svg";
import toa_semi_bold_white from "../../assets/logos/darkMode/toa_semi-bold_white.svg";
import useTheme from "../../hooks/styling/useTheme";
import axios from "axios";

export default function ResetPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [requestSent, setRequestSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const theme = useTheme();

    const navigate = useNavigate();

    // When mounted, check if user is logged in. Redirect if so.
    useEffect(() => {
        setLoading(true);
        UserManager.getUser()
            .then((user) => {
                if (user.loggedIn) {
                    navigate("/app");
                }
            })
            .catch((err) => {
                setLoading(false);
            });
        setLoading(false);
    }, []);

    var submitBtn = loading ? (
        <div style={{ position: "relative", minHeight: 40 }}>
            <Spinner />
        </div>
    ) : (
        <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-primary-green px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-green"
        >
            Submit Request
        </button>
    );

    /**
      * Validate email address using regex
      * there are many regexes for email validation, this one is simple and should work for most cases
      *
      * @param {string} email - email address to validate
      * @returns {boolean} - true if email is valid, false otherwise
      */
    function validateEmail(email) {
        // source for email regex: https://www.abstractapi.com/guides/email-validation-regex-javascript
        var re = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
        return re.test(email);
    }

    /**
     * 
   */
    async function sendRequest(event) {
        event.preventDefault(); // Don't refresh/redirect from form

        setLoading(true);
        await axios.post("/api/user/request-password-reset", { email: email }).then((response) => {
            if (response.data.status == "ok") {
                console.log("Password reset email sent");
                setRequestSent(true);
            }
            else {
                console.log("Error sending password reset email");
                setRequestSent(true);
                // TODO figure out best way to handle errors so we dont give away any info
            }
        }
        ).catch((error) => {
            console.log(error);
        });

        setLoading(false);
    }

    if (!requestSent) {
        return (
            <>
                <div className="flex flex-col pb-6">
                    <div className="mt-2.5">
                        <div className="flex flex-row mx-2 px-2 py-2.5 items-center justify-center flex-wrap gap-y-2">
                            <div className="text-4xl font-semibold leading-10 text-center text-gray-700 align-middle text-nowrap dark:text-gray-50">
                                Reset Password for
                            </div>
                            <img
                                className="flex-none w-auto justify-self-start mx-[5px] pl-[5px] h-7"
                                src={theme === "dark" ? toa_semi_bold_white : toa_semi_bold}
                                alt="Toa"
                            />
                        </div>
                    </div>
                    <div className="px-2 my-2 pb-2.5">
                        <div className="text-xl font-medium leading-7 text-center text-gray-900 align-middle dark:text-gray-200 text-pretty">
                            Enter your email to recover your account
                        </div>
                    </div>
                </div>
                <form className="max-w-xs mx-auto space-y-6" onSubmit={sendRequest}>
                    <div>
                        <div className="mt-2">
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                autoComplete="email"
                                placeholder="john.doe@example.com"
                                label={"Email address"}
                                required
                                onChange={setEmail}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    {/* Error Message */}
                    <div className="text-red-500">{error}</div>

                    <div>{submitBtn}</div>
                </form>
            </>
        );
    }
    if (requestSent) {
        return (
            <Card className="flex flex-col items-center justify-center bg-white dark:!bg-gray-700 gap-3">
                <div className="text-xl font-medium leading-7 text-center text-gray-900 align-middle dark:text-gray-200 text-pretty">
                    Password reset email was sent if an account with that email exists
                </div>
                <div className="mb-1 text-sm font-normal leading-5 text-center text-pretty">
                    Can't find the email? Check your spam folder or
                    <span className="font-semibold cursor-pointer text-primary-rose hover:text-primary-rose-400" onClick={() => setRequestSent(false)}>
                        {" "} try again
                    </span>
                </div>
            </Card>
        );
    }
}
