import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Button({
  variant = "primary-rose",
  onClick,
  children,
  disabled = false,
  className = "",
  dropdownItems = [],
  id=undefined
}) {
  switch (variant) {
    case "primary":
    case "primary-rose":
    case "primary-green":
    default:
      return (
        <button
          id={id}
          disabled={disabled}
          className={classNames(
            disabled
              ? "bg-gray-200 text-gray-400"
              : `bg-${
                  variant === "primary" || !variant ? "primary-green" : variant
                }
              text-white
                `,
            `
            text-sm
            font-semibold
            rounded-lg
            px-4
            py-2
            shadow
            hover:bg-opacity-80
            `,
            `focus-visible:outline-primary-green-300 focus-visible:ring-primary-green-300`,
            className
          )}
          onClick={onClick}
        >
          {children}
        </button>
      );
    case "secondary":
      return (
        <button
          id={id}
          disabled={disabled}
          className={classNames(
            disabled
              ? "text-gray-400"
              : "hover:bg-gray-100 text-gray-900",
            `
            bg-white dark:bg-gray-700
            text-sm
            font-semibold
            rounded-lg
            px-4
            py-2
            hover:bg-gray-100 dark:hover:bg-gray-600
            border dark:border-gray-500
            `,
            className
          )}
          onClick={onClick}
        >
          {children}
        </button>
      );

    case "dropdown":
      return (
        <div className="flex">
          <button
            id={id}
            disabled={disabled}
            className={classNames(
              `
          bg-white
          hover:bg-gray-200
          text-gray-600
          ring-1
          ring-gray-300
          rounded-l-md
          font-semibold
          text-sm
          px-6
          py-1
          `,
              className
            )}
            onClick={onClick}
          >
            {children}
          </button>
          <Menu as="div" className="relative">
            <div>
              <Menu.Button className="justify-center px-1 py-2 text-sm font-semibold text-gray-900 bg-white rounded-r-md ring-1 ring-gray-300 hover:bg-gray-200">
                <ChevronDownIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {dropdownItems.map((item, i) => {
                    return (
                      <Menu.Item key={i}>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            onClick={item.action ?? (() => {})}
                          >
                            {item.label}
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      );
  }
}
