import moment from "moment";
import Week from "./Week";
import Job from "./Job";
import Stage from "./Stage";

/**
 * Raw forecast data from the server.
 * Broken down by stage and then by week.
 *
 * @typedef RawForecastData
 * @property {string} status - The status of the request.
 * @property {{
 *  _stageId_:{
 *    convRate:number,
 *    avgTimeToInstall:number,
 *    activeInStage:number,
 *    weekCounts:{
 *      _weekDate_:number
 *    },
 *    weekCountsWConv:{
 *      _weekDate_:number
 *    },
 *    weekJobs: {
 *     _weekDate_:[JobModel]
 *    }
 *  }
 * }} stageForecasts - Forecasts broken down by stage
 * @property {MarketModel} market - The market the forecast is for
 * @property {[StageModel]} stages - The installer's stages
 */

export default class Forecast {
  // ------------------ //
  // --- Properties --- //
  // ------------------ //

  /**
   * All materials that could be used in the forecast.
   * @type {{_materialId_:MaterialModel}}
   */
  allMaterials = [];

  /**
   * Default materials for the forecast.
   * Map of material group ID to material ID.
   * Null means no default material.
   * @type {{_materialGroupId_:ObjectId}}
   */
  defaultMaterials = {};

  /**
   * The material groups used in the forecast.
   * @type {MaterialGroup[]}
   */
  allMaterialGroups = [];

  /**
   * The materials used in the forecast.
   * Built up in the fromRawForecastData function.
   * This maps material group ID to material ID to number of jobs using that material.
   * @type {{_materialGroupId_:{_materialId_:number}}}
   */
  usedMaterials = {};

  /**
   * The weeks in the forecast.
   * @type {Week[]}
   */
  weeks = [];
  // TODO: setter getter? readonly/private?

  /**
   * The stages in the forecast.
   * @type {Stage[]}
   */
  stages = [];
  // TODO: setter getter? readonly/private?

  /**
   * The jobs in the forecast. Sorted by estimated date.
   * @type {Job[]}
   */
  #jobs = [];

  /**
   * The jobs in the forecast. Sorted by estimated date.
   */
  get jobs() {
    return this.#jobs;
  }

  // ------------------- //
  // --- Constructor --- //
  // ------------------- //

  constructor() { }

  // --------------- //
  // --- Methods --- //
  // --------------- //
  //#region Methods

  /**
   * Creates 20 weeks starting from the current week.
   * Sets material groups for the weeks.
   */
  createDefaultWeeks() {
    for (var i = 0; i < 20; i++) {
      let weekStart = moment().utc().add(i, "week").startOf("week");
      this.weeks.push(new Week(weekStart, this.allMaterialGroups));
    }
  }

  /**
   * Moves specified job to the specified week.
   * This updates both the job and the week.
   *
   * @param {Job} job - Job to move
   * @param {number} index - Index of week to move the job to
   * @throws {Error} - If the index is invalid
   */
  moveJobToWeekIndex(job, index) {
    // Validate index
    if (index < 0 || index >= this.weeks.length) {
      throw new Error("Invalid week index");
    }

    // Remove job from current week
    if (job.week) {
      job.week.removeJobs([job]);
    }

    // Add job to new week
    this.weeks[index].addJobs([job]);
    job.week = this.weeks[index];
  }

  /**
   * Moves specified job to the specified week.
   * This updates botht the job and the week.
   *
   * @param {Job} job - Job to move
   * @param {Week} week - Week to move the job to
   * @throws {Error} - If the week is not in the forecast
   */
  moveJobToWeek(job, week) {
    this.moveJobToWeekIndex(job, this.weeks.indexOf(week));
  }

  /**
   * Removes all jobs from the given week.
   * Also removes the week from the jobs.
   *
   * @param {Week} week - The week to clear
   */
  clearWeek(week) {
    let weekJobs = week.jobs;
    if (week.jobs.length > 0) {
      week.removeJobs(week.jobs);
      for (let job of weekJobs) {
        job.week = null;
      }
    }
  }

  /**
   * Adds the given jobs to the forecast. Enforces sorting by estimated date.
   *
   * @param {Job[]} jobs - The jobs to add to the forecast
   */
  addJobs(jobs) {
    /**
     * Binary insert the job into the jobs array.
     *
     * @param {Job} job - The job to insert
     * @param {Job[]} jobs - The jobs array to insert into
     */
    function binaryInsert(job, jobs) {
      let low = 0;
      let high = jobs.length;
      while (low < high) {
        let mid = (low + high) >>> 1; // Divide by 2 and truncate
        if (jobs[mid].estimatedDate < job.estimatedDate) low = mid + 1;
        else high = mid;
      }
      jobs.splice(low, 0, job);
    }

    // Add jobs to forecast
    jobs.forEach((job) => {
      binaryInsert(job, this.#jobs);
    });
  }

  /**
   * Removes the given jobs from the forecast.
   *
   * @param {Job[]} jobs - The jobs to remove from the forecast
   */
  removeJobs(jobs) {
    jobs.forEach((job) => {
      let index = this.#jobs.indexOf(job);
      if (index !== -1) {
        this.#jobs.splice(index, 1);
      }
    });
  }

  /**
   * Adjusts the jobs materials based on defaults.
   * If a job is missing a material, it will use the default material for the group.
   * If a job is missing a quantity, it will use the default quantity config for the material.
   *
   * @param {JobModel} job
   * @returns {JobModel} - The job model with the materials applied
   */
  applyDefaults(job) {
    // Deep clone job
    let jobWithDefaults = structuredClone(job);

    // Go through job's material map
    for (let materialGroup in jobWithDefaults.materialMap) {
      let { material: matId, quantity: qty } =
        jobWithDefaults.materialMap[materialGroup];

      // If material is missing, use default
      if (!matId) {
        jobWithDefaults.materialMap[materialGroup].material = "default";
      }

      // If has quantity, use it
      if (qty !== undefined && qty !== null) {
        continue;
      }

      // If quantity is missing, use default

      // Get material
      let mat = matId
        ? this.allMaterials[matId]
        : this.allMaterials[this.defaultMaterials[materialGroup]];

      // If no material exists or no quantity config, use 0 quantity
      if (!mat || !mat.defaultQuantityConfig) {
        jobWithDefaults.materialMap[materialGroup].quantity = 0;
        continue;
      }

      // ---  Apply default quantity config --- //

      let config = mat.defaultQuantityConfig;
      switch (config.configType) {
        // If constant, use constant
        case "constant":
          jobWithDefaults.materialMap[materialGroup].quantity = config.value;
          break;

        // If per unit, multiply value by unit to apply
        case "perUnit":
          let unit = jobWithDefaults[config.unit];
          jobWithDefaults.materialMap[materialGroup].quantity =
            config.value * unit;
          break;

        // If default, use 0
        default:
          jobWithDefaults.materialMap[materialGroup].quantity = 0;
          break;
      }
    }

    return jobWithDefaults;
  }

  /**
   * Pretty prints the forecast to the console.
   * Useful for debugging.
   * Uses console.table to show lots of values by week.
   */
  print() {
    let tablePrint = {
      weeks: this.weeks.map((week) => week.date.format("M/D")),
      input: this.weeks.map((week) => week.inputCount),
      jobs: this.weeks.map((week) => week.jobs.length),
      likelyJobs: this.weeks.map((week) => week.likelyJobCount),
      toaJobsWConv: this.weeks.map((week) => Math.ceil(week.toaCount)),
      toaJobsWoConv: this.weeks.map((week) => week.toaCountWoConv),
      available: this.getAvailablesAndRemainders().available,
      remainder: this.getAvailablesAndRemainders().remainder,
    };

    // Add used materials to table print
    for (let matGroup in this.usedMaterials) {
      for (let matId in this.usedMaterials[matGroup]) {
        tablePrint[`${matGroup}:${matId}`] = this.weeks.map((week) => {
          return week.materials[matGroup] && week.materials[matGroup][matId]
            ? week.materials[matGroup][matId]
            : 0;
        });
      }
    }

    console.table(tablePrint);
  }

  /**
   * Gets the forecast by week in a simple format.
   *
   * @returns {[{
   *  label: string,
   *  startOfWeek: Date,
   *  input: number,
   *  potentialWoConv: number,
   *  potential: number, 
   *  goal: number,
   *  materials: {[materialGroupId:string]:{[materialId:string]:number}},
   *  materialsWoConv: {[materialGroupId:string]:{[materialId:string]:number}},
   *  neededForInputLikely: number,
   *  available: number,
   *  remainder: number
   * }]} - The forecast by week
   */
  getByWeek() {
    let availablesAndRemainders = this.getAvailablesAndRemainders();
    return this.weeks.map((week, i) => {
      return {
        label: week.date.format("M/D"),
        startOfWeek: week.date,

        input: week.inputCount,
        potentialWoConv: week.toaCountWoConv,
        potential: Math.ceil(week.toaCount),
        goal: week.goal,
        materials: week.materials,
        materialsWoConv: week.materialsWoConv,
        neededForInputLikely: week.jobs.length,
        available: availablesAndRemainders.available[i],
        remainder: availablesAndRemainders.remainder[i],
      };
    });
  }

  /**
   * Adjusts the input count for the specified week.
   * Also updates the job allocations based on the input count.
   * Allocates more jobs than specified so that likely jobs equals input count.
   *
   * @param {number} weekIndex - Index of week to set input for
   * @param {number} input - Input count for the week
   */
  setWeekInput(weekIndex, input) {
    this.weeks[weekIndex].inputCount = input;

    // Update job allocations based on input
    // Slot jobs into weeks based on input counts
    // Want likely jobs to equal input count

    // Clear all week jobs
    for (let week of this.weeks) {
      this.clearWeek(week);
    }

    // Go through weeks
    let jobsIndex = 0;
    for (let week of this.weeks) {
      // Allocate jobs until likely job count equals input count
      while (week.likelyJobCount < week.inputCount) {
        // If no more jobs, break
        if (jobsIndex >= this.jobs.length) {
          break;
        }

        // Add job to week
        let job = this.jobs[jobsIndex];
        this.moveJobToWeek(job, week);
        jobsIndex += 1;
      }
    }
  }

  /**
   * Sets all the input counts for the forecast.
   * The length of the inputs array must match the length of the weeks array.
   *
   * If the asOf date is provided, the inputs will be adjusted to match the weeks.
   * For example, if the asOf date was last week, the inputs would be shifted to match the weeks.
   *
   * @param {number[]} inputs - The input counts for each week
   * @param {Date} asOf - The week the inputs were generated
   *
   * @throws {Error} - If the inputs length does not match the weeks length
   */
  setWeekInputs(inputs, asOf = null) {
    if (inputs.length !== this.weeks.length) {
      throw new Error("Inputs length must match weeks length");
    }

    // Calculate offset of weeks
    let offset = 0;
    if (asOf) {
      let asOfMoment = moment(asOf).utc().startOf("week");
      let firstWeek = this.weeks[0].date;
      offset = asOfMoment.diff(firstWeek, "weeks");
    }

    inputs.forEach((input, i) => {
      // Calculate index of week based on offset
      let weekIndex = i + offset;
      if (weekIndex < 0 || weekIndex >= this.weeks.length) {
        return;
      }

      this.setWeekInput(weekIndex, input);
    });
  }

  /**
   * Gets the total input count for the forecast.
   *
   * @returns {number} - The total input count for the forecast
   */
  getTotalInput() {
    return this.weeks.reduce((acc, week) => acc + week.inputCount, 0);
  }

  /**
   * Gets the total likely job count for the forecast.
   *
   * @returns {number} - The total likely job count for the forecast
   */
  getTotalLikely() {
    // TODO: ceil at end?
    return this.weeks.reduce((acc, week) => acc + Math.ceil(week.toaCount), 0);
  }

  /**
   * Reports the weeks of capacity. This is the number of
   * weeks before existing jobs are completed.
   *
   * @returns {number} - The number of weeks before existing jobs are completed
   */
  getWeeksOfCapacity() {
    return this.weeks.reduce((acc, week) => {
      if (week.likelyJobCount === 0 || week.inputCount === 0) {
        return acc;
      }

      // If enough jobs to meet input, add 1 to capacity
      // Else add `likely / input` to capacity
      if (week.likelyJobCount >= week.inputCount) {
        return acc + 1;
      } else {
        return acc + week.likelyJobCount / week.inputCount;
      }
    }, 0);
  }

  /**
   * Reports, by week, the available and remainder of jobs based on the input count and
   * TOA estimations. The available is the TOA estimation for the week plus the remainder
   * from the previous week. The remainder is the available minus the input count.
   *
   * @returns {{available:[number],remainder:[number]}} - The estimated available and remainder by week
   */
  getAvailablesAndRemainders() {

    let inputs = this.weeks.map((week) => week.inputCount);
    let estimated = this.weeks.map((week) => week.toaCount);

    return Forecast.generateAvailableAndRemainder(inputs, estimated);
  }

  /**
   * Formats the forecast for saving to the server.
   * Specifically the /api/forecast/save POST endpoint.
   *
   * @returns {{
   *  currentWeek:Date,
   * numActiveJobs:number,
   * numEstimatedJobs:number,
   * numInputJobs:number,
   * weeksOfCapacity:number,
   * toaForecastWoConversion:number[],
   * toaForecast:number[],
   * inputForecast:number[],
   * toaMaterialForecast:{materialGroupId:{materialId:number[]}},
   * stageStats:{
   *  ID:ObjectId,
   *  activeInStage:number,
   *  avgTimeToInstall:number,
   *  convRate:number,
   *  fromWeek:number,
   *  toWeek:number,
   *  name:string,
   *  order:number
   * }[]
   * }}
   */
  formatForSaving() {
    let result = {
      currentWeek: this.weeks[0].date.toDate(),

      numActiveJobs: this.jobs.length,
      numEstimatedJobs: this.getTotalLikely(),
      numInputJobs: this.getTotalInput(),

      weeksOfCapacity: this.getWeeksOfCapacity(),

      toaForecastWoConversion: this.weeks.map((week) => week.toaCountWoConv),
      toaForecast: this.weeks.map((week) => week.toaCount),
      inputForecast: this.weeks.map((week) => week.inputCount),

      stageStats: this.stages,
    };


    // --- Build toaMaterialForecast --- //

    let toaMaterialForecast = {};

    // Iterate through weeks and build material forecast structure
    for (let week of this.weeks) {

      // Iterate through materials in week
      for (let matGroup in week.materials) {

        // If material group not in forecast, add it
        if (!toaMaterialForecast[matGroup]) {
          toaMaterialForecast[matGroup] = {};
        }

        // Iterate through materials in group
        for (let matId in week.materials[matGroup]) {

          // If material not in forecast, add it
          if (!toaMaterialForecast[matGroup][matId]) {
            toaMaterialForecast[matGroup][matId] = [];
          }
        }
      }
    }

    // Iterate through weeks and populate material forecast
    for (let week of this.weeks) {

      // Iterate through materials (not just in week, but all materials in forecast)
      for (let matGroup in toaMaterialForecast) {

        // Iterate through materials in group
        for (let matId in toaMaterialForecast[matGroup]) {

          // Get values for material
          // 0 if null or undefined
          let matVal = week.materials[matGroup] && week.materials[matGroup][matId]
            ? week.materials[matGroup][matId]
            : 0;

          // Add material count to forecast
          toaMaterialForecast[matGroup][matId].push(matVal);
        }
      }
    }

    result.toaMaterialForecast = toaMaterialForecast;


    return result;
  }

  //#endregion
  // ---------------------- //
  // --- Static Methods --- //
  // ---------------------- //
  //#region Static Methods

  /**
   * Creates a new instance of the Forecast class from the raw forecast data.
   *
   * @param {RawForecastData} rawForecastData - The raw forecast data from the server
   *
   * @return {Forecast} - The forecast
   */
  static fromRawForecastData(rawForecastData, allMaterials, allMaterialGroups) {
    let forecast = new Forecast();

    // Turn allMaterials into dict of _id:material
    forecast.allMaterials = allMaterials.reduce((acc, mat) => {
      acc[mat._id] = mat;
      return acc;
    }, {});
    // Get default materials for each group
    forecast.defaultMaterials = allMaterialGroups.reduce((acc, group) => {
      acc[group._id] = group.defaultMaterial;
      return acc;
    }, {});
    // Set allMaterialGroups
    forecast.allMaterialGroups = allMaterialGroups;

    // Create weeks (must be after allMaterialGroups so weeks get material groups)
    forecast.createDefaultWeeks();

    // Go through stages in raw forecast data
    for (let stageId of Object.keys(rawForecastData.stageForecasts)) {
      /**
       * @type {{
       *    convRate:number,
       *    avgTimeToInstall:number,
       *    activeInStage:number,
       *    weekCounts:{
       *      _weekDate_:number
       *    },
       *    weekCountsWConv:{
       *      _weekDate_:number
       *    },
       *    weekJobs: {
       *     _weekDate_:[JobModel]
       *    }
       * }}
       */
      let stageForecast = rawForecastData.stageForecasts[stageId];

      // Create stage
      let stageModel = rawForecastData.stages.find(
        (stage) => stage._id === stageId
      );
      let stage = new Stage(
        stageId,
        stageModel.name,
        stageForecast.convRate,
        stageForecast.avgTimeToInstall,
        stageForecast.activeInStage,
        stageModel.order
      );
      forecast.stages.push(stage);

      // Go through weeks in stage forecast
      for (let weekDate of Object.keys(stageForecast.weekCounts)) {
        let weekIndex = forecast.weeks.findIndex((week) =>
          week.date.isSame(moment(weekDate), "week")
        );
        stage.recordWeek(weekIndex);

        if (weekIndex !== -1) {
          let week = forecast.weeks[weekIndex];

          // Stats
          week.toaCountWoConv += stageForecast.weekCounts[weekDate];
          week.toaCount += stageForecast.weekCountsWConv[weekDate];

          // Jobs
          let weekToaJobs = stageForecast.weekJobs[weekDate];
          let weekJobs = weekToaJobs.map((job) => {
            // Track used materials
            for (let matGroup in job.materialMap) {
              let matId = job.materialMap[matGroup].material;
              if (!matId) matId = "default";

              // If group not in used materials, add it
              if (!forecast.usedMaterials[matGroup]) {
                forecast.usedMaterials[matGroup] = {};
              }

              // If material not in used materials, add it
              if (!forecast.usedMaterials[matGroup][matId]) {
                forecast.usedMaterials[matGroup][matId] = 0;
              }

              // Add quantity to used materials
              forecast.usedMaterials[matGroup][matId] += 1;
            }

            return new Job(
              job._id,
              stage,
              moment(job.estimatedInstallDate),
              forecast.applyDefaults(job),
              week
            );
          });

          // Note: don't add jobs to week yet as those are based on input count
          forecast.addJobs(weekJobs);
        }
      }
    }

    return forecast;
  }

  /**
   * Creates a shallow copy of the given forecast.
   * Useful for updating React state (e.g. `setForecast(Forecast.copy(oldForecast))`).
   * Referenced internal objects are not copied so changes to them will affect the original forecast.
   *
   * @param {Forecast} forecast - The forecast to copy
   * @return {Forecast} - The copy of the forecast
   */
  static shallowCopy(forecast) {
    let newForecast = new Forecast();
    newForecast.allMaterials = forecast.allMaterials;
    newForecast.defaultMaterials = forecast.defaultMaterials;
    newForecast.allMaterialGroups = forecast.allMaterialGroups;
    newForecast.usedMaterials = forecast.usedMaterials;
    newForecast.weeks = forecast.weeks;
    newForecast.#jobs = forecast.#jobs;
    newForecast.stages = forecast.stages;
    return newForecast;
  }

  /**
   * Generates available and remainder from inputs and estimated.
   * Available will accumulate over weeks if input uses less than available.
   * Remainder will be negative if input uses more than available.
   * Negatives will not accumulate (i.e. no borrowing from future weeks).
   * 
   * @param {number[]} inputs 
   * @param {number[]} estimated 
   * @returns 
   */
  static generateAvailableAndRemainder(inputs, estimated) {
    if (inputs.length !== estimated.length) {
      throw new Error("Inputs and estimated must be same length");
    }

    let available = [];
    let remainder = [];

    // Go through weeks
    for (let i = 0; i < inputs.length; i++) {
      // Max of 0 and last week's remainder so it doesn't calculate off negative (don't accumulate missing)
      let lastRemainder = i === 0 ? 0 : Math.max(remainder[i - 1], 0);

      // Available is last week's remainder plus this week's estimated
      available.push(Math.ceil(lastRemainder + estimated[i]));

      // Remainder is available minus input
      remainder.push(Math.ceil(available[i] - inputs[i]));
    }

    return { available, remainder };
  }

  //#endregion
}
