import React from "react";
import { ReferenceLine, ResponsiveContainer, Line, LineChart } from "recharts";

export default function Sparkline({ data, dataKey, line = null, height="100%", minHeight=150 }) {
  // TODO: get primary-green dynamically (https://stackoverflow.com/questions/61118060/how-to-access-tailwind-colors-from-javascript)
  var primaryGreen = "#1CB7BE";

  // Determine line label position
  var lineLabelPos = "insideTopLeft"; // Default (if no data)
  if (line && line.value) {
    var min = null;
    var max = null;
    data.forEach((d) => {
      let value = d[dataKey];
      if (min == null || min > value) {
        min = value;
      }
      if (max == null || max < value) {
        max = value;
      }
    });
    if (min != null) {
      let middle = (max + min) / 2.0;
      lineLabelPos = line.value > middle ? "insideTopLeft" : "insideBottomLeft";
    }
  }

  return (
    // Note: relative->absolute wrapper is to force size of responsive container to shrink fast when resizing window.
    //       Animations are slow when shrinking recharts and it affected the flex layout.
    // Note: ResponsiveContainer width is 99% b/c there is a bug where it won't shrink if it's 100%
    <div className="relative w-full" style={{ height, minHeight }}>
      <div className="absolute inset-0">
        <ResponsiveContainer width="99%" height={height}>
          <LineChart data={data}
          cursor="pointer"
          >
            <Line
              connectNulls
              type="monotone"
              dataKey={dataKey}
              stroke={primaryGreen}
              strokeWidth={3}
              dot={false}
            />
            {line && (
              <ReferenceLine
                y={line.value}
                label={{
                  value: line.label,
                  position: lineLabelPos,
                }}
                strokeDasharray="4 4"
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
