import React, { useEffect } from "react";
import Button from "../../components/input/Button";
import { Grid } from "react-bootstrap-icons";
import MarketInfoCard from "../../components/OperationsSetup/MarketsPage/MarketInfoCard";
import { FolderPlusIcon, MapIcon } from "@heroicons/react/24/outline";
import AddMarketModal from "../../components/OperationsSetup/MarketsPage/AddMarketModal";
import UserManager from "../../tools/UserManager";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleMarkets = [
  {
    _id: 1,
    name: "Colorado",
    marketFormulaValues: ["CO", "Colorado", "colorado"],
    primaryDistributor: null,
    mainCrews: [],
    description: "Colorado market description",
    status: "active",
    createdAt: "2021-07-01T00:00:00.000",
    stats: {
      totalInstalls: 9600,
      totalPipeline: 721,
      wattageYTD: 10_800_000,
      last6Months: [15, 20, 4, 7, 6, 10],
    },
  },
];

export default function MarketsPage() {
  const [markets, setMarkets] = React.useState(null); // TODO: replace with API call

  const [showAddMarketModal, setShowAddMarketModal] = React.useState(false);

  useEffect(() => {
    // Get markets from API
    if (!markets) {
      UserManager.makeAuthenticatedRequest(
        "/api/operations-setup/markets/get-markets",
        "GET"
      )
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            setMarkets(res.data.markets);
          } else {
            console.log("Error getting markets");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  var content = markets ? (
    <GridView markets={markets} />
  ) : (
    <div className="flex flex-wrap gap-3">
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </div>
  );

  // If no markets, show empty state
  if (markets && markets.length === 0) {
    content = (
      <div>
        <EmptyState setShowAddMarketModal={setShowAddMarketModal} />
      </div>
    );
  }

  function handleCreateMarket(newMarket) {
    console.log('here');
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/markets/add-market",
      "POST",
      newMarket
    )
      .then((res) => {
        console.log('then', res.data);
        var status = res.data.status;
        if (status === "ok") {
          var m = res.data.market;
          if (!m.stats) m.stats = {};

          // Add new market to list
          var temp = [...markets];
          temp.push(m);
          setMarkets(temp);
          setShowAddMarketModal(false);
        } else {
          alert("Error creating market. Markets must have unique names.");
        }
      })
      .catch((err) => {
        console.log('catch');
        console.error(err);
        alert("Error creating market. Markets must have unique names.")
      });
  }

  return (
    <>
      {/* Actions */}
      <div className="p-2 flex items-center gap-4 flex-wrap">
        {/* TODO: change to dropdown */}
        <div className="basis-full lg:basis-0">
          <div className="font-semibold text-lg">Markets</div>
        </div>

        {/* TODO: search */}

        {/* Buttons and Toggle */}

        <div className="ml-auto">{/* TODO: toggle grid/list */}</div>

        <div>
          <Button
            onClick={() => {
              setShowAddMarketModal(true);
            }}
            variant="primary-green"
          >
            Add Market
          </Button>
        </div>
      </div>

      {/* Body */}
      <div className="overflow-auto">{content}</div>

      {/* Modals */}
      <AddMarketModal
        open={showAddMarketModal}
        setOpen={setShowAddMarketModal}
        handleNewMarket={handleCreateMarket}
      />
    </>
  );
}

function GridView({ markets }) {
  return (
    <div className="flex w-100 gap-8">
      {/* Left Side */}
      {/* TODO: market map */}

      {/* Right Side */}
      <div className="grow">
        <div className="flex flex-wrap gap-6">
          {markets.map((market, index) => (
            <MarketInfoCard key={index} market={market} />
          ))}
        </div>
      </div>
    </div>
  );
}

function LoadingCard() {
  function LoadingBar({ height = 20, width = 100 }) {
    return (
      <div
        className="bg-gray-200 rounded-lg animate-pulse"
        style={{ height: `${height}px`, width: `${width}px` }}
      ></div>
    );
  }

  return (
    <div className="shadow rounded-lg bg-white flex flex-col">
      {/* Header */}
      <div className="border-b rounded-t-lg py-5 px-6 bg-gray-50 flex items-center justify-between gap-4">
        {/* Title */}
        <div className="flex gap-2 flex-col text-base">
          <LoadingBar />
          <LoadingBar height={15} />
        </div>
        {/* Active Icon */}
        <div>
          <LoadingBar height={30} width={30} />
        </div>
        {/* Button */}
        <div>
          <LoadingBar height={30} width={100} />
        </div>
      </div>
      {/* Total Installs */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <LoadingBar />
        <LoadingBar width={150} />
      </div>
      {/* Total Pipeline */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <LoadingBar />
        <LoadingBar width={75} />
      </div>
      {/* Created Date */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <LoadingBar />
        <LoadingBar />
      </div>
      {/* Last 6 Months */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <LoadingBar />
        <LoadingBar width={125} />
      </div>
    </div>
  );
}

function EmptyState({ setShowAddMarketModal }) {
  return (
    <div className="flex flex-col justify-center items-center mt-[20vh] lg:mt-[30vh]">
      <MapIcon className="w-12 h-12 text-gray-400" />
      <div className="text-sm font-semibold text-gray-900">
        No Markets Found
      </div>
      <div className="text-sm font-normal text-gray-500">
        Add a market to get started.
      </div>
      <div>
        <Button
          variant="primary-green"
          className="mt-6"
          onClick={() => {
            setShowAddMarketModal(true);
          }}
        >
          Add Market
        </Button>
      </div>
    </div>
  );
}
