import { createContext } from "react";

export type ForecastHistoryContextType = {
  markets: MarketType[];
  materials: MaterialType[];
  materialGroups: MaterialGroupType[];
};

export type MarketType = {
  name: string;
  _id: string;
}

export type MaterialType = {
  name: string;
  model: string;
  brand: string;
  _id: string;
}

export type MaterialGroupType = {
  name: string;
  _id: string;
}

const ForecastHistoryContext = createContext<ForecastHistoryContextType>({
  markets: null,
  materials: null,
  materialGroups: null,
});

export default ForecastHistoryContext;