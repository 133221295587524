module.exports = {
  isValidMapping,
  isValidRawJobMapping,
};

function isValidRawJobMapping(rawJobMapping, schema) {
  if (!rawJobMapping) return false;
  if (!schema) return false;

  var baseKeys = [
    "name",
    "marketFormula",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "postalCode",
    "contactName",
    "contactPhone",
    "contactEmail",
    "systemSize",
  ];

  var materialGroupKeys = ["brand", "model", "name", "quantity"];

  // Check base keys
  for (var i = 0; i < baseKeys.length; i++) {
    if (!(baseKeys[i] in rawJobMapping)) {
      return false;
    }

    if (!isValidMapping(rawJobMapping[baseKeys[i]], schema)) {
      return false;
    }
  }

  // Check stages
  if (!rawJobMapping.stages || !Array.isArray(rawJobMapping.stages)) {
    return false;
  }

  for (var i = 0; i < rawJobMapping.stages.length; i++) {
    var stage = rawJobMapping.stages[i];

    if (!stage.stage || !stage.date) {
      return false;
    }

    if (!isValidMapping(stage.date, schema)) {
      return false;
    }
  }

  // Check materialGroups
  if (
    !rawJobMapping.materialGroups ||
    !Array.isArray(rawJobMapping.materialGroups)
  ) {
    return false;
  }

  for (var i = 0; i < rawJobMapping.materialGroups.length; i++) {
    var materialGroup = rawJobMapping.materialGroups[i];

    if (!materialGroup.materialGroup) {
      return false;
    }

    // Check non custom fields
    for (var j = 0; j < materialGroupKeys.length; j++) {
      if (!(materialGroupKeys[j] in materialGroup)) {
        return false;
      }

      if (!isValidMapping(materialGroup[materialGroupKeys[j]], schema)) {
        return false;
      }
    }

    // Check custom fields
    if (
      !materialGroup.customFields ||
      !Array.isArray(materialGroup.customFields)
    ) {
      return false;
    }

    for (var j = 0; j < materialGroup.customFields.length; j++) {
      if (!materialGroup.customFields[j].name) {
        return false;
      }

      if (!isValidMapping(materialGroup.customFields[j].value, schema)) {
        return false;
      }
    }
  }

  // Check custom fields
  // TODO:

  // --- Check cancellation --- //

  // Exists
  if (
    !rawJobMapping.cancelDate ||
    !rawJobMapping.cancelStatus ||
    !rawJobMapping.cancelStatusValues
  ) {
    return false;
  }

  // Date and status fields valid
  if (
    !isValidMapping(rawJobMapping.cancelDate, schema) ||
    !isValidMapping(rawJobMapping.cancelStatus, schema)
  ) {
    return false;
  }

  // Status values valid
  if (
    !Array.isArray(rawJobMapping.cancelStatusValues)
  ) {
    return false;
  }

  return true;
}

function isValidMapping(mapping, schema) {
  if (!mapping || !mapping.length) return false;

  // Check each mapping
  var currSchema = schema;
  for (var i = 0; i < mapping.length; i++) {

    var m = mapping[i];
    var isLast = i === mapping.length - 1;

    // Check if field exists
    if (m.type !== "null") {
      if (!(m.fieldName in currSchema)) {
        return false;
      } else {
        currSchema = currSchema[m.fieldName];
        if (Array.isArray(currSchema)) {
          if (currSchema.length === 0) return false;
          currSchema = currSchema[0];
        }
      }
    }

    // Checks by type
    switch (m.type) {
      case "field":
        // Check if last
        if (!isLast) {
          return false;
        }
        break;

      case "nestedField":
        // Check if not last
        if (isLast) {
          return false;
        }
        break;

      case "arrayItem":
        // Check if last and has index that is a number
        if (
          !isLast ||
          m.index === undefined ||
          Number(m.index) === NaN ||
          m.index === ""
        ) {
          return false;
        }
        break;

      case "arrayObj":
        // Check if not last
        if (isLast) {
          return false;
        }

        // Check identifierQuery
        if (!isValidIdentifQuery(m.identifierQuery, currSchema)) {
          return false;
        }

        // Check deduplication
        // TODO: improve dedup field check
        // TODO: test
        if (
          !m.deduplication ||
          !["max", "min"].includes(m.deduplication.method) ||
          !m.deduplication.field
        ) {
          return false;
        }

        break;

      case "null":
        // Check if last
        if (!isLast) {
          return false;
        }
        break;

      default:
        break;
    }
  }

  return true;
}

// Check that the query is in the format of a Mongoose/MongoDB query
// Currently should only have $and, $or, $eq, and $ne operators
// Some other requirements are needed due to the way the query is used
function isValidIdentifQuery(query, schema) {
  function recurValidQuery(q) {
    // Check if not object
    if (typeof q !== "object" || q === null) {
      return false;
    }

    // Get keys
    var keys = Object.keys(q);

    // Should only have 1 key
    if (keys.length !== 1) {
      return false;
    }

    var key = keys[0];

    // Check if $and or $or
    if (["$and", "$or"].includes(key)) {
      // Check that value is an array
      if (!Array.isArray(q[key])) {
        return false;
      }

      // Check that each element is a valid query
      for (var i = 0; i < q[key].length; i++) {
        if (!recurValidQuery(q[key][i])) {
          return false;
        }
      }
    }
    // Check if $eq (or just key:value) or $ne
    else {
      // key:value should be key:<value>|key:{$eq:<value>}|key:{$ne:<value>}

      // Check key is not operator
      if (key[0] === "$") {
        return false;
      }

      // Check that key is in schema
      if (!schema[key]) {
        return false;
      }

      // If value is not an object, it should have type string (string represents value)
      if (typeof q[key] !== "object") {
        // Must be string and not ""
        if (typeof q[key] !== "string" || q[key] === "") {
          return false;
        }
      }
      // If value is an object, it should just be $eq or $ne mapping to a string
      else {
        if (!q[key]) return false;

        var valueKeys = Object.keys(q[key]);

        // Should only have 1 key
        if (valueKeys.length !== 1) {
          return false;
        }

        var valueKey = valueKeys[0];

        // Check if $eq or $ne
        if (!["$eq", "$ne"].includes(valueKey)) {
          return false;
        }

        // Check that value is a string
        if (typeof q[key][valueKey] !== "string" && q[key][valueKey]) {
          return false;
        }
      }
    }

    return true;
  }

  return recurValidQuery(query); // TODO: implement
}
