import React from "react";
import { useOutletContext } from "react-router-dom";

import Forecast from "../../../pages/Forecast/classes/Forecast";
import Week from "../../../pages/Forecast/classes/Week";
import { ForecastSnapshotViewContextType} from  "../../../contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx"
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Last6WksTable({ isStatic = false }) {
  const outletContextValue = useOutletContext();
  
  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
      lastSixWeeks,
      lastSixWeeksInput,
    } = outletContextValue;
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
    } = outletContextValue;
  }

  let sixWksAgo = moment().utc().startOf("week").subtract(6, "weeks");

  let weeks = Array.from({ length: 6 }, (_, i) =>
    sixWksAgo.clone().add(i, "weeks")
  );

  let installsPerWeek = weeks.map((week) => {
    let installs = lastSixWeeks?.find((wk) => {
      let wkDate = moment()
        .utc()
        .week(wk._id.week + 1) // +1 because mongo weeks are 0-indexed
        .year(wk._id.year);
      return wkDate.isSame(week, "week");
    });

    return installs?.count;
  });

  if (isStatic) {
    installsPerWeek = staticForecastData?.last6WeeksActual;
    lastSixWeeksInput = staticForecastData?.last6WeeksForecast;

    // If bad shape, fill with nulls
    if (lastSixWeeksInput?.length !== 6) {
      lastSixWeeksInput = Array.from({ length: 6 }, () => null);
    }

    // If bad shape, fill with nulls
    if (installsPerWeek?.length !== 6) {
      installsPerWeek = Array.from({ length: 6 }, () => null);
    }
  }

  return (
    <div className="flex flex-row items-end gap-2">
      {/* Indicators */}
      <div>
        <div className="w-2.5 h-10 bg-secondary-orange"></div>
        <div className="w-2.5 h-10 bg-gray-600 dark:bg-gray-200"></div>
      </div>

      {/* Table */}
      <div className="grow">
        <table className="relative w-full text-center">
          <thead>
            <tr>
              {weeks.map((week, i) => (
                <th
                  className="w-1/6 pb-2.5 text-sm font-medium text-gray-500 dark:text-gray-300"
                  key={i}
                >
                  {week.format("M/D")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {installsPerWeek?.map((count, i) => (
                <Cell key={i} value={count || null} />
              ))}
            </tr>
            <tr>
              {
                lastSixWeeksInput?.map((count, i) => (
                  <Cell key={i} value={count || null} green />
                ))
              }
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Cell({ green = false, value }) {
  return (
    // TODO: not exact same height as forecast input
    <td
      className={classNames(
        "w-1/6 h-10 py-2 border text-sm font-medium text-gray-500 dark:text-gray-300",
        green
          ? "border-primary-green"
          : "border-gray-300 border-b-primary-green"
      )}
    >
      {value ?? "-"}
    </td>
  );
}
