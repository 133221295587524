import SimpleBarCard from "../../../SimpleBarCard";
import Card from "../../../Card";
import Table from "../../../Table";
import Pagination from "../../../nav/Pagination";
import { useContext } from "react";
import JobPipelineContext from "../../../../contexts/pipeline/jobs/JobPipelineContext";

const wideJobTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stage",
  },
  {
    label: "Market",
    field: "market",
  },
  // {
  //   label: "Days",
  //   field: "daysSinceCreated",
  // },
  // {
  //   label: "Stage Days",
  //   field: "daysInStage",
  // },
];

/**
 * This is the ListView component used in the Pipeline Jobs page.
 * This shows a list of stages and a table of jobs.
 * The table can be filtered by stage.
 *
 * // TODO: update this comment once finalized
 */
export default function ListView() {
  const {
    jobs,
    formattedJobs,

    markets,
    selectedMarketId,
    setSelectedMarketId: handleMarketSelected,

    stages,
    selectedStageIndex,
    setSelectedStageIndex,

    search,
    setSearch: handleSearch,

    dateFilter,

    jobPagination: pagination,
    setJobListPage: handlePageChange,
  } = useContext(JobPipelineContext);

  var maxJobs = Math.max(...(stages?.map((s) => s.jobCount) || []));

  // Generate cards for each stage
  var stagesCards = stages?.slice(1).map((s, i) => {
    return (
      <SimpleBarCard
        key={i}
        className="w-[180px]"
        title={`S${i + 1} - ${s.name}`}
        value={s.jobCount}
        percent={maxJobs ? (s.jobCount / maxJobs) * 100 : 0}
        grayed={selectedStageIndex !== -1 && selectedStageIndex !== i + 1} // +1 because first stage is not selectable
        onClick={() => setSelectedStageIndex(i + 1, true)} // +1 because first stage is not selectable
      />
    );
  });

  return (
    <div className="flex flex-col gap-4 mx-4">
      {/* Stages */}
      <div className="flex flex-wrap justify-center gap-4">{stagesCards}</div>

      {/* Jobs */}
      <div className="w-full">
        <Card>
          <Table
            noSelect
            noEdit
            thinFirstColumn
            fields={wideJobTableFields.concat(
              stages?.slice(1).map((s, i) => {
                return {
                  label: s.name,
                  field: s._id,
                };
              })
            )}
            data={formattedJobs}
          />
          <Pagination {...pagination} onChange={handlePageChange} />
        </Card>
      </div>
    </div>
  );
}