import React, { useEffect, useState } from 'react'
import Button from './Button'
import Input from './Input'
import $ from 'jquery'
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import IconDropdown from './IconDropdown';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import classNames from '../../tools/classNames';
import Dropdown from './Dropdown';
import Spinner from '../Spinner';


/**
 * 
 * @param {array} columns - array of objects representing the columns of the table
 * @param {number} currColumnWidth - width of the current column
 * @param {number} editColumnWidth - width of the edit column
 * 
 * @returns - a percentage representing the width of the current column in relation to the total width of the table
 */
function getColumnWidthRatio(columns, currColumnWidth, editColumnWidth) {
    // sum all of the widths of the columns
    let denominator = 0
    // add the width of the edit column
    denominator += editColumnWidth
    columns.forEach((column) => {
        denominator += column.columnWidth
    })
    // get the percentage of the current column width
    let percentage = (currColumnWidth / denominator) * 100
    return percentage
}


/**
 * 
 * @param {array} tableData - array representing the data in the table
 * @param {function} setTableData - function to set the table data
 * @param {array} columns - array of objects representing the columns of the table
 * @param {string} addButtonText - text for the button that adds a new row
 * @param {boolean} innerAddButton - boolean to determine if the add button should be displayed at the bottom of the table
 * @param {boolean} outerAddButton - boolean to determine if the add button should be displayed at the top of the table
 * @param {function} addButtonClicked - function to call when the add button is clicked
 * @param {string} height - height of the table
 * @param {number} editColumnWidth - width of the edit column, used to calculate the percentage width of each column
 * @param {boolean} editableRows - boolean to determine if the rows are editable
 * @param {boolean} ellipsisDropdown - boolean to determine if the ellipsis dropdown should be displayed
 * @param {function} filter - function to filter the table data, not implemented
 * @param {function} sort - function to sort the table data, not implemented
 * @param {function} onSaveRow - function to call when the save button is clicked, should return a promise that resolves with the row id
 * @param {string} error - error message to display at the top of the table
 * @param {function} setError - function to set the error message
 * @param {function} onCancelRow - function to call when the cancel button is clicked
 * @param {function} onRowLoseFocus - function to call when the row loses focus
 * 
 * @returns - a table with editable rows, with the ability to add, edit, and delete rows
 */
export default function InputTable({
    tableData,
    setTableData,
    columns,
    addButtonText,
    innerAddButton = true,
    outerAddButton = true,
    addButtonClicked = null,
    height = null,
    editColumnWidth = 5,
    editableRows = true,
    ellipsisDropdown = true,
    useOnBlur = true,
    filter = null,  // TODO: implement filtering
    sort = null,    // TODO: implement sorting
    onSaveRow = () => { },
    error = null,
    setError = () => { },
    onCancelRow = () => { },
    onRowLoseFocus = () => { },
}) {

    /**
     * Updates the data in the table for a given row and column
     * 
     * @param {string} rowId - id of the row to set the data for
     * @param {object} column - object representing the column to set the data for
     * @param {string} value - value to set in the column
     */
    function setRowData(rowId, column, value) {
        var columnNames = columns.map((column) => column.accessor)
        setTableData((tableData) => {
            const updatedTableData = tableData.map((row) => {
                if (row.id === rowId) {
                    return {
                        ...row,
                        [column]: columnNames.includes(column) ? { ...row[column], value: value } : value
                    }
                }
                return row
            })
            return updatedTableData
        })
    }


    /**
     * Adds a new row to the table with an empty value for each column, temporarily setting the rowId to the highest id + 1 until the row is saved
     */
    function addEmptyRow() {
        // find the highest id in the tableData
        let highestId = 0
        tableData.forEach((row) => {
            // if row.id is an integer and is greater than the current highestId, set highestId to row.id
            // checking for integer because when we click the save button, we are setting the ids to the id we get from the database
            if (parseInt(row.id)) {
                if (row.id > highestId) {
                    highestId = row.id
                }
            }
        })
        // get column names, omitting the id and rowStatus columns
        let columnNames = columns.map((column) => column.accessor)

        // create a new row with the highest id + 1
        let newRow = { id: highestId + 1, rowStatus: 'new' }
        columnNames.forEach((columnName) => {
            newRow[columnName] = { value: '', className: '' }
        })
        // add the new row to the tableData
        setTableData((tableData) => {
            return [...tableData, newRow]
        })
    }


    /**
     * Determines if a row is already being edited
     * @returns - boolean representing if a row is already being edited
     */
    function rowAlreadyActive() {
        let activeRow = false
        tableData.forEach((row) => {
            if (row.rowStatus === 'editing' || row.rowStatus === 'new') {
                activeRow = true
            }
        })
        return activeRow
    }

    /**
     * Deletes a row from the table
     * @param {string} rowId - id of the row to delete
     */
    function deleteRow(rowId) {
        setTableData((tableData) => {
            const updatedTableData = tableData.filter((row) => row.id !== rowId)
            return updatedTableData
        })
    }

    return (
        <div className="w-full px-4 mt-3 sm:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-end gap-3">
                {error && (
                    <div className='text-red-500'>
                        {error}
                    </div>
                )}
                {outerAddButton && (
                    <Button
                        onClick={() => {
                            // if addButtonClicked is a function, call it
                            if (addButtonClicked) {
                                addButtonClicked()
                            } else if (!rowAlreadyActive() && editableRows) {
                                addEmptyRow()
                            }
                        }}
                        variant="primary-green"
                        disabled={tableData.some(item => item.rowStatus === 'editing' || item.rowStatus === 'new')}
                    >
                        {addButtonText}
                    </Button>
                )}
            </div>
            <div
                // if height is set, set the height of the table
                {...(height ? { style: { height: height } } : {})}
                className="mt-2 -mx-4 overflow-y-auto sm:-mx-0 overflow-x-clip"
            >
                <table
                    className="w-full divide-y divide-gray-200 border-gray-200 border-b-[1px]"
                >
                    <thead
                        // if the table no data, render the header with a border
                        className={tableData.length === 0 ? "border-gray-200 border-b-[1px]" : ""}
                    >
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    key={column.accessor}
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                    style={{ width: getColumnWidthRatio(columns, column.columnWidth, editColumnWidth) + "%" }}
                                >
                                    {column.Header}
                                </th>
                            ))}
                            <th
                                scope="col"
                                className="relative py-3.5 pr-4 sm:pr-0 w-0"
                                style={{ width: getColumnWidthRatio(columns, editColumnWidth, editColumnWidth) + "%" }}
                            >
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">

                        {tableData.map((row) => (
                            <InputTableRow
                                row={row}
                                columns={columns}
                                tableData={tableData}
                                setRowData={setRowData}
                                deleteRow={deleteRow}
                                editableRows={editableRows}
                                ellipsisDropdown={ellipsisDropdown}
                                useOnBlur={useOnBlur}
                                rowAlreadyActive={rowAlreadyActive}
                                editColumnWidth={editColumnWidth}
                                onSaveRow={onSaveRow}
                                setError={setError}
                                onCancelRow={onCancelRow}
                                onRowLoseFocus={onRowLoseFocus}
                            />
                        ))}
                    </tbody>
                </table>
                {innerAddButton && (
                    <div className='flex flex-row justify-end py-4 border-gray-200 border-b-[1px]'>
                        {/* TODO: use addRowButtonDisabled to gray this out and make it non clickable when true */}
                        <div className={classNames(
                            tableData.some(item => item.rowStatus === 'editing' || item.rowStatus === 'new')
                                ? 'text-gray-400 hover:text-gray-300 cursor-default'
                                : 'text-primary-green hover:text-primary-green-400 cursor-pointer',
                            'flex flex-row items-center gap-2'
                        )}
                            onClick={() => {
                                // only add a new row if there are no rows being edited
                                if (!tableData.some(item => item.rowStatus === 'editing' || item.rowStatus === 'new')) {
                                    // if addButtonClicked is a function, call it
                                    if (addButtonClicked) {
                                        addButtonClicked()
                                    } else if (!rowAlreadyActive() && editableRows) {
                                        addEmptyRow()
                                    }
                                }
                            }}
                        >
                            <div className='text-sm font-normal leading-5 text-center align-middle'>
                                {addButtonText}
                            </div>
                            <PlusCircleIcon className='w-5 h-5 ml-1' />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}


/**
 * 
 * @param {object} row - object containing the current row data with a value for each column
 * @param {array} columns - array of objects representing the columns of the table
 * @param {array} tableData - array of objects representing all of the rows in the table
 * @param {function} setRowData - function to set a value in the current row, given the row id, column name, and value
 * @param {function} deleteRow - function to delete a row from the table, given the row id
 * @param {boolean} editableRows - boolean to determine if the rows are editable
 * @param {boolean} ellipsisDropdown - boolean to determine if the ellipsis dropdown should be displayed
 * @param {function} rowAlreadyActive - function to determine if a row is already being edited
 * @param {number} editColumnWidth - width of the edit column
 * @param {function} onSaveRow - function to call when the save button is clicked, should return a promise that resolves with the row id
 * @param {function} setError - function to set an error message
 * @param {function} onCancelRow - function to call when the cancel button is clicked
 * @param {function} onRowLoseFocus - function to call when the row loses focus
 * 
 * @returns - a single row in the table, with editable fields if the row is being edited
 */
function InputTableRow({
    row,
    columns,
    tableData,
    setRowData,
    deleteRow,
    editableRows,
    ellipsisDropdown = true,
    useOnBlur,
    rowAlreadyActive,
    editColumnWidth,
    onSaveRow = () => { },
    setError = () => { },
    onCancelRow = () => { },
    onRowLoseFocus = () => { },
}) {

    // tempRowData is used to store the row data when the row is being edited
    const [tempRowData, setTempRowData] = useState({})

    const [focusColumn, setFocusColumn] = useState('name')

    const [saving, setSaving] = useState(false)

    let editing = row.rowStatus === 'editing' || row.rowStatus === 'new'

    // when focus column changes, focus on the input field
    useEffect(() => {
        if (editing) {
            document.getElementById(focusColumn)?.focus()
        }
    }, [editing, focusColumn])


    /**
     * 
     * @param {object} row - object representing the current row
     * @returns - boolean representing if all required fields have a value in the row, sets an error message if not
     */
    function requredFieldsFilled(row) {
        let requiredFieldsFilled = true
        columns.forEach((column) => {
            if (column.required && row[column.accessor].value === '') {
                requiredFieldsFilled = false
            }
        })
        if (!requiredFieldsFilled) {
            // set error message notifying the user which fields are required, using the column Header
            let requiredFields = columns.filter((column) => column.required)
            let requiredFieldsString = ''
            requiredFields.forEach((column) => {
                requiredFieldsString += column.Header + ', '
            }
            )
            requiredFieldsString = requiredFieldsString.slice(0, -2)
            setError(`Required Fields: ${requiredFieldsString}`)

        }
        return requiredFieldsFilled
    }


    /**
     * @param {object} row - object representing the current row
     * @returns - boolean representing if the given row has unique values for the columns that are set to unique, comparing against all other rows in the table
     */
    function checkUniqueColumns(row) {
        let uniqueColumns = true
        columns.forEach((column) => {
            if (column.unique) {
                tableData.forEach((tableRow) => {
                    if (tableRow.id === row.id) {
                        return
                    }
                    if (tableRow[column.accessor].value === row[column.accessor].value) {
                        uniqueColumns = false
                        setError(`${column.Header} must be unique`)
                    }
                })
            }
        })
        return uniqueColumns
    }


    /**
     * Checks whether the values in the row pass validation check functions that are defined in each column
     * @param {object} row - object representing the current row
     * @returns - boolean representing if the given row passes all validation checks for the columns that have a validate function
     */
    function validateColumns(row) {
        let valid = true
        columns.forEach((column) => {
            if (column.validate) {
                if (!column.validate(row[column.accessor].value)) {
                    valid = false
                }
            }
        })
        return valid
    }


    /*
    * Function to get the editable field based on the column editType
    */
    function getEditableField(column) {
        switch (column.editType) {
            case 'input':
                return (
                    <input
                        type="text"
                        id={column.accessor}
                        value={row[column.accessor].value}
                        placeholder={column.placeholder}
                        className={classNames(
                            "block w-full rounded-md border-0 px-2 py-1.5 shadow-sm",
                            // Light Mode
                            "text-gray-900",
                            // Dark Mode
                            "dark:text-gray-200 dark:bg-gray-700",
                            "placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6 checked:bg-primary-rose focus:checked:bg-primary-rose",
                            "ring-1 ring-inset ring-gray-300"
                        )}
                        onChange={(e) => {
                            setRowData(row.id, column.accessor, e.target.value)
                        }}
                    />
                )
            case 'dropdown':
                return (
                    <Dropdown
                        id={column.accessor}
                        wide
                        value={row[column.accessor].value}
                        options={column.options}
                        selectedValue={row[column.accessor].value}
                        placeholder={column.placeholder}
                        onSelected={(option) => {
                            setRowData(row.id, column.accessor, option.value)
                        }}
                    />
                )
        }
    }


    function restoreTempRowData() {
        // if the row had data before editing, set the row data to the tempRowData
        // if the row didn't exist before editing, delete the row
        // if tempRowData is not an empty object, set the row data to the tempRowData
        if (Object.keys(tempRowData).length !== 0) {
            var columnNames = columns.map((column) => column.accessor)
            for (let key in tempRowData) {
                if (columnNames.includes(key)) {
                    setRowData(row.id, key, tempRowData[key].value)
                } else {
                    setRowData(row.id, key, tempRowData[key])
                }
            }
            setTempRowData({})
        }
        setRowData(row.id, 'rowStatus', 'readonly')
        setFocusColumn(null)
    }


    return (
        // if row.rowStatus is readonly, then render the row using text fields
        row.rowStatus === 'readonly' ? (
            <tr
                key={row.id}
                id={row.id}
                // top padding of 1px
                className={classNames(
                    editableRows ? "cursor-pointer hover:bg-gray-50 hover:bg-opacity-50" : "",
                    "h-[51px]"
                )}
            >
                {columns.map((column) => (
                    <td
                        key={column.accessor}
                        className={classNames(
                            "px-3 py-2 text-sm font-medium truncate"
                        )}
                        style={{ width: getColumnWidthRatio(columns, column.columnWidth, editColumnWidth) + "%" }}
                        onClick={() => {
                            if (!rowAlreadyActive() && editableRows) {
                                // set the tempRowData to the current row data
                                setTempRowData(row)
                                // set the focus column to the current column
                                setFocusColumn(column.accessor)
                                // set the row status to active
                                setRowData(row.id, 'rowStatus', 'editing')
                            }
                        }}
                    >
                        <span
                            className={classNames(
                                "text-sm leading-5 align-middle text-left text-ellipsis",
                                row[column.accessor].className != '' ? row[column.accessor].className : "font-medium"
                            )}
                        >
                            {row[column.accessor].value}
                        </span>
                    </td>
                ))}
                <td
                    className={classNames(
                        "py-1 pr-4 text-sm font-medium text-right sm:pr-0"
                    )}
                    style={{ width: getColumnWidthRatio(columns, editColumnWidth, editColumnWidth) + "%" }}
                >
                    <div className='flex flex-row items-center justify-end gap-2 pr-1'>
                        {row.inlineButtons ? (
                            row.inlineButtons.map((button) => (
                                <Button
                                    key={button.value}
                                    onClick={() => {
                                        if (button.onClick) {
                                            button.onClick(row)
                                        }
                                    }}
                                    variant={button.variant}
                                    disabled={button.disabled}
                                    className='!text-nowrap'
                                >
                                    {button.label}
                                </Button>
                            ))
                        ) : null}

                        {ellipsisDropdown ? (
                            <IconDropdown
                                options={row.menuOptions}
                                onSelected={(item) => {
                                    // if onSelected is a function, call it with the row data
                                    if (item.onSelected) {
                                        item.onSelected(row)
                                    }
                                    if (item.value === 'delete') {
                                        deleteRow(row.id)
                                    }
                                    if (item.value === 'edit') {
                                        if (!rowAlreadyActive() && editableRows) {
                                            // set the tempRowData to the current row data
                                            setTempRowData(row)
                                            // set the row status to active
                                            setRowData(row.id, 'rowStatus', 'editing')
                                        }
                                    }
                                }}
                            />
                        ) : null}
                    </div>
                </td>
            </tr>
        ) : (
            // else render the row using text input fields
            <tr
                key={row.id}
                id={row.id}
                // onBlur is called when the row loses focus
                onBlur={(event) => {
                    if (useOnBlur) {
                        if (!saving) {
                            const currentTarget = event.currentTarget;
                            const relatedTarget = event.relatedTarget;
    
                            // Check if the new focused element is outside the current row
                            if (!currentTarget.contains(relatedTarget)) {
                                restoreTempRowData()
                            } else if (relatedTarget?.id === 'save') {
                                return
                            }
                        }
                        onRowLoseFocus()
                    }
                }}
            >
                {
                    columns.map((column) => (
                        <td
                            key={column.accessor}
                            className={classNames(
                                "py-1.5 text-sm font-medium text-gray-900",
                                column.editable ? "pl-1 pr-3" : "px-3",
                            )}
                            style={{ width: getColumnWidthRatio(columns, column.columnWidth, editColumnWidth) + "%" }}
                            onClick={() => {
                                if (!rowAlreadyActive() && editableRows) {
                                    // set the focus column to the current column
                                    setFocusColumn(column.accessor)
                                    setRowData(row.id, 'rowStatus', 'editing')
                                }
                            }}
                        >
                            {/* 
                                if the column is editable, get the editable field type (dropdown, input, etc.) 
                                if the column isn't editable, but the row is new, still allow editing until the row is saved
                                TODO: not sure if this is the best way to handle this
                            */}
                            {column.editable || row.rowStatus === 'new' ? (
                                getEditableField(column)
                            ) : (
                                <span
                                    className={classNames(
                                        "text-sm leading-5 align-middle text-left",
                                        // column.primary ? "font-medium" : "font-normal text-gray-500"
                                        row[column.accessor].className != '' ? row[column.accessor].className : "font-medium"
                                    )}
                                >
                                    {row[column.accessor].value}
                                </span>
                            )}

                        </td>
                    ))
                }
                < td
                    className="py-1.5 pr-4 text-sm font-medium text-right sm:pr-0"
                    style={{ width: getColumnWidthRatio(columns, editColumnWidth, editColumnWidth) + "%" }}
                >
                    <div className='flex flex-row justify-end gap-2 pr-2'>
                        {saving
                            ? (
                                <Spinner />
                            )
                            : (
                                <Button
                                    id='save'
                                    onClick={async () => {
                                        setSaving(true)
                                        // only save if the required fields are filled
                                        if (
                                            requredFieldsFilled(row) &&
                                            checkUniqueColumns(row) &&
                                            validateColumns(row)
                                        ) {
                                            let result = await onSaveRow(row)
                                            console.log("result", result)
                                            if (result?.id) {
                                                // update the row data with the new value
                                                // added this for updating the role dropdown
                                                // before, it would display the role as the value of the dropdown rather than the label
                                                setRowData(row.id, 'id', result.id)
                                                if (result.newValue && result.column) {
                                                    setRowData(result.id, result.column, result.newValue)
                                                }
                                            }
                                            if (!result) {
                                                restoreTempRowData()
                                            }
                                            else {
                                                setRowData(result?.id || row.id, 'rowStatus', 'readonly')
                                                setTempRowData({})
                                            }
                                        }
                                        setSaving(false)
                                    }}
                                    variant="primary-green"
                                // className='!py-0'
                                >
                                    Save
                                </Button>
                            )}

                        <Button
                            onClick={() => {
                                restoreTempRowData()
                                onCancelRow()
                            }}
                            variant="secondary"
                            disabled={saving}
                        >
                            Cancel
                        </Button>
                    </div>
                </td >
            </tr >
        )
    )

}