import Week from './Week.js';
import Stage from './Stage.js';
import moment from 'moment';

/**
 * Represents a job in the forecast.
 * @class
 */
export default class Job {

  // ------------------ //
  // --- Properties --- //
  // ------------------ //

  /**
   * The ID of the job.
   * @type {ObjectId}
   */
  ID;

  /**
   * The stage the job is in.
   * @type {Stage}
   */
  stage;

  /**
   * The actual job model
   * @type {JobModel}
   */
  details;

  /**
   * The week the job is in.
   * @type {Week}
   */
  week;

  /**
   * The TOA estimated install date
   * @type {moment.Moment}
   */
  estimatedDate;

  /**
   * The materials required for the job.
   * @type {{_materialGroupId_:{material:ObjectId, quantity:number}}}
   */
  get materials() {
    return this.details.materialMap;
  }

  /**
   * The likelihood of the job converting to an install.
   * @type {number}
   */
  get likelihoodOfInstall() {
    return this.stage.convRate;
  }

  // ------------------- //
  // --- Constructor --- //
  // ------------------- //

  /**
   * Creates a new instance of the Job class.
   *
   * @param {ObjectId} id - The ID of the job
   * @param {Stage} stage - The stage the job is in
   * @param {moment.Moment} estimatedDate - The TOA estimated install date
   * @param {JobModel} details - The actual job model
   * @param {Week} week - The week the job is in
   */
  constructor(id, stage, estimatedDate, details, week) {
    this.ID = id;
    this.stage = stage;
    this.details = details;
    this.estimatedDate = estimatedDate;
    this.week = week;
  }

  // --------------- //
  // --- Methods --- //
  // --------------- //

}