import React, { Component } from 'react'
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap'
import FloCard from '../components/deprecated/FloCard';
// import StylingTool from '../tools/StylingTool'; // Deprecated

export default class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: localStorage.getItem("theme")
    };

    this.setLight = this.setLight.bind(this);
    this.setDark = this.setDark.bind(this);
  }

  setLight() {
    // StylingTool.setLight(); // Deprecated
    this.setState({
      theme: "light"
    });
  }

  setDark() {
    // StylingTool.setDark(); // Deprecated
    this.setState({
      theme: "dark"
    });
  }

  render() {
    return (
      <>
        <Col className="pt-3 pb-3">
          <div className='flo-title'>Settings</div>
          <div className='pt-3'>

            <Row>

              {/* Theme */}
              <Col md="auto">
                <FloCard>
                  <FloCard.Title>Theme</FloCard.Title>
                  <FloCard.Body>
                    <ButtonGroup>
                      <Button variant={(this.state.theme !== 'light' ? 'outline-' : '') + 'flo-primary'} onClick={this.setLight}>Light</Button>
                      <Button variant={(this.state.theme !== 'dark' ? 'outline-' : '') + 'flo-primary'} onClick={this.setDark}>Dark</Button>
                    </ButtonGroup>
                  </FloCard.Body>
                </FloCard>
              </Col>

            </Row>

          </div>
        </Col>
      </>
    )
  }
}
