import React from "react";
import { useOutletContext } from "react-router-dom";

import Forecast from "../../../pages/Forecast/classes/Forecast";
import Table from "../../Table";

import {ForecastSnapshotViewContextType} from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AvailableRemainderTable({ isStatic = false}) {
  const contextValue = useOutletContext();
  
  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = contextValue;
  } else {
  
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
    } = contextValue;
  }

  var { available, remainder } = forecastData?.getAvailablesAndRemainders() ?? {
    available: [],
    remainder: [],
  };
  if(isStatic) {
    var { available, remainder } = Forecast.generateAvailableAndRemainder(staticForecastData.inputForecast, staticForecastData.toaForecast);
  }

  // Last week with jobs in it. Different from capacity as this handles gaps in capacity
  // I.e. (if capacity by week is [1,0,1,...] capacity would be 2 but we want a border after the 3rd week)
  let lastWeek = forecastData?.weeks.reduce((acc, week, i) => {
    if (week.likelyJobCount > 0) {
      return i;
    }
    return acc;
  }, -1) ?? -1;

  if (isStatic) {
    // Get last week using capacity and skipping weeks with 0 input
    
    let weeksUsed = 0;
    let i = 0;
    while (weeksUsed < staticForecastData.weeksOfCapacity && i < staticForecastData.inputForecast.length) {
      if (staticForecastData.inputForecast[i] > 0) {
        weeksUsed++;
      }
      i++;
    }
    lastWeek = i - 1;
  }

  return (
    <table className="table w-full">
      <tbody>
        <tr>
          {available.map((value, i) => {
            return <Cell value={value} key={i} index={i} lastWeek={lastWeek} />;
          })}
        </tr>
        <tr>
          {remainder.map((value, i) => {
            return <Cell value={value} key={i} index={i} lastWeek={lastWeek} />;
          })}
        </tr>
      </tbody>
    </table>
  );
}

function Cell({ value, lastWeek = 0, index = -1 }) {
  let fontColor = value >= 0 ? "text-gray-400" : "text-primary-rose";

  let showBorder = index === lastWeek;

  return (
    <td
      className={classNames(
        fontColor,
        showBorder ? "border-r-[3px] border-gray-400" : "",
        "text-center py-2 font-medium text-sm w-[5%] max-w-min"
      )}
    >
      {value}
    </td>
  );
}
