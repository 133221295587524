import Button from "../input/Button";
import Input from "../input/Input";
import toa_semi_bold from "../../assets/logos/lightMode/toa_semi-bold.svg";
import toa_semi_bold_white from "../../assets/logos/darkMode/toa_semi-bold_white.svg";
import axios from "axios";
import { useState } from "react";
import Spinner from "../../components/Spinner";
import UserManager from "../../tools/UserManager";


/**
 * Step one of the setup process, where the user sets their username and password
 * 
 * @param {Object} userData - object containing user data
 * @param {Function} setUserData - function to update user data
 * @param {Function} nextStep - function to move to the next step in the setup process
 * @returns {JSX.Element} - step one of the setup process
 */
export default function StepOne(userData, setUserData, nextStep, error, setError, loading, setLoading) {
    function verifyPassword() {
        // TODO: add password verification logic
        if (userData.password) {
            return true
        }
        return false
    }

    function verifyConfirmPassword() {
        // TODO: add password verification logic
        if (userData.confirmPassword && userData.confirmPassword === userData.password) {
            return true
        }
        return false
    }

    async function registerUser() {
        // Make sure passwords are the same
        if (!verifyPassword()) {
            setError("Please enter a password");
            return;
        }

        if (!verifyConfirmPassword()) {
            setError("Passwords do not match");
            return;
        }

        // Make API call
        setLoading(true);

        // log out any existing user
        await UserManager.logout();

        const response = await axios.post(
            "/api/user/register",
            {
                email: userData.userEmail,
                password: userData.password,
                firstName: userData.firstName,
                lastName: userData.lastName,
            },
            {
                withCredentials: true,
            }
        );
        setLoading(false);

        const data = response.data;

        if (data.status === "ok") {
            // Clear error
            setError("");

            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem('loggedIn', true)

            nextStep()
        } else if (data.status === "error") {
            // Report error
            var msg = "There was an error registering.";
            if (data.error === "Missing field") {
                msg = "Please enter your name, your email, and a secure password";
            } else if (data.error === "User exists") {
                msg = "A user with that email already exists.";
            } else if (data.error === "Bad password") {
                msg =
                    "That password is not secure enough. Password must be at least 8 characters long, have: at least 1 uppercase letter, at least 1 lowercase letter, a number, and a special character ($@#!&).";
            }
            console.log(msg)
            setError(msg);
        }
    }

    var continueButton = loading ? (
        <div style={{ position: "relative", minHeight: 40 }}>
            <Spinner />
        </div>
    ) : (
        <Button type="submit"
            onClick={() => {
                registerUser()
            }}
            className='mx-2 my-4 rounded-md cursor-pointer'
            variant='primary-green'

        >
            Continue
        </Button>
    );

    return (
        <div className="flex flex-col items-center justify-center gap-2 p-4">

            <div className='flex flex-col items-center gap-2 mb-4 text-center'>
                <div className="flex flex-row mx-2 px-2 py-2.5 items-center justify-center flex-wrap gap-y-2">
                    <div className="text-3xl font-bold leading-9 text-gray-700 dark:text-gray-50">
                        Welcome to
                    </div>
                    <img
                        className="flex-none w-auto justify-self-start mx-[5px] pl-[5px] h-7 block dark:hidden"
                        src={toa_semi_bold}
                        alt="Toa"
                    />
                    <img
                        className="flex-none w-auto justify-self-start mx-[5px] pl-[5px] h-7 hidden dark:block"
                        src={toa_semi_bold_white}
                        alt="Toa"
                    />
                </div>
                <div className="text-lg font-light leading-7 text-gray-900 dark:text-gray-200">
                    the connected workspace for operations and analytics
                </div>
            </div>

            <div className="m-2 text-sm font-normal leading-5 text-center text-gray-900 dark:text-gray-200">
                You're accepting an invitation sent to <span className="font-bold">{userData.userEmail}</span>
            </div>

            <div className='flex flex-col w-full gap-4 sm:w-72'>
                <Input
                    label="First Name"
                    placeholder="John"
                    type="text"
                    value={userData.firstName}
                    onChange={(value) => {
                        setUserData({ ...userData, firstName: value })
                    }}
                />

                <Input
                    label="Last Name"
                    placeholder="Doe"
                    type="text"
                    value={userData.lastName}
                    onChange={(value) => {
                        setUserData({ ...userData, lastName: value })
                    }}
                />

                {/* break line */}
                <div className="h-0.5 w-full border-b bg-gray-300 my-2"></div>

                <Input
                    label="Set a password"
                    type="password"
                    value={userData.password}
                    onChange={(value) => { setUserData({ ...userData, password: value }) }}
                />

                <Input
                    label="Confirm password"
                    type="password"
                    value={userData.confirmPassword}
                    onChange={(value) => { setUserData({ ...userData, confirmPassword: value }) }}
                />

                {/* Error Message */}
                <div className="text-red-500">{error}</div>

                {/* continue button */}
                {continueButton}
            </div>
        </div>
    )
}