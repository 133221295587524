import React, { useEffect, useState } from "react";
import SubNav from "../components/nav/SubNav";
import { Outlet, useLocation } from "react-router-dom";
import StepsPanel from "../components/nav/StepsPanels";
import UserManager from "../tools/UserManager";
import pluralize from "pluralize";
import TopBar from "../components/nav/TopBar";

export default function OperationsSetupPage() {
  const [subNavTabs, setSubNavTabs] = useState([
    {
      id: "1",
      name: "Material",
      href: "material",
      current: true,
      status: "upcoming",
      description: " Mateiral Group(s)",
      itemsDone: -1,
    },
    {
      id: "2",
      name: "Markets",
      href: "markets",
      current: false,
      status: "upcoming",
      description: " Market(s) Created",
      itemsDone: -1,
    },
    {
      id: "3",
      name: "Stages",
      href: "stages",
      current: false,
      status: "upcoming",
      description: " Stage(s) Created",
      itemsDone: -1,
    },
    {
      id: "4",
      name: "Data Connectors",
      href: "data-connectors",
      current: false,
      status: "upcoming",
      description: " Connection(s)",
      itemsDone: -1,
    },
    {
      id: "5",
      name: "Data Mapping",
      href: "data-mapping",
      current: false,
      status: "upcoming",
      description: " Field(s) Mapped",
      itemsDone: -1,
    },
  ]);

  const [isGettingCounts, setIsGettingCounts] = useState(false);

  var location = useLocation();

  useEffect(() => {
    updateCounts();

    // Update counts every 30 seconds
    if (isGettingCounts) return;
    var interval = setInterval(() => {
      updateCounts();
    }, 30000);
  }, [location]);

  function updateCounts() {
    // Update counts
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/get-counts",
      "GET"
    )
      .then((res) => {
        var temp = [...subNavTabs];

        temp[0].itemsDone = res.data.counts.numMaterialGroups;
        temp[1].itemsDone = res.data.counts.numMarkets;
        temp[2].itemsDone = res.data.counts.numStages;
        temp[3].itemsDone = res.data.counts.numConnections;
        temp[4].itemsDone = res.data.counts.numFieldsMapped;

        setSubNavTabs(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Set descriptions based on completeness of each step
  // TODO: implement with real data
  subNavTabs[0].description = pluralize('Material Group', subNavTabs[0].itemsDone, true)
  subNavTabs[1].description = pluralize('Market', subNavTabs[1].itemsDone, true) + " Created";
  subNavTabs[2].description = pluralize('Stage', subNavTabs[2].itemsDone, true) + " Created";
  subNavTabs[3].description = pluralize('Connection', subNavTabs[3].itemsDone, true);
  subNavTabs[4].description =  subNavTabs[4].itemsDone > 0 ? "Mapping Complete" : "Mapping Incomplete";

  // Set current tab (and statuses) based on URL
  var tabName = location.pathname.split("/").pop();
  subNavTabs.forEach((tab) => {
    if (tab.href == tabName) {
      tab.current = true;
      tab.status = `current${tab.itemsDone > 0 ? "Complete" : ""}`;
    } else {
      tab.current = false;
      tab.status = tab.itemsDone > 0 ? "complete" : "upcoming";
    }
  });

  /**
   * Selects a tab by name. This adjusts subNavTabs.
   * @param {string} tabName Name of tab to select
   */
  function selectTab(tabName) {
    var temp = [...subNavTabs];
    temp.forEach((tab) => {
      tab.current = tab.name == tabName ? true : false;
    });
    setSubNavTabs(temp);
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        {/* Top Bar */}
        <TopBar>
          Operations Setup
        </TopBar>

        {/* Body */}
        <div className="inset-0 flex flex-col flex-1 p-6 pt-2 space-y-2">
          {/* Sub Nav */}
          <div className="p-2 pt-0">
            {/* <SubNav tabs={subNavTabs}
              onSelected={(tabName) => {
                selectTab(tabName);
              }}
            /> */}
            <StepsPanel steps={subNavTabs} />
          </div>

          <Outlet />
        </div>
      </div>
    </>
  );
}
