import React, { useState, useEffect } from 'react'
import Input from './Input'

/**
 * Input component for selecting a time range, start and end time
 * 
 * @param onChange - function to call when the time range changes
 * @param label - label for the input
 * @param enforceStartBeforeEnd - if true, enforce that the start time is before or equal to the end time
 */
export default function TimeRangeInput({
    onChange,
    label,
    enforceStartBeforeEnd = false,
}) {

    const [startTime, setStartTime] = useState("")

    const [endTime, setEndTime] = useState("")

    return (
        <div>
            {label && (
                <label
                    className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                    {label}
                </label>
            )}
            <div className='flex flex-row items-center w-full gap-2'>
                <div className='w-full'>
                    <Input
                        type="time"
                        value={startTime}
                        onBlur={(value) => {
                            if (enforceStartBeforeEnd && endTime) {
                                if (value <= endTime) {
                                    setStartTime(value)
                                    onChange({
                                        startTime: value,
                                        endTime
                                    })
                                } else {
                                    setStartTime(endTime)
                                    onChange({
                                        startTime: endTime,
                                        endTime
                                    })
                                }
                            } else {
                                setStartTime(value)
                                onChange({
                                    startTime: value,
                                    endTime
                                })
                            }

                        }}
                    />
                </div>
                <div>
                    -
                </div>
                <div className='w-full'>
                    <Input
                        type="time"
                        value={endTime}
                        onBlur={(value) => {
                            if (enforceStartBeforeEnd && startTime) {
                                if (value >= startTime) {
                                    setEndTime(value)
                                    onChange({
                                        startTime,
                                        endTime: value
                                    })
                                } else {
                                    setEndTime(startTime)
                                    onChange({
                                        startTime,
                                        endTime: startTime
                                    })
                                }
                            } else {
                                setEndTime(value)
                                onChange({
                                    startTime,
                                    endTime: value
                                })
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
