import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleCard({
  bgColor = "primary-rose",
  initials,
  href,
  text,
  subtext,
  grayed,
  hideEllipsis,
  onEllipsisClick = () => {},
}) {
  if (!initials && text) initials = text.substring(0, 2);

  return (
    <div className="flex rounded-md shadow w-full group">
      <div
        className={classNames(
          grayed ? "bg-gray-600" : "bg-" + bgColor,
          "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white uppercase"
        )}
      >
        {initials}
      </div>
      <div
        className={classNames(
          "flex flex-1 items-center justify-between truncate rounded-r-md border-gray-200",
          grayed ? "bg-gray-300" : "bg-white group-hover:bg-gray-200"
        )}
      >
        <div className="flex-1 truncate px-4 py-2 text-sm">
          <a href={href} className="font-medium text-gray-900 capitalize">
            {text}
          </a>
          <p className="text-gray-500">{subtext}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          {!hideEllipsis && (
            <button
              type="button"
              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-green focus:ring-offset-2"
              onClick={onEllipsisClick}
            >
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
