import { FolderIcon, HomeModernIcon, MapIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";


/**
 * 
 * @param {function} setNextStepButtonText - function to set the text of the next step button
 * @param {function} setNextStepButtonDisabled - function to set the next step button disabled/enabled
 * @param {function} setGoToToaButtonDisabled - function to set the go to toa button disabled/enabled 
 * 
 * @returns {JSX.Element} - Operations Setup component that displays the operations setup guide, no actions are performed
 */
export default function OperationsSetup({
  setNextStepButtonText = (t: string) => {},
  setNextStepButtonDisabled = (b: boolean) => {},
  setGoToToaButtonDisabled = (b: boolean) => {},
}) {
  useEffect(() => {
    setNextStepButtonText("Skip to Roles Setup");
    setNextStepButtonDisabled(false);
    setGoToToaButtonDisabled(false);
  }, []);

  // TODO: handle dark mode
  return (
    <article className="flex flex-col items-center gap-40 pt-20">
      <div className="flex flex-col items-center">
        <h1 className="text-xl font-semibold leading-7">Operations Setup</h1>
        <p className="text-base font-normal leading-6 text-gray-500">Build automated forecasts</p>
      </div>
      <p className="text-xl font-medium leading-7">
        You can create an automated Forecast by setting up these three things
        through our Operations Setup guide
      </p>
      <div className="flex gap-24">
        <div className="flex flex-col gap-1 items-center max-w-[300px]">
          <MapIcon className="w-24 h-24 pb-5 text-gray-400" />
          <h2 className="text-xl font-semibold leading-7">Define Markets</h2>
          <p className="text-base font-normal leading-6 text-gray-500">Find or Send invites to Distributors to start collaborating.</p>
        </div>
        <div className="flex flex-col gap-1 items-center max-w-[300px]">
          <FolderIcon className="w-24 h-24 pb-5 text-gray-400" />
          <h2 className="text-xl font-semibold leading-7">Define Stages</h2>
          <p className="text-base font-normal leading-6 text-gray-500">Define your markets and stages to create forecasts.</p>
        </div>
        <div className="flex flex-col gap-1 items-center max-w-[300px]">
          <HomeModernIcon className="w-24 h-24 pb-5 text-gray-400" />
          <h2 className="text-xl font-semibold leading-7">Define Material</h2>
          <p className="text-base font-normal leading-6 text-gray-500">Add team members and assign roles and permissions.</p>
        </div>
      </div>
    </article>
  );
}
