import React, { Component, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ToaNav from "../components/nav/ToaNav";
import $ from "jquery";
import EasterEggs from "../components/easterEggs/EasterEggs.tsx";
import UserManager from "../tools/UserManager.js";


export default function AppLayout() {

  const navigate = useNavigate();

  const [user, setUser] = useState({});

  // When created
  // Get user info (/api/user/info)
  // If not logged in, redirect to login page
  useEffect(() => {
    UserManager.getUser()
      .then((user) => {
        if (!user.loggedIn) {
          navigate("/login/login");
        }
        setUser(user.userData);
      })
      .catch((err) => {
        navigate("/login/login");
      });
  }, []);

  if (!user) return <>
    <div className="dark:bg-gray-700" />
  </>

  return (
    <>
      <div className="dark:bg-gray-700">
        <EasterEggs>
          <ToaNav page="App">
            <Outlet />
          </ToaNav>
        </EasterEggs>
      </div>
    </>
  );
}
