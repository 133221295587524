import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  Squares2X2Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import pluralize from "pluralize";
import React, { useEffect, useState, useRef } from "react";
import Button from "../../components/input/Button";
import Card from "../../components/Card";
import IconToggle from "../../components/input/IconToggle.tsx";
import EditMappingCard from "../../components/OperationsSetup/DataMappingPage/EditMappingCard";
import Spinner from "../../components/Spinner";
import StepsPanel from "../../components/nav/StepsPanels";
import {
  isValidMapping,
  isValidRawJobMapping,
} from "../../tools/OperationsSetup/Validator";
import UserManager from "../../tools/UserManager";
import Modal from "../../components/Modal";
import Alert from "../../components/feedback/Alert";
import debounce from "../../tools/debounce";
import { map } from "jquery";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// Get schema
var exampleSchema = {
  test: {
    test2: 1,
    test3: "test",
    test4: {
      test5: 2,
      test6: [],
      test7: [1],
    },
  },
  systemSize: 5200,
  panelQuantity: 13,
  panelType: "VSUN",
  city: "Henderson",
  zip: "89011",
  state: "Nevada",
  dateSold: "2023-07-11",
  marketFormula: "Nevada",
  invertersBrand: "SolarEdge",
  invertersSize: "",
  invertersQuantity: 1,
  status: "Active",
  tasks: [
    {
      status: "Not Started",
      task_type: "System Design",
      customer: "131117.0",
      status_date_time: "2023-07-11T03:59:19Z",
    },
  ],
  appointments: [],
  qtyCaulk: 1,
  qtyEndSleeves: 10,
  qtyMicroBolts: 13,
  qtyRails: 7,
  qtySoladecks: null,
  qtyStandoffs: 19,
};

var exampleMaterialGroups = [
  {
    _id: 1,
    name: "panel",
    customFields: [
      {
        name: "wattage",
        type: "number",
        unit: "W",
      },
    ],
  },
  {
    _id: 2,
    name: "inverter",
    customFields: [],
  },
  {
    _id: 3,
    name: "battery",
    customFields: [
      {
        name: "wattHours",
        type: "number",
        unit: "Wh",
      },
      {
        name: "voltage",
        type: "number",
        unit: "V",
      },
    ],
  },
];

var exampleStages = [
  {
    name: "Design",
    _id: 1,
  },
  {
    name: "Permit",
    _id: 2,
  },
  {
    name: "Install",
    _id: 3,
  },
  {
    name: "Inspection",
    _id: 4,
  },
  {
    name: "Utility",
    _id: 5,
  },
];

var exampleMapping = {
  name: null,

  marketFormula: null,

  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  postalCode: null,

  contactName: null,
  contactPhone: null,
  contactEmail: null,

  systemSize: null,

  stages: null, //stages.map((s) => ({ stage: s._id, date: null })), // TODO: implement

  materialGroups: null /*materialGroups.map((mg) => ({
    materialGroup: mg._id,
    brand: null,
    model: null,
    name: null,
    quantity: null,
    customFields: mg.customFields.map((cf) => ({
      name: cf.name,
      value: null,
    })),
  })),*/, // TODO: implement

  customFields: [], // TODO: implement

  cancelDate: null,
  cancelStatus: false,
  cancelStatusValues: [], // TODO: implement
};

export default function DataMappingPage() {
  const [isSummaryView, setIsSummaryView] = useState(true);
  const [state, setState] = useState({
    stages: null,
    materialGroups: null,
    schema: null,
    mapping: null,
    missingJobsCount: null,
  });
  const [showConfirmForceMap, setShowConfirmForceMap] = useState(false);
  const [alert, setAlert] = useState({
    severity: "good",
    title: "Success!",
    subtext: "",
    showing: false,
  });

  const [selectedMappingId, setSelectedMappingId] = useState(1);

  // Debounce save
  var debouncedSaveMapping = useRef(debounce(saveMapping, 1500)).current;

  var { stages, materialGroups, schema, mapping, missingJobsCount } = state;

  useEffect(() => {
    // Get stages, material groups, schema, and mapping
    if (!stages || !materialGroups || !schema || !mapping) {
      Promise.all([
        UserManager.makeAuthenticatedRequest(
          "/api/operations-setup/stages/get-stages"
        ),
        UserManager.makeAuthenticatedRequest(
          "/api/operations-setup/materials/get-groups"
        ),
        UserManager.makeAuthenticatedRequest(
          "/api/operations-setup/data-mapping/get-schema"
        ),
        UserManager.makeAuthenticatedRequest(
          "/api/operations-setup/data-mapping/get-mapping"
        ),
        UserManager.makeAuthenticatedRequest(
          "/api/operations-setup/data-mapping/get-missing-jobs-count"
        ),
      ])
        .then((res) => {
          // Handle stages
          var res_stages = res[0];
          var stages_status = res_stages.data.status;
          if (stages_status === "ok") {
            var stages = res_stages.data.stages;
          } else {
            console.error(res_stages.data.error);
            setAlert({
              severity: "error",
              title: "Error getting stages",
              showing: true,
            });
            return;
          }

          // Handle material groups
          var res_materialGroups = res[1];
          var materialGroups_status = res_materialGroups.data.status;
          if (materialGroups_status === "ok") {
            var materialGroups = res_materialGroups.data.materialGroups;
          } else {
            console.error(res_materialGroups.data.error);
            setAlert({
              severity: "error",
              title: "Error getting material groups",
              showing: true,
            });
            return;
          }

          // Handle schema
          var res_schema = res[2];
          var schema_status = res_schema.data.status;
          if (schema_status === "ok") {
            var schema = res_schema.data.schema;
          } else {
            console.error(res_schema.data.error);
            setAlert({
              severity: "error",
              title: "Error getting schema",
              showing: true,
            });
            return;
          }

          // Handle mapping
          var res_mapping = res[3];
          var mapping_status = res_mapping.data.status;
          if (mapping_status === "ok") {
            var mapping = res_mapping.data.installerMapping?.current;

            // Ensure stages are up to date
            if (
              mapping.stages.map((s) => s.stage) != stages.map((s) => s._id)
            ) {
              // Loop through stages and add any missing ones
              stages.forEach((stage, index) => {
                if (!mapping.stages.find((s) => s.stage === stage._id)) {
                  mapping.stages.splice(index, 0, {
                    stage: stage._id,
                    date: null,
                  });
                }
              });
            }

            // Ensure material groups are up to date
            if (
              materialGroups.map((mg) => mg._id) !=
              mapping.materialGroups.map((mg) => mg.materialGroup)
            ) {
              // Loop through material groups and add any missing ones
              materialGroups.forEach((mg, index) => {
                if (
                  !mapping.materialGroups.find(
                    (m) => m.materialGroup === mg._id
                  )
                ) {
                  mapping.materialGroups.splice(index, 0, {
                    materialGroup: mg._id,
                    brand: null,
                    model: null,
                    name: null,
                    quantity: null,
                    customFields: mg.customFields.map((cf) => ({
                      name: cf.name,
                      value: null,
                    })),
                  });
                }
              });
            }
          } else {
            console.error(res_mapping.data.error);
            setAlert({
              severity: "error",
              title: "Error getting mapping",
              showing: true,
            });
            return;
          }

          // Handle missing jobs count
          var res_missingJobsCount = res[4];
          var missingJobsCount_status = res_missingJobsCount.data.status;
          if (missingJobsCount_status === "ok") {
            var missingJobsCount = res_missingJobsCount.data.count;
          } else {
            console.error(res_missingJobsCount.data.error);
            setAlert({
              severity: "error",
              title: "Error getting missing jobs count",
              showing: true,
            });
            return;
          }

          // Setup mapping (will set state)
          setupMapping({
            stages: stages,
            materialGroups: materialGroups,
            schema: schema,
            mapping: mapping,
            missingJobsCount: missingJobsCount,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [stages, materialGroups, schema, mapping]);

  function setupMapping({
    stages,
    materialGroups,
    schema,
    mapping,
    missingJobsCount,
  }) {
    var newMap = {
      name: null,

      marketFormula: null,

      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      postalCode: null,

      contactName: null,
      contactPhone: null,
      contactEmail: null,

      systemSize: null,

      cancelDate: null,
      cancelStatus: false,
      cancelStatusValues: [],

      // Make sure stage mappings are using current stages
      stages: stages.map((s) => ({
        stage: s._id,
        date: mapping?.stages.find((m) => m.stage === s._id)?.date || null,
      })),

      // Make sure material mappings are using current material groups
      materialGroups: materialGroups.map((mg) => {
        var mappingGroup = mapping?.materialGroups.find(
          (m) => m.materialGroup === mg._id
        );

        return {
          materialGroup: mg._id,
          brand: mappingGroup?.brand || null,
          model: mappingGroup?.model || null,
          name: mappingGroup?.name || null,
          quantity: mappingGroup?.quantity || null,
          customFields: mg.customFields.map((cf) => ({
            name: cf.name,
            value:
              mappingGroup?.customFields.find((mcf) => mcf.name === cf.name)
                ?.value || null,
          })),
        };
      }),

      ...mapping,
    };

    // Set mapping
    setState({
      ...state,
      stages: stages,
      materialGroups: materialGroups,
      schema: schema,
      mapping: newMap,
      missingJobsCount: missingJobsCount,
    });
  }

  // Loading
  if (
    stages === null ||
    materialGroups === null ||
    schema === null ||
    mapping === null ||
    missingJobsCount === null
  ) {
    return <Spinner />;
  }

  var jobMappings = [
    {
      group: "Job Info",
      mappings: [
        {
          name: "name",
          label: "Job Name",
          mapping: mapping.name,
        },
        {
          name: "systemSize",
          label: "Job Size",
          mapping: mapping.systemSize,
        },
        {
          name: "marketFormula",
          label: "Market",
          mapping: mapping.marketFormula,
        },
      ],
    },
    {
      group: "Address",
      mappings: [
        {
          name: "addressLine1",
          label: "Line 1",
          mapping: mapping.addressLine1,
        },
        {
          name: "addressLine2",
          label: "Line 2",
          mapping: mapping.addressLine2,
        },
        {
          name: "city",
          label: "City",
          mapping: mapping.city,
        },
        {
          name: "state",
          label: "State",
          mapping: mapping.state,
        },
        {
          name: "postalCode",
          label: "Postal Code",
          mapping: mapping.postalCode,
        },
      ],
    },
    {
      group: "Contact",
      mappings: [
        {
          name: "contactName",
          label: "Name",
          mapping: mapping.contactName,
        },
        {
          name: "contactPhone",
          label: "Phone",
          mapping: mapping.contactPhone,
        },
        {
          name: "contactEmail",
          label: "Email",
          mapping: mapping.contactEmail,
        },
      ],
    },
    {
      group: "Custom Fields",
      mappings: [],
    },
  ];

  var stageMappings = [
    {
      group: "Stages",
      mappings: mapping.stages.map((s, i) => ({
        name: s.stage,
        label: stages[i].name,
        mapping: s.date,
      })),
    },
    {
      group: "Cancellation",
      mappings: [
        {
          name: "cancelDate",
          label: "Cancel Date",
          mapping: mapping.cancelDate,
        },
        {
          name: "cancelStatus",
          label: "Status",
          mapping: mapping.cancelStatus,
        },
        {
          name: "cancelStatusValues",
          label: "Cancel Status Values",
          mapping: mapping.cancelStatusValues,
          type: "inputArray",
        },
      ],
    },
  ];

  var materialMappings = mapping.materialGroups.map((mgm, i) => ({
    group:
      pluralize.plural(materialGroups[i].name).charAt(0).toUpperCase() +
      pluralize.plural(materialGroups[i].name).slice(1),
    _id: mgm.materialGroup,
    mappings: [
      {
        name: "name",
        label: "Name",
        mapping: mgm.name,
      },
      {
        name: "brand",
        label: "Brand",
        mapping: mgm.brand,
      },
      {
        name: "model",
        label: "Model",
        mapping: mgm.model,
      },
      {
        name: "quantity",
        label: "Quantity",
        mapping: mgm.quantity,
      },
      ...mgm.customFields.map((cf) => ({
        name: "custom_" + cf.name,
        label: cf.name.charAt(0).toUpperCase() + cf.name.slice(1),
        mapping: cf.value,
      })),
    ],
  }));

  var selectedMapping = [jobMappings, stageMappings, materialMappings][
    selectedMappingId - 1
  ];

  function handleMappingChange(newMapping) {
    // New mapping is the updated job/stage/material mappings
    // Need to update mapping state accordingly

    var temp = structuredClone(mapping);

    switch (selectedMappingId) {
      case 1: // Job Mapping
        // Job info group
        temp.name = newMapping[0].mappings[0].mapping;
        temp.systemSize = newMapping[0].mappings[1].mapping;
        temp.marketFormula = newMapping[0].mappings[2].mapping;

        // Address group
        temp.addressLine1 = newMapping[1].mappings[0].mapping;
        temp.addressLine2 = newMapping[1].mappings[1].mapping;
        temp.city = newMapping[1].mappings[2].mapping;
        temp.state = newMapping[1].mappings[3].mapping;
        temp.postalCode = newMapping[1].mappings[4].mapping;

        // Contact group
        temp.contactName = newMapping[2].mappings[0].mapping;
        temp.contactPhone = newMapping[2].mappings[1].mapping;
        temp.contactEmail = newMapping[2].mappings[2].mapping;

        // Custom Fields group
        // TODO: implement
        break;

      case 2: // Stage Mapping
        var tempStages = [];

        newMapping[0].mappings.forEach((mapping) => {
          tempStages.push({
            stage: mapping.name,
            date: mapping.mapping,
          });
        });

        temp.stages = tempStages;

        // Cancellation
        temp.cancelDate = newMapping[1].mappings[0].mapping;
        temp.cancelStatus = newMapping[1].mappings[1].mapping;
        temp.cancelStatusValues = newMapping[1].mappings[2].mapping;

        break;

      case 3: // Material Mapping
        // TODO: don't push. make temp and replace
        var groups = [];
        newMapping.forEach((group) => {
          // Get custom fields
          var customFields = [];
          group.mappings.forEach((mapping) => {
            if (mapping.name.startsWith("custom_")) {
              customFields.push({
                name: mapping.name.substring(7), // Drop "custom_"
                value: mapping.mapping,
              });
            }
          });

          // Get material group
          groups.push({
            materialGroup: group._id,

            // Standard fields
            brand: group.mappings.find((m) => m.name === "brand").mapping,
            model: group.mappings.find((m) => m.name === "model").mapping,
            name: group.mappings.find((m) => m.name === "name").mapping,
            quantity: group.mappings.find((m) => m.name === "quantity").mapping,

            customFields: customFields,
          });
        });

        temp.materialGroups = groups;
        break;

      default:
        break;
    }

    // Set validity
    temp.isValidMapping = isValidRawJobMapping(temp, schema);

    // Save mapping
    setState({
      ...state,
      mapping: temp,
    });
    debouncedSaveMapping(temp, {
      ...state,
      mapping: temp,
    });
  }

  function saveMapping(mapping, currState) {
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/data-mapping/save-mapping",
      "POST",
      {
        mapping: mapping,
      }
    )
      .then((res) => {
        if (res.data.status === "ok") {
          var newMapping = res.data.mapping;
          // TODO: do we need to update here if updated before save?
          // setState({
          //   ...currState,
          //   mapping: newMapping,
          // });
        } else {
          setAlert({
            severity: "error",
            title: "Error saving mapping",
            showing: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          severity: "error",
          title: "Error saving mapping",
          showing: true,
        });
      });
  }

  function handleMapMissing() {
    // Double check that mapping is valid
    if (!mapping.isValidMapping) {
      setAlert({
        severity: "error",
        title: "Invalid Mapping",
        showing: true,
      });
      return;
    }

    // Set missing jobs count to -1 to indicate that we are mid mapping
    setState({
      ...state,
      missingJobsCount: -1,
    });

    // Map missing jobs
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/data-mapping/convert-missing-jobs",
      "POST"
    )
      .then((res) => {
        if (res.data.status === "ok") {
          // Reduce missing jobs count by number of jobs mapped
          setState({
            ...state,
            missingJobsCount: missingJobsCount - res.data.jobsTranslated,
          });
        } else {
          setAlert({
            severity: "error",
            title: "Error mapping missing jobs",
            showing: true,
          });

          // Reset missing jobs count
          UserManager.makeAuthenticatedRequest(
            "/api/operations-setup/data-mapping/get-missing-jobs-count"
          )
            .then((res) => {
              var status = res.data.status;
              if (status === "ok") {
                var missingJobsCount = res.data.count;

                setState({
                  ...state,
                  missingJobsCount: missingJobsCount,
                });
              } else {
                console.error(res.data.error);
                setAlert({
                  severity: "error",
                  title: "Error getting missing jobs count",
                  showing: true,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleForceMap() {
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/data-mapping/force-convert-jobs",
      "POST"
    )
      .then((res) => {
        if (res.data.status === "ok") {
          setAlert({
            severity: "good",
            title: "Jobs successfully mapped",
            showing: true,
          });
        } else {
          setAlert({
            severity: "error",
            title: "Error force mapping jobs",
            showing: true,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  // Get content

  var content;
  if (isSummaryView) {
    content = (
      <div className="flex items-start gap-6 m-2">
        {/* Job Mappings */}
        <MappingCard
          title="Job Mapping"
          mappings={jobMappings}
          schema={schema}
        />

        {/* Stage Mappings */}
        <MappingCard
          title="Stage Mapping"
          mappings={stageMappings}
          hideNewField
          schema={schema}
        />

        {/* Material Mappings */}
        <MappingCard
          className="grow"
          title="Material Mapping"
          mappings={materialMappings}
          wide
          schema={schema}
        />
      </div>
    );
  } else {
    var fieldsMapped = {
      jobs: {
        total: 0,
        mapped: 0,
      },
      stages: {
        total: 0,
        mapped: 0,
      },
      materials: {
        total: 0,
        mapped: 0,
      },
    };

    // Iterate through mappings to count total and mapped fields
    jobMappings.forEach((group) => {
      group.mappings.forEach((mapping) => {
        fieldsMapped.jobs.total++;
        if (mapping.mapping && isValidMapping(mapping.mapping, schema)) {
          fieldsMapped.jobs.mapped++;
        }
      });
    });
    stageMappings.forEach((group) => {
      group.mappings.forEach((mapping) => {
        fieldsMapped.stages.total++;
        // Input Array
        if (
          mapping.type === "inputArray" &&
          mapping.mapping &&
          Array.isArray(mapping.mapping)
        ) {
          fieldsMapped.stages.mapped++;
        }
        // Standard
        else if (mapping.mapping && isValidMapping(mapping.mapping, schema)) {
          fieldsMapped.stages.mapped++;
        }
      });
    });
    materialMappings.forEach((group) => {
      group.mappings.forEach((mapping) => {
        fieldsMapped.materials.total++;
        if (mapping.mapping && isValidMapping(mapping.mapping, schema)) {
          fieldsMapped.materials.mapped++;
        }
      });
    });

    var steps = [
      {
        id: "1",
        name: "Job Mapping",
        description: `${fieldsMapped.jobs.mapped} of ${fieldsMapped.jobs.total} Fields Mapped`,
        href: "#job-mapping",
        status:
          fieldsMapped.jobs.mapped === fieldsMapped.jobs.total
            ? "complete"
            : "upcoming",
      },
      {
        id: "2",
        name: "Stage Mapping",
        description: `${fieldsMapped.stages.mapped} of ${fieldsMapped.stages.total} Fields Mapped`,
        href: "#stage-mapping",
        status:
          fieldsMapped.stages.mapped === fieldsMapped.stages.total
            ? "complete"
            : "upcoming",
      },
      {
        id: "3",
        name: "Material Mapping",
        description: `${fieldsMapped.materials.mapped} of ${fieldsMapped.materials.total} Fields Mapped`,
        href: "#material-mapping",
        status:
          fieldsMapped.materials.mapped === fieldsMapped.materials.total
            ? "complete"
            : "upcoming",
      },
    ];
    steps.forEach((step) => {
      if (step.id == selectedMappingId) {
        step.status =
          step.status === "complete" ? "currentComplete" : "current";
      }
    });
    // TODO: change complete status based on fieldsMapped

    content = (
      <div className="flex flex-col items-center gap-6 p-2">
        {/* Mapping Sections */}
        <div>
          <StepsPanel
            steps={steps}
            disconnected
            focusOnIncomplete
            boldTitle
            selectedBorderColor="bg-primary-rose"
            onSelected={(s) => {
              setSelectedMappingId(Number(s.id));
            }}
          />
        </div>

        {/* Mapping Details */}
        <EditMappingCard
          title={
            ["Job Mapping", "Stage Mapping", "Material Mapping"][
              selectedMappingId - 1
            ]
          }
          schema={schema}
          mappings={selectedMapping}
          onChange={handleMappingChange}
        />
        {/* TODO: dynamic mappings */}
      </div>
    );
  }

  return (
    <>
      {/* Actions */}
      <div className="flex flex-wrap items-center gap-4 p-2">
        {/* // TODO: implement dropdown functionality */}
        <div className="flex items-center text-lg font-semibold text-gray-900 basis-full lg:basis-0 whitespace-nowrap">
          <div className="flex flex-col">
            <div>{isSummaryView ? "Field Mapping" : "Edit Field Mapping"}</div>
            <div className="text-base font-medium text-gray-500">
              {isSummaryView
                ? "Review mapping from Project Management data to TOA"
                : "Select TOA Field to be mapped and add Mapped Field and Mapping Rules where necessary"}
            </div>
          </div>
        </div>

        <div className="ml-auto">
          <IconToggle
            selectedIconId="summary"
            onChange={(id) => {
              setIsSummaryView(id === "summary");
            }}
            icons={[
              {
                id: "summary",
                name: "Summary",
                content: {
                  on: (
                    <Squares2X2Icon
                      className={classNames(
                        "text-primary-green stroke-2",
                        "h-5 w-5 stroke-2"
                      )}
                      aria-hidden="true"
                    />
                  ),
                  off: (
                    <Squares2X2Icon
                      className={classNames(
                        "text-gray-500 stroke-2",
                        "h-5 w-5 stroke-2"
                      )}
                      aria-hidden="true"
                    />
                  ),
                },
              },
              {
                id: "edit",
                name: "Edit",
                content: {
                  on: (<PencilIcon
                    className={classNames(
                      "text-primary-green stroke-2",
                      "h-4 w-4 stroke-2"
                    )}
                    aria-hidden="true"
                  />),
                  off: (<PencilIcon
                    className={classNames(
                      "text-gray-500 stroke-2",
                      "h-4 w-4 stroke-2"
                    )}
                    aria-hidden="true"
                  />),
                }
              },
            ]}
          />
        </div>

        {/* Mapping Status Indicator */}
        <div>
          {mapping.isValidMapping ? (
            <div className="p-2 text-sm font-normal border rounded-lg border-primary-green text-primary-green">
              Valid Mapping
            </div>
          ) : (
            <div className="p-2 text-sm font-normal border rounded-lg border-secondary-orange text-secondary-orange">
              Invalid Mapping
            </div>
          )}
        </div>

        <div>
          {" "}
          {missingJobsCount > 0 ? (
            <Button
              variant="primary-green"
              disabled={!mapping.isValidMapping}
              onClick={handleMapMissing}
            >
              Map {missingJobsCount} Jobs
            </Button>
          ) : missingJobsCount === -1 ? (
            <div className="p-2 text-sm font-normal border rounded-lg border-secondary-orange text-secondary-orange">
              Mapping Jobs...
            </div>
          ) : (
            <div className="p-2 text-sm font-normal border rounded-lg border-primary-green text-primary-green">
              All Jobs Mapped
            </div>
          )}
        </div>

        {/* Force Map */}
        {mapping.isValidMapping && (
          <div>
            <Button
              variant="secondary-orange"
              onClick={() => {
                setShowConfirmForceMap(true);
              }}
            >
              Force Map All
            </Button>
          </div>
        )}

        {/* TODO: implement <div className="">
          <Button variant="primary-green">Add Field</Button>
        </div> */}
      </div>

      {/* Body */}
      <div className="overflow-auto">{content}</div>

      {/* Alert */}
      <Alert
        {...alert}
        setShowing={(val) => {
          setAlert({ ...alert, showing: val });
        }}
      />

      {/* Force Map All Modal */}
      <Modal open={showConfirmForceMap} setOpen={setShowConfirmForceMap}>
        <div className="flex flex-col gap-6">
          {/* Title */}
          <div className="flex items-center gap-3 text-secondary-orange">
            <ExclamationTriangleIcon className="w-6 h-6 text-secondary-orange" />
            <div className="text-lg font-semibold">
              Are you sure you want to force map all jobs?
            </div>
          </div>

          {/* Description */}
          <div className="text-gray-500">
            <p>
              This action will force map all existing jobs based on the current
              mapping. Note that new or updated jobs will automatically use the
              current mapping regardless of whether you or not you take this
              action.{" "}
              <span className="italic">
                If old jobs do not fit this mapping, it is not recommended to
                force map as it will upset the integrity of the data.
              </span>{" "}
              This action cannot be undone.
            </p>
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-4">
            <Button
              variant="secondary"
              onClick={() => {
                setShowConfirmForceMap(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="secondary-orange"
              onClick={() => {
                setShowConfirmForceMap(false);
                handleForceMap();
              }}
            >
              Force Map All Jobs
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function MappingGroup({ group, wide = false, schema }) {
  const [isExpanded, setIsExpanded] = useState(true);

  var halfMappingSize = Math.ceil(group.mappings.length / 2);

  return (
    <div className="text-gray-500">
      {/* Group Label */}
      {group.group && (
        <div className="flex items-center gap-3 font-semibold">
          <div>{group.group}</div>
          <div className="border-t border-gray-500 grow"></div>
          <div>
            {isExpanded ? (
              <ChevronUpIcon
                className="w-5 h-5 hover:text-gray-400 text-gray-500 cursor-pointer stroke-[3px]"
                onClick={() => setIsExpanded(false)}
              />
            ) : (
              <ChevronDownIcon
                className="w-5 h-5 hover:text-gray-400 text-gray-500 cursor-pointer stroke-[3px]"
                onClick={() => setIsExpanded(true)}
              />
            )}
          </div>
        </div>
      )}
      {/* Group Contents */}
      {isExpanded && (
        <div
          className={classNames(
            group.group && "px-2",
            "pt-2 flex flex-col divide-y border-gray-300"
          )}
        >
          {!wide ? (
            group.mappings.map((mapping) => (
              <MappingItem key={mapping.name} item={mapping} schema={schema} />
            ))
          ) : (
            <div className="grid grid-cols-2 gap-9">
              <div className="border-gray-300 divide-y">
                {group.mappings
                  .map((mapping) => (
                    <MappingItem
                      key={mapping.name + "_1"}
                      item={mapping}
                      schema={schema}
                    />
                  ))
                  .slice(0, halfMappingSize)}
              </div>
              <div className="border-gray-300 divide-y">
                {group.mappings
                  .map((mapping) => (
                    <MappingItem
                      key={mapping.name + "_2"}
                      item={mapping}
                      schema={schema}
                    />
                  ))
                  .slice(halfMappingSize)}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function MappingItem({ item, schema }) {
  var mappingDescription = "";

  // Empty mapping ([]) should be null
  if (item.mapping && item.mapping.length === 0) {
    item.mapping = null;
  }

  if (item.mapping) {
    item.mapping.forEach((m) => {
      switch (m.type) {
        case "field":
          mappingDescription += m.fieldName;
          break;

        case "arrayItem":
          mappingDescription += `[${m.fieldName}]`;
          break;

        case "arrayObj":
          mappingDescription += `[${m.fieldName}].`;
          break;

        case "nestedField":
          mappingDescription += `${m.fieldName}.`;
          break;

        case "null":
          mappingDescription = (
            <div className="flex gap-2">
              <div>No Field</div>
              <abbr
                className="text-secondary-orange cursor-help"
                title="Note that it is recommended to map to a field if possible. Other features of TOA may not work as expected if you do not map to a field. Fields like stage dates, market/address, and materials are vital to the functionality of TOA."
              >
                *
              </abbr>
            </div>
          );
          break;

        default:
          break;
      }
    });
  }

  var isValid = isValidMapping(item.mapping, schema);
  if (item.type === "inputArray") {
    isValid = true;
    mappingDescription = item.mapping
      ? item.mapping.map((v) => `"${v}"`).join(", ")
      : "No Values";
  }

  return (
    <div
      className={classNames(
        "flex items-center gap-3 py-1",
        item && item.mapping && item.mapping[0].type === "null" && "bg-gray-100"
      )}
    >
      {!isValid && <div className="w-1.5 h-8 bg-secondary-orange" />}
      <div className="grid items-center grid-cols-2 gap-2 pl-3 grow h-9">
        <div
          className={classNames(
            "font-medium",
            !isValid && "text-secondary-orange -ml-3"
          )}
        >
          {item.label}
        </div>
        <div>{mappingDescription}</div>
      </div>
    </div>
  );
}

function MappingCard({
  title = "Title",
  mappings = [],
  hideNewField = false,
  className = "",
  wide = false,
  schema,
  onEditPressed = () => {},
}) {
  // Determine if mappings are valid
  var success = true;
  var totalCount = 0;
  var mappedCount = 0;
  for (var i = 0; i < mappings.length; i++) {
    var mapping = mappings[i];
    mapping.mappings.forEach((item) => {
      totalCount++;
      if (!item.mapping) {
        success = false;
      } else if (item.type === "inputArray" && Array.isArray(item.mapping)) {
        mappedCount++;
      } else if (isValidMapping(item.mapping, schema)) {
        mappedCount++;
      }
    });
  }
  if (mappedCount !== totalCount) success = false;

  var indicatorColor = success ? "emerald-500" : "secondary-orange";

  return (
    <Card className={className}>
      <div className="-m-4">
        {/* Header */}
        <div className="flex items-center gap-3 p-3 border-b border-gray-300">
          {/* Indicator */}
          <div
            className={classNames(
              "w-10 h-10 rounded-full flex items-center justify-center",
              success ? "border-2 border-primary-green" : "bg-secondary-orange"
            )}
          >
            {success ? (
              <CheckIcon className="w-6 h-6 text-primary-green" />
            ) : (
              <XMarkIcon className="w-6 h-6 text-white" />
            )}
          </div>

          {/* Title */}
          <div className="flex flex-col lg:min-w-[400px]">
            <div className="text-xl font-semibold text-gray-900">{title}</div>
            <div className="text-sm font-normal text-gray-900">
              {mappedCount} of {totalCount} Fields Mapped
            </div>
          </div>
        </div>

        {/* Body */}
        <div className="flex flex-col gap-2 px-6 py-3">
          {mappings.map((mapping) => {
            return (
              <MappingGroup
                key={mapping.group}
                group={mapping}
                wide={wide}
                schema={schema}
              />
            );
          })}
        </div>

        {/* Footer */}
        {!hideNewField &&
          false && ( // TODO: implement
            <div className="flex justify-end px-6 py-3 rounded-b-lg bg-gray-50">
              <Button variant="primary-green">New Field</Button>
            </div>
          )}
      </div>
    </Card>
  );
}
