import React, { Component } from 'react'
import { Col } from 'react-bootstrap'

export default class RightBar extends Component {
  render() {
    return (
      <Col className='pr-0' style={{ "maxWidth": 300 }}>

        <div className='flo-content flo-border-left h-100 w-100' style={{ "position": "relative" }}>
          <div style={{ "position": "absolute", "top": 0, "left": 0, "right": 0, "bottom": 0, "overflowY": "auto" }}>
            <div className="p-3">
              {this.props.children}
            </div>
          </div>
        </div>
        
      </Col>
    )
  }
}
