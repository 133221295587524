import React, { useContext, Fragment } from "react";
import { useOutletContext } from "react-router-dom";

import MaterialForecastContext from "../../../../contexts/forecast/material/MaterialForecastContext";
import Card from "../../../Card";

import moment from "moment";
import pluralize from "pluralize";

import Week from "src/pages/Forecast/classes/Week";
import Forecast from "src/pages/Forecast/classes/Forecast";
import MaterialGroupTable from "../../../MaterialGroupTable";

import { ForecastSnapshotViewContextType } from "../../../../contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MaterialGroupCard({ group, isStatic = false }) {
  const outletContextValue = useOutletContext();

  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = outletContextValue;
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
      materialGroups: matGroups,
      materials,
    } = outletContextValue;
  }

  // Material Forecast context
  const { xWeekForecast, handleXWeekForecastChange } = useContext(
    MaterialForecastContext
  );

  if (!forecastData && !isStatic) return null;

  // X week date range
  let currentWeek = moment().utc().startOf("week");
  let xWeekForecastEndDate = currentWeek
    .clone()
    .add(xWeekForecast, "weeks")
    .subtract(1, "day");

  // Get used materials
  let usedMats = !isStatic
    ? forecastData?.usedMaterials
    : staticForecastData.toaMaterialForecast;

  // Get default materials
  const defaultMaterials = materials?.filter((m) => m.isDefaultMaterial);

  // Get materials for group
  let groupMats = materials?.filter((m) => m.materialGroup === group._id) || [];
  // Filter by used mats
  let usedGroupMats = groupMats.filter((m) => m._id in (usedMats[group._id] ?? {}));

  // Add default, unassigned, and total
  let usedGroupMatsWithSpecial = [...usedGroupMats];
  let defaultMat = defaultMaterials.find((m) => m.materialGroup === group._id);
  if (defaultMat)
    usedGroupMatsWithSpecial.push({ ...defaultMat, _id: "default" });
  usedGroupMatsWithSpecial.push({
    _id: "unassigned",
    name: "Unassigned",
    materialGroup: group._id,
  });
  usedGroupMatsWithSpecial.push({
    _id: "total",
    name: "Total",
    materialGroup: group._id,
  });
  let formattedGroup = {
    name: group.name,
    field1: { key: "brand", label: "Brand" },
    field2: { key: "model", label: "Model" },
    items: usedGroupMatsWithSpecial,
    avgPerJob: group.avgPerJob,
    _id: group._id,
  };

  // Default and unassigned stat rows
  let defaultUnassignedStats = [
    {
      ...defaultMat,
      _id: "default",
    },
    {
      _id: "unassigned",
      name: "Future",
      materialGroup: group._id,
    },
  ];
  if (!defaultMat) {
    defaultUnassignedStats.shift();
  }

  return (
    <Fragment>
      <Card className="grid grid-cols-4 col-span-4">
        {/* Group Info */}
        <div className="grid items-stretch justify-between grid-cols-5 divide-x">
          {/* Group Summary */}
          <div className="flex flex-col items-center justify-center col-span-2 pr-2 text-center">
            <div className="text-xl font-semibold">
              {pluralize(group.name, 2, false)}
            </div>
            <div className="text-sm font-medium">
              <div>{xWeekForecast} Wk Forecast</div>
              <div>
                ({currentWeek.format("M/D")} -{" "}
                {xWeekForecastEndDate.format("M/D")})
              </div>
            </div>
            {/* // TODO: implement prev forecast comparison bar chart */}
            {/* <div className="flex flex-col justify-center h-20 font-extrabold text-red-500">
              BAR CHART
            </div> */}
            {/* // TODO: implement prev forecast diff */}
            {/* <div className="text-xl font-semibold text-primary-green">+180</div> */}
          </div>
          {/* Material names */}
          <div className="col-span-3 flex flex-col gap-2.5 justify-end min-w-0 pl-2 pb-1">
            <table className="table w-full text-sm font-medium text-center">
              <thead>
                <tr>
                  <th className="w-1/2 py-2 font-medium max-w-min"></th>
                  <th className="w-1/4 py-2 font-medium max-w-min">
                    {xWeekForecast} Wk
                  </th>
                  <th className="w-1/4 py-2 font-medium text-gray-400 max-w-min">
                    20 Wk
                  </th>
                </tr>
              </thead>
              <tbody>
                {usedGroupMats.map((m, i) => {
                  return (
                    <MaterialStatsRow
                      isStatic={isStatic}
                      key={i}
                      material={m}
                      xWeekForecast={xWeekForecast}
                      forecastData={forecastData}
                      group={group}
                    />
                  );
                })}
              </tbody>
            </table>

            {/* Default and Unassigned */}
            <table className="table w-full text-sm font-medium text-center">
              <tbody>
                {defaultUnassignedStats.map((m, i) => {
                  return (
                    <MaterialStatsRow
                      isStatic={isStatic}
                      key={i}
                      material={m}
                      xWeekForecast={xWeekForecast}
                      forecastData={forecastData}
                      group={group}
                    />
                  );
                })}
              </tbody>
            </table>

            {/* Total */}
            <table className="table w-full text-sm font-bold text-center">
              <tbody>
                <MaterialStatsRow
                  isStatic={isStatic}
                  material={{
                    _id: "total",
                    name: "TOTAL",
                    materialGroup: group._id,
                  }}
                  xWeekForecast={xWeekForecast}
                  forecastData={forecastData}
                  group={group}
                />
              </tbody>
            </table>
          </div>
        </div>
        {/* Group Table */}
        <div className="col-span-3 px-2">
          <MaterialGroupTable
            isStatic={isStatic}
            noGap
            materialGroup={formattedGroup}
            noLabels
            weeksData={forecastData?.getByWeek()}
          />
        </div>
      </Card>
    </Fragment>
  );
}

function MaterialStatsRow({
  material,
  xWeekForecast,
  forecastData,
  group,
  isStatic = false,
}) {
  const outletContextValue = useOutletContext();

  let xWeekTotal = 0;
  let total = 0;

  if (!isStatic) {
    // Reduce weeks to get total
    let forecastByWeek = forecastData?.getByWeek();

    if (forecastByWeek) {
      for (let i = 0; i < xWeekForecast; i++) {
        xWeekTotal +=
          forecastByWeek[i]?.materials?.[group._id]?.[material._id] || 0;
      }

      // Reduce all weeks to get total
      for (let i = 0; i < 20; i++) {
        total += forecastByWeek[i]?.materials?.[group._id]?.[material._id] || 0;
      }
    }
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
      materialGroups: matGroups,
      materials,
    } = outletContextValue;

    // Sum up the values for the given group and material in the static material forecast
    total = staticForecastData.toaMaterialForecast?.[group._id]?.[
      material._id
    ]?.reduce((acc, curr) => acc + curr, 0) ?? 0;

    // Sum up the values for the given group and material in the static material forecast for the xWeekForecast (first X weeks)
    xWeekTotal = staticForecastData.toaMaterialForecast?.[group._id]?.[
      material._id
    ]
      ?.slice(0, xWeekForecast) // Get the first X weeks
      .reduce((acc, curr) => acc + curr, 0) ?? 0;
  }

  // Round and format
  xWeekTotal = Math.ceil(xWeekTotal);
  total = Math.ceil(total);

  xWeekTotal = xWeekTotal.toLocaleString();
  total = total.toLocaleString();
  let title =
    `Name: ${material.name ?? "-"}\n` +
    `Brand: ${material.brand ?? "-"}\n` +
    `Model: ${material.model ?? "-"}`;

  let displayName = material.name;

  if (!displayName) {
    displayName = material.brand;
    if (displayName && material.model) {
      displayName += ` ${material.model}`;
    } else if (!displayName) {
      displayName = material.model;
    }
    if (!displayName) {
      displayName = "Empty Material";
    }
  }

  return (
    <tr>
      <td
        className={classNames(
          "items-center justify-center w-1/2 max-w-[90px]",
          material._id === "unassigned" ? "text-gray-500" : ""
        )}
      >
        {material._id === "default" && (
          <div className="text-xs text-gray-500">Default</div>
        )}
        <div className="line-clamp-1" title={title}>
          {displayName}
        </div>
      </td>
      <td className="py-2.5 border border-r-primary-green border-r-[3px] w-1/4 max-w-[90px]">
        {xWeekTotal}
      </td>
      <td className="py-2.5 border text-gray-400  w-1/4 max-w-[90px]">
        {total}
      </td>
    </tr>
  );
}
