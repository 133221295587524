import React, { useEffect, useState } from "react";
import classNames from "src/tools/classNames";


export type IconType = {
  id: string | number;
  name: string;
  content: {
    on: JSX.Element;
    off: JSX.Element;
  };
};

type PropType = {
  icons: IconType[];
  selectedIconId: string | number;
  onChange: (iconId: string | number) => void;
  paddingClasses?: string;
};

/**
 * Simple toggle switch for switching between icons.
 * Takes an array of icons that define the on and off state content.
 * Icons are identified by their `id` property.
 * 
 * If you are looking for a toggle switch that switches between grid and 
 * list views (or rows/columns), see `GridListToggle` which is a wrapper around this component.
 */
export default function IconToggle({
  icons,
  selectedIconId: selectedIconId_prop,
  onChange = (iconId: string) => { },
  paddingClasses = "px-[0.375rem] py-1",
}: PropType): JSX.Element {
  const [selectedIconId, setSelectedIconId] = useState(selectedIconId_prop);

  function handleClick(iconId: string | number) {
    setSelectedIconId(iconId);
  }

  // Update the parent component with the selected icon when it changes
  useEffect(() => {
    onChange(selectedIconId);
  }, [selectedIconId]);

  // Update on prop change
  useEffect(() => {
    setSelectedIconId(selectedIconId_prop);
  }, [selectedIconId_prop]);

  return (
    <div
      className={classNames(
        "isolate inline-flex space-x-1 rounded-lg shadow-sm p-[2px] border",

        // Light Mode
        "bg-gray-200 border-gray-300",

        // Dark Mode
        "dark:bg-gray-800 dark:border-gray-600"
      )}
    >
      {icons.map((icon) => {
        return (
          <button
            key={icon.id}
            type="button"
            className={classNames(
              "relative inline-flex items-center rounded-md",
              paddingClasses,
              icon.id === selectedIconId
                ? "bg-white dark:bg-gray-600 shadow"
                : ""
            )}
            onClick={() => handleClick(icon.id)}
          >
            {icon.id === selectedIconId ? icon.content.on : icon.content.off}
          </button>
        );
      })}
    </div>
  );
}
