import React from 'react'
import moment from 'moment';
import classNames from 'src/tools/classNames';

type Data = string | Date | number | Fraction;

type Fraction = {
  numerator: number;
  denominator: number;
}

type Classes = {
  base?: string;
  numerator?: string;
  denominator?: string;
}

type Props = {
  data: Data[];
  classes?: Classes;
}

/**
 * A mini grid that displays a row of values. Just pass in the data and it will display it.
 * Takes strings, dates, numbers, and fractions (numerators and denominators) as input.
 */
export default function MiniGrid({ data = [], classes }: Props) {
  return (
    <table className="w-full table-fixed">
      <tbody>
        <tr>
          {data?.map((value, i) => (
            <ValueRender value={value} key={i} totalCount={data.length} index={i} classes={classes} />
          ))}
        </tr>
      </tbody>
    </table>
  )
}

type ValueRenderProps = { value: Data, totalCount: number, index: number, classes?: Classes }

/**
  * Renders the value in a cell. If the value is a date, it will be formatted to show only the month.
  */
function ValueRender({ value, classes }: ValueRenderProps): JSX.Element {

  let output: Data | JSX.Element = value;

  if (output instanceof Date) {
    output = moment(output).format("MMM");
  }
  else if (output instanceof Object) {
    output = <div className="flex flex-col -my-2.5 divide-y-2 divide-gray-300 px-1">
      <div className={classNames(classes?.numerator)}>{output.numerator}</div>
      <div className={classNames("text-xs", classes?.denominator)}>{output.denominator}</div>
    </div>
  }
  // TODO: dark mode

  return <td className={classNames('text-center border border-gray-200 text-xs font-normal leading-4 text-gray-500 py-2',
    classes?.base
  )}>{output}</td>
}
