import React from "react";
import { useOutletContext } from "react-router-dom";
import Forecast from "src/pages/Forecast/classes/Forecast";
import {
  ArrowsPointingOutIcon,
  BellAlertIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import ForecastSnapshotViewContext, {
  ForecastSnapshotViewContextType,
} from "../../../contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CapacityBarIndicator({ isStatic = false }) {
  /**
   * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
   */
  const outletContextValue = useOutletContext();

  if (!isStatic) {
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = outletContextValue;

    if (!forecastData) {
      return;
    }

    var { available, remainder } = forecastData?.getAvailablesAndRemainders();
    var capacity = forecastData?.getWeeksOfCapacity();
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
      materialGroups: matGroups,
      materials,
    } = outletContextValue;

    if (!staticForecastData) {
      return;
    }

    var { available, remainder } = Forecast.generateAvailableAndRemainder(
      staticForecastData.inputForecast,
      staticForecastData.toaForecast
    );
    var capacity = staticForecastData.weeksOfCapacity;
  }

  let lastWeek =
    forecastData?.weeks.reduce((acc, week, i) => {
      if (week.likelyJobCount > 0) {
        return i;
      }
      return acc;
    }, -1) ?? -1;

  if (isStatic) {
    let weeksUsed = 0;
    let i = 0;
    while (
      weeksUsed < staticForecastData.weeksOfCapacity &&
      i < staticForecastData.inputForecast.length
    ) {
      if (staticForecastData.inputForecast[i] > 0) {
        weeksUsed++;
      }
      i++;
    }
    lastWeek = i - 1;
  }

  // Generate array of chunks
  // Ranges: start and end of chunk
  // Statuses: empty, good, issue
  let chunks = [];

  let currChunk = null;

  var numWeeks = isStatic
    ? staticForecastData.toaForecast.length
    : forecastData.weeks.length;
  for (let i = 0; i < numWeeks; i++) {
    let weekStatus = "";

    let inputCount = isStatic
      ? staticForecastData.inputForecast[i]
      : forecastData.weeks[i].inputCount;

    if (inputCount === 0 || i > lastWeek) {
      weekStatus = "empty";
    } else if (remainder[i] >= 0) {
      weekStatus = "good";
    } else if (remainder[i] < 0) {
      weekStatus = "issue";
    }

    if (!currChunk) {
      currChunk = {
        start: i,
        end: i + 1,
        status: weekStatus,
      };
    }

    if (currChunk.status === weekStatus) {
      currChunk.end = i + 1;
    } else {
      chunks.push(currChunk);
      currChunk = {
        start: i,
        end: i + 1,
        status: weekStatus,
      };
    }
  }
  chunks.push(currChunk);

  return (
    <div className="flex justify-center">
      {chunks.map((chunk, i) => {
        return <Chunk key={i} {...chunk} numIndexes={available.length} />;
      })}
    </div>
  );
}

function Chunk({ start, end, numIndexes, status = "empty" }) {
  let color = "";
  switch (status) {
    case "good":
      color = "bg-primary-green";
      break;
    case "issue":
      color = "bg-primary-rose";
      break;
    case "empty":
    default:
      color = "bg-gray-300";
  }

  let rounded = "";
  if (start === 0 && end === numIndexes) {
    rounded = "4px";
  } else if (start === 0) {
    rounded = "4px 0 0 4px";
  } else if (end === numIndexes) {
    rounded = "0 4px 4px 0";
  }

  return (
    <div
      className={classNames("h-5 relative", rounded, color)}
      style={{
        flex: `${end - start} 1 0%`,
        borderRadius: rounded,
      }}
    >
      {status === "issue" && (
        <div className="absolute -translate-x-1/2 top-full left-1/2">
          <ExclamationTriangleIcon className="h-4 w-4 text-primary-rose stroke-[2]" />
        </div>
      )}
    </div>
  );
}
