import React, { Component } from 'react'
import { Col } from 'react-bootstrap'

export default class SupportPage extends Component {
  render() {
    return (
      <Col className="pt-3 pb-3">
        <div className='flo-title'>Support</div>
      </Col>
    )
  }
}
