import React from "react";
import {
  ReferenceLine,
  ResponsiveContainer,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Label,
  Legend,
} from "recharts";

export default function ToaLineChart({
  data = [],
  dataKeys = [],
  dashPatterns = [],
  names = [],
  colors = [],
  height = 500,
  lines = [],
  customTooltip = null,
  dots = true,
  xAxis = true,
  yAxis = true,
  xAxisSettings = {},
  yAxisSettings = {},
  selectedIndex = -1,
  legendSettings = null,
  handleClick = () => {},
}) {
  // TODO: get primary-green dynamically (https://stackoverflow.com/questions/61118060/how-to-access-tailwind-colors-from-javascript)

  // TODO: adjust inputs to allow for multiple lines (i.e. datakeys, lines, colors) so that you can use multiple lines
  var Dot = ({ cx, cy, stroke, payload, value, index, color }) => {
    if (index === selectedIndex) {
      // console.log({cx, cy, stroke, payload, value, index, color})
      return (
        <svg
          x={cx - 8}
          y={cy - 8}
          width={16}
          height={16}
          fill={color}
          viewBox="0 0 10 10"
        >
          <circle r={5} cx={5} cy={5} fill="white" />
          <circle r={4} cx={5} cy={5} />
        </svg>
      );
    } else if (dots) {
      return (
        <svg
          x={cx - 5}
          y={cy - 5}
          width={10}
          height={10}
          fill={color}
          viewBox="0 0 10 10"
        >
          <circle r={5} cx={5} cy={5} />
        </svg>
      );
    }

    return <></>;
  };

  return (
    // Note: relative->absolute wrapper is to force size of responsive container to shrink fast when resizing window.
    //       Animations are slow when shrinking recharts and it affected the flex layout.
    // Note: ResponsiveContainer width is 99% b/c there is a bug where it won't shrink if it's 100%
    <div className="relative w-full" style={{ height: height }}>
      <div className="absolute inset-0">
        <ResponsiveContainer width="99%" height={height}>
          <LineChart
            cursor="pointer"
            data={data}
            onClick={(data, event) => {
              if (!data || !data.activePayload || !data.activePayload[0]) {
                return;
              }

              var index = data.activeTooltipIndex;
              var data = data.activePayload[0].payload;

              handleClick(data, index);
            }}
          >
            {/* Lines */}
            {dataKeys.map((dataKey, i) => (
              <Line
                key={i}
                name={(names.length > i && names[i]) || dataKey}
                isAnimationActive={false} // Forces dots to work. Known bug
                connectNulls
                type="monotone"
                dataKey={dataKey}
                strokeDasharray={
                  (dashPatterns.length > i && dashPatterns[i]) || "0"
                }
                stroke={(colors.length > i && colors[i]) || "#1cb7be"} // TODO: primary green
                strokeWidth={3}
                dot={
                  <Dot color={(colors.length > i && colors[i]) || "#1cb7be"} />
                }
              />
            ))}

            {/* Tooltip */}
            <Tooltip
              content={customTooltip}
              wrapperStyle={{ outline: "none" }}
            />

            {/* Reference lines */}
            {lines.map((line, i) => (
              <ReferenceLine
                key={i}
                y={line.value}
                label={{
                  value: line.label,
                  position: line.position || "insideBottomRight",
                }}
                strokeDasharray="4 4"
                stroke={line.color || "#1cb7be"}
              />
            ))}

            {/* X Axis */}
            {xAxis && (
              <XAxis
                className="stroke-gray-600 fill-gray-600 dark:stroke-white dark:fill-white"
                stroke=""
                {...xAxisSettings}
              />
            )}

            {/* Y Axis */}
            {yAxis && (
              <YAxis
                className="stroke-gray-600 fill-gray-600 dark:stroke-white dark:fill-white"
                stroke=""
                {...yAxisSettings}
              />
            )}

            {/* Legend */}
            {legendSettings && (
              <Legend verticalAlign="bottom" height={36} {...legendSettings} />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
