import React, { useEffect, useState } from "react";
import useLastInputs from "../inputs/useLastInputs.tsx";
import useLocalStorage from "../useLocalStorage.tsx";

const KONAMI_CODE: string[] = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "b",
  "a",
];

/**
 * Custom hook to check if the Konami code has been entered.
 * Toggles a boolean value in local storage upon successful entry.
 * 
 * @returns boolean - true if the Konami code has been entered
 */
export default function useKonamiCode(): boolean {  
  const [hasKonamiCode, setHasKonamiCode] = useLocalStorage<boolean>("konamiCode", false);

  const inputs: string[] = useLastInputs(KONAMI_CODE.length);

  // Check if the inputs match the Konami code
  useEffect(() => {
    if (inputs.join("") === KONAMI_CODE.join("")) {
      setHasKonamiCode(!hasKonamiCode);
    }
  }, [inputs]);

  return hasKonamiCode;
}
