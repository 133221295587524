import React from "react";
import { useOutletContext } from "react-router-dom";
import BulletChart from "../BulletChart";
import ForecastInput from "./ForecastInput";

import moment from "moment";
import pluralize from "pluralize";
import Modal from "../Modal";
import Button from "../input/Button";
import SimpleJobDonut from "./SimpleJobDonut";
import { Link } from "react-router-dom";

import { ForecastSnapshotViewContextType } from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";
import Forecast from "src/pages/Forecast/classes/Forecast";
import { formatStaticForecastForBulletChart } from "./history/ForecastHistoryRow.tsx";

export default function BulletChartAndInput({
  stageStats,
  selectedIndex,
  disabled = false,
  fixedWidth = false,
  isStatic = false,
}) {
  const contextValue = useOutletContext();

  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData: fullForecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
      lastForecastDate,
    } = contextValue;
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
    } = contextValue;
  }

  let forecastData = fullForecastData?.getByWeek();
  let capacity = fullForecastData?.getWeeksOfCapacity();
  if (isStatic) {
    let {available, remainder} = staticForecastData ? Forecast.generateAvailableAndRemainder(staticForecastData.inputForecast, staticForecastData.toaForecast) : {available: [], remainder: []};
    forecastData = staticForecastData?.toaForecast.map((toaValue, i) => {

      let startOfWeek = moment(staticForecastData?.week).utc().add(i, "weeks");
      
      return {

        label: startOfWeek.format("M/D"),
        startOfWeek: startOfWeek,

        toaValue: toaValue,
        input: staticForecastData.inputForecast[i],
        potential: Math.ceil(staticForecastData.toaForecast[i]),
        potentialWoConv: staticForecastData.toaForecastWoConversion[i],

        remainder: remainder[i],
        available: available[i],
      }
      
    });
    capacity = staticForecastData?.weeksOfCapacity;
  }

  let lastWeek = fullForecastData?.weeks.reduce((acc, week, i) => {
    if (week.likelyJobCount > 0) {
      return i;
    }
    return acc;
  }, -1);

  if (isStatic) {
    // Get last week using capacity and skipping weeks with 0 input
    
    let weeksUsed = 0;
    let i = 0;
    while (weeksUsed < staticForecastData?.weeksOfCapacity && i < staticForecastData?.inputForecast.length) {
      if (staticForecastData.inputForecast[i] > 0) {
        weeksUsed++;
      }
      i++;
    }
    lastWeek = i - 1;
  }

  // % from left to place capacity indicator
  // Add 1 to lastWeek to account for 0 indexing
  // Multiply by 0.05 to get 5% increments
  let capacityPercent = (lastWeek + 1) * 0.05;

  // TODO: handle isStatic capacity percent. Not just weeks of capacity as there could be gaps in capacity (i.e. [1,0,1] is 2 weeks of capacity but line should show after second filled week)

  const [selectedWeekIndex, setSelectedWeekIndex] = React.useState(null);
  const [showWeekDetailsModal, setShowWeekDetailsModal] = React.useState(false);

  function handleWeekSelected(index) {
    setSelectedWeekIndex(index);
    setShowWeekDetailsModal(true);
  }

  function handleForecastInputChanged(index, value) {
    // Try to convert to number
    var num = Number(value);
    if (isNaN(num)) return;

    onJobCountInput(index, num);
  }

  let totalActive =
    forecastData?.reduce((a, b) => a + b.potentialWoConv, 0) || 0;
  if (isStatic) {
    totalActive = staticForecastData?.numActiveJobs || 0;
  }

  let totalEstimated = forecastData?.reduce((a, b) => a + b.potential, 0) || 0;
  if (isStatic) {
    totalEstimated = staticForecastData?.numEstimatedJobs || 0;
  }

  let bulletChartData = forecastData?.map((item, i) => {
    // Format for showing remainders
    // If outside capacity, show default values.
    // If inside capacity, these should sum to the input but display issues in red. (remainders that are negative)
    item = {
      ...item,
      inputOrAvailable:
        i <= lastWeek ? Math.min(item.input, item.available) : item.input,
      posRemainder: i <= lastWeek ? -Math.min(0, item.remainder) : 0,
    };

    // If no selected index, return item
    if (selectedIndex === -1) return item;

    // If item in range of selected weeks, highlight it
    let from = stageStats && stageStats[selectedIndex].fromWeek;
    let to = stageStats && stageStats[selectedIndex].toWeek;

    if (i >= from && i <= to) {
      return { ...item, highlight: true };
    } else {
      return item;
    }
  });
  if (isStatic) {
    bulletChartData = formatStaticForecastForBulletChart(staticForecastData);

    // Highlight selected stage weeks
    if (selectedIndex !== -1) {
      let from = stageStats && stageStats[selectedIndex].fromWeek;
      let to = stageStats && stageStats[selectedIndex].toWeek;

      bulletChartData = bulletChartData?.map((item, i) => {
        if (i >= from && i <= to) {
          return { ...item, highlight: true };
        } else {
          return item;
        }
      });
    }
  }

  return (
    <>
      {/* Section Title */}
      <div className="flex items-center justify-start gap-16">
        {/* Text */}
        <div>
          {/* Number of Potential Jobs */}
          <div className="text-xl font-semibold leading-7 whitespace-nowrap">
            {pluralize("Estimated Job", totalEstimated, true)}
          </div>
          {/* Number of Active Jobs */}
          <div className="text-sm font-medium leading-5 text-gray-500">
            {totalActive}
            {" Active Pipeline"}
          </div>
        </div>

        {/* Legend */}
        <div className="flex items-center gap-6 text-sm font-medium text-gray-500">
          {/* Completed */}
          <div className="flex items-center gap-3">
            <div className="w-4 h-4 rounded-sm bg-secondary-orange"></div>
            Completed Jobs
          </div>

          {/* Estimated */}
          <div className="flex items-center gap-3">
            <div className="w-4 h-4 bg-gray-300 rounded-sm dark:bg-gray-500"></div>
            TOA Estimated Jobs
          </div>

          {/* Active */}
          <div className="flex items-center gap-3">
            <div className="w-4 h-4 bg-gray-600 rounded-sm dark:bg-gray-200"></div>
            Installer Forecast
          </div>
        </div>

        {/* Last Forecast */}
        {!isStatic && (
          <div className="flex items-center ml-auto text-sm font-medium text-gray-500 whitespace-nowrap">
            {lastForecastDate ? (
              <div>
                Previous Forecast{" "}
                {pluralize(
                  "day",
                  moment().utc().diff(lastForecastDate, "days"),
                  true
                )}{" "}
                ago:{" "}
                <span className="text-primary-green">
                  {lastForecastDate.format("M/D/YY")}
                </span>
              </div>
            ) : (
              <div>No Forecasts Submitted</div>
            )}
          </div>
        )}
      </div>

      <div className="relative">
        {/* Bullet Chart */}
        <div className="relative -mx-1.5">
          <BulletChart
            data={bulletChartData}
            frontValueDataKey="inputOrAvailable"
            frontValueRedDataKey={isStatic ? "remainder" : "posRemainder"}
            backValueDataKey={isStatic ? "toaValue" : "potential"}
            tickValueDataKey="goal"
            tickStyle={{
              baseStyle: {
                strokeWidth: 2,
                stroke: "#6B7280",
                strokeDasharray: [5, 3],
              },
              aboveTickStyle: {},
              belowTickStyle: {},
            }}
          />
        </div>

        {/* Forecast Inputs */}
        <div className="flex gap-2 -ml-[18px]">
          {/* Row Indicators to Match Legend */}
          <div className="flex flex-col">
            <div className="bg-gray-300 dark:bg-gray-500 w-2.5 h-[50%]"></div>
            <div className="bg-gray-600 dark:bg-gray-200 w-2.5 h-[50%]"></div>
          </div>

          <ForecastInput
            data={forecastData || []}
            onChange={isStatic ? () => {} : handleForecastInputChanged}
            onToaFieldClick={(data, index) => {
              if (!isStatic) {
                handleWeekSelected(index);
              }
            }}
            fixedWidth={fixedWidth}
            isStatic={isStatic}
          />
        </div>

        {/* Capacity Indicator */}
        {capacityPercent > 0 && (
          <div
            className="absolute z-0 bottom-0 top-1 w-[3px] -translate-x-[1.5px] bg-gray-400"
            style={{
              left: `${capacityPercent * 100}%`,
            }}
          />
        )}
      </div>

      {/* Week Details Modal */}
      <WeekDetailsModal
        {...{
          forecastData,
          selectedWeekIndex,
          showWeekDetailsModal,
          setShowWeekDetailsModal,
          selectedMarket,
        }}
      />
    </>
  );
}

function WeekDetailsModal({
  forecastData,
  selectedWeekIndex,
  showWeekDetailsModal,
  setShowWeekDetailsModal,
  selectedMarket,
}) {
  if (
    !forecastData ||
    selectedWeekIndex < 0 ||
    selectedWeekIndex > forecastData.length
  )
    return null;

  var potential = forecastData[selectedWeekIndex]?.potential;
  var potentialWoConv = forecastData[selectedWeekIndex]?.potentialWoConv;
  var weekStr =
    forecastData[selectedWeekIndex]?.startOfWeek.format("YYYY-MM-DD");

  var startOfWeek = forecastData[selectedWeekIndex]?.startOfWeek;
  var endOfWeek = startOfWeek?.clone().add(6, "days");
  var weekRangeStr = `${startOfWeek?.format("M/D")} - ${endOfWeek?.format(
    "M/D"
  )}`;

  return (
    <Modal open={showWeekDetailsModal} setOpen={setShowWeekDetailsModal} wide>
      <div className="flex flex-col p-2">
        <div className="flex justify-between gap-16">
          <div className="flex flex-col gap-4">
            {/* Week Metadata */}
            <div className="flex flex-col">
              {/* <div className="text-sm font-medium text-gray-400">Installer:</div> */}
              <div className="text-sm font-medium text-gray-400">
                Market:{" "}
                <span className="text-gray-900">
                  {selectedMarket?.name || "-"}
                </span>
              </div>
              <div className="text-sm font-medium text-gray-400">
                Week: <span className="text-gray-900">{weekRangeStr}</span>
              </div>
            </div>

            {/* Details Btn */}
            <Link
              className="outline-none"
              to={`/app/forecast/week?week=${weekStr}&market=${selectedMarket?._id}`}
            >
              <Button variant="primary-green">Go To Details</Button>
            </Link>
          </div>

          {/* Pie Chart */}
          <div>
            <SimpleJobDonut
              numerator={potential}
              denominator={potentialWoConv}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
