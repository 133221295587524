import React, { useEffect, useState } from "react";
import Button from "../../input/Button";
import Card from "../../Card";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import { Bars2Icon } from "@heroicons/react/20/solid";
import {
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import Dropdown from "../../input/Dropdown";
import IdentifyingLogic from "./IdentifyingLogic";
import SelectFieldInput from "./SelectFieldInput";
import { isValidMapping } from "../../../tools/OperationsSetup/Validator";
import { data } from "jquery";
import CancelStatusValueInputModal from "./CancelStatusValueInputModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function EditMappingCard({
  mappings,
  title,
  schema,
  onChange = () => {},
}) {
  const [mappingsAndPos, setMappingsAndPos] = useState({
    mappings: mappings,
    selectedMappingPos: [0, 0], // [groupIndex, mappingIndex]
    selectedMapping: mappings[0]?.mappings[0],
  });

  var {
    mappings: localMappings,
    selectedMappingPos,
    selectedMapping,
  } = mappingsAndPos;

  const dedupMinMaxOptions = [
    [
      { label: "Largest/Newest", value: "max" },
      { label: "Smallest/Oldest", value: "min" },
    ],
  ];

  useEffect(() => {
    // Update mappingsAndPos
    var pos0 = Math.min(
      mappingsAndPos.selectedMappingPos[0],
      localMappings.length - 1
    );
    var pos1 = Math.min(
      mappingsAndPos?.selectedMappingPos[1],
      localMappings[pos0]?.mappings.length - 1
    );

    // Check if mapping groups changed, if so, reset pos to 0,0
    if (mappings.length !== localMappings.length) {
      [pos0, pos1] = [0, 0];
    } else {
      // Check groups by name
      for (var i = 0; i < mappings.length; i++) {
        if (mappings[i].group !== localMappings[i].group) {
          [pos0, pos1] = [0, 0];
          break;
        }
      }
    }

    setMappingsAndPos({
      mappings: mappings,
      selectedMappingPos: [pos0, pos1],
      selectedMapping: mappings[pos0]?.mappings[pos1],
    });
  }, [mappings]);

  var isArrayMap = mappingIsArray(selectedMapping?.mapping);
  var isArrayObj =
    selectedMapping?.mapping?.find((m) => m.type === "arrayObj") || false;

  // Get subschema if necessary
  if (isArrayMap) {
    var subSchema = schema;
    for (var i = 0; i < selectedMapping.mapping.length; i++) {
      var m = selectedMapping.mapping[i];
      subSchema = subSchema[m.fieldName];
      if (m.type === "arrayItem" || m.type === "arrayObj") {
        subSchema = subSchema[0];
        break;
      }
    }
  }

  return (
    <Card className="w-full">
      <div className="flex flex-col -m-4">
        {/* Header */}
        <div className="flex items-center justify-start gap-3 border-b border-gray-300">
          <div className="w-2 h-[76px] rounded-tl-full bg-primary-rose" />
          <div className="text-xl font-semibold text-gray-900">{title}</div>
        </div>

        {/* Body */}
        <div className="flex border-gray-300 divide-x">
          {/* Left Side */}
          <div className="flex flex-col gap-3 py-6 basis-1/2 px-9">
            {/* Content */}
            <table className="text-base font-bold text-gray-500 border-separate table-auto border-spacing-y-1">
              <thead className="text-left">
                <tr>
                  <th className="col-span-3 pb-4">TOA Fields</th>
                  <th className="col-span-1 pb-4"></th>
                  <th className="col-span-3 pb-4">Custom Field Mapping</th>
                  <th className="col-span-1 pb-4"></th>
                  <th className="col-span-3 pb-4">Data Sample</th>
                </tr>
              </thead>

              <tbody>
                {
                  // Mapping Items
                  localMappings.map((group, gIndex) => (
                    <React.Fragment key={group.group}>
                      {/* Group Heading */}
                      {group.group && (
                        <tr>
                          <td>{group.group}</td>
                        </tr>
                      )}

                      {/* Group Items */}
                      {group.mappings.map((mapping, mIndex) => (
                        <MappingItem
                          selected={selectedMapping === mapping}
                          key={gIndex + "-" + mIndex}
                          mapping={mapping}
                          schema={schema}
                          onSelected={() => {
                            setMappingsAndPos({
                              ...mappingsAndPos,
                              selectedMappingPos: [gIndex, mIndex],
                              selectedMapping: mapping,
                            });
                          }}
                          onChange={(newMapping) => {
                            var newMappings = structuredClone(localMappings);
                            newMappings[gIndex].mappings[mIndex].mapping =
                              newMapping;
                            onChange(newMappings);
                          }}
                        />
                      ))}
                    </React.Fragment>
                  ))
                }
              </tbody>
            </table>

            {/* Footer */}
            <div className="flex justify-end py-3 mt-auto">
              {/* <Button variant="primary-green">New Field</Button> */}
            </div>
          </div>

          {/* Right Side */}
          <div className="flex flex-col gap-3 py-6 basis-1/2 px-9">
            {/* Identifying */}
            <div className="flex flex-col gap-6 pb-3">
              {/* Header */}
              <div className="flex">
                {/* Title */}
                <div className="flex flex-col gap-0">
                  <div className="text-lg font-semibold">Identifying</div>
                  <div className="text-sm font-medium">
                    Identify items in{" "}
                    <span className="text-primary-green">
                      {selectedMapping?.mapping?.find((m) =>
                        ["arrayItem", "arrayObj"].includes(m.type)
                      )?.fieldName ?? "N/A"}
                    </span>
                  </div>
                </div>
              </div>

              {/* Body */}
              <div>
                <IdentifyingLogic
                  mapping={selectedMapping}
                  isArrayMap={isArrayMap}
                  subSchema={subSchema}
                  onChange={(mapping) => {
                    var newMappings = structuredClone(localMappings);
                    newMappings[selectedMappingPos[0]].mappings[
                      selectedMappingPos[1]
                    ] = mapping;
                    onChange(newMappings);
                  }}
                />
              </div>
            </div>

            {/* Deduplication */}
            <div className="flex flex-col gap-6">
              {/* Header */}
              <div className="flex">
                {/* Title */}
                <div className="flex flex-col gap-0">
                  <div className="text-lg font-semibold">Deduplication</div>
                  <div className="text-sm font-medium">
                    Choose which duplicate item to use
                  </div>
                </div>
              </div>

              {/* Body */}
              <div className="">
                {isArrayMap && isArrayObj ? (
                  <div className="flex items-center">
                    Choose the object where
                    <div className="px-2">
                      <SelectFieldInput
                        field={
                          selectedMapping.mapping.find(
                            (m) => m.type === "arrayObj"
                          ).deduplication.field
                        }
                        schema={subSchema}
                        onSelect={(field) => {
                          // Update array obj mapping
                          var newMappings = structuredClone(localMappings);
                          newMappings[selectedMappingPos[0]].mappings[
                            selectedMappingPos[1]
                          ].mapping.find(
                            (m) => m.type === "arrayObj"
                          ).deduplication.field = field;
                          onChange(newMappings);
                        }}
                      />
                    </div>
                    is the
                    <div className="px-2">
                      <Dropdown
                        above
                        options={dedupMinMaxOptions}
                        selectedValue={
                          selectedMapping.mapping.find(
                            (m) => m.type === "arrayObj"
                          ).deduplication?.method
                        }
                        onSelected={(option) => {
                          // Update array obj mapping
                          var newMappings = structuredClone(localMappings);
                          newMappings[selectedMappingPos[0]].mappings[
                            selectedMappingPos[1]
                          ].mapping.find(
                            (m) => m.type === "arrayObj"
                          ).deduplication.method = option.value;
                          onChange(newMappings);
                        }}
                      />
                    </div>
                    .
                  </div>
                ) : (
                  <div className="flex items-center justify-center p-6 text-sm text-gray-500">
                    {isArrayObj
                      ? "Mapping does not require deduplication (using index)"
                      : "Mapping does not require deduplication (no array)"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

function MappingItem({
  mapping,
  selected = false,
  schema = {},
  onSelected = () => {},
  onChange = () => {},
}) {
  // Get data sample from schema
  var dataSample = null;
  if (mapping.mapping && mapping.type !== "inputArray") {
    dataSample = "N/A";
    var currSchema = schema;

    // Traverse schema based on mapping
    for (var i = 0; i < mapping.mapping.length; i++) {
      var m = mapping.mapping[i];
      if (m.type !== "null") {
        // Traverse
        currSchema = currSchema[m.fieldName];

        // If array, get first element
        if (Array.isArray(currSchema)) {
          if (currSchema.length === 0) {
            dataSample = null;
            break;
          }
          currSchema = currSchema[0];
        }
      }
    }

    // If ended on a value, set data sample
    if (currSchema && typeof currSchema !== "object") {
      dataSample = currSchema;
    }
  }

  // Handle field select
  function handleFieldSelect(fieldPath) {
    var newMapping = pathToMapping(fieldPath);
    onChange(newMapping);
  }

  // Check if valid
  var isValid = isValidMapping(mapping.mapping, schema);
  if (mapping.type === "inputArray") {
    isValid = mapping.mapping && Array.isArray(mapping.mapping);
  }

  return (
    <tr
      className={classNames(
        "cursor-pointer items-center h-8",
        selected ? "bg-gray-100" : "hover:bg-gray-200"
      )}
      onClick={() => {
        onSelected();
      }}
    >
      <td
        className={classNames(
          "text-sm font-medium py-3 pl-4 relative",
          selected ? "text-gray-900" : "text-gray-500"
        )}
      >
        {!isValid && (
          <div className="bg-secondary-orange w-1.5 absolute left-0 inset-y-0"></div>
        )}
        {mapping.label}
      </td>
      <td>
        <div className="flex items-center justify-center px-3">
          <ArrowLeftIcon className="w-6 h-6 text-gray-900" />
        </div>
      </td>
      <td className="">
        {mapping.type === "inputArray" ? (
          <CancelStatusValueInputModal
            values={mapping.mapping}
            setValues={onChange}
          />
        ) : (
          <SelectFieldInput
            allowNoField
            field={mapping.mapping ? mappingToPath(mapping.mapping) : null}
            schema={schema}
            onSelect={handleFieldSelect}
          />
        )}
      </td>
      <td>
        {mapping.type !== "inputArray" && (
          <div className="flex items-center justify-center px-3">
            <Bars2Icon className="w-6 h-6 text-gray-900" />
          </div>
        )}
      </td>
      <td className="col-span-3">
        {mapping.type !== "inputArray" &&
          (dataSample ? (
            dataSample
          ) : (
            <InformationCircleIcon className="w-6 h-6 text-secondary-orange" />
          ))}
      </td>
    </tr>
  );
}

function mappingIsArray(mapping) {
  var isArray = false;

  if (!mapping) return false;

  mapping.forEach((m) => {
    if (m.type === "arrayItem" || m.type === "arrayObj") {
      isArray = true;
    }
  });

  return isArray;
}

function pathToMapping(path) {
  // Templates

  const fieldMappingTemplate = {
    type: "field",
    fieldName: null,
  };

  const nestedMappingTemplate = {
    type: "nestedField",
    fieldName: null,
  };

  const arrayItemMappingTemplate = {
    type: "arrayItem",
    fieldName: null,
    index: 0, // -1 for last
  };

  const arrayObjMappingTemplate = {
    type: "arrayObj",
    fieldName: null,
    identifierQuery: { $and: [] },
    deduplication: {
      field: null,
      method: "max", // max, min
    },
  };

  const nullMappingTemplate = {
    type: "null",
    fieldName: null,
  };

  // Build mapping
  var mapping = path.map((p) => {
    var m;

    switch (p.type) {
      case "field":
        m = { ...fieldMappingTemplate };
        break;

      case "obj":
        m = { ...nestedMappingTemplate };
        break;

      case "arrayItem":
        m = { ...arrayItemMappingTemplate };
        break;

      case "arrayObj":
        m = { ...arrayObjMappingTemplate };
        break;

      case "null":
        m = { ...nullMappingTemplate };
        break;

      default:
        break;
    }

    m.fieldName = p.key;

    return m;
  });

  return mapping;
}

function mappingToPath(mapping) {
  var path = mapping.map((m) => {
    var p = { key: m.fieldName };

    switch (m.type) {
      case "field":
        p.type = "field";
        break;

      case "nestedField":
        p.type = "obj";
        break;

      case "arrayItem":
        p.type = "arrayItem";
        break;

      case "arrayObj":
        p.type = "arrayObj";
        break;

      case "null":
        p.type = "null";
        break;

      default:
        break;
    }

    return p;
  });

  return path;
}
