import {
  ClockIcon,
  HomeIcon,
  QueueListIcon,
} from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import Button from "../../components/input/Button";
import Dropdown from "../../components/input/Dropdown";
import SubNav from "../../components/nav/SubNav";
import UserManager from "../../tools/UserManager";
import SubmitForecastModal from "../../components/Forecast/SubmitForecastModal";
import moment from "moment";

import Week from "./classes/Week";
import Forecast from "./classes/Forecast";
import Job from "./classes/Job";
import Stage from "./classes/Stage";
import TopBar from "../../components/nav/TopBar";

import debounce from "../../tools/debounce";

/**
 * Raw forecast data from the server.
 * Broken down by stage and then by week.
 *
 * @typedef RawForecastData
 * @property {string} status - The status of the request.
 * @property {{
 *  _stageId_:{
 *    convRate:number,
 *    avgTimeToInstall:number,
 *    activeInStage:number,
 *    weekCounts:{
 *      _weekDate_:number
 *    },
 *    weekCountsWConv:{
 *      _weekDate_:number
 *    },
 *    weekJobs: {
 *     _weekDate_:[Job]
 *    }
 *  }
 * }} stageForecasts - Forecasts broken down by stage
 * @property {Market} market - The market the forecast is for
 * @property {[Stage]} stages - The installer's stages
 */

/**
 * @typedef ForecastContext - The context for the forecast page
 * @property {MarketModel} selectedMarket - The selected market
 * @property {RawForecastData} rawForecastData - The raw forecast data
 * @property {Forecast} forecastData - The forecast data
 * @property {CallableFunction} onJobCountInput - The function to handle job count input
 * @property {[Material]} materials - The materials
 * @property {[MaterialGroup]} materialGroups - The material groups
 * @property {Date} lastForecastDate - The last forecast date
 * @property {[{_id:{week:number,year:number},count:number}]} lastSixWeeks - The last six weeks
 * @property {[number]} lastSixWeeksInput - The last six weeks input
 */

/**
 * Renders the ForecastPage component.
 * This component displays a forecast page with various tabs and functionality.
 */
export default function ForecastPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [markets, setMarkets] = useState(null);
  const [selectedMarket, setSelectedMarket] = useState(null);

  const [materials, setMaterials] = useState(null);
  const [materialGroups, setMaterialGroups] = useState(null);

  /**
   *
   * @type {[RawForecastData, React.Dispatch<RawForecastData>]} state variable for the raw forecast data
   */
  const [rawForecastData, setRawForecastData] = useState(null);

  /**
   * @type {[Forecast, React.Dispatch<Forecast>]} state variable for the forecast data
   */
  const [forecastData, setForecastData] = useState(null);

  const [lastForecast, setLastForecast] = useState(null);

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const [lastSixWeeksInput, setLastSixWeeksInput] = useState(null);

  const [loading, setLoading] = useState(false);

  const [tabs, setTabs] = useState([
    {
      name: "Job Forecast",
      icon: <HomeIcon />,
      href: "job",
      current: true,
    },
    {
      name: "Material Forecast",
      icon: <QueueListIcon />,
      href: "material",
      current: false,
    },
  ]);

  /**
   * Get the market ID from the search parameters
   */
  let marketId = searchParams.get("market");

  const location = useLocation();

  /**
   * Adjust tabs based on the current location
   */
  useEffect(() => {
    setTabs((oldTabs) => {
      var newTabs = oldTabs.map((oldTab) => {
        if (location.pathname.includes(oldTab.href)) {
          return { ...oldTab, current: true };
        }
        return { ...oldTab, current: false };
      });
      return newTabs;
    });
  }, [location]);

  /**
   * Get the list of markets and the materials when the page loads
   */
  useEffect(() => {
    setLoading(true);
    // Get markets
    UserManager.makeAuthenticatedRequest("/api/markets/installer/find", "GET")
      .then((res) => {
        if (res.data.status === "ok") {
          setMarkets(res.data.markets.sort((a, b) => a.name > b.name));

          if (marketId) {
            setSelectedMarket(res.data.markets.find((m) => m._id === marketId));
          } else {
            setSelectedMarket(res.data.markets[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (!selectedMarket) {
      return;
    }

    // Get materials
    getMaterials(selectedMarket._id)
      .then((data) => {
        let { materials: ms, materialGroups: mgs } = data;

        setMaterials(ms);
        setMaterialGroups(mgs);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedMarket]);

  /**
   * Update the search parameters when the selected market changes
   */
  useEffect(() => {
    if (selectedMarket && searchParams.get("market") !== selectedMarket._id) {
      setSearchParams({ market: selectedMarket._id });
    }
  }, [selectedMarket, searchParams, location]);

  /**
   * Get the forecast data once ready to
   * Requires market, materials, and materialGroups to be loaded
   */
  useEffect(() => {
    if (selectedMarket && materials && materialGroups) {
      setLoading(true);
      getForecastData(selectedMarket._id)
        .then((data) => {
          setRawForecastData(data);
          setLastForecast(data?.lastForecast);

          let forecast = Forecast.fromRawForecastData(
            data,
            materials,
            materialGroups
          );

          if (data.currentForecast) {
            // Set week inputs
            forecast.setWeekInputs(
              data.currentForecast.inputForecast,
              data.currentForecast.week
            );

            // Shift last six weeks if needed
            let thisWeek = moment().utc().startOf("week");
            let currForecastWeek = moment(data.currentForecast.week).utc();
            let diff = thisWeek.diff(currForecastWeek, "weeks");

            let lastSixWeeksInput_temp = [];

            // Grab from curr forecast's last six weeks
            for (let i = diff; i < 6; i++) {
              if (
                i >= 0 &&
                i < data.currentForecast?.last6WeeksForecast?.length
              ) {
                lastSixWeeksInput_temp.push(
                  data.currentForecast.last6WeeksForecast[i]
                );
              } else {
                lastSixWeeksInput_temp.push(null);
              }
            }

            // Grab from curr forecast's input to cover week diff (e.g. if curr is last week, grab one week and put it into lastSixWeeksInput)
            for (let i = 0; i < diff; i++) {
              if (i < data.currentForecast.inputForecast.length) {
                lastSixWeeksInput_temp.push(
                  data.currentForecast.inputForecast[i]
                );
              } else {
                lastSixWeeksInput_temp.push(null);
              }
            }

            setLastSixWeeksInput(lastSixWeeksInput_temp);
          }

          setForecastData(forecast);
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [selectedMarket, materials, materialGroups]);

  /**
   * Handle the selection of a market from the dropdown
   * @param {Object} option - The selected option from the dropdown
   */
  function handleMarketSelected(option) {
    var marketId = option.value;
    var market = markets.find((m) => m._id === marketId);
    setSelectedMarket(market);
  }

  /**
   * Handle the click event for the "Submit Forecast" button
   */
  async function handleSubmitForecastClick() {
    // TODO: we need to wait for forecastData, lastSixWeeksInput, and rawForecastData to be ready before we can submit
    // how do I do this?
    // Save forecast then show modal
    await saveForecast({
      ...forecastData.formatForSaving(),
      market: selectedMarket._id,
      week: forecastData.weeks[0].date,
      last6WeeksForecast: lastSixWeeksInput,
      last6WeeksActual: rawForecastData?.lastSixWeeks?.map((wk) => wk.count),
    });
    setShowSubmitModal(true);
  }

  /**
   * Add the market query to the tabs' hrefs
   * @param {Array} tabs - The array of tabs
   * @returns {Array} - The updated array of tabs with market query added to hrefs
   */
  function addQueryToTabs(tabs) {
    let marketId = searchParams.get("market");

    let newTabs = tabs.map((tab) => {
      return {
        ...tab,
        href: tab.href + (marketId ? "?market=" + marketId : ""),
      };
    });

    return newTabs;
  }

  /**
   * Save the forecast data to the server
   *
   * @param {Forecast} forecastData - The forecast data to save
   */
  async function saveForecast(forecastData) {
    if (!forecastData) {
      return;
    }

    // Save the forecast data
    try {
      let res = await UserManager.makeAuthenticatedRequest(
        "/api/forecast/save",
        "POST",
        {
          forecast: forecastData,
        }
      );
      console.log(res);
    } catch (error) {
      console.log(error);
      // TODO: show alert
    }
  }

  /**
   * Debounced version of saveForecast
   */
  const debouncedSaveForecast = useCallback(debounce(saveForecast), []);

  /**
   * Updates the forecast data when a job count input is changed.
   * This will adjust material forecasts based on the input.
   * This will also save the forecast data to the server (debounced).
   *
   * @param {number} weekIndex - The index of the week
   * @param {number} value - The value to set
   */
  function handleJobCountInput(weekIndex, value) {
    forecastData.setWeekInput(weekIndex, value);
    setForecastData(Forecast.shallowCopy(forecastData));

    debouncedSaveForecast({
      ...forecastData.formatForSaving(),
      market: selectedMarket._id,
      week: forecastData.weeks[0].date,
      last6WeeksForecast: lastSixWeeksInput,
      last6WeeksActual: rawForecastData?.lastSixWeeks?.map((wk) => wk.count),
    });
  }

  let lastForecastDate = lastForecast?.submittedAt
    ? moment(lastForecast.submittedAt).utc()
    : null;

  /**
   *
   * @type {ForecastContext}
   */
  let contextValue = {
    selectedMarket,
    rawForecastData,
    forecastData,
    onJobCountInput: handleJobCountInput,
    materials,
    materialGroups,
    lastForecastDate,
    lastSixWeeks: rawForecastData?.lastSixWeeks,
    lastSixWeeksInput: lastSixWeeksInput,
  };

  return (
    <>
      <div className="flex-col">
        {/* Top Bar */}
        <TopBar>Forecast</TopBar>

        {/* Body */}
        <div className="inset-0 flex flex-col flex-1 pt-2 gap-y-2">
          {/* Actions */}
          <div className="flex flex-wrap items-center gap-4 px-6 py-2">
            <div>
              <SubNav
                tabs={addQueryToTabs(tabs)}
                onSelected={(tabName) => {
                  setTabs((oldTabs) => {
                    // Adjust current tab
                    var newTabs = oldTabs.map((oldTab) => {
                      if (oldTab.name === tabName) {
                        return { ...oldTab, current: true };
                      }
                      return { ...oldTab, current: false };
                    });
                    return newTabs;
                  });
                }}
                navClasses="!space-x-0 border border-gray-300 bg-gray-300 dark:bg-gray-500 gap-0.5"
                currentTabClasses="bg-white dark:bg-gray-800 !border-b-[3px]"
                tabClasses="!whitespace-nowrap border-b-[0px] bg-gray-200 dark:bg-gray-600"
              />
            </div>

            <div className="basis-full lg:basis-0">
              <Dropdown
                bold
                justifyLeft
                options={
                  markets
                    ? [
                      markets.map((market) => ({
                        value: market._id,
                        label: market.name,
                      })),
                    ]
                    : []
                }
                onSelected={handleMarketSelected}
                placeholder="Select Market"
                selectedValue={markets ? selectedMarket?._id : ""}
              />
            </div>

            {/* History */}
            <div className="ml-auto">
              <Link
                to={`history?${selectedMarket ? "market=" + selectedMarket._id : ""
                  }`}
              >
                <Button className="!py-2 !px-2.5" variant="secondary">
                  <ClockIcon className="w-5 h-5 stroke-[3] stroke-primary-green" />
                </Button>
              </Link>
            </div>

            {/* Submit */}
            <div>
              <Button
                variant="primary-green"
                onClick={handleSubmitForecastClick}
                disabled={loading}
              >
                Submit Forecast
              </Button>
            </div>
          </div>

          {/* Main */}
          <div className="relative flex justify-start overflow-x-auto">
            {/* <div className="grid min-w-full grid-cols-5 p-6 border border-red-400 shrink-0">
              <div className="col-span-1 border border-blue-400">1</div>
              <div className="col-span-4 border border-green-400">2</div>
              <div className="col-span-1 border border-blue-400">3</div>
              <div className="col-span-4 border border-green-400">4</div>
              
            </div> */}
            <Outlet context={contextValue} />
          </div>
        </div>
      </div>

      {/* Modals */}
      <SubmitForecastModal
        open={showSubmitModal}
        setOpen={setShowSubmitModal}
        contextValue={contextValue}
      />
    </>
  );
}

/**
 * Retrieves forecast data for a given market.
 * @param {string} marketId - The ID of the market.
 * @returns {Promise<Object|null>} - A promise that resolves to the forecast data if successful, or null if there was an error.
 */
async function getForecastData(marketId) {
  try {
    var res = await UserManager.makeAuthenticatedRequest(
      `/api/forecast/get?market=${marketId}&includeJobs=true`,
      "GET"
    );
  } catch (err) {
    console.error(err);
  }

  // Check if forecast data is valid
  if (res.data.status === "ok") {
    return res.data;
  } else {
    console.error(
      "Error getting forecast data: ",
      res.data.error || res.data.message
    );
    throw new Error("Error getting forecast data");
  }
}

/**
 * Retrieves the material groups and materials from the server.
 * @returns {Array} An array of material groups.
 */
async function getMaterials(marketId = null) {
  let url = "/api/materials/get-with-groups?getAvgPerJob=true";

  if (marketId) {
    url += `&marketId=${marketId}`;
  }

  try {
    var res = await UserManager.makeAuthenticatedRequest(url, "GET");
  } catch (err) {
    console.error(err);
  }

  if (res.data.status === "ok") {
    return {
      materialGroups: res.data.groups,
      materials: res.data.materials,
    };
  }
}
