import Button from "../input/Button";
import Input from "../input/Input";
import RadioCards from "../input/RadioCards";

import { HomeModernIcon } from '@heroicons/react/24/outline';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { QrCodeIcon } from '@heroicons/react/24/solid';

const companyTypes = [
    {
      label: 'Installer',
      description: 'Team or company bringing together material and labor.',
      icon: <HomeModernIcon className="h-12 text-gray-400 md:h-24" />,
      id: 'installer'
    },
    {
      label: 'Distributor',
      description: 'Providing product and services to facilitate teams within TOA.',
      icon: <BuildingOffice2Icon className="h-12 text-gray-400 md:h-24" />,
      id: 'distributor'
    },
    {
      label: 'Manufacturer',
      description: 'Creating products for different sales channels.',
      icon: <QrCodeIcon className="h-12 text-gray-400 md:h-24" />,
      id: 'manufacturer'
    },
  ]


  /**
   * step two of the setup process, where the user sets their company name and type
   * 
   * @param {Object} companyData - object containing company data (companyName, companyType)
   * @param {Function} setCompanyData - function to update company data
   * @param {Function} nextStep - function to move to the next step in the setup process
   * @returns {JSX.Element} - step two of the setup process
   */
export default function StepTwo(companyData, setCompanyData, nextStep) {
    return (
        <div className="flex flex-col items-center justify-center">

            <div className='flex flex-col items-center justify-center pb-3 md:pb-6'>
                <div className='my-1 md:my-2.5'>
                    <div className='my-2 px-2 py-2.5 text-center'>
                        <div className='text-lg font-bold leading-6 text-gray-700 md:leading-8 md:text-2xl dark:text-gray-50'>
                            What's the name of your company or team?
                        </div>
                    </div>
                </div>

                <div className='w-80'>
                    <Input
                        placeholder="Company Name"
                        type="text"
                        value={companyData.companyName}
                        onChange={(value) => { setCompanyData({ ...companyData, companyName: value }) }}
                    />
                </div>

                <div className='w-56 h-6 md:h-14'>
                </div>

                <div className='px-0 py-1 my-1 text-center md:px-2 md:py-2 md:my-2'>
                    <div className='text-lg font-bold leading-6 text-gray-700 md:leading-8 md:text-2xl dark:text-gray-50'>
                        What type of Company are you?
                    </div>
                </div>

                <div className='px-2 py-1 my-1 text-center md:py-2 md:my-2'>
                    <div className='font-light leading-5 text-gray-900 text-md md:text-lg md:leading-7 dark:text-gray-200'>
                        We'll guide you through how to connect with your partners within TOA.
                    </div>
                </div>
            </div>

            <div className='flex flex-row gap-6'>
                <RadioCards
                    cards={companyTypes}
                    onSelected={(selected) => {
                        setCompanyData({ ...companyData, companyType: selected })
                    }}
                    defaultSelected={companyData.companyType}
                />
            </div>

            {/* continue button */}
            <div className='my-2'>
                <div className='py-6 my-2'>
                    <Button type="submit"
                        onClick={() => {
                            if (companyData.companyName && companyData.companyType) {
                                nextStep()
                            }
                        }}
                        className='px-3 mx-2 rounded-md w-72'
                        variant='primary-green'
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </div>
    )
}