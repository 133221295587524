import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/input/Button";
import Card from "../../components/Card";
import Input from "../../components/input/Input";
import UserManager from "../../tools/UserManager";
import Breadcrumbs from "../../components/nav/Breadcrumbs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleGroup = {
  _id: "g123",
  createdAt: "2023-09-18T14:43:02.980Z",
  customFields: [],
  description: "",
  installer: "i1234",
  name: "Inverters",
  updatedAt: "2023-09-18T14:43:02.980Z",
};

export default function MaterialGroupPage() {
  // Get material group id from url
  const { id } = useParams();

  // Get edit mode from url query
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var editMode = searchParams.get("edit") === "true";

  const navigate = useNavigate();

  // States
  const [group, setGroup] = useState(null);
  const [tempGroup, setTempGroup] = useState({}); // For editing

  useEffect(() => {
    // Get group from API
    if (!group) {
      UserManager.makeAuthenticatedRequest(
        `/api/operations-setup/materials/get-group/${id}`
      )
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            setGroup(res.data.materialGroup);
            setTempGroup(res.data.materialGroup);
          } else {
            console.log(res.data.error);
            navigate("/app/operations-setup/materials");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/app/operations-setup/materials");
        });
    }
  }, [location]);

  // Actions based on edit mode
  var actions = editMode
    ? [
        {
          label: "Cancel",
          type: "secondary",
          onClick: () => {
            searchParams.set("edit", "false");
            navigate(location.pathname + "?" + searchParams.toString());
          },
        },
        {
          label: "Save",
          type: "primary",
          onClick: handleSave,
          disabled: !validate(tempGroup),
        },
      ]
    : [
        {
          label: "Edit",
          type: "secondary",
          onClick: () => {
            setTempGroup(group);
            searchParams.set("edit", "true");
            navigate(location.pathname + "?" + searchParams.toString());
          },
        },
      ];

  // Info card content
  var infoCardContent = {
    left: [
      // Name
      {
        label: "Name",
        value: group?.name,
        edit: {
          type: "text",
          value: tempGroup.name,
          placeholder: group?.name,
          onChange: (v) => {
            setTempGroup({ ...group, name: v });
          },
        },
        loadingSize: {
          width: editMode ? 300 : 150,
          height: editMode ? 30 : undefined,
        },
        required: true,
      },

      // Description
      {
        label: "Description",
        value: group?.description,
        edit: {
          type: "textarea",
          value: tempGroup.description,
          placeholder: group?.description,
          onChange: (value) => {
            setTempGroup({ ...tempGroup, description: value });
          },
        },
        loadingSize: {
          width: editMode ? 300 : 200,
          height: editMode ? 150 : undefined,
        },
      },
    ],
    right: [
      // Created Date
      {
        label: "Created Date",
        value: formatDate(group?.createdAt),
        loadingSize: {
          width: 150,
        },
      },

      // Custom Fields
      {
        label: "Custom Fields",
        value: editMode
          ? group?.customFields
          : group?.customFields.map((f) => f.name).join(", "),
        edit: {
          type: "array",
          value: tempGroup.customFields,
          onChange: (v) => {
            setTempGroup({ ...tempGroup, customFields: v });
          },
        },
        loadingSize: {
          width: 250,
          height: 40,
        },
      },
    ],
  };

  // --- Functions --- //

  function handleSave() {
    // Set group to null to force reload / loading state
    setGroup(null);

    // Update group to temp group
    UserManager.makeAuthenticatedRequest(
      `/api/operations-setup/materials/update-group/${id}`,
      "POST",
      tempGroup
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var g = res.data.materialGroup;
          setGroup(g);
          setTempGroup(g);

          // Navigate to view mode
          searchParams.set("edit", "false");
          navigate(location.pathname + "?" + searchParams.toString());
        } else {
          console.log(res.data.error);
          alert("Error updating material group");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Error updating material group");
      });
  }

  return (
    <div className="m-4">
      {/* Breadcrumbs */}
      <Breadcrumbs
        pages={[
          {
            name: "Operations Setup",
            href: "/app/operations-setup",
            current: false,
          },
          {
            name: "Material",
            href: "/app/operations-setup/material",
            current: false,
          },
          {
            name: group?.name,
            href: `/app/operations-setup/material/group/${group?._id}`,
            current: true,
            loading: !group,
          },
        ]}
      />

      {/* Body */}
      <div className="flex flex-col mt-2 gap-8">
        {/* Header and Actions */}
        <div className="flex flex-row justify-between items-center">
          {/* Header */}
          <div className="text-gray-900 text-2xl font-bold leading-8">
            {group ? group.name : <LoadingBar height={32} widht={200} />}
          </div>

          {/* Actions */}
          <div className="flex flex-row gap-2 items-center">
            {actions.map((action, index) => (
              <Button
                key={index}
                variant={action.type}
                onClick={action.onClick}
                disabled={action.disabled}
              >
                {action.label}
              </Button>
            ))}
          </div>
        </div>

        {/* Properties Content */}
        <Card className="flex flex-col">
          {/* Header */}
          <div className="border-b flex flex-row justify-between items-center -mx-4 px-8 pb-4">
            {/* Title and Subtitle */}
            <div className="flex flex-col text-base">
              <div className="font-semibold text-gray-900 text-xl leading-7">
                Information
              </div>
              <div className="text-base leading-6 font-normal text-gray-500 ">
                System data used for pipeline management
              </div>
            </div>
          </div>

          {/* Content */}

          <div className="flex flex-row justify-around my-6 gap-12 mx-12 text-sm leading-5 font-normal">
            {/* Left Column */}
            <div className="grow flex flex-col divide-y">
              {infoCardContent.left.map((item, index) => (
                <LineItem
                  key={index}
                  group={group}
                  item={item}
                  editMode={editMode}
                />
              ))}
            </div>

            {/* Right Column */}
            <div className="grow flex flex-col divide-y">
              {infoCardContent.right.map((item, index) => (
                <LineItem
                  key={index}
                  group={group}
                  item={item}
                  editMode={editMode}
                />
              ))}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

function LineItem({ group, item, editMode }) {
  var { label, value, edit, loadingSize, required } = item;

  var content = null;

  if (!group) {
    // Loading
    content = <LoadingBar {...loadingSize} />;
  } else {
    // Editing and can edit
    if (editMode && edit) {
      switch (edit.type) {
        // Array
        case "array":
          content = (
            <div className="flex flex-col gap-2">
              {/* Array header */}
              {/* <div>TODO: header</div> */}

              {edit.value.map((item, index) => (
                // Array item
                <div className="flex flex-row items-center gap-2" key={index}>
                  <Input
                    placeholder=""
                    type="text"
                    value={item.name}
                    highlightOnEmpty
                    onChange={(v) => {
                      var newArray = [...edit.value];
                      newArray[index].name = v;
                      edit.onChange(newArray);
                    }}
                  />

                  <MinusCircleIcon
                    className="text-primary-rose w-6 h-6 cursor-pointer"
                    onClick={() => {
                      var newArray = [...edit.value];
                      newArray.splice(index, 1);
                      edit.onChange(newArray);
                    }}
                  />
                </div>
                // TODO: units and value type
              ))}
              <PlusCircleIcon
                className="w-6 h-6 text-primary-green cursor-pointer"
                onClick={() => {
                  var newArray = [...edit.value];
                  newArray.push({ name: "" });
                  edit.onChange(newArray);
                }}
              />
            </div>
          );
          break;

        // Regular inputs
        default:
          content = (
            <Input
              placeholder={edit.placeholder}
              type={edit.type}
              value={edit.value}
              onChange={edit.onChange}
              highlightOnEmpty={required}
            />
          );
      }
    } else {
      // View
      content = value;
    }
  }

  return (
    <div className="flex justify-between items-center py-3">
      <div className="basis-1/2 text-gray-500">{label}</div>
      <div className="basis-1/2 text-gray-900">{content}</div>
    </div>
  );
}

function LoadingBar({ height = 20, width = 100 }) {
  return (
    <div
      className={classNames("bg-gray-300 animate-pulse rounded-2xl")}
      style={{
        height: `${height}px`,
        width: `${width}px`,
      }}
    ></div>
  );
}

function formatDate(dateStr) {
  if (!dateStr) return null;

  return moment(dateStr).format("MM / DD / YY");
}

function validate(group) {
  // Require name
  if (!group.name) return false;

  // Require non-empty custom fields values
  if (group.customFields) {
    for (var i = 0; i < group.customFields.length; i++) {
      if (!group.customFields[i].name) return false;
    }
  }

  return true;
}
