import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const items = [
  {
    id: 1,
    title: "Back End Developer",
    department: "Engineering",
    type: "Full-time",
    location: "Remote",
  },
  {
    id: 2,
    title: "Front End Developer",
    department: "Engineering",
    type: "Full-time",
    location: "Remote",
  },
  {
    id: 3,
    title: "User Interface Designer",
    department: "Design",
    type: "Full-time",
    location: "Remote",
  },
];

export default function Pagination({
  currentPage = 1,
  itemsPerPage = 10,
  totalItems = 0,
  numberOfAdjacentPages: numAdj = 1,
  onChange = () => {},
  hideTopBorder = false,
  hideShowingText = false,
}) {
  var numPages = Math.ceil(totalItems / itemsPerPage);

  var currentClasses =
    "z-10 bg-primary-green text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-green";

  var unselectedClasses =
    "ring-1 ring-inset ring-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-offset-0 dark:bg-gray-700";

  var sharedClasses =
    "relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20";

  // Generate the showing text

  var showingStart = (currentPage - 1) * itemsPerPage + 1;
  var showingEnd = currentPage * itemsPerPage;

  if (showingEnd > totalItems) {
    showingEnd = totalItems;
  }

  if (totalItems === 0) {
    showingStart = 0;
  }

  // Format number to have commas
  function format(n) {
    return n.toLocaleString("en-US");
  }

  var showing = (
    <p className="text-sm text-gray-700 dark:text-gray-300">
      Showing <span className="font-medium">{format(showingStart)}</span> to{" "}
      <span className="font-medium">{format(showingEnd)}</span> of{" "}
      <span className="font-medium">{format(totalItems)}</span> results
    </p>
  );

  // Generate which pages to show
  // Should show adjacent pages on either side of the current page
  // Should show the first and last pages
  var pages = [];

  // Show all pages if there are 5 or less
  if (numPages <= 5) {
    for (var i = 1; i <= numPages; i++) {
      pages.push(i);
    }
  }

  // Represent ellipses with null
  else if (numPages > 0) {
    // 1
    pages.push(1);

    // Ellipses
    if (currentPage > 2 + numAdj) {
      pages.push(null);
    }

    // Loop from page before the current page to page after the current page
    for (
      let i = Math.max(2, currentPage - numAdj);
      i <= Math.min(numPages - 1, currentPage + numAdj);
      i++
    ) {
      pages.push(i);
    }

    // Ellipses
    if (currentPage < numPages - 1 - numAdj) {
      pages.push(null);
    }

    // Last
    pages.push(numPages);
  }

  var allowPrev = currentPage > 1;
  var allowNext = currentPage < numPages;

  return (
    <div
      className={classNames(
        hideTopBorder ? "" : "border-t",
        "flex items-center justify-between border-gray-200 dark:border-gray-400  px-4 py-3 sm:px-6"
      )}
    >
      {/* On Small Screens */}
      <div className="flex justify-between flex-1 sm:hidden">
        <a
          href="#"
          className={classNames(
            "relative inline-flex items-center rounded-md border border-gray-300 bg-white dark:bg-gray-700 px-4 py-2 text-sm font-medium",
            !allowPrev
              ? "cursor-default text-gray-400 dark:text-gray-600"
              : "hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-white"
          )}
          onClick={() => {
            if (allowPrev) {
              onChange(currentPage - 1);
            }
          }}
        >
          Previous
        </a>
        <a
          href="#"
          className={classNames(
            "relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white dark:bg-gray-700 px-4 py-2 text-sm font-medium",
            !allowNext
              ? "cursor-default text-gray-400 dark:text-gray-600"
              : "hover:bg-gray-100 dark:hover:bg-gray-600 text-gray-700 dark:text-white"
          )}
          onClick={() => {
            if (allowNext) {
              onChange(currentPage + 1);
            }
          }}
        >
          Next
        </a>
      </div>

      {/* On Big Screens */}
      <div className="hidden gap-2 sm:flex sm:flex-1 sm:items-center sm:justify-between">
        {!hideShowingText && <div>{showing}</div>}
        <div>
          <nav
            className="inline-flex -space-x-px rounded-md shadow-sm isolate"
            aria-label="Pagination"
          >
            <a
              href="#"
              className={classNames(
                "relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300  focus:z-20 focus:outline-offset-0",
                !allowPrev ? "cursor-default bg-gray-200 dark:bg-gray-800" : "hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-gray-700"
              )}
              onClick={() => {
                if (allowPrev) {
                  onChange(currentPage - 1);
                }
              }}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
            </a>
            {pages.map((p, i) => {
              return (
                <a
                  key={i}
                  className={classNames(
                    p === currentPage ? currentClasses : unselectedClasses,
                    sharedClasses,
                    p === null
                      ? "cursor-default hover:bg-white dark:hover:bg-gray-600 !text-gray-400"
                      : ""
                  )}
                  href="#"
                  onClick={() => {
                    if (p !== null) {
                      onChange(p);
                    }
                  }}
                >
                  {p || "..."}
                </a>
              );
            })}
            <a
              href="#"
              className={classNames(
                "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0",
                !allowNext ? "cursor-default bg-gray-200 dark:bg-gray-800" : "hover:bg-gray-100 dark:hover:bg-gray-600 dark:bg-gray-700"
              )}
              onClick={() => {
                if (allowNext) {
                  onChange(currentPage + 1);
                }
              }}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
