import React, { Component } from "react";
import "../styles/Spinner.css";
import Lottie from "react-lottie";
import * as lightSpinner from "src/assets/animation/spinner/lightSpinner.json";
import * as lightSpinner_bigDot from "src/assets/animation/spinner/lightSpinner_bigDot.json";
import * as darkSpinner_bigDot from "src/assets/animation/spinner/darkSpinner_bigDot.json";
import useTheme from "../hooks/styling/useTheme";

/**
 * Spinner component. Displays a spinner animation of the TOA logo.
 * 
 * @param {number} size - Size of the spinner
 */
export default function Spinner({ size = 30 }) {
  const darkMode = useTheme() === "dark";

  var data = darkMode ? darkSpinner_bigDot : lightSpinner_bigDot;

  return (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: data,
            rendererSettings: {
              className: "cursor-default",
            },
          }}
          isClickToPauseDisabled
          height={size}
          width={size}
        />
  );
}
