import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const pagesExample = [
  { name: "Projects", href: "#", current: false },
  { name: "Project Nero", href: "#", current: true },
];

export default function Breadcrumbs({ pages }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index !== 0 && (
                <ChevronRightIcon
                  className="flex-shrink-0 w-5 h-5 mr-4 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <Link
                to={page.href}
                className="text-sm font-medium text-gray-500  hover:text-gray-700"
                aria-current={page.current ? "page" : undefined}
              >
                {!page.loading ? page.name : <div className="h-5 bg-gray-300 rounded-full animate-pulse w-14" />}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
