import React, { useEffect, useState } from "react";
import logo from "../assets/logos/lightMode/logo.svg";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import UserManager from "../tools/UserManager";
import Spinner from "../components/Spinner";
import Button from "../components/input/Button";
import axios from "axios";

export default function InvitePage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState(null);

  var description = <></>;

  const c = searchParams.get("c");
  const e = searchParams.get("e");

  const navigate = useNavigate();

  function acceptInvite() {
    if (user) {
      UserManager.makeAuthenticatedRequest(`/api/invite/accept?c=${c}&e=${e}`)
        .then((res) => {
          // Update user in local storage
          localStorage.setItem("user", JSON.stringify(res.data.user));

          // Go to app
          navigate("/app");
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // Encode company ID and email
      var c_encoded = encodeURIComponent(c);
      var e_encoded = encodeURIComponent(e);
      navigate("/login/register?c=" + c_encoded + "&e=" + e_encoded + "&i=true");
    }
  }

  function declineInvite() {
    // TODO: change to auth req using usermanager
    axios.get("/api/invite/reject?c=" + c + "&e=" + e).finally(() => {
      navigate("/login/login");
    });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      // Validate invite
      if (!c || !e) {
        // Invalid invite
        return;
      }
      // TODO: change to auth req using usermanager
      var res = await axios.get("/api/invite/validate?c=" + c + "&e=" + e);

      if (res.data.status === "pending") {
        // Valid, pending invite
        setCompany({
          _id: c,
          name: res.data.name,
          type: res.data.type,
        });
      } else if (res.data.status === "rejected") {
        // Rejected Invite
        description = <>This invite has been rejected.</>;
        setLoading(false);
        return;
      } else {
        // Invalid invite
        description = <>This invite is invalid.</>;
        setLoading(false);
        return;
      }

      // Get user with user manager
      try {
        var user = (await UserManager.getUser()).userData;
        setUser(user);
      } catch (err) {
        // No user
        user = null;
      }

      setLoading(false);
    }

    fetchData();
  }, []);

  // --- Set description --- //
  // If already part of a company
  if (user?.company) {
    // If already in invited company
    if (user.company._id === c) {
      description = (
        <>
          You are already a member of{" "}
          <span className="font-semibold">{company.name}</span> on Toa.
        </>
      );
    } else {
      description = (
        <>
          You are already a member of{" "}
          <span className="font-semibold">{user.company.name}</span> on Toa. You
          have been invited to join{" "}
          <span className="font-semibold">{company.name}</span> on Toa.
        </>
      );
    }
  }
  // If not part of a company
  else if (company) {
    description = (
      <>
        You have been invited to join{" "}
        <span className="font-semibold">{company.name}</span> on Toa.
      </>
    );
  } else {
    description = <>This invite is invalid.</>;
    var invalid = true;
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-3">
      {/* Logo */}
      <div>
        <img src={logo} alt="Toa Logo" className="w-20 h-20" />
      </div>

      {/* Header */}
      <div className="text-3xl font-bold">Welcome to Toa</div>

      {/* Description */}
      <div className="relative text-lg text-center">
        {loading ? (
          <div className="pt-4">
            <Spinner />
          </div>
        ) : c && e ? (
          description
        ) : (
          "This invite is invalid."
        )}
      </div>

      {/* Actions */}
      {!loading && !invalid && (
        <div className="flex gap-3">
          {user?.company?._id === c ? (
            <Button>
              <Link to="/app">Go to Toa</Link>
            </Button>
          ) : (
            <>
              <Button onClick={acceptInvite}>Accept Invite</Button>
              <Button variant="secondary" onClick={declineInvite}>
                Decline Invite
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
