import React, { useEffect, useState } from 'react'
import { redirect, useLocation, useNavigate } from 'react-router-dom'
import ProgressDots from '../components/SetupPage/ProgressDots'
import StepOne from '../components/SetupPage/StepOne'
import StepTwo from '../components/SetupPage/StepTwo'
import StepThree from '../components/SetupPage/StepThree'
import logo from "../assets/logos/lightMode/logo.svg";
import logo_white from "../assets/logos/darkMode/logo_white.svg";
import toa from "../assets/logos/lightMode/toa.svg";
import toa_white from "../assets/logos/darkMode/toa_white.svg";
import useTheme from "../hooks/styling/useTheme";
import UserManager from '../tools/UserManager'


/**
 * SetupPage with 3 steps to setup a new account, new company, and invite team members
 */
export default function SetupPage() {
  // get params from url
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const firstName = searchParams.get('firstName')
  const lastName = searchParams.get('lastName')
  const companyName = searchParams.get('companyName')
  const companyType = searchParams.get('companyType')
  const invitingCompany = searchParams.get('invitingCompany')

  // replace spaces in email with +, this is needed specifically because of the way firefox handles spaces in urls
  if (email) {
    email.replace(/ /g, "+")
  }

  // navigate function
  const navigate = useNavigate();

  // theme
  const theme = useTheme();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [invitingCompanyId, setInvitingCompanyId] = useState(invitingCompany || null)

  // single state variable for all of step 1
  const [userData, setUserData] = useState({
    userEmail: email || null,
    // if userName is not null, split it into first and last name
    firstName: firstName || null,
    lastName: lastName || null,
    password: null,
    confirmPassword: null
  })

  // single state variable for all of step 2
  const [companyData, setCompanyData] = useState({
    companyName: companyName || null,
    companyType: companyType || null
  })

  // single state variable for all single invite emails
  const [inviteEmails, setInviteEmails] = useState([
    '',
    '',
    ''
  ])

  // state variable to tell us if we are using bulk invite or not
  const [useBulkInvite, setUseBulkInvite] = useState(false)
  // state variable for all bulk invite emails
  const [bulkInviteEmails, setBulkInviteEmails] = useState([])

  // steps
  const [steps, setSteps] = useState([
    { name: 'Step 1', status: 'current' },
    { name: 'Step 2', status: 'upcoming' },
    { name: 'Step 3', status: 'upcoming' },
  ])


  // update the steps state variable to mark the next step as current and the current step as complete
  function nextStep() {
    let currentStepIndex = steps.findIndex(step => step.status === 'current')
    if (currentStepIndex === steps.length - 1) {
      return
    }
    let newSteps = steps.map((step, index) => {
      if (index === currentStepIndex) {
        return { ...step, status: 'complete' }
      } else if (index === currentStepIndex + 1) {
        return { ...step, status: 'current' }
      } else {
        return step
      }
    })
    setSteps(newSteps)
  }

  // update the steps state variable to mark the previous step as current and the current step as upcoming
  function prevStep() {
    let currentStepIndex = steps.findIndex(step => step.status === 'current')
    if (currentStepIndex === 0) return
    let newSteps = steps.map((step, index) => {
      if (index === currentStepIndex) {
        return { ...step, status: 'upcoming' }
      } else if (index === currentStepIndex - 1) {
        return { ...step, status: 'current' }
      } else {
        return step
      }
    })
    setSteps(newSteps)
  }

  function getStep() {
    if (steps[0].status === 'current' || userData.firstName === null || userData.password === null || userData.confirmPassword === null) {
      return StepOne(userData, setUserData, nextStep, error, setError, loading, setLoading)
    } else if (steps[1].status === 'current' || companyData.companyName === null || companyData.companyType === null) {
      return StepTwo(companyData, setCompanyData, nextStep)
    } else if (steps[2].status === 'current' || (!inviteEmails[0] && !inviteEmails[1] && !inviteEmails[2])) {
      return StepThree(companyData, inviteEmails, setInviteEmails, useBulkInvite, setUseBulkInvite, nextStep, prevStep, error, setError, loading, setLoading, navigate, invitingCompanyId)
    }
  }

  return (
    <>
      <div className="flex flex-col min-h-screen gap-2 bg-gray-200 dark:bg-gray-800">

        <div className='flex flex-col items-center justify-start p-4 grow'>
          <div
            className='h-4 md:h-36'
          />
          <ProgressDots steps={steps} />

          {/* dynamic content */}
          {getStep()}

        </div>

        <div className="flex flex-row items-center justify-center gap-3 mb-4 ml-4 sm:justify-start">
          <img
            className="flex-none w-auto h-11"
            src={theme === "dark" ? logo_white : logo}
            alt="Toa"
          />
          <img
            className="w-auto h-8"
            src={theme === "dark" ? toa_white : toa}
            alt="Toa"
          />
        </div>
      </div>
    </>
  )
}