import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import Card from "../../../Card";
import { ExclamationTriangleIcon, BellIcon } from "@heroicons/react/24/outline";

import pluralize from "pluralize";
import Forecast from "../../../../pages/Forecast/classes/Forecast";

export default function IssuesCard({ isStatic = false }) {
  /**
   * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
   */
  const contextValue = useOutletContext();

  if (!isStatic) {
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = contextValue;
    
    if (!forecastData) {
      return <div></div>
    }
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
    } = contextValue;
  }

  // TODO: handle capacity gaps
  if (!isStatic) {
    var { available, remainder } = forecastData?.getAvailablesAndRemainders();
    var capacity = forecastData?.getWeeksOfCapacity();
    var lastWeek = forecastData?.weeks.reduce((acc, week, i) => {
      if (week.likelyJobCount > 0) {
        return i;
      }
      return acc;
    }, -1) ?? -1;
  } else {
    var { available, remainder } = Forecast.generateAvailableAndRemainder(
      staticForecastData.inputForecast,
      staticForecastData.toaForecast
    );
    var capacity = staticForecastData.weekOfCapacity;
    var lastWeek = -1;
    
    let weeksUsed = 0;
    let i = 0;
    while (weeksUsed < staticForecastData.weeksOfCapacity && i < staticForecastData.inputForecast.length) {
      if (staticForecastData.inputForecast[i] > 0) {
        weeksUsed++;
      }
      i++;
    }
    lastWeek = i - 1;
  }

  // Generate array of chunks
  // Ranges: start and end of chunk i.e. [start,end) inclusive to exclusive
  // Types: issue, alert, none
  // Remainder: number of jobs remaining
  let chunks = [];

  let currChunk = null;
  for (let i = 0; i < remainder.length; i++) {
    let weekType = "";

    if (remainder[i] >= 0) {
      weekType = "none";
    } else if (remainder[i] < 0) {
      weekType = "issue";
    }

    // If outside capacity, alert not issue
    if (i > lastWeek && weekType === "issue") {
      weekType = "alert";
    }

    if (!currChunk) {
      currChunk = {
        start: i,
        end: i + 1,
        type: weekType,
        remainder: remainder[i],
      };
    }

    if (currChunk.type !== weekType) {
      currChunk.end = i;
      chunks.push(currChunk);
      currChunk = {
        start: i,
        end: i + 1,
        type: weekType,
        remainder: remainder[i],
      };
    } else {
      currChunk.end = i + 1;
      currChunk.remainder += remainder[i];
    }
  }
  chunks.push(currChunk);

  let issues = chunks
    .filter((c) => c.type !== "none")
    .map((chunk) => {
      let { start, end, type, remainder } = chunk;

      end = end - 1;

      let weekSpan = end - start + 1;

      let message = `${pluralize(
        "job",
        Math.abs(remainder),
        true
      )} not ready to install ${pluralize("wk", weekSpan, false)} ${start}${
        weekSpan > 1 ? `-${end}` : ""
      }`;
      return { type, message };
    });

  return (
    <div className="max-h-[100px] grow">
      <Card className="h-full">
        <div className="h-full overflow-y-scroll">
          {issues.length ? (
            issues.map((issue, i) => <Issue key={i} {...issue} />)
          ) : (
            <p className="flex items-center justify-center h-full text-gray-400">
              No issues found
            </p>
          )}
        </div>
      </Card>
    </div>
  );
}

function Issue({ type = "issue", message = "" }) {
  let icon =
    type === "issue" ? (
      <ExclamationTriangleIcon className="w-5 h-5 stroke-[1.6px] text-primary-rose" />
    ) : (
      <BellIcon className="w-5 h-5 stroke-[1.6px] text-secondary-orange" />
    );
  return (
    <div className="flex items-center gap-2">
      {icon}
      {message}
    </div>
  );
}
