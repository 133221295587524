/**
 * Returns a debounced function that will only execute after a certain timeout.
 * Especially useful for functions that are called frequently, such as event handlers.
 * Note that this creates a new function so it needs to be carefully used with React components.
 * Often `useCallback` is helpful to prevent unnecessary re-renders of this function.
 * (e.g. `const debouncedFunction = useCallback(debounce(() => console.log('Hello World'), 300), []);`
 * It is also helpful to pass arguments to the debounced function using `useCallback` to prevent unnecessary re-renders as the dependencies of `useCallback` would rerender this and create a new debounced function so it would not be debounced.
 *
 * Example Usage:
 * ```
 *  const debouncedFunction = debounce(() => console.log('Hello World'), 300);
 *  debouncedFunction();
 *  debouncedFunction();
 *  debouncedFunction();
 *  debouncedFunction(); // Only one 'Hello World' will be logged after 300ms
 * ```
 * @param {CallableFunction} func - Function to be debounced
 * @param {Number} timeout - Timeout in milliseconds
 * @returns {CallableFunction} - A debounced function
 */
export default function debounce(func = () => {}, timeout = 300) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
