import {
  Bars3Icon,
  BellAlertIcon,
  CalendarIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  Cog8ToothIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  RectangleStackIcon,
  SparklesIcon,
  UserGroupIcon,
  Bars3BottomRightIcon,
  ShoppingCartIcon,
  ChatBubbleBottomCenterTextIcon,
  BuildingOffice2Icon,
  MapIcon,
} from "@heroicons/react/24/outline";
import {
  BellAlertIcon as BellAlertIconSolid,
  CalendarIcon as CalendarIconSolid,
  ChartBarSquareIcon as ChartBarSquareIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
  Cog8ToothIcon as Cog8ToothIconSolid,
  ExclamationTriangleIcon as ExclamationTriangleIconSolid,
  HomeIcon as HomeIconSolid,
  RectangleStackIcon as RectangleStackIconSolid,
  SparklesIcon as SparklesIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid,
  BuildingOffice2Icon as BuildingOffice2IconSolid,
  MapIcon as MapIconSolid,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo_toa from "src/assets/logos/lightMode/logo_toa.svg";
import logo from "src/assets/logos/lightMode/logo.svg";
import toa from "src/assets/logos/lightMode/toa.svg";
import logo_white from "src/assets/logos/darkMode/logo_white.svg";
import toa_white from "src/assets/logos/darkMode/toa_white.svg";
import SubmitFeedback from "../input/feedback/SubmitFeedback";
import useTheme from "../../hooks/styling/useTheme";

const navigation_noCompany = [
  {
    name: "Home",
    href: "",
    icon: HomeIcon,
    selectedIcon: HomeIconSolid,
    current: false,
  },
];

const navigation_installer = [
  {
    name: "Dashboard",
    href: "",
    icon: HomeIcon,
    selectedIcon: HomeIconSolid,
    current: false,
  },
  {
    name: "Alerts",
    href: "alerts",
    icon: BellAlertIcon,
    selectedIcon: BellAlertIconSolid,
    current: false,
  },
  {
    name: "Performance",
    href: "performance",
    icon: ChartPieIcon,
    selectedIcon: ChartPieIconSolid,
    current: false,
  },
  { separator: true, num: 1 },
  {
    name: "Orders",
    href: "orders",
    icon: ShoppingCartIcon,
    selectedIcon: ShoppingCartIconSolid,
    current: false,
  },
  {
    name: "Forecast",
    href: "forecast",
    icon: ChartBarSquareIcon,
    selectedIcon: ChartBarSquareIconSolid,
    current: false,
  },
  {
    name: "Pipeline",
    href: "pipeline",
    icon: RectangleStackIcon,
    selectedIcon: RectangleStackIconSolid,
    current: false,
  },
];
const bottomNavigation_installer = [
  {
    name: "Automation",
    href: "automation",
    icon: SparklesIcon,
    selectedIcon: SparklesIconSolid,
    current: false,
  },
  {
    name: "Operations Setup",
    href: "operations-setup",
    icon: Cog8ToothIcon,
    selectedIcon: Cog8ToothIconSolid,
    current: false,
  },
  {
    name: "Team",
    href: "team",
    icon: UserGroupIcon,
    selectedIcon: UserGroupIconSolid,
    current: false,
  },
  {
    name: "Connections",
    href: "connections",
    icon: BuildingOffice2Icon,
    selectedIcon: BuildingOffice2IconSolid,
    current: false,
  }
];

const rightNavigation_installer = [
  {
    name: "Alerts",
    href: "#",
    icon: ExclamationTriangleIcon,
    selectedIcon: ExclamationTriangleIconSolid,
    current: false,
  },
  {
    name: "Messages",
    href: "#",
    icon: ChatBubbleLeftRightIcon,
    selectedIcon: ChatBubbleLeftRightIconSolid,
    current: false,
  },
  {
    name: "People",
    href: "#",
    icon: UserGroupIcon,
    selectedIcon: UserGroupIconSolid,
    current: false,
  },
];

const navigation_distributor = [
  {
    name: "Forecast Hub",
    href: "",
    icon: HomeIcon,
    selectedIcon: HomeIconSolid,
    current: false,
  },
  {
    name: "Alerts",
    href: "alerts",
    icon: BellAlertIcon,
    selectedIcon: BellAlertIconSolid,
    current: false,
  },
  {
    name: "Performance",
    href: "performance",
    icon: ChartPieIcon,
    selectedIcon: ChartPieIconSolid,
    current: false,
  },
  { separator: true, num: 1 },
  {
    name: "Orders",
    href: "orders",
    icon: CalendarIcon,
    selectedIcon: CalendarIconSolid,
    current: false,
  },
  {
    name: "Installer Forecasts",
    href: "installer-forecasts",
    icon: ChartBarSquareIcon,
    selectedIcon: ChartBarSquareIconSolid,
    current: false,
  },
  {
    name: "Installer Pipeline",
    href: "installer-pipeline",
    icon: RectangleStackIcon,
    selectedIcon: RectangleStackIconSolid,
    current: false,
  },
];

const bottomNavigation_distributor = [
  {
    name: "Automation",
    href: "automation",
    icon: SparklesIcon,
    selectedIcon: SparklesIconSolid,
    current: false,
  },
  {
    name: "Team",
    href: "team",
    icon: UserGroupIcon,
    selectedIcon: UserGroupIconSolid,
    current: false,
  },
  {
    name: "Locations",
    href: "locations",
    icon: MapIcon,
    selectedIcon: MapIconSolid,
    current: false,
  },
  {
    name: "Connections",
    href: "connections",
    icon: BuildingOffice2Icon,
    selectedIcon: BuildingOffice2IconSolid,
    current: false,
  },
];
const rightNavigation_distributor = [
  {
    name: "Alerts",
    href: "#",
    icon: ExclamationTriangleIcon,
    selectedIcon: ExclamationTriangleIconSolid,
    current: false,
  },
  {
    name: "Messages",
    href: "#",
    icon: ChatBubbleLeftRightIcon,
    selectedIcon: ChatBubbleLeftRightIconSolid,
    current: false,
  },
  {
    name: "People",
    href: "#",
    icon: UserGroupIcon,
    selectedIcon: UserGroupIconSolid,
    current: false,
  },
];

const rightBottomNavigation = [
  {
    name: "Feedback",
    href: "#",
    icon: ChatBubbleBottomCenterTextIcon,
    selectedIcon: ChatBubbleBottomCenterTextIconSolid,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function NavItem(item, notCollapsed) {
  var path = window.location.pathname.split("/");
  if (item.href === "") {
    item.current = path.length == 2 || path[2] == "";
  } else {
    item.current = item.href === path[2];
  }

  var Icon = item.current ? item.selectedIcon : item.icon;

  return item.separator ? (
    <div key={item.num} className="border-t dark:border-gray-600"></div>
  ) : (
    <Link
      key={item.name}
      to={item.href}
      className={classNames(
        item.current
          ? "bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white"
          : "text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-300 dark:text-gray-300",
        "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
      )}
    >
      <Icon
        className={classNames(
          item.current
            ? "text-gray-500 dark:text-gray-100"
            : "text-gray-400 dark:text-gray-300 group-hover:text-gray-500 dark:group-hover:text-gray-400",
          "h-6 w-6 flex-shrink-0",
          notCollapsed ? "mr-3" : ""
        )}
        aria-hidden="true"
      />
      {notCollapsed && item.name}
    </Link>
  );
}

const NAV_BREAKPOINT = 1024;
export default function ToaNav({ children }) {
  const [leftbarOpen, setLeftbarOpen] = useState(true);
  const [rightbarOpen, setRightbarOpen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const theme = useTheme();

  // Get user info from local storage
  const user = JSON.parse(localStorage.getItem("user"));

  const location = useLocation();

  // Set navigation based on user's company type
  if (user?.company) {
    if (user.company.type === "installer") {
      var navigation = navigation_installer;
      var bottomNavigation = bottomNavigation_installer;
      var rightNavigation = rightNavigation_installer;
    } else if (user.company.type === "distributor") {
      var navigation = navigation_distributor;
      var bottomNavigation = bottomNavigation_distributor;
      var rightNavigation = rightNavigation_distributor;
    }
  } else {
    var navigation = navigation_noCompany;
    var bottomNavigation = [];
    var rightNavigation = [];
  }

  // Resize listener
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Navigation listener
  useEffect(() => {}, [location]);

  return (
    <div className="h-screen">
      {/* Top Nav */}
      <div className="fixed inset-x-0 top-0 z-30 flex items-center justify-between h-16 border-b dark:border-gray-600 lg:hidden bg-gray-50 dark:bg-gray-800">
        {/* Left nav toggle */}
        <button
          type="button"
          className="ml-1 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
          onClick={() => {
            if (!leftbarOpen && windowDimensions.width < NAV_BREAKPOINT) {
              setRightbarOpen(false);
            }
            setLeftbarOpen(!leftbarOpen);
          }}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="w-6 h-6" aria-hidden="true" />
        </button>

        {/* Page Title */}
        <div className="flex justify-center flex-1 min-w-0 space-x-2">
          <div className="flex space-x-2 place-content-center">
            {/* Small Logo (<lg) */}
            <img
              className={classNames(
                "h-8 w-auto flex-none",
                windowDimensions.width < NAV_BREAKPOINT ? "" : "hidden"
              )}
              src={theme === "dark" ? logo_white : logo}
              alt="Toa"
            />
            {/* Text */}
            <h2 className="flex-1 text-xl font-semibold sm:truncate sm:text-3xl sm:tracking-tight">
              {
                navigation.concat(bottomNavigation).find((item) => item.current)
                  ?.name
              }
            </h2>
          </div>
        </div>

        {/* Right nav toggle*/}
        {
          // Only show right nav toggle if there is right navigation
          rightNavigation && rightNavigation.length > 0 && (
            <button
              type="button"
              className="mr-1 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
              onClick={() => {
                if (!rightbarOpen && windowDimensions.width < NAV_BREAKPOINT) {
                  setLeftbarOpen(false);
                }
                setRightbarOpen(!rightbarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="w-6 h-6" aria-hidden="true" />
            </button>
          )
        }
      </div>

      {/* Dark Overlay */}
      <div
        className={classNames(
          "fixed inset-0 bg-gray-600 bg-opacity-75 lg:hidden z-10",
          leftbarOpen || rightbarOpen ? "" : "hidden"
        )}
      />

      {/* Side bars and main content */}
      {/* Style here is to make the sidebar the full height minus the top nav */}
      <div
        className="flex lg:h-full mt-[64px] lg:mt-0"
        // style={{ height: "calc(100vh - 4rem)" }}
      >
        {/* Left Nav */}
        <div
          className={classNames(
            "inset-y-0 flex flex-col z-20 fixed lg:relative lg:top-0",
            leftbarOpen ? "w-64" : "w-13",
            windowDimensions.width < NAV_BREAKPOINT && !leftbarOpen
              ? "hidden"
              : ""
          )}
        >
          <div className="flex flex-col flex-1 min-h-0 bg-white border-r border-gray-200 dark:border-gray-600 dark:bg-gray-800">
            <div className="flex flex-col flex-1 pb-2 overflow-y-auto">
              {/* Logo */}
              <div className="px-2 pt-2 pb-2 border-b dark:border-gray-600 h-14">
                <button
                  className="flex items-center px-2 py-2 space-x-2"
                  onClick={() => setLeftbarOpen(!leftbarOpen)}
                >
                  <img
                    className={classNames("h-6 w-auto")}
                    src={theme === "dark" ? logo_white : logo}
                    alt="Toa Logo"
                  />
                  <img
                    className={classNames(
                      "h-4 w-auto",
                      leftbarOpen ? "" : "hidden"
                    )}
                    src={theme === "dark" ? toa_white : toa}
                    alt="Toa"
                  />
                </button>
              </div>
              {/* Navigation */}
              <nav className="relative flex-1 px-2 mt-3 space-y-1 bg-white dark:bg-gray-800">
                {navigation.map((item) => NavItem(item, leftbarOpen))}
                <div className="absolute bottom-0 inset-x-2">
                  {bottomNavigation.map((item) => NavItem(item, leftbarOpen))}
                </div>
              </nav>
            </div>
            <div
              className={classNames(
                "flex flex-shrink-0 border-t border-gray-200 dark:border-gray-600",
                leftbarOpen ? "p-4" : "p-2"
              )}
            >
              <Link to="/app/user" className="flex-shrink-0 block w-full group">
                <div
                  className={classNames(
                    "flex items-center",
                    leftbarOpen ? "" : "justify-center"
                  )}
                >
                  <div>
                    <div
                      className={classNames(
                        "rounded-full bg-primary-rose text-white flex items-center justify-center",
                        leftbarOpen ? "h-9 w-9" : "h-6 w-6 text-xs"
                      )}
                    >
                      {user?.firstName.length > 0 && user?.firstName.charAt(0)}
                      {user?.lastName.length > 0 && user?.lastName.charAt(0)}
                    </div>
                  </div>
                  {leftbarOpen && (
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-500">
                        {user?.firstName} {user?.lastName}
                      </p>
                      <p className="text-xs font-medium text-gray-500 dark:text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Main Content */}
        {/* // TODO: Implement <Outlet /> */}
        <div className="flex flex-col flex-1 overflow-auto">
          <main className="flex flex-col flex-1">{children}</main>
        </div>

        {/* Right Nav */}
        {rightNavigation && rightNavigation.length > 0 && (
          <div
            className={classNames(
              "inset-y-0 flex flex-col fixed top-16 right-0 z-20 lg:relative lg:top-0",
              rightbarOpen ? "w-64" : "w-13",
              windowDimensions.width < NAV_BREAKPOINT && !rightbarOpen
                ? "hidden"
                : ""
            )}
          >
            <div className="flex flex-col flex-1 min-h-0 bg-white border-l border-gray-200 dark:border-gray-600 dark:bg-gray-800">
              <div className="flex flex-col flex-1 pb-2 overflow-y-auto">
                {/* Toggle */}
                <div className="justify-end hidden px-2 pt-2 pb-2 border-b dark:border-gray-600 h-14 lg:flex">
                  <button
                    className="flex items-center px-2 py-2 space-x-2"
                    onClick={() => setRightbarOpen(!rightbarOpen)}
                  >
                    <Bars3BottomRightIcon
                      className="flex-shrink-0 w-6 h-6 text-gray-500 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <nav className="relative flex flex-col flex-1 gap-1 px-2 mt-3 bg-white dark:bg-gray-800">
                  {rightNavigation.map((item) => NavItem(item, rightbarOpen))}
                  <div className="mt-auto"></div>
                  <SubmitFeedback
                    // notCollapsed={rightbarOpen}
                    wide={rightbarOpen}
                    onToggleCollapsed={(collapsed) => {
                      if (!collapsed) {
                        setRightbarOpen(true);
                      }
                    }}
                  />
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
