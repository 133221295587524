import {
  Bars2Icon,
  CheckIcon,
  FolderPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import Button from "../../components/input/Button";
import Dropdown from "../../components/input/Dropdown";
import Input from "../../components/input/Input";
import Modal from "../../components/Modal";
import SearchBar from "../../components/SearchBar";
import {
  Reorder,
  useMotionValue,
  useDragControls,
  motion,
} from "framer-motion";
import Card from "../../components/Card";
import {
  EllipsisVerticalIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";
import AddStageModal from "../../components/OperationsSetup/Stages/AddStageModal";
import UserManager from "../../tools/UserManager";
import Spinner from "../../components/Spinner";
import moment from "moment";
import EditStageModal from "../../components/OperationsSetup/Stages/EditStageModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleStages = [
  {
    _id: 1,
    name: "Booked",
    status: "active",
    description: "Jobs that have been booked through the sales team",
    createdAt: "2021-01-01",
  },
  {
    _id: 2,
    name: "Design",
    status: "active",
    description: "Projects that are being designed",
    createdAt: "2021-01-01",
  },
  {
    _id: 3,
    name: "Permit",
    status: "active",
    description: "Plans submitted for permit. Waiting for permit to be issued.",
    createdAt: "2021-01-01",
  },
  {
    _id: 4,
    name: "Schedule",
    status: "active",
    description: "Jobs with permits that need to be scheduled",
    createdAt: "2021-01-01",
  },
  {
    _id: 5,
    name: "Install",
    status: "active",
    description: "Scheduled jobs that are waiting for install",
    createdAt: "2021-01-01",
  },
];

export default function StagesPage() {
  const [showAddStageModal, setShowAddStageModal] = useState(false);
  const [showEditStageModal, setShowEditStageModal] = useState(false);
  const [editStage, setEditStage] = useState(null);

  const [stages, setStages] = useState(null);
  const [stageOrder, setStageOrder] = useState([]);
  const [prevStageOrder, setPrevStageOrder] = useState([]);

  useEffect(() => {
    // Get stages
    if (!stages) {
      UserManager.makeAuthenticatedRequest(
        "/api/operations-setup/stages/get-stages",
        "GET"
      )
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            setStages(res.data.stages);
            setStageOrder(res.data.stages.map((stage) => stage._id));
            setPrevStageOrder(res.data.stages.map((stage) => stage._id));
          } else {
            console.log(res.data.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function handleSaveOrder() {
    // Make api call to save order
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/stages/update-order",
      "POST",
      { order: stageOrder }
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          setStages(res.data.stages);
          setStageOrder(res.data.stages.map((stage) => stage._id));
          setPrevStageOrder(res.data.stages.map((stage) => stage._id));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleAddStage() {
    setShowAddStageModal(true);
  }

  function handleEditStage(editedStage) {
    // Make api call to edit stage
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/stages/edit-stage",
      "POST",
      editedStage
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var temp = stages.map((stage) => {
            if (stage._id === editedStage._id) {
              return editedStage;
            } else {
              return stage;
            }
          });
          setStages(temp);

          setShowEditStageModal(false);
        } else {
          console.log(res.data.error);
          alert("Error editing stage");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleCreateStage(newStage) {
    // Check unique name
    if (stages) {
      var nameExists = stages.find((stage) => stage.name === newStage.name);
      if (nameExists) {
        alert("Stage name already exists");
        return;
      }
    }

    // Make api call to create stage
    UserManager.makeAuthenticatedRequest(
      "/api/operations-setup/stages/add-stage",
      "POST",
      newStage
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          newStage = res.data.stage;

          var temp = stages || [];
          temp.push(newStage);
          setStages(temp);
          setStageOrder([...stageOrder, newStage._id]);

          // If this is the first stage, set prevStageOrder since it is the only possible order
          if ([...stageOrder, newStage._id].length === 1) {
            setPrevStageOrder([...stageOrder, newStage._id]);
          }

          setShowAddStageModal(false);
        } else {
          console.log(res.data.error);
          alert("Error creating stage");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  var content = stages && (
    <ListView
      stageOrder={stageOrder}
      setStageOrder={setStageOrder}
      stages={stages}
      setShowAddStageModal={setShowAddStageModal}
      setEditStage={setEditStage}
      setShowEditStageModal={setShowEditStageModal}
    />
  );

  // If stages null, set content accordingly
  if (!stages) {
    content = (
      <div className="pt-44">
        <Spinner />
      </div>
    );
  }

  // If no stages, set content accordingly
  if (stages && stages.length === 0) {
    content = (
      <div className="flex flex-col justify-center items-center mt-[20vh] lg:mt-[30vh]">
        <FolderPlusIcon className="w-12 h-12 text-gray-400" />
        <div className="text-sm font-semibold text-gray-900">
          No Stages Found
        </div>
        <div className="text-sm font-normal text-gray-500">
          Add stage to get started.
        </div>
        <div>
          <Button
            variant="primary-green"
            className="mt-6"
            onClick={handleAddStage}
          >
            Add Stages
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Actions */}
      <div className="flex flex-wrap items-center gap-4 p-2">
        <div className="text-lg font-semibold basis-full lg:basis-0">
          Stages
        </div>

        {/* // TODO: implement searchbar functionality */}
        <div className="shrink">
          <SearchBar />
        </div>

        <div className="flex gap-2 ml-auto">
          {stages && (
            <>
              <Button onClick={handleAddStage} variant="primary-green">
                Add Stage
              </Button>
              <Button
                onClick={handleSaveOrder}
                variant="primary-green"
                disabled={stageOrder.every(
                  (id, index) => id === prevStageOrder[index]
                )}
              >
                Save Stage Order
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="">{content}</div>

      {/* Modals */}
      {/* Add stage modal */}
      <AddStageModal
        open={showAddStageModal}
        setOpen={setShowAddStageModal}
        handleNewStage={handleCreateStage}
      />

      {/* Edit Stage Modal */}
      <EditStageModal
        open={showEditStageModal}
        setOpen={setShowEditStageModal}
        stage={editStage}
        handleEditStage={handleEditStage}
      />
    </>
  );
}

function ListItem({ id, stages, index, setEditStage, setShowEditStageModal }) {
  const y = useMotionValue(0);
  const [draggable, setDraggable] = useState(false);

  var stage = stages.find((stage) => stage._id === id);

  return (
    <>
      <Reorder.Item
        value={id}
        key={id}
        style={{ y }}
        className="bg-white grow border grid grid-flow-col grid-cols-12 auto-cols-min items-center gap-3 -my-[1px] text-gray-500"
        dragListener={draggable}
        onDragEnd={() => setDraggable(false)}
      >
        {/* Grabber & Name */}
        <div className="flex items-center h-full col-span-4 lg:col-span-2">
          <div
            className="shrink flex items-center -space-x-3.5 cursor-grab border-r h-full mr-3 px-1"
            onMouseEnter={() => setDraggable(true)}
            onMouseLeave={() => setDraggable(false)}
            onTouchStart={() => setDraggable(true)}
          >
            <Bars2Icon className="w-5 h-5 text-gray-400" />
          </div>
          {/* Name */}
          <div className="py-2 text-sm font-medium text-gray-900">
            {stage.name}
          </div>
        </div>
        {/* Active */}
        <div className="flex col-span-2 lg:col-span-1">
          <div className="shrink min-w-[20px]">
            {stage.status === "active" ? (
              <CheckIcon className="w-5 h-5 text-primary-green" />
            ) : (
              <XMarkIcon className="w-5 h-5 text-primary-rose" />
            )}
          </div>
        </div>
        {/* Created Date */}
        <div className="hidden col-span-2 lg:block">
          {formatDate(stage.createdAt)}
        </div>
        {/* Description */}
        <div className="col-span-5 py-2 lg:col-span-6">{stage.description}</div>
        {/* Edit */}
        <div className="flex justify-end col-span-1 pr-2">
          <button
            className="justify-end text-primary-green"
            onClick={() => {
              setEditStage(stage);
              setShowEditStageModal(true);
            }}
          >
            Edit
          </button>
        </div>

        {/* Stage Indicator */}
        <div
          className={`absolute -left-[75px] bg-gray-100 text-gray-900 text-sm font-medium p-1.5 rounded-md`}
        >
          Stage {index + 1}
        </div>
      </Reorder.Item>
    </>
  );
}
function ListView({
  stageOrder,
  setStageOrder,
  stages,
  setShowAddStageModal,
  setEditStage,
  setShowEditStageModal,
}) {
  return (
    <Card className="">
      <div className="flex p-3 ml-[85px]">
        {/* Stage list */}
        <div className="grow flex flex-col gap-3.5">
          {/* Header */}
          <div className="grid justify-between grid-cols-12 gap-3 text-sm font-semibold grow">
            <div className="col-span-4 lg:col-span-2 ml-11">Name</div>
            <div className="col-span-2 lg:col-span-1">Active</div>
            <div className="hidden col-span-2 lg:block">Created Date</div>
            <div className="col-span-4">Description</div>
            <div className="col-span-2 lg:col-span-1">{/* Edit */}</div>
          </div>
          {/* Items */}
          <Reorder.Group
            axis="y"
            className="relative flex flex-col gap-0"
            values={stageOrder}
            onReorder={setStageOrder}
            style={{ listStyle: "none" }}
          >
            {stageOrder.map((id, index) => (
              <ListItem
                key={id}
                id={id}
                stages={stages}
                index={index}
                setEditStage={setEditStage}
                setShowEditStageModal={setShowEditStageModal}
              />
            ))}
          </Reorder.Group>
          {/* Add Stage */}
          <div className="flex items-center justify-end pb-4 border-b">
            <div
              className="flex items-center gap-3 cursor-pointer text-primary-green"
              onClick={() => setShowAddStageModal(true)}
            >
              Add Stage
              <PlusCircleIcon className="w-5 h-5" />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

function formatDate(date) {
  if (!date) return null;

  return moment(date).format("YYYY-MM-DD");
}
