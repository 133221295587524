import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleBarCard({
  title,
  value,
  percent,
  grayed,
  onClick,
  className,
}) {
  return (
    <div
      className={classNames(
        "group shadow rounded-lg px-3 py-4",
        grayed ? "bg-gray-300 dark:bg-gray-500" : "bg-white dark:bg-gray-800",
        onClick
          ? "hover:bg-gray-100 dark:hover:bg-gray-600 hover:cursor-pointer"
          : "",
        className
      )}
      onClick={() => onClick && onClick()}
    >
      <div className="flex flex-col gap-2">
        {/* Name */}
        <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
          {title}
        </div>

        {/* Value and Bar */}
        <div className="flex flex-row gap-1">
          {/* Value */}
          <div className="text-2xl font-semibold">{value}</div>

          {/* Bar */}
          <div className="flex flex-col justify-center flex-grow mx-3">
            {/* Bg */}
            <div className="h-2 rounded-full">
              {/* Fill */}
              <div
                className={classNames(
                  "h-2 rounded-full",
                  grayed
                    ? "bg-gray-600 group-hover:bg-primary-rose"
                    : "bg-primary-rose"
                )}
                style={{ width: `${percent}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
