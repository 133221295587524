import moment from "moment";

/**
 * Groups week values by month.
 * This assumes that the 0 index is for the current week.
 * If a month ends in the middle of a week, the week will be split between the two months.
 * The split is determined by where in the week the month ends. The split is out of business days.
 * For example, if a month ends on a Tuesday, it will take 2/5 of the week's value instead of 3/7.
 */
export function groupWeeksByMonths(values: number[]): number[] {
  let months = [];

  let cursor = null;

  // Go through all weeks
  for (let i = 0; i < values.length; i++) {
    // If null currMonth, set it
    if (!cursor) {
      cursor = moment().utc().startOf("week");
      months.push(0);
    }

    let nextWeeksMonth = cursor.clone().add(1, "week").month();

    // If month changes, split the week
    if (nextWeeksMonth !== cursor.month()) {
      // Determine split
      let dayOfWeek = cursor.day();
      let split = dayOfWeek / 5; // Sunday is 0, Saturday is 6. So Tuesday gives 2/5 as desired.
      split = Math.min(1, split); // Cap at 1 so Saturday doesn't give 6/5.

      // Add split values
      months[months.length - 1] += values[i] * split; // Split for current month
      months.push(values[i] * (1 - split)); // Add remainder to next month
    } else {
      // Add full value
      months[months.length - 1] += values[i];
    }

    // Move cursor to next week
    cursor.add(1, "week");
  }

  return months;
}
