import React, { useContext, useState } from "react";
import Card from "../../Card";
import BucketsDiagram from "../../BucketsDiagram";
import JobPipelineContext from "../../../contexts/pipeline/jobs/JobPipelineContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function JobsInPipelineCard() {
  const {
    jobs,
    formattedJobs,

    markets,
    selectedMarketId,
    setSelectedMarketId: handleMarketSelected,

    stages,
    selectedStageIndex: selectedIndex,
    setSelectedStageIndex: onSelect,

    search,
    setSearch: handleSearch,

    dateFilter,

    jobPagination: pagination,
    setJobListPage: handlePageChange,
  } = useContext(JobPipelineContext);

  // Ghost loading
  if (!stages) {
    return (
      <div>
        <div className="flex gap-10 pt-5 pb-3">
          {/* List */}
          <div className="flex flex-col gap-5 grow">
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
          </div>
          {/* Historical */}
          <div className="grid grid-cols-2 grow gap-x-2 gap-y-5">
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
          </div>
          {/* Stages */}
          <div className="relative flex flex-col gap-5 grow">
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
            <div className="h-10 bg-gray-200 rounded-lg dark:bg-gray-700 animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  var exampleStages = [
    {
      name: "Booked",
      description: "Sold Deals",
      jobCount: 43,
      avgDays: 12,
      avgSize: 7.1,
      stagnantCount: 0,
    },
    {
      name: "Design",
      description: "In Design",
      jobCount: 12,
      avgDays: 23,
      avgSize: 6.8,
      stagnantCount: 2,
    },
    {
      name: "Permit",
      description: "Obtaining Permit",
      jobCount: 82,
      avgDays: 32,
      avgSize: 5.2,
      stagnantCount: 4,
    },
    {
      name: "Schedule",
      description: "Need to Schedule",
      jobCount: 36,
      avgDays: 5,
      avgSize: 9.2,
      stagnantCount: 0,
    },
    {
      name: "Install",
      description: "Waiting for Install",
      jobCount: 53,
      avgDays: 7,
      avgSize: 6.8,
      stagnantCount: 0,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {/* Title */}
      {/* <div className="flex flex-col p-2">
          <div className="text-base font-semibold">Jobs in Pipeline</div>
          <div className="text-sm font-normal">
            {`Unfinished jobs sold in the last 12 months`}
          </div>
        </div> */}

      {/* Body */}
      <div className="grid gap-3 sm:grid-cols-3">

        {/* Historical avgs */}
        <div className="flex-col items-stretch hidden col-start-2 sm:flex">
          <div className="text-base font-medium text-center">
            Historical Averages
          </div>
          <div className="flex justify-around py-2 border-b">
            <div className="text-xs font-semibold text-gray-700 dark:text-gray-400">
              Conversion
            </div>
            <div className="text-xs font-semibold text-gray-700 dark:text-gray-400">
              Cycle Time
            </div>
          </div>
        </div>

        {/* Active Pipeline */}
        <div className="flex-col items-stretch hidden sm:flex">
          <div className="text-base font-medium text-center">
            Active Pipeline
          </div>
          <div className="flex justify-around py-2 text-xs font-semibold text-center text-gray-700 border-b dark:text-gray-400">
            {stages &&
              formatNumber(
                stages.reduce((a, b) => {
                  return a + b.jobCount;
                }, 0)
              )}{" "}
            Active Jobs
          </div>
        </div>

        {stages?.slice(1).map((stage, index) => {
          return (
            <React.Fragment key={index}>
              {/* Stage Name */}
              <div
                key={index}
                className="items-center hidden gap-2 p-2 sm:flex"
              >
                {/* Number */}
                <div className="flex flex-col justify-center">
                  <div
                    className={classNames(
                      "bg-gray-50 dark:bg-gray-600 border border-gray-200 dark:border-gray-400 rounded-full",
                      "text-gray-500 dark:text-white text-lg text-semibold text-center",
                      "w-[30px] h-[30px]"
                    )}
                  >
                    {index + 1}
                  </div>
                </div>

                {/* Text */}
                <div className="flex flex-col justify-center flex-1 items-left">
                  <div className="text-base font-semibold uppercase line-clamp-1">
                    {stage.name}
                  </div>
                  {stage.description && (
                    <div className="text-sm font-medium text-gray-500 whitespace-normal dark:text-gray-400 line-clamp-2">
                      {stage.description}
                    </div>
                  )}
                </div>
              </div>

              {/* Stage Historical Data */}
              <div className="items-center justify-center hidden sm:flex">
                <div className="grid grid-cols-2 grow">
                  <div className="flex flex-col justify-center col-span-1">
                    <div className="text-center text-gray-600 font-medium bg-gray-100 dark:bg-gray-700 dark:text-gray-300 mx-[2px] my-2 py-2 px-4 rounded-l-md">
                      {Math.round(stage.convRateLast3MoAvg * 100)}%
                    </div>
                  </div>
                  <div className="flex flex-col justify-center col-span-1">
                    <div className="text-center text-gray-600 font-medium bg-gray-100 dark:bg-gray-700 dark:text-gray-300 mx-[2px] my-2 py-2 px-4 rounded-r-md">
                      {Math.round(stage.cycleTimeLast90)}
                    </div>
                  </div>
                </div>
              </div>

              {/* Stage Active Pipeline */}
              <div className="flex flex-col items-center justify-center">
                <BucketDiagramRect
                  stage={stage}
                  index={index}
                  stages={stages?.slice(1)}
                  selectedIndex={selectedIndex - 1} // -1 because first stage is not selectable
                  onSelect={(index) => {
                    onSelect(index + 1, true); // +1 because first stage is not selectable
                  }}
                />
              </div>
            </React.Fragment>
          );
        })}

      </div>
    </div>
  );
}

function formatNumber(n) {
  // Format number with commas
  return n?.toLocaleString("en-US");
}

function BucketDiagramRect({ stage, index, stages, selectedIndex, onSelect }) {
  let jobCount = stage.jobCount;
  let maxJobCount = Math.max(...stages.map((stage) => stage.jobCount));
  let width = (jobCount / maxJobCount) * 100;
  let style = {
    width: `${width}%`,
  };

  let isSelected = selectedIndex === index;

  return (
    <div
      className={classNames(
        "flex items-center justify-center relative",
        "rounded-md h-11 cursor-pointer",
        "text-lg font-bold",
        isSelected ? "bg-primary-green" : "bg-gray-200 dark:bg-gray-600 hover:bg-primary-green/20 dark:hover:bg-primary-green/60",
        isSelected ? "text-white" : ""
      )}
      style={style}
      onClick={() => {
        onSelect(index);
      }}
    >
      {formatNumber(jobCount)}

      {/* Upper Connector */}
      {index > 0 && (
        <div className="absolute inset-x-0 flex items-end justify-center bottom-full">
          {/* Rounded */}
          {!isSelected && (
            <div className="relative w-2 h-2 bg-gray-200 dark:bg-gray-600">
              <div className="absolute inset-0 bg-white rounded-br-full dark:bg-gray-800"></div>
            </div>
          )}
          {/* Bar */}
          <div className="w-3 h-6 bg-gray-200 dark:bg-gray-600"></div>
          {/* Rounded */}
          {!isSelected && (
            <div className="relative w-2 h-2 bg-gray-200 dark:bg-gray-600">
              <div className="absolute inset-0 bg-white rounded-bl-full dark:bg-gray-800"></div>
            </div>
          )}
        </div>
      )}

      {/* Lower Connector */}
      {index < stages.length - 1 && (
        <div className="absolute inset-x-0 flex items-start justify-center top-full">
          {/* Rounded */}
          {!isSelected && (
            <div className="relative w-2 h-2 bg-gray-200 dark:bg-gray-600">
              <div className="absolute inset-0 bg-white rounded-tr-full dark:bg-gray-800"></div>
            </div>
          )}
          {/* Bar */}
          <div className="w-3 h-6 bg-gray-200 dark:bg-gray-600"></div>
          {/* Rounded */}
          {!isSelected && (
            <div className="relative w-2 h-2 bg-gray-200 dark:bg-gray-600">
              <div className="absolute inset-0 bg-white rounded-tl-full dark:bg-gray-800"></div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
