import React from "react";

type Props = {
  startValue: number;
  startLabel?: string;
  endValue: number;
  endLabel?: string;

  width?: number;
  height?: number;
  radius?: number;
  curve?: number;

  funnelClassName?: string;
};

/**
 * Displays a mini funnel chart. It makes use of SVG paths to draw the funnel.
 * It shows a label and value on top and bottom. Color (and other classes of course) can be set with `funnelClassName`.
 * E.g. `funnelClassName="fill-primary-green"`
 * Width, height, radius, and curve adjust the size and shape of the funnel
 */
export default function MiniFunnel({
  startValue,
  startLabel = null,
  endValue,
  endLabel = null,

  width = 40,
  height = 40,
  radius: r = 4,
  curve: c = 5,

  funnelClassName = "",
}: Props) {
  let maxValue = Math.max(startValue, endValue);

  // Percentages of the max value
  let startPerc = startValue / maxValue;
  let endPerc = endValue / maxValue;

  // Width of the funnel at start and end based on percentages and width
  let startWidth = width * startPerc;
  let endWidth = width * endPerc;

  // X coordinates of the right and left edges of the funnel at start and end
  let startRightX = width / 2 + startWidth / 2;
  let endRightX = width / 2 + endWidth / 2;

  let startLeftX = width / 2 - startWidth / 2;
  let endLeftX = width / 2 - endWidth / 2;

  // Ensure X values are at least radius away from middle
  startRightX = Math.max(startRightX, width / 2 + r);
  endRightX = Math.max(endRightX, width / 2 + r);
  startLeftX = Math.min(startLeftX, width / 2 - r);
  endLeftX = Math.min(endLeftX, width / 2 - r);

  // --- Path String for SVG --- //

  // Goes clockwise starting from top middle

  // Start
  let pathString = `M ${width / 2} 0 `; // Start at top middle

  // Go to top right corner and then down a bit
  pathString += `H ${startRightX - r} `; // Move to top right corner (just before rounding)
  pathString += `a ${r} ${r} 0 0 1 ${r} ${r} `; // Round top right corner
  pathString += `v ${height / 3 - r} `; // Move down a bit

  // Curve to end width
  pathString += `C ${startRightX} ${height / 3 + c}, `; // Start control point
  pathString += `${endRightX} ${(height * 2) / 3 - c}, `; // End control point
  pathString += `${endRightX} ${(height * 2) / 3} `; // End curve

  // Go to bottom right corner, then bottom left corner, then up a bit
  pathString += `v ${height / 3 - r} `; // Move to bottom right corner (just before rounding)
  pathString += `a ${r} ${r} 0 0 1 ${-r} ${r} `; // Round bottom right corner
  pathString += `H ${endLeftX + r} `; // Move to bottom left corner (just before rounding)
  pathString += `a ${r} ${r} 0 0 1 ${-r} ${-r} `; // Round bottom left corner
  pathString += `v ${-height / 3 + r} `; // Move up a bit

  // Curve to start width
  pathString += `C ${endLeftX} ${(height * 2) / 3 - c}, `; // Start control point
  pathString += `${startLeftX} ${height / 3 + c}, `; // End control point
  pathString += `${startLeftX} ${height / 3} `; // End curve

  // Go to top left corner and then close path
  pathString += `v ${-height / 3 + r} `; // Move to top left corner (just before rounding)
  pathString += `a ${r} ${r} 0 0 1 ${r} ${-r} `; // Round top left corner
  pathString += "Z"; // Close path

  return (
    <div className="flex flex-col items-center justify-between gap-3">
      <div className="flex flex-col items-center -space-y-1">
        <Label label={startLabel} />
        <Value value={startValue} />
      </div>

      <div className="flex flex-col items-center">
        <svg width={width} height={height}>
          <path radius={4} rx={4} d={pathString} className={funnelClassName} />
        </svg>
      </div>

      <div className="flex flex-col items-center -space-y-1.5">
        <Value value={endValue} />
        <Label label={endLabel} />
      </div>
    </div>
  );
}

type LabelProp = {
  label: string;
};

function Label({ label }: LabelProp) {
  // TODO: dark mode
  return <p className="text-xs font-medium leading-4 text-center text-gray-400">{label}</p>;
}

type ValueProp = {
  value: number;
};
function Value({ value }: ValueProp) {

  // Format value (e.g. 1000 -> 1,000)
  let valueStr = value?.toLocaleString() || "0";

  return <p className="text-2xl font-semibold leading-8">{valueStr}</p>;
}
