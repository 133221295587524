import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/input/Button";
import Switch from "../../components/input/Switch";
import UserManager from "../../tools/UserManager";
import Breadcrumbs from "../../components/nav/Breadcrumbs";
import moment from "moment";
import Card from "../../components/Card";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Input from "../../components/input/Input";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const exampleMarket = {
  _id: 1,
  name: "Colorado",
  marketFormulaValues: ["CO", "Colorado", "colorado"],
  primaryDistributor: null,
  mainCrews: [],
  description: "Colorado market description",
  status: "active",
  createdAt: "2021-07-01T00:00:00.000",
  stats: {
    totalInstalls: 9600,
    totalPipeline: 721,
    wattageYTD: 10_800_000,
    last6Months: [15, 20, 4, 7, 6, 10],
  },
};

export default function MarketPage() {
  // Get market ID from URL
  const { id } = useParams();

  // Get edit mode from url query
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  var editMode = searchParams.get("edit") === "true";

  const navigate = useNavigate();

  // States
  const [market, setMarket] = React.useState(null);
  const [tempMarket, setTempMarket] = React.useState({}); // For editing

  useEffect(() => {
    // Get market from API
    if (!market) {
      UserManager.makeAuthenticatedRequest(
        `/api/operations-setup/markets/get-market/${id}`,
        "GET"
      )
        .then((res) => {
          var status = res.data.status;
          if (status === "ok") {
            setMarket(res.data.market);
            setTempMarket(res.data.market);
          } else {
            console.log("Error getting market");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [location]);

  // Actions based on edit mode
  var actions = editMode
    ? [
        {
          label: "Cancel",
          type: "secondary",
          onClick: () => {
            searchParams.set("edit", false);
            navigate(`${location.pathname}?${searchParams.toString()}`);
          },
        },
        {
          label: "Save",
          type: "primary",
          onClick: handleSave,
          disabled: !validate(tempMarket),
        },
      ]
    : [
        {
          label: "Edit",
          type: "secondary",
          onClick: () => {
            setTempMarket(market);
            searchParams.set("edit", true);
            navigate(`${location.pathname}?${searchParams.toString()}`);
          },
        },
      ];

  // Info Card Content
  var infoCardContent = {
    left: [
      // Name
      {
        label: "Name",
        value: market?.name,
        edit: {
          type: "text",
          value: tempMarket.name,
          placeholder: market?.name,
          onChange: (value) => {
            setTempMarket({ ...tempMarket, name: value });
          },
        },
        loadingSize: {
          width: editMode ? 300 : 150,
          height: editMode ? 30 : undefined,
        },
        required: true,
      },

      // Description
      {
        label: "Description",
        value: market?.description,
        edit: {
          type: "textarea",
          value: tempMarket.description,
          placeholder: market?.description,
          onChange: (value) => {
            setTempMarket({ ...tempMarket, description: value });
          },
        },
        loadingSize: {
          width: editMode ? 300 : 200,
          height: editMode ? 150 : undefined,
        },
      },

      // Active
      {
        label: "Active",
        value:
          market?.status === "active" ? (
            <CheckIcon className="text-primary-green w-6 h-6" />
          ) : (
            <XMarkIcon className="text-primary-rose w-6 h-6" />
          ),
        edit: {
          type: "switch",
          value: tempMarket.status === "active",
          onChange: (value) => {
            setTempMarket({
              ...tempMarket,
              status: value ? "active" : "inactive",
            });
          },
        },
        loadingSize: {
          width: 50,
        },
      },
    ],
    right: [
      // Created Date
      {
        label: "Created Date",
        value: formatDate(market?.createdAt),
        loadingSize: {
          width: 150,
        },
      },

      // TODO: primary distributor

      // TODO: crews

      // Market Formula Values
      {
        label: "Market Values",
        value: editMode
          ? market?.marketFormulaValues
          : market?.marketFormulaValues.join(", "),
        edit: {
          type: "array",
          value: tempMarket.marketFormulaValues,
          onChange: (value) => {
            setTempMarket({ ...tempMarket, marketFormulaValues: value });
          },
        },
        loadingSize: {
          width: 250,
          height: 40,
        },
      },
    ],
  };

  // Stats Card Content
  var statsCardContent = {
    left: [
      // Total Installs
      {
        label: "Total Installs",
        value: formatNumber(market?.stats?.totalInstalls || 0),
        loadingSize: {
          width: 150,
        },
      },

      // Total Pipeline
      {
        label: "Total Pipeline",
        value: formatNumber(market?.stats?.totalPipeline || 0),
        loadingSize: {
          width: 75,
        },
      },
    ],
    right: [
      // Wattage YTD
      {
        label: "Wattage YTD",
        value: formatWatts(market?.stats?.wattageYTD || 0),
        loadingSize: {
          width: 150,
        },
      },

      // Last 6 Months
      {
        label: "Last 6 Months",
        value: <LittleBars data={market?.stats?.last6Months} />,
        loadingSize: {
          width: 125,
          height: 50,
        },
      },
    ],
  };

  // --- Functions --- //

  function handleSave() {
    // Set market to null to force reload / loading state
    setMarket(null);

    // Update market to temp market
    UserManager.makeAuthenticatedRequest(
      `/api/operations-setup/markets/update-market`,
      "POST",
      tempMarket
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var m = res.data.market;
          if (!m.stats) m.stats = {};
          setMarket(m);
          setTempMarket(m);

          // Navigate to view mode
          searchParams.set("edit", false);
          navigate(`${location.pathname}?${searchParams.toString()}`);
        } else {
          console.log("Error updating market");
          alert("Error updating market");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Error updating market");
      })
  }

  return (
    <div className="m-4">
      {/* Breadcrumbs */}
      <Breadcrumbs
        pages={[
          {
            name: "Operations Setup",
            href: "/app/operations-setup",
            current: false,
          },
          {
            name: "Markets",
            href: "/app/operations-setup/markets",
            current: false,
          },
          {
            name: market?.name,
            href: `/app/operations-setup/markets/${market?._id}`,
            current: true,
            loading: !market,
          },
        ]}
      />

      {/* Body */}
      <div className="flex flex-col mt-2 gap-8">
        {/* Header and Actions */}
        <div className="flex flex-row justify-between items-center">
          {/* Header */}
          <div className="text-gray-900 text-2xl font-bold leading-8">
            {market ? market.name : <LoadingBar height={32} width={200} />}
          </div>

          {/* Actions */}
          <div className="flex flex-row gap-2">
            {actions.map((action, index) => (
              <Button
                key={index}
                variant={action.type}
                onClick={action.onClick}
                disabled={action.disabled}
              >
                {action.label}
              </Button>
            ))}
          </div>
        </div>

        {/* Properties Content */}
        <Card className="flex flex-col">
          {/* Header */}
          <div className="border-b flex flex-row justify-between items-center -mx-4 px-8 pb-4">
            {/* Title and Subtitle */}
            <div className="flex flex-col text-base">
              <div className="font-semibold text-gray-900 text-xl leading-7">
                Information
              </div>
              <div className="text-base leading-6 font-normal text-gray-500 ">
                System data used for pipeline management
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="flex flex-row justify-around my-6 gap-12 mx-12 text-sm leading-5 font-normal">
            {/* Left Column */}
            <div className="grow flex flex-col divide-y">
              {infoCardContent.left.map((item, index) => (
                <LineItem
                  key={index}
                  market={market}
                  item={item}
                  editMode={editMode}
                />
              ))}
            </div>

            {/* Right Column */}
            <div className="grow flex flex-col divide-y">
              {infoCardContent.right.map((item, index) => (
                <LineItem
                  key={index}
                  market={market}
                  item={item}
                  editMode={editMode}
                />
              ))}
            </div>
          </div>
        </Card>

        {/* Stats Content */}
        {!editMode && (
          <Card className="flex flex-col">
            {/* Header */}
            <div className="border-b flex flex-row justify-between items-center -mx-4 px-8 pb-4">
              {/* Title and Subtitle */}
              <div className="flex flex-col text-base">
                <div className="font-semibold text-gray-900 text-xl leading-7">
                  Stats
                </div>
                <div className="text-base leading-6 font-normal text-gray-500 ">
                  Data from jobs within the market
                </div>
              </div>
            </div>
            {/* Content */}
            <div className="flex flex-row justify-around my-6 gap-12 mx-12 text-sm leading-5 font-normal">
              {/* Left Column */}
              <div className="grow flex flex-col divide-y">
                {statsCardContent.left.map((item, index) => (
                  <LineItem
                    key={index}
                    market={market}
                    item={item}
                    editMode={editMode}
                  />
                ))}
              </div>

              {/* Right Column */}
              <div className="grow flex flex-col divide-y">
                {statsCardContent.right.map((item, index) => (
                  <LineItem
                    key={index}
                    market={market}
                    item={item}
                    editMode={editMode}
                  />
                ))}
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}

function LineItem({ market, item, editMode }) {
  var { label, value, edit, loadingSize, required } = item;

  var content = null;

  if (!market) {
    // Loading
    content = <LoadingBar {...loadingSize} />;
  } else {
    if (editMode && edit) {
      switch (edit.type) {
        // Switch
        case "switch":
          content = <Switch checked={edit.value} setChecked={edit.onChange} />;
          break;

        // Array
        case "array":
          content = (
            <div className="flex flex-col gap-2">
              {edit.value.map((item, index) => (
                <div key={index} className="flex flex-row gap-2">
                  <Input
                    placeholder=""
                    type="text"
                    value={item}
                    highlightOnEmpty
                    onChange={(value) => {
                      var newArray = [...edit.value];
                      newArray[index] = value;
                      edit.onChange(newArray);
                    }}
                  />
                  {index > 0 && (
                    <MinusCircleIcon
                      className="text-primary-rose w-6 h-6 hover:cursor-pointer"
                      onClick={() => {
                        var newArray = [...edit.value];
                        newArray.splice(index, 1);
                        edit.onChange(newArray);
                      }}
                    />
                  )}
                </div>
              ))}
              <PlusCircleIcon
                className="w-6 h-6 text-primary-green hover:cursor-pointer"
                onClick={() => {
                  edit.onChange([...edit.value, ""]);
                }}
              />
            </div>
          );
          break;

        // Regular Inputs
        default:
          content = (
            <Input
              placeholder={edit.placeholder}
              type={edit.type}
              value={edit.value}
              onChange={edit.onChange}
              highlightOnEmpty={required}
            />
          );
          break;
      }
    } else {
      // View
      content = value;
    }
  }

  return (
    <div className="flex justify-between items-center py-3">
      <div className="basis-1/2 text-gray-500">{label}</div>
      <div className="basis-1/2 text-gray-900">{content}</div>
    </div>
  );
}

function LoadingBar({ height = 20, width = 100 }) {
  return (
    <div
      className={classNames("bg-gray-300 animate-pulse rounded-2xl")}
      style={{
        height: `${height}px`,
        width: `${width}px`,
      }}
    ></div>
  );
}

function LittleBars({ data = [] }) {
  // Generate last 6 mo bar chart
  var bars = <></>;
  // Get largest
  var largest = data.reduce((a, b) => Math.max(a, b), 0);

  bars = data.map((d, i) => {
    var percent = (100.0 * d) / largest;

    return (
      <div
        key={i}
        className={`w-2 rounded bg-primary-rose`}
        style={{ height: `${percent}%`, minHeight: percent > 0 ? "4px" : undefined }}
      ></div>
    );
  });

  return <div className="flex items-end gap-1 h-[30px]">{bars}</div>;
}

function formatDate(dateStr) {
  if (!dateStr) return null;

  return moment(dateStr).format("MM / DD / YY");
}

function formatNumber(number) {
  return number.toLocaleString();
}

function formatWatts(watts) {
  return (
    Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(watts) + "W"
  );
}

function validate(market) {
  // Require name
  if (!market.name || market.name.length === 0) {
    return false;
  }

  // Require at least one market formula value
  if (!market.marketFormulaValues || market.marketFormulaValues.length === 0) {
    return false;
  }

  // Require non-empty market formula values
  if (
    !market.marketFormulaValues.every(
      (marketFormulaValue) => marketFormulaValue.length > 0
    )
  ) {
    return false;
  }

  return true;
}
