import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Card from "../../components/Card";
import BulletChartAndInput from "../../components/Forecast/BulletChartAndInput";
import MaterialForecastContext from "../../contexts/forecast/material/MaterialForecastContext";

import moment from "moment";
import MaterialGroupTable from "../../components/MaterialGroupTable";

import { Disclosure } from "@headlessui/react";
import AvailableRemainderTable from "../../components/Forecast/material/AvailableRemainderTable";
import ForecastCountSummary from "../../components/Forecast/material/cards/ForecastCountSummary";
import Forecast from "./classes/Forecast";

import pluralize from "pluralize";
import MaterialGroupCard from "../../components/Forecast/material/cards/MaterialGroupCard";
import Last6WksTable from "../../components/Forecast/material/Last6WksTable";
import IssuesCard from "../../components/Forecast/material/cards/IssuesCard";

import { ForecastSnapshotViewContextType } from "../../contexts/forecast/history/snapshot/ForecastSnapshotViewContext.tsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MaterialForecastPage({ isStatic = false }) {
  const outletContextValue = useOutletContext();

  if (!isStatic) {
    /**
     * @type {import("src/pages/Forecast/ForecastPage").ForecastContext}
     */
    var {
      selectedMarket,
      rawForecastData,
      forecastData,
      onJobCountInput,
      materials,
      materialGroups: matGroups,
    } = outletContextValue;
  } else {
    /**
     * @type {ForecastSnapshotViewContextType}
     */
    var {
      forecast: staticForecastData,
      market: staticMarket,
      stages: stages,
      materialGroups: matGroups,
      materials,
    } = outletContextValue;
  }

  // TODO: get user pref?
  const [xWeekForecast, setXWeekForecast] = React.useState(4);

  // Material Forecast Context value to pass down
  let contextValue = {
    xWeekForecast,
    handleXWeekForecastChange,
  };

  function handleXWeekForecastChange(newVal) {
    setXWeekForecast(newVal);
  }

  return (
    <MaterialForecastContext.Provider value={contextValue}>
      <div className="grid min-w-full grid-cols-4 gap-6 px-6 shrink-0">
        {/* Key Considerations */}
        <div className="flex flex-col justify-between col-span-1">
          <ForecastCountSummary isStatic={isStatic} />
          <Last6WksTable isStatic={isStatic} />
        </div>
        {/* Job Forecast */}
        <div className="flex flex-col col-span-3 gap-10 px-2">
          <div className="pl-1 pr-4">
            <BulletChartAndInput isStatic={isStatic} />
          </div>
        </div>

        <div className="col-span-4">
          <Disclosure as="div">
            {({ open }) => (
              <>
                <Disclosure.Button className="grid items-center justify-center w-full grid-cols-5 gap-4">
                  {/* Line */}
                  <div className="h-0 border-t border-gray-400 grow"></div>
                  <div className="flex items-center col-span-4 gap-4">
                    {/* Icon and Text */}
                    <div className="flex items-center gap-2">
                      {open ? (
                        <ChevronUpIcon className="w-4 h-4 stroke-2" />
                      ) : (
                        <ChevronDownIcon className="w-4 h-4 stroke-2" />
                      )}
                      <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                        {open ? "Collapse" : "Expand"} Details
                      </p>
                    </div>
                    {/* Line */}
                    <div className="h-0 border-t border-gray-400 grow"></div>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className="pt-2">
                  <div className="grid grid-cols-4 gap-6">
                    <div className="flex items-stretch justify-between col-span-1 gap-4">
                      <IssuesCard isStatic={isStatic} />
                      <div className="flex flex-col justify-center text-sm font-medium text-gray-600 dark:text-gray-400">
                        <div className="py-2">Available</div>
                        <div className="py-2">Remainder</div>
                      </div>
                    </div>
                    <div className="flex items-center col-span-3 pl-3 pr-6">
                      <AvailableRemainderTable isStatic={isStatic} />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        {/* Material Groups */}
        {matGroups && matGroups.length > 0 ? (
          matGroups.map((g) => {
            return <MaterialGroupCard group={g} key={g._id} isStatic={isStatic} />;
          })
        ) : (
          <div className="flex items-center justify-center col-span-6 pt-10 text-gray-500">
            No material groups found
          </div>
        )}
      </div>
    </MaterialForecastContext.Provider>
  );
}
