import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UserManager from "../../tools/UserManager";
import Spinner from "../../components/Spinner";
import Input from "../../components/input/Input";
import toa_semi_bold from "../../assets/logos/lightMode/toa_semi-bold.svg";
import toa_semi_bold_white from "../../assets/logos/darkMode/toa_semi-bold_white.svg";
import useTheme from "../../hooks/styling/useTheme";

export default function Login() {
  // get params from url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirect");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const theme = useTheme();

  // When mounted, check if user is logged in. Redirect if so.
  useEffect(() => {
    setLoading(true);
    UserManager.getUser()
      .then((user) => {
        if (user.loggedIn && !redirectUrl) {
          navigate("/app");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    setLoading(false);
  }, []);

  /**
   * // TODO comment
   * @param {Event} event
   */
  async function loginUser(event) {
    event.preventDefault(); // Don't refresh/redirect from form

    setLoading(true);

    // log out any existing user
    await UserManager.logout();

    var success = await UserManager.login(email, password);

    setLoading(false);
    if (success) {
      // Clear error
      setError("");
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/app");
      }
    } else {
      setError(
        "Login failed. Please make sure your email and password are correct."
      );
    }
  }

  var submitBtn = loading ? (
    <div style={{ position: "relative", minHeight: 40 }}>
      <Spinner />
    </div>
  ) : (
    <button
      type="submit"
      className="flex w-full justify-center rounded-md bg-primary-green px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-green"
    >
      Log in
    </button>
  );

  return (
    <>
      <div className="flex flex-col pb-6">
        <div className="mt-2.5">
          <div className="flex flex-row mx-2 px-2 py-2.5 items-center justify-center flex-wrap gap-y-2">
            <div className="text-4xl font-semibold leading-10 text-center text-gray-700 align-middle text-nowrap dark:text-gray-50">
              Sign in to
            </div>
            <img
              className="flex-none w-auto justify-self-start mx-[5px] pl-[5px] h-7 block"
              src={theme === "dark" ? toa_semi_bold_white : toa_semi_bold}
              alt="Toa"
            />
          </div>
        </div>
        <div className="px-2 my-2 pb-2.5">
          <div className="text-xl font-medium leading-7 text-center text-gray-900 align-middle dark:text-gray-200">
            The Connected Workspace for your Operations and Analytics.
          </div>
        </div>
      </div>
      <form className="max-w-xs mx-auto space-y-6" onSubmit={loginUser}>
        <div>
          <div className="mt-2">
            <Input
              value={email}
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              placeholder="john.doe@example.com"
              label={"Email address"}
              required
              onChange={(value) => setEmail(value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <div className="mt-2">
            <Input
              value={password}
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              label={"Password"}
              required
              onChange={(value) => setPassword(value)}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Error Message */}
        <div className="text-red-500">{error}</div>

        <div>{submitBtn}</div>
      </form>

      <p className="mt-4 text-center">
        <Link
          to="/login/request-password-reset"
          className="text-sm font-medium leading-5 text-primary-green hover:text-primary-green"
        >
          Forgot password?
        </Link>
      </p>
    </>
  );
}
