import React, { useEffect, useState } from "react";
import { Form, Button, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { InfoCircleFill } from "react-bootstrap-icons";
import axios from "axios";
import Spinner from "../../components/Spinner";
import UserManager from "../../tools/UserManager";
import Input from "../../components/input/Input";


export default function Register() {
  const [searchParams] = useSearchParams("i");

  // get i as a boolean
  const i = searchParams.get("i") === "true";
  const c = searchParams.get("c");
  const e = searchParams.get("e");

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState(null);


  const navigate = useNavigate();

  // When mounted, check if user is logged in. Redirect if so.
  useEffect(() => {
    setLoading(true);
    UserManager.getUser()
      .then((user) => {
        if (user.loggedIn) {
          navigate("/app");
        }
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
        // If invite, set email
        if (i && c) {
          setEmail(e);
        }
      });
  }, []);

  useEffect(() => {
    if (c) {
      axios.get("/api/company/company-name?id=" + c).then((res) => {
        setCompany(res.data.name);
      }
      );
    }
  }, [c]);

  /**
   *
   * @param {*} event
   * @returns
   */
  async function registerUser(event) {
    event.preventDefault(); // Don't refresh/redirect from form

    // Make sure passwords are the same
    if (password !== confirmPass) {
      setError("Passwords don't match.");
      return;
    }

    // Make API call
    setLoading(true);

    // log out any existing user
    await UserManager.logout();

    const response = await axios.post(
      "/api/user/register",
      {
        email,
        password,
        firstName,
        lastName,
        ...(i && c && { viaInvite: true, companyId: c })
      },
      {
        withCredentials: true,
      }
    );
    setLoading(false);

    const data = response.data;

    if (data.status === "ok") {
      // Clear error
      setError("");

      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem('loggedIn', true)

      // Redirect to app
      navigate("/app");
    } else if (data.status === "error") {
      // Report error
      console.log(data);
      var msg = "There was an error registering.";
      if (data.error === "Missing field") {
        msg = "Please enter your name, your email, and a secure password";
      } else if (data.error === "User exists") {
        msg = "A user with that email already exists.";
      } else if (data.error === "Bad password") {
        msg =
          "That password is not secure enough. Password must be at least 8 characters long, have: at least 1 uppercase letter, at least 1 lowercase letter, a number, and a special character ($@#!&).";
      }
      setError(msg);
    }
  }

  var header = (i && c && e) ? (
    <div className="flex flex-col items-center justify-center">
      <div className="my-1">
        <div className="px-2 my-1">
          <div className="text-4xl font-bold leading-10 text-center text-gray-900 dark:text-gray-50">
            Join <span className="text-primary-rose">{company}</span> on TOA
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="text-xl font-medium leading-7 text-gray-900 dark:text-gray-200">
          The connected workspace for operations and analytics
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-row items-center justify-center px-2 mx-2">
      <div className="text-4xl font-semibold leading-10 text-center text-gray-700 align-middle text-nowrap dark:text-gray-50">
        Create your account
      </div>
    </div>
  );

  var acceptInviteHeader = (i && c && e) ? (
    <div className="py-2 m-2 text-sm font-normal leading-5 text-center text-gray-900 dark:text-gray-200">
      You're accepting an invitation sent to <span className="font-bold">{e}</span>
    </div>
  ) : (
    null
  );

  var emailInput = (i && c && e) ? (
    null
  ) : (
    <div>
      <div className="mt-2">
        <Input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          placeholder="john.doe@example.com"
          value={email}
          label={"Email address"}
          required
          onChange={setEmail}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );

  var submitBtn = loading ? (
    <div style={{ position: "relative", minHeight: 40 }}>
      <Spinner />
    </div>
  ) : (
    <button
      type="submit"
      className="flex w-full justify-center rounded-md bg-primary-green px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-green focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-green"
    >
      Sign up
    </button>
  );

  return (
    <>
      <div className="flex flex-col pb-6">

        {header}

      </div>

      {acceptInviteHeader}

      <form className="max-w-xs mx-auto space-y-6" onSubmit={registerUser}>

        {/* Email */}
        {emailInput}

        {/* First Name */}
        <div>
          <div className="mt-2">
            <Input
              id="firstName"
              name="firstName"
              type="firstName"
              autoComplete="firstName"
              placeholder="John"
              value={firstName}
              label={"First Name"}
              required
              onChange={setFirstName}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Last Name */}
        <div>
          <div className="mt-2">
            <Input
              id="lastName"
              name="lastName"
              type="lastName"
              autoComplete="lastName"
              placeholder="Doe"
              value={lastName}
              label={"Last Name"}
              required
              onChange={setLastName}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Password */}
        <div>
          <div className="mt-2">
            <Input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder="Password"
              value={password}
              label={"Password"}
              required
              onChange={setPassword}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Confirm Password */}
        <div>
          <div className="mt-2">
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              autoComplete="current-password"
              placeholder="Confirm password"
              value={confirmPass}
              label={"Confirm Password"}
              required
              onChange={setConfirmPass}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-green sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        {/* Error Message */}
        <div className="text-red-500">{error}</div>

        <div>{submitBtn}</div>
      </form>
    </>
  );
}