export default class Stage {

  // ------------------ //
  // --- Properties --- //
  // ------------------ //

  /**
   * The ID of the stage.
   * @type {ObjectId}
   */
  ID;

  /**
   * The name of the stage.
   * @type {string}
   */
  name;

  /**
   * The % of jobs that convert to installs in this stage.
   * @type {number}
   */
  
  convRate;

  /**
   * The average time to install in this stage. (in days)
   * @type {number}
   */
  avgTimeToInstall;

  /**
   * The number of active jobs in this stage.
   * @type {number}
   */
  activeInStage;

  /**
   * The order of the stage. I.e. stages are sorted by this number.
   * @type {number}
   */
  order;

  /**
   * For the jobs in this stage, the earliest week an estimated install date is.
   * @type {number}
   */
  fromWeek = null;

  /**
   * For the jobs in this stage, the latest week an estimated install date is.
   */
  toWeek = null;

  // ------------------- //
  // --- Constructor --- //
  // ------------------- //

  /**
   * Creates a new instance of the Stage class.
   * @param {ObjectId} id - The ID of the stage
   * @param {string} name - The name of the stage
   */
  constructor(id, name, convRate, avgTimeToInstall, activeInStage, order) {
    this.ID = id;
    this.name = name;
    this.convRate = convRate;
    this.avgTimeToInstall = avgTimeToInstall;
    this.activeInStage = activeInStage;
    this.order = order;
  }
  
  // --------------- //
  // --- Methods --- //
  // --------------- //

  /**
   * Records the week of a job in this stage.
   * This updates the fromWeek and toWeek properties.
   * 
   * @param {number} weekIndex - The week to record
   */
  recordWeek(week) {
    if (this.fromWeek === null || week < this.fromWeek) {
      this.fromWeek = week;
    }
    if (this.toWeek === null || week > this.toWeek) {
      this.toWeek = week;
    }
  }
}