import React, { useState, useEffect, useCallback } from 'react'
import Card from '../../components/Card'
import InputTable from '../../components/input/InputTable'
import SearchBar from '../../components/SearchBar'
import Button from '../../components/input/Button'
import Input from '../../components/input/Input'
import UserManager from '../../tools/UserManager'
import Modal from '../../components/Modal'
import { XMarkIcon } from '@heroicons/react/20/solid'
import ComboboxSearchBar from '../../components/ComboboxSearchBar';
import {
  requestCompanySearch,
  addCompanySearchResult,
  sortConnections
} from '../../tools/connectionManagement'
import debounce from '../../tools/debounce'
import validateEmail from '../../tools/validateEmail';
import { useNavigate } from 'react-router-dom'


/**
 * A table will display all of the user's connections. Admin users will be able to send/accept/reject connection requests, remove connections, and view connection details.
 * @returns - a component that allows admin users to manage their connections
 */
export default function ManageConnectionsPage() {

  const navigate = useNavigate()

  // get current logged in user
  const [currentUser, setCurrentUser] = useState({})
  // const [currentUsersCompany, setCurrentUsersCompany] = useState({})

  // connections state
  const [connections, setConnections] = useState([])

  // error state
  const [error, setError] = useState('')

  // add connections modal state
  const [showAddConnectionsModal, setShowAddConnectionsModal] = useState(false)

  // confirm disconnection modal state
  const [showConfirmDisconnectionModal, setShowConfirmDisconnectionModal] = useState(false)

  // state for a connection that is about to be disconnected
  const [connectionToDisconnect, setConnectionToDisconnect] = useState({})

  // search value and results
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState([])

  // connection request companies
  const [connectionRequestCompanies, setConnectionRequestCompanies] = useState([])

  // invite state
  const [invites, setInvites] = useState([])
  const [inputCompanyName, setInputCompanyName] = useState('')
  const [inputContactName, setInputContactName] = useState('')
  const [inputEmail, setInputEmail] = useState('')


  // debounced request company search
  const debouncedRequestCompanySearch = useCallback(debounce(requestCompanySearch, 500), []);

  const userCompanyType = currentUser.company?.type

  const tableColumns = [
    {
      Header: "Name",
      accessor: "name",
      placeholder: "Company Name",
      required: false,
      primary: true,
      columnWidth: 4,
      editable: false,
      editType: null,
      validate: (value) => { return true },
    },
    {
      Header: "Status",
      accessor: "status",
      placeholder: "",
      required: true,
      primary: true,
      columnWidth: 4,
      editable: false,
      editType: null,
      validate: (value) => { return true },
    },
    {
      Header: "Connection Date",
      accessor: "connectionDate",
      placeholder: "",
      required: true,
      primary: true,
      columnWidth: 4,
      editable: false,
      editType: null,
      validate: (value) => { return true },
    },
    {
      Header: "Company Type",
      accessor: "companyType",
      placeholder: "",
      required: true,
      primary: true,
      columnWidth: 4,
      editable: false,
      editType: null,
      validate: (value) => { return true },
    },
    // Keep this comment here, will uncomment when teammate profiles are implemented
    // TODO: implement teammate profiles and uncomment this
    // {
    //   Header: "Team",
    //   accessor: "team",
    //   placeholder: "",
    //   required: true,
    //   primary: true,
    //   columnWidth: 3,
    //   editable: false,
    //   editType: null,
    //   validate: (value) => { return true },
    // }
  ]

  // when the search value updates, request the company search using the debounced function
  useEffect(() => {
    let searchCompanyType = userCompanyType === 'installer' ? 'distributor' : 'installer'
    var idsToExclude = connections.map(connection => connection.id)
    debouncedRequestCompanySearch(searchValue, searchCompanyType, setSearchResults, connectionRequestCompanies, idsToExclude)
  }, [searchValue])

  // on page load, get the current user
  useEffect(() => {
    UserManager.getUser()
      .then((user) => {
        setCurrentUser(user.userData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // when the user updates/on page load, fetch the connections
  useEffect(() => {
    if (currentUser.company) {
      getConnections()
    }
  }, [currentUser])


  async function getConnections() {
    let sharedForecasts = []
    // if userCompanyType is distibutor, get forecasts for connected installers
    if (userCompanyType === 'distributor') {
      try {
        const response = await UserManager.makeAuthenticatedRequest(
          "/api/forecast/get-shared-forecasts",
          "GET"
        )

        if (response.data.status === "ok") {
          sharedForecasts = response.data.forecasts
        }
        else {
          console.log("Error getting shared forecasts")
        }
      } catch (error) {
        console.log(error)
      }
    }

    // fetch connections
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list",
      "GET"
    ).then((res) => {
      let newConnections = []
      res.data.connections.forEach(connection => {
        // switch case on connection status, possible values are 'pending_request', 'incoming_request', 'active', 'rejected'
        var connectionStatus = ''
        var menuOptions = []
        var inlineButtons = []
        var statusClassName = ''
        switch (connection.status) {
          case 'pending_request':
            menuOptions = [
              { label: 'Cancel Request', value: 'deleteRequest', onSelected: (item) => { deleteRequest(item) } },
            ]
            connectionStatus = 'Pending Request'
            statusClassName = 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-yellow-700 ring-1 ring-inset ring-yellow-200'
            break
          case 'pending_create_company':
            menuOptions = [
              { label: 'Cancel Request', value: 'deleteRequest', onSelected: (item) => { deleteRequest(item) } },
            ]
            connectionStatus = 'Pending Request'
            statusClassName = 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-yellow-700 ring-1 ring-inset ring-yellow-200'
            break
          case 'incoming_request':
            inlineButtons = [
              { label: 'Accept', value: 'acceptRequest', variant: 'primary', onClick: (item) => { acceptRequest(item) } },
              { label: 'Reject', value: 'rejectRequest', variant: 'secondary', onClick: (item) => { rejectRequest(item) } },
            ]
            connectionStatus = 'Incoming Request'
            statusClassName = 'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-blue-700 ring-1 ring-inset ring-blue-200'
            break
          case 'active':
            menuOptions = [
              { label: 'Disconnect', value: 'disconnect', onSelected: (item) => { confirmDisconnect(item) } },
            ]
            // if sharedForecaasts has a forecast with installer === connection.id, add inlineButtons
            if (sharedForecasts.some(forecast => forecast.installer === connection.id)) {
              inlineButtons = [
                {
                  label: 'View Forecast',
                  value: 'viewForecast',
                  variant: 'secondary',
                  onClick: (item) => { viewForecast(item) }
                }
              ]
            }

            // Keep this comment here, will uncomment when connection pages are implemented
            // TODO: implement connection pages and uncomment this
            // inlineButtons = [
            //   {
            //     label: 'Go To ' + connection.companyType.charAt(0).toUpperCase() + connection.companyType.slice(1),
            //     value: 'goToConnection',
            //     variant: 'secondary',
            //     onClick: (item) => { goToConnection(item) }
            //   },
            // ]
            connectionStatus = 'Active'
            statusClassName = 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-green-700 ring-1 ring-inset ring-green-200'
            break
          case 'rejected':
            menuOptions = [
              { label: 'Clear', value: 'deleteRequest', onSelected: (item) => { deleteRequest(item) } },
            ]
            connectionStatus = 'Request Rejected'
            statusClassName = 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-red-700 ring-1 ring-inset ring-red-200'
            break
          default:
            connectionStatus = connection.status
        }

        newConnections.push(structureRowData({
          id: connection.id,
          name: connection.name,
          status: connectionStatus,
          statusClassName: statusClassName,
          connectionDate: connection.connectionDate ? formatDate(connection.connectionDate) : '--',
          companyType: connection.companyType.charAt(0).toUpperCase() + connection.companyType.slice(1),
          team: connection.team ? connection.team : '--',
          rowStatus: 'readonly',
          menuOptions: menuOptions,
          inlineButtons: currentUser.company?.role === 'admin' ? inlineButtons : []
        }))
      })
      setConnections(sortConnections(newConnections))
    })
  }


  function formatDate(date) {
    var d = new Date(date)
    return d.toLocaleDateString()
  }


  /**
  * Helper function to structure row data for the InputTable
  *
  * @param {string} id - the id of the connection
  * @param {string} name - the company name of the connection
  * @param {string} status - the status of the connection
  * @param {string} statusClassName - any styling classes to apply to the status cell
  * @param {string} connectionDate - the date the connection was accepted
  * @param {string} companyType - the type of company the connection is (installer or distributor)
  * @param {array} team - team members of the connection
  * @param {string} rowStatus - the status of the row (editing, new, or readonly)
  * @param {array} menuOptions - the menu options for the row
  * @param {array} inlineButtons - the inline buttons for the row
  */
  function structureRowData({
    id,
    name,
    status,
    statusClassName,
    connectionDate,
    companyType,
    team,
    rowStatus,
    menuOptions,
    inlineButtons
  }) {
    return {
      id: id,
      name: {
        value: name,
        className: ''
      },
      status: {
        value: status,
        className: statusClassName ? statusClassName : ''
      },
      connectionDate: {
        value: connectionDate,
        className: ''
      },
      companyType: {
        value: companyType,
        className: ''
      },
      team: {
        value: team,
        className: ''
      },
      rowStatus: rowStatus,
      menuOptions: menuOptions,
      inlineButtons: inlineButtons
    }
  }

  /**
   * Function to go to the connection page
   * TODO: implement connection pages 
   */
  function goToConnection(data) {
    console.warn("goToConnection not implemented")
  }

  /**
   * Function to navigate to the view forecast page
  */
  function viewForecast(data) {
    // navigate to the view forecast page
    navigate('/app/installer-forecasts/job?installerId=' + data.id)
  }


  async function sendConnectionRequest(companyId) {
    const res = await UserManager.makeAuthenticatedRequest(
      '/api/company/connect-request',
      'POST',
      {
        companyId: companyId
      }
    )
    if (res.data.status === "ok") {
      setError('')
      setConnections(prevConnections => {
        return prevConnections = [
          ...prevConnections,
          structureRowData({
            id: res.data.newConnectionRequest._id,
            name: res.data.newConnectionRequest.name,
            status: 'Pending Request',
            statusClassName: 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-yellow-700 ring-1 ring-inset ring-yellow-200',
            connectionDate: '--',
            companyType: userCompanyType === 'installer' ? 'Distributor' : 'Installer',
            team: '--',
            rowStatus: 'readonly',
            menuOptions: [
              { label: 'Cancel Request', value: 'deleteRequest', onSelected: (item) => { deleteRequest(item) } },
            ]
          })
        ]
      })
    } else {
      console.log(res.data)
    }
  }


  async function sendCreateCompanyInvite(companyType, companyName, email) {
    const res = await UserManager.makeAuthenticatedRequest(
      '/api/company/create-company-invite',
      'POST',
      {
        companyType: companyType,
        companyName: companyName,
        email: email
      }
    )
    if (res.data.status === "ok") {
      setError('')
      setConnections(prevConnections => {
        return [
          ...prevConnections,
          structureRowData({
            id: email,
            name: companyName,
            status: 'Pending Invite',
            statusClassName: 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-yellow-700 ring-1 ring-inset ring-yellow-200',
            connectionDate: '--',
            companyType: companyType.charAt(0).toUpperCase() + companyType.slice(1),
            team: '--',
            rowStatus: 'readonly',
            menuOptions: [
              { label: 'Delete', value: 'deleteRequest', onSelected: (item) => { deleteRequest(item) } },
            ]
          })
        ]
      })
    } else {
      console.log(res.data)
    }
  }


  function acceptRequest(data) {
    const promise = UserManager.makeAuthenticatedRequest(
      "/api/company/connect-accept",
      "POST",
      {
        companyId: data.id
      }
    ).then((res) => {
      if (res.data?.status === 'ok') {
        setError('')
        // convert the row to an active connection with Go To button and Disconnect menu option
        setConnections(prevConnections => {
          return sortConnections(prevConnections.map(connection => {
            if (connection.id === data.id) {
              return {
                ...connection,
                // get the connection date from the res.data.connections array
                connectionDate: { value: formatDate(res.data.connections.find(c => c.id === data.id).connectionDate), className: '' },
                status: { value: 'Active', className: 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-green-700 ring-1 ring-inset ring-green-200' },
                // Keep this comment here, will uncomment when connection pages are implemented
                // TODO: implement connection pages and uncomment this
                // inlineButtons: [
                //   {
                //     label: 'Go To ' + connection.companyType.value.charAt(0).toUpperCase() + connection.companyType.value.slice(1),
                //     value: 'goToConnection',
                //     variant: 'secondary',
                //     onClick: (item) => { goToConnection(item) }
                //   },
                // ],
                inlineButtons: [],
                menuOptions: [
                  { label: 'Disconnect', value: 'disconnect', onSelected: (item) => { confirmDisconnect(item) } },
                ]
              }
            }
            return connection
          }))
        })
      }
      else {
        setError('Error accepting request')
      }
    })
  }


  function rejectRequest(data) {
    const promise = UserManager.makeAuthenticatedRequest(
      "/api/company/connect-reject",
      "POST",
      {
        companyId: data.id
      }
    ).then((res) => {
      if (res.data?.status === 'ok') {
        setError('')
        // remove the connection from the table
        setConnections(prevConnections => prevConnections.filter(connection => connection.id !== data.id))
      }
      else {
        setError('Error rejecting request')
      }
    })
  }


  function confirmDisconnect(data) {
    // set connectionToDisconnect to the connection
    setConnectionToDisconnect(data)

    // show the confirm disconnection modal
    setShowConfirmDisconnectionModal(true)
  }


  function disconnect(data) {
    // disconnect from the connection
    const promise = UserManager.makeAuthenticatedRequest(
      "/api/company/disconnect",
      "POST",
      {
        companyId: data.id
      }
    ).then((res) => {
      if (res.data?.status === 'ok') {
        setError('')
        // remove the connection from the table
        setConnections(prevConnections => prevConnections.filter(connection => connection.id !== data.id))
      }
      else {
        setError('Error disconnecting')
      }
    })
  }


  function deleteRequest(data) {
    // var removeConnection = false;
    const promise = UserManager.makeAuthenticatedRequest(
      "/api/company/connect-cancel",
      "POST",
      {
        companyId: data.id,
      }
    ).then((res) => {
      if (res.data?.status === 'ok') {
        setError('')
        setConnections(prevConnections => prevConnections.filter(connection => connection.id !== data.id))
      }
      else {
        setError('Error deleting request')
      }
    })
  }


  function handleAddConnection() {
    if (inputCompanyName !== '' && inputContactName !== '' && inputEmail !== '') {
      // if an invite with the same email already exists, do not add it
      if (invites.some((item) => item.email === inputEmail)) {
        setError('A matching invite is already in the list.')
        return
      }
      // if an invite with the same company name already exists, do not add it
      if (invites.some((item) => item.companyName === inputCompanyName)) {
        setError('A matching invite is already in the list.')
        return
      }
      // if the email is not valid, do not add it
      if (!validateEmail(inputEmail)) {
        setError('Invalid email address.')
        return
      }
      setInvites([...invites, {
        companyName: inputCompanyName,
        name: inputContactName,
        email: inputEmail
      }])
      setInputCompanyName('')
      setInputContactName('')
      setInputEmail('')
      setError('')
    }
  }


  var requestAndInvite = (
    <div className="flex flex-col items-center justify-start w-1/2">
      <div className="flex flex-col items-center justify-start mt-10">
        <div className="text-xl font-semibold leading-7 text-center text-gray-900 align-middle">
          {userCompanyType === 'installer' ? 'Connect with Existing Distributors' : 'Connect with Existing Installers'}
        </div>
        <div className="text-base font-normal leading-6 text-center text-gray-500 align-middle">
          {userCompanyType === 'installer' ? 'Find a distributor to start collaborating' : 'Find an installer to start collaborating'}
        </div>
        <div className="w-full mt-6">
          <ComboboxSearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchResults={searchResults}
            onSelectItem={(company) => {
              addCompanySearchResult(company, searchResults, setSearchResults, connectionRequestCompanies, setConnectionRequestCompanies)
            }}
            placeholder={
              userCompanyType === 'installer'
                ? 'Search Distributors'
                : 'Search Installers'
            }
          />
        </div>
      </div>
      <div className="w-3/4 border-b border-gray-300 my-7" />
      <div className='flex flex-col items-center justify-start'>
        <div className='text-xl font-semibold leading-7 text-left text-gray-900 align-middle'>
          {
            userCompanyType === 'installer' ?
              'Send an Invite to Onboard New Distributor'
              : 'Send an Invite to Onboard New Installer'
          }
        </div>
        <div className='text-base font-normal leading-6 text-center text-gray-500 align-middle'>
          {
            userCompanyType === 'installer' ?
              'Invite distributors to start collaborating'
              : 'Invite installers to start collaborating'
          }
        </div>
      </div>
      <div className='flex flex-col gap-4 mt-4 w-72'>
        <Input
          label='Company Name'
          name='companyName'
          type='text'
          placeholder='Company Name'
          required
          value={inputCompanyName}
          onChange={(value) => {
            setInputCompanyName(value)
          }}
        />
        <div className='w-full border-b border-gray-300' />
        <Input
          label='Contact Name'
          name='contactName'
          type='text'
          placeholder='John Jensen'
          required
          value={inputContactName}
          onChange={(value) => {
            setInputContactName(value)
          }}
        />
        <Input
          label='Email Address'
          name='email'
          type='email'
          placeholder='example@email.com'
          required
          value={inputEmail}
          onChange={(value) => {
            setInputEmail(value)
          }}
        />
        {error ? <div className='w-full text-sm font-normal leading-6 text-center text-red-500 align-middle text-pretty'>{error}</div> : null}
        <Button
          className='w-full mt-3'
          variant='primary-green'
          disabled={inputCompanyName === '' || inputContactName === '' || inputEmail === ''}
          onClick={() => {
            handleAddConnection()
          }}
        >
          {
            userCompanyType === 'installer' ?
              'Add Distributor'
              : 'Add Installer'
          }
        </Button>
      </div>
    </div>
  )

  var connectionRequestTable = (
    <div className="w-full pl-4 sm:pl-6 lg:pl-12">
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full border-b border-gray-300 divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold leading-5 text-gray-900 align-middle">
                    New Connections
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {/* 
                  TODO: for some reason when you backspace the last character in company ComboBoxSearchBar  
                  connectionRequestCompanies.map still gets called and throws an error because connectionRequestCompany is undefined
                */}
                {connectionRequestCompanies?.map((connectionRequestCompany) => (
                  <tr key={connectionRequestCompany?._id}>
                    <td className="px-3 py-2.5 text-sm font-medium leading-5 text-gray-900 align-middle whitespace-nowrap">
                      {connectionRequestCompany?.name}
                    </td>
                    <td className="relative py-2.5 px-2.5 text-sm font-medium text-right border border-gray-300 whitespace-nowrap w-[44px] h-[44px]">
                      <XMarkIcon
                        className='w-5 h-5 cursor-pointer text-primary-rose hover:text-primary-rose-400'
                        onClick={() => {
                          setConnectionRequestCompanies(connectionRequestCompanies.filter((item) => item._id !== connectionRequestCompany?._id))
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )

  var inviteCompaniesTable = (
    <div className="w-full pl-4 sm:pl-6 lg:pl-12">
      <div className="flow-root mt-3">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full border-b border-gray-300 divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold leading-5 text-gray-900 align-middle">
                    New Invites
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {invites.map((invite) => (
                  <tr key={invite.email}>
                    <td className="px-3 py-2.5 text-sm font-medium leading-5 text-gray-900 align-middle whitespace-nowrap">
                      {invite.companyName}
                    </td>
                    <td className="px-3 py-2.5 text-sm font-normal leading-5 text-gray-500 align-middle whitespace-nowrap">
                      {invite.name}
                    </td>
                    <td className="px-3 py-2.5 text-sm font-normal leading-5 text-gray-500 align-middle whitespace-nowrap">
                      {invite.email}
                    </td>
                    <td className="relative py-2.5 px-2.5 text-sm font-medium text-right border border-gray-300 whitespace-nowrap w-[44px] h-[44px]">
                      <XMarkIcon
                        className='w-5 h-5 cursor-pointer text-primary-rose hover:text-primary-rose-400'
                        onClick={() => {
                          setInvites(invites.filter((item) => item.email !== invite.email))
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )

  var displayInvites = (
    // set this div to be 200 pixels less than the height of the parent div
    <div
      style={{ height: 'calc(84vh - 200px)' }}
      className='flex flex-col items-center justify-start w-1/2 mt-12 overflow-y-auto overflow-x-clip'
    >
      {connectionRequestTable}
      {inviteCompaniesTable}
    </div>
  )


  return (
    <div>
      {/* Confirm Disconnection Modal */}
      <Modal
        open={showConfirmDisconnectionModal}
        setOpen={setShowConfirmDisconnectionModal}
      >
        <div className='flex flex-col items-center justify-center gap-2 text-balance'>
          <div className="text-xl font-semibold leading-7 text-center align-middle">
            Are you sure you want to disconnect from {connectionToDisconnect.name?.value}?
          </div>
          <div className="flex flex-row justify-center gap-4 mt-4">
            <Button
              variant='primary'
              onClick={() => {
                // disconnect from the connection
                disconnect(connectionToDisconnect)
                setShowConfirmDisconnectionModal(false)
                setConnectionToDisconnect({})
              }}
            >
              Disconnect
            </Button>
            <Button
              variant='secondary'
              onClick={() => {
                setShowConfirmDisconnectionModal(false)
                setConnectionToDisconnect({})
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* Add Connections Modal */}
      <Modal
        open={showAddConnectionsModal}
        setOpen={() => {
          setSearchValue('')
          setConnectionRequestCompanies([])
          setInvites([])
          setShowAddConnectionsModal(false)
        }}
        wide
      >
        <div className='flex flex-col items-center justify-start w-[80vw] h-[84vh]'>
          <div className='self-end'>
            <XMarkIcon
              className='w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-300'
              onClick={() => {
                setSearchValue('')
                setConnectionRequestCompanies([])
                setInvites([])
                setShowAddConnectionsModal(false)
              }}
            />
          </div>
          <div className='flex flex-row justify-around w-full px-6'>
            <div className='flex flex-row justify-around w-full px-6'>
              {requestAndInvite}
              {displayInvites}
            </div>
          </div>
          <div className='flex flex-col justify-center grow'>
            <div className='flex flex-row justify-center w-full gap-2 mt-4'>
              <Button
                variant='primary'
                onClick={async () => {
                  // sendInvites(connectionRequestCompanies, invites, currentUser)
                  // add the new connections to the connections table
                  let newConnections = []
                  for (let company of connectionRequestCompanies) {
                    await sendConnectionRequest(company._id)
                  }

                  // send the invites
                  for (let invite of invites) {
                    let inviteCompanyType = userCompanyType === 'installer' ? 'distributor' : 'installer'
                    await sendCreateCompanyInvite(inviteCompanyType, invite.companyName, invite.email)
                  }

                  // clear the connection request companies and invites
                  setSearchValue('')
                  setConnectionRequestCompanies([])
                  setInvites([])
                  setShowAddConnectionsModal(false)
                }}
                // disabled={emailsToAdd.length === 0}
                disabled={invites.length === 0 && connectionRequestCompanies.length === 0}
              >
                Send Requests and Invites
              </Button>
              <Button
                variant='secondary'
                onClick={() => {
                  setSearchValue('')
                  setConnectionRequestCompanies([])
                  setInvites([])
                  setShowAddConnectionsModal(false)
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <div className='flex flex-col gap-2 mt-4'>
        <Card title="Manage Connections">
          <div className='flex flex-col'>
            <div className='flex flex-row items-center justify-between'>
              {/* 
                keep this here, will uncomment when searching is implemented
                TODO: implement searching and uncomment this
              */}
              {/* <div className='flex flex-row items-center gap-3'>
                <div className='w-64'>
                  <SearchBar />
                </div>
              </div> */}
              {/* keep this here, will uncomment when filtering and sorting is implemented */}
              {/* <div className='flex flex-row items-center gap-4'>
                  <FilterSort />
              </div> */}
            </div>
            <div>
              <InputTable
                tableData={connections}
                setTableData={setConnections}
                columns={tableColumns}
                addButtonText={"Add Connections"}
                outerAddButton={currentUser.company?.role === 'admin'}
                innerAddButton={currentUser.company?.role === 'admin'}
                height={'calc(100vh - 195px)'}
                editColumnWidth={4}
                editableRows={false}
                ellipsisDropdown={currentUser.company?.role === 'admin'}
                useOnBlur={false}
                addButtonClicked={() => {
                  setError("")
                  setShowAddConnectionsModal(true)
                }}
                error={error}
                setError={setError}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
