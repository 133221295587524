import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function weekDescr(from, to) {
  if (from === null || to === null) {
    return "";
  }

  if (from === to) {
    if (from === 0) return "Next Week";
    else return from + " Wks out";
  } else {
    return from + " - " + to + " Wks out";
  }
}

export default function BucketTimelineDiagram({
  values,
  width = 90,
  connHeight = 6,
  connWidth = 110,
  offset = { x: 70, y: 30 },
  borderRadius = 6,
  selectedIndex = -1,
  funnelHeight = 100,
  weekGap = 10,
  onSelect,
  disabled = false,
  fill = "fill-gray-200 dark:fill-gray-800",
}) {
  if (!values || !values.length) {
    return null;
  }

  var X_MARGIN = 10; // Currently just moves bottoms of funnels inwards to line up with bullet chart below on forecast page

  var heights = values.map((v) => v.height);
  var labels = values.map((v) => v.label);

  var strokeWidth = 3;

  // Adjust widths to be at least as wide as the connector between boxes
  var adjHeights = heights.map((w) => w + connHeight + 2 * borderRadius + 2);

  var maxHeight = adjHeights.reduce((a, b) => Math.max(a, b)) + connHeight;

  var totalWidth =
    heights.length * width + (heights.length - 1) * connWidth + offset.x * 2;
  var totalHeight = maxHeight + offset.y * 2 + funnelHeight;

  totalWidth = 1030;
  // TODO: remove this hardcoding. This is currently the desired width of the svg element
  // This works well though. 1030 is the width of 20 weeks of cells in the forecast input table.
  // Making this dynamic might be a bit tricky, but it's not a priority right now.

  // Rect X coordinates
  // Go from center to the left/right depending on the index and number of buckets
  function rectX(i) {
    var centerX = totalWidth / 2.0;

    return (
      centerX + // Center of the diagram
      (i - (heights.length - 1) / 2.0) * width + // Move by widths
      (i - (heights.length - 1) / 2.0) * connWidth // Move by spacing between boxes
    );
  }

  function generateArrows() {
    // Generate triangles pointing to the left between each bucket
    var center = maxHeight / 2.0;
    var triangleHeight = 36;
    var triangleWidth = 30;

    return (
      <>
        {adjHeights.map((h, i) =>
          i === adjHeights.length - 1 ? null : (
            <path
              key={i}
              className={classNames("fill-gray-200 dark:fill-gray-800")}
              d={
                "M" +
                (rectX(i) -
                  triangleWidth * 0.5 +
                  width * 0.5 +
                  connWidth * 0.5) + // x
                // (i * width +
                //   (i + 0.5) * connWidth +
                //   offset.x +
                //   width -
                //   triangleWidth * 0.5) + // x
                " " +
                (center + offset.y) + // y
                " l" +
                triangleWidth +
                " " +
                -triangleHeight / 2.0 +
                " v" +
                triangleHeight +
                " z"
              }
            />
          )
        )}
      </>
    );
  }

  function generateRects(classes = "", interactive = false) {
    var centerY = maxHeight / 2.0;

    // Radii of upper corners between rect and horizontal connector
    var upperInnerRad = (i) => (
      <path
        className={fill}
        d={
          "M" +
          (rectX(i) + width * 0.5) +
          // (i * width + i * connWidth + offset.x + width) +
          " " +
          (centerY - connHeight / 2.0 + offset.y - borderRadius) +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 0 " +
          borderRadius +
          " " +
          borderRadius +
          " v" +
          connHeight +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 0 " +
          -borderRadius +
          " " +
          borderRadius +
          " z"
        }
      />
    );

    // Radii of lower corners between rect and horizontal connector
    var lowerInnerRad = (i) => (
      <path
        className={fill}
        d={
          "M" +
          (rectX(i) - width * 0.5) +
          // (i * width + i * connWidth + offset.x) +
          " " +
          (centerY - connHeight / 2.0 + offset.y - borderRadius) +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 1 " +
          -borderRadius +
          " " +
          borderRadius +
          " v" +
          connHeight +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 1 " +
          borderRadius +
          " " +
          borderRadius +
          " z"
        }
      />
    );

    return (
      <>
        {/* Rects */}
        {adjHeights.map((h, i) => {

          // Get count of jobs in this stage
          let jobCount = values[i].activeInStage;
          
          //  If interactive and job count is 0, don't show the stage
          if (interactive && jobCount === 0) {
            return null;
          }


          return i === selectedIndex ? null : (
            <React.Fragment key={i}>
              <rect
                className={classes}
                x={rectX(i) - width / 2.0}
                // x={
                //   i * width +
                //   i * connWidth +
                //   offset.x -
                //   (i === selectedIndex ? strokeWidth : 0)
                // }
                y={
                  centerY -
                  h / 2.0 +
                  offset.y -
                  (i === selectedIndex ? strokeWidth : 0)
                }
                height={h + (i === selectedIndex ? strokeWidth * 2 : 0)}
                width={width + (i === selectedIndex ? strokeWidth * 2 : 0)}
                rx={borderRadius}
                onClick={() => interactive && onSelect(i)}
              />

              {i === adjHeights.length - 1 ? null : upperInnerRad(i)}
              {i === 0 ? null : lowerInnerRad(i)}
            </React.Fragment>
          );
        })}

        {/* Horizontal Connector */}
        {!interactive && (
          <rect
            className={fill}
            x={rectX(0) - width / 2.0}
            y={maxHeight / 2.0 - connHeight / 2.0 + offset.y}
            height={connHeight}
            width={heights.length * width + (heights.length - 1) * connWidth}
          />
        )}
      </>
    );
  }

  function generateSelectedRect() {
    if (selectedIndex === -1) {
      return null;
    }
    var w = adjHeights[selectedIndex];
    var center = maxHeight / 2.0;

    return (
      <g className="drop-shadow-lg">
        <rect
          x={rectX(selectedIndex) - width / 2.0 - strokeWidth} // minus strokeWidth to account for the stroke width. minus width/2.0 to account for the fact that the rect is centered
          // x={
          //   selectedIndex * width +
          //   selectedIndex * connWidth +
          //   offset.x -
          //   strokeWidth
          // }
          y={center - w / 2.0 + offset.y - strokeWidth}
          height={w + strokeWidth * 2}
          width={width + strokeWidth * 2}
          rx={borderRadius + strokeWidth}
          className={classNames("fill-primary-rose cursor-pointer")}
          onClick={() => onSelect(selectedIndex)}
        />
      </g>
    );
  }

  function generateLabels() {
    var center = maxHeight / 2.0;
    return (
      <>
        {labels.map((l, i) => (
          <React.Fragment key={i}>
            {/* Title */}
            <text
              x={rectX(i)}
              // x={i * width + i * connWidth + offset.x + width / 2.0}
              y={8}
              textAnchor="middle"
              alignmentBaseline="central"
              dominantBaseline="central"
              className={classNames(
                "uppercase text-base font-semibold fill-gray-900 dark:fill-white"
              )}
            >
              {values[i].title}
            </text>

            {/* Label */}
            <text
              x={rectX(i)}
              // x={i * width + i * connWidth + offset.x + width / 2.0}
              y={center + offset.y + (i === selectedIndex ? -10 : 0)}
              textAnchor="middle"
              alignmentBaseline="central"
              dominantBaseline="central"
              className={classNames(
                "text-base font-semibold pointer-events-none",
                i === selectedIndex ? "fill-white" : "fill-gray-600 dark:fill-gray-200"
              )}
            >
              {l}
            </text>

            {/* Selected Label */}
            {i === selectedIndex ? (
              <text
                x={rectX(i)}
                // x={i * width + i * connWidth + offset.x + width / 2.0}
                y={center + offset.y + 10}
                textAnchor="middle"
                alignmentBaseline="central"
                dominantBaseline="central"
                className={classNames(
                  "text-sm font-medium pointer-events-none fill-white"
                )}
              >
                {values[selectedIndex].selectedLabel}
              </text>
            ) : null}

            {/* Funnel Label */}
            <text
              x={rectX(i)}
              // x={i * width + i * connWidth + offset.x + width / 2.0}
              y={maxHeight + offset.y * 2 - 16}
              textAnchor="middle"
              alignmentBaseline="central"
              dominantBaseline="central"
              className={classNames("text-sm font-medium fill-gray-400")}
            >
              {weekDescr(values[i].fromWeek, values[i].toWeek)}
            </text>
          </React.Fragment>
        ))}
      </>
    );
  }

  function generateFunnels(
    classes = "fill-gray-700/10 dark:fill-gray-900/60",
    selectedClasses = "fill-primary-rose order-last"
  ) {
    var weekSize = (totalWidth - X_MARGIN * 2 - weekGap * 19.0) / 20.0;

    function funnel(value, i) {
      var tl_x = rectX(i) - width / 2.0; // minus width/2.0 to account for the fact that the rect is centered
      // var tl_x = i * width + i * connWidth + offset.x;
      var tr_x = tl_x + width;
      var t_y = maxHeight + offset.y * 2;
      var bl_x = value.fromWeek * (weekSize + weekGap) + X_MARGIN;
      var br_x = value.toWeek * (weekSize + weekGap) + weekSize + X_MARGIN;
      var b_y = t_y + funnelHeight - borderRadius;

      var middle_y = (t_y + b_y) / 2.0;

      return (
        <React.Fragment key={i}>
          {/* Top Bar for Rounded Corners */}
          <path
            className={classNames(
              i === selectedIndex ? selectedClasses : classes
            )}
            d={
              "M" +
              tl_x +
              " " +
              t_y +
              " A" +
              borderRadius +
              " " +
              borderRadius +
              " 0 0 1 " +
              (tl_x + borderRadius) +
              " " +
              (t_y - borderRadius) +
              " L" +
              (tr_x - borderRadius) +
              " " +
              (t_y - borderRadius) +
              " A" +
              borderRadius +
              " " +
              borderRadius +
              " 0 0 1 " +
              tr_x +
              " " +
              t_y +
              " z"
            }
          />

          {/* Main Funnel */}
          <path
            className={classNames(
              i === selectedIndex ? selectedClasses : classes
            )}
            d={
              // Top Left Corner
              "M" +
              tl_x +
              " " +
              t_y +
              // Top Line to Top Right Corner
              " L" +
              tr_x +
              " " +
              t_y +
              // Curve to Bottom Right Corner
              " C" +
              tr_x +
              " " +
              middle_y +
              " " +
              br_x +
              " " +
              middle_y +
              " " +
              br_x +
              " " +
              b_y +
              // Bottom Line to Bottom Left Corner
              " L" +
              bl_x +
              " " +
              b_y +
              // Curve to Top Left Corner
              " C" +
              bl_x +
              " " +
              middle_y +
              " " +
              tl_x +
              " " +
              middle_y +
              " " +
              tl_x +
              " " +
              t_y +
              " z"
            }
          />

          {/* Bottom Bar for Rounded Corners */}
          <path
            className={classNames(
              i === selectedIndex ? selectedClasses : classes
            )}
            d={
              "M" +
              bl_x +
              " " +
              b_y +
              " A" +
              borderRadius +
              " " +
              borderRadius +
              " 0 0 0 " +
              (bl_x + borderRadius) +
              " " +
              (b_y + borderRadius) +
              " L" +
              (br_x - borderRadius) +
              " " +
              (b_y + borderRadius) +
              " A" +
              borderRadius +
              " " +
              borderRadius +
              " 0 0 0 " +
              br_x +
              " " +
              b_y +
              " z"
            }
          />
        </React.Fragment>
      );
    }

    return (
      <>
        {values.map((value, i) => {
          // Calculate the coordinates of the funnel

          // Skip selected to do it last
          if (i === selectedIndex) {
            return;
          }

          // Skip if null weeks
          if (value.fromWeek === null || value.toWeek === null) {
            return;
          }

          return funnel(value, i);
        })}
        {
          // Do the selected one last so it's on top
          selectedIndex !== -1 &&
            values[selectedIndex].fromWeek !== null &&
            values[selectedIndex].toWeek !== null &&
            funnel(values[selectedIndex], selectedIndex)
        }
      </>
    );
  }

  return (
    <svg width={totalWidth} height={totalHeight} strokeWidth={strokeWidth}>
      <defs>
        <clipPath id="clip">{generateRects()}</clipPath>
      </defs>
      <g>
        {/* Main Shape */}
        {generateRects(classNames(fill))}

        {/* Arrows */}
        {generateArrows()}

        {/* Funnels */}
        {generateFunnels()}

        {/* Hover Shapes */}
        {!disabled &&
          generateRects(
            classNames(
              "opacity-0 hover:opacity-20 hover:cursor-pointer fill-primary-rose"
            ),
            true
          )}
        {generateSelectedRect()}
        {generateLabels()}
      </g>
    </svg>
  );
}
