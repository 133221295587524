import React from "react";

export default function DataConnectorsPage() {
  return (
    <div className="flex justify-center items-center">
      <div className="grow relative">
        <div className="absolute inset-0 flex justify-center items-center z-[-1] text-gray-500">Loading</div>
        <iframe title="TOA Integration Documentation" className="w-full h-[calc(100vh-200px)]" src="https://toa.readme.io" />
      </div>
    </div>
  );
}
