import React, { useEffect, useState } from "react";

type SetValue<T> = (value: T) => void;

/**
 * Custom hook to store and retrieve values from local storage.
 * Manages type checking and parsing of values so no need to worry about JSON parsing.
 * 
 * 
 * Example usage:
 * ```javascript
 * const [storedValue, setStoredValue] = useLocalStorage<boolean>("isSuperCool", false);
 * 
 * function handleClick() {
 *  setStoredValue(true);
 * }
 * ```
 *
 * @param key - Key to access within local storage
 * @param initialValue - Initial value to store in local storage if the key does not exist. Also used if parsing the stored value fails.
 * @returns - Stored value and a function to update the stored value
 */
export default function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T, SetValue<T>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = localStorage.getItem(key);

    // If the item is not null, parse it, otherwise return the initial value
    // If there is an error parsing the item, return the initial value
    try {
      var value = item ? JSON.parse(item) : initialValue;
    } catch (error) {
      value = initialValue;
    }

    return value;
  });

  /**
   * Updates the hook's state and local storage with the new value.
   * Null values will remove the key from local storage.
   * 
   * @param value - value to store in local storage
   */
  const setValue: SetValue<T> = (value: T) => {
    setStoredValue(value);

    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  // Sync with localStorage
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key) {
        setStoredValue(JSON.parse(e.newValue || "null"));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  return [storedValue, setValue];
}
