import { Popover } from "@headlessui/react";
import {
  ChatBubbleLeftRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useState, useEffect } from "react";
import { Ellipsis } from "react-bootstrap/esm/PageItem";
import Button from "src/components/input/Button";
import { ForecastType } from "src/pages/Forecast/history/ForecastHistoryPage";
import MiniMaterialPlanning from "./MiniMaterialPlanning.tsx";
import ForecastHistoryContext, {
  ForecastHistoryContextType,
  MarketType,
} from "src/contexts/forecast/history/ForecastHistoryContext.tsx";
import moment from "moment";
import classNames from "src/tools/classNames.js";
import BulletChart from "src/components/BulletChart.js";
import IconDropdown from "src/components/input/IconDropdown.js";
import Modal from "src/components/Modal.js";
import MultiSelectListbox from "src/components/input/MultiSelectListbox.tsx";
import Dropdown from "src/components/input/Dropdown.js";
import InputTable from "src/components/input/InputTable.js";
import UserManager from "src/tools/UserManager.js";

import ForecastClass from "src/pages/Forecast/classes/Forecast.js";

import pluralize from "pluralize";
import { Link } from "react-router-dom";

type Props = {
  forecast: ForecastType;
  onDeleteRow: (id: string) => void;
  distributors: any;
};

/**
 * A row in the forecast history list.
 * Shows market, week, and some other info.
 * 
 * @param forecast - Forecast to display
 * @param onDeleteRow - Callback when the row is deleted
 * 
 * @returns {JSX.Element} - Forecast history row
 */
export default function ForecastHistoryRow({
  forecast,
  onDeleteRow,
  distributors
}: Props): JSX.Element {
  // Get markets from context
  const { markets } = useContext<ForecastHistoryContextType>(
    ForecastHistoryContext
  );

  // Get market info
  const market: MarketType = markets?.find(
    (market) => market._id === forecast.market
  );
  const marketName: string = market?.name;
  const abbrev: string = calculateAbbreviation(marketName);

  // Date handling
  const weekFormat = moment(forecast.week).utc().format("MMM D, YYYY");
  const submittedFormat = moment(forecast.submittedAt).utc().format("M/D/YY");

  // Format for bullet chart
  // TODO: implement issues.
  // requires remainders and adding values to the array
  const chartData = formatStaticForecastForBulletChart(forecast);

  const sum = (...nums: number[]) => nums.reduce((a, b) => a + b, 0);

  // TODO: adjust this to be jobs in capacity
  const jobsInCapacity = sum(...forecast.inputForecast);
  const jobCountDisplay = pluralize("Job", Math.ceil(jobsInCapacity), true);

  // selected distributor ids for the multi select listbox
  const [selectedDistributorIds, setSelectedDistributorIds] = useState([])

  // shared distributors
  const [sharedDistributors, setSharedDistributors] = useState([])

  // share modal open
  const [shareModalOpen, setShareModalOpen] = useState(false)

  const tableColumns = [
    {
      Header: "Distributor",
      accessor: "distributor",
      placeholder: "",
      required: false,
      primary: true,
      columnWidth: 4,
      editable: false,
      editType: null,
      validate: (value) => { return true },
    },
  ]


  /**
   * on page load, get the forecast.
   */
  useEffect(() => {
    getForecast()
  }, [])


  /**
   * Remove a distributor from the visibility list of the forecast, in the database and in the table.
   * @param distributorId the distributor id to remove
   */
  async function removeDistributor(distributorId) {
    let response = await UserManager.makeAuthenticatedRequest(
      '/api/forecast/unshare-forecast',
      'POST',
      { forecastId: forecast._id, distributorIds: [distributorId] }
    )

    if (response.data.status === 'ok') {
      setSharedDistributors(structureDistributorTableData(response.data.forecast.visibility))
    } else {
      console.error(response.data.message)
    }
  }


  /**
   * 
   * @param forecastId ID of the forecast to get
   */
  function getForecast() {
    UserManager.makeAuthenticatedRequest(
      '/api/forecast/get-visibility?forecastId=' + forecast._id,
      'GET',
    ).then((res) => {
      if (res.data.status === 'ok') {
        console.log(res.data)
        setSharedDistributors(structureDistributorTableData(res.data.forecast.visibility))
      } else {
        console.error(res.data.message)
      }
    })
  }


  /**
   * Share the forecast with the selected distributors.
   */
  function shareExistingForecast() {
    UserManager.makeAuthenticatedRequest(
      '/api/forecast/share-existing-forecast',
      'POST',
      { forecastId: forecast._id, distributorIds: selectedDistributorIds }
    ).then((res) => {
      if (res.data.status === 'ok') {
        setSharedDistributors(structureDistributorTableData(res.data.forecast.visibility))
        setSelectedDistributorIds([])
      } else {
        console.error(res.data.message)
      }
    })
  }


  /**
   * Delete the forecast with the given ID.
   */
  function deleteForecast() {
    UserManager.makeAuthenticatedRequest(
      '/api/forecast/delete',
      'DELETE',
      { forecastId: forecast._id }
    ).then((res) => {
      if (res.data.status === 'ok') {
        onDeleteRow(forecast._id)
      } else {
        console.error(res.data.message)
      }
    })
  }


  /**
   * 
   * @param forecastVisibility  
   * @returns structured data for the distributor table
   */
  function structureDistributorTableData(forecastVisibility) {
    return forecastVisibility.map((distributor) => {
      return {
        id: distributor.id,
        distributor: {
          value: distributor.name,
          className: '',
        },
        rowStatus: 'readonly',
        menuOptions: [
          {
            label: 'Remove',
            value: 'remove',
            onSelected: (item) => {
              removeDistributor(item.id)
            }
          }
        ],
        inlineButtons: [],
      }
    })
  }


  /**
   * Handle distributor selection.
   * @param {string[]} distributorIds
   */
  function handleDistributorSelected(distributorIds) {
    setSelectedDistributorIds(distributorIds)
  }

  /**
   * close the share modal
   */
  function closeShareModal() {
    setShareModalOpen(false)
    setSelectedDistributorIds([])
  }

  /**
   * open the share modal
   */
  function openShareForecastModal() {
    setShareModalOpen(true)
  }


  // Generate distributors options
  var distributorsOptions = distributors.map((d) => {
    // if the distributor is already shared, don't show them in the multi select listbox
    if (sharedDistributors.find((distributor) => distributor.id === d.id)) {
      return null
    }
    return {
      label: d.name,
      value: d.id,
    };
  }).filter((d) => d !== null)

  return (
    <>
      <Modal
        open={shareModalOpen}
        setOpen={closeShareModal}
        wide
      >
        <div className="flex flex-col w-[750px]">
          {/* Content */}
          <div className="flex flex-col divide-y">
            {/* Top Section */}
            <div className="flex flex-col gap-6 pb-6 mx-8">
              <div className="flex flex-col">
                <div className="text-base font-semibold">Share Forecast</div>
                <div className="text-sm text-gray-500">
                  Share this forecast with your connected Distributors.
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-medium">
                  Select Distributors
                </div>
                <div className="flex items-center justify-start gap-2 text-gray-500 dark:text-gray-400">
                  <MultiSelectListbox
                    itemType="Distributor"
                    options={distributorsOptions}
                    selectedOptionsValues={selectedDistributorIds}
                    onChange={(values) => {
                      handleDistributorSelected(values)
                    }}
                  />
                  <Button
                    variant="primary"
                    disabled={selectedDistributorIds.length === 0}
                    onClick={() => {
                      shareExistingForecast()
                    }}
                  >
                    Share Forecast
                  </Button>
                </div>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="">
              <div className='mx-8 mt-6 text-sm font-medium leading-5 text-left align-middle'>
                Distributors with access to this forecast
              </div>
              <div className="overflow-y-auto max-h-[50vh]">
                <InputTable
                  columns={tableColumns}
                  tableData={sharedDistributors}
                  innerAddButton={false}
                  outerAddButton={false}
                  editableRows={false}
                  setTableData={setSharedDistributors}
                />
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="relative flex justify-end gap-3 px-4 py-3 -mx-4 bg-gray-100 rounded-b-lg dark:bg-gray-700 sm:-mx-6 -bottom-4 sm:-bottom-6">
            <Button variant="primary" onClick={closeShareModal}>
              Done
            </Button>
          </div>
        </div>
      </Modal>


      <li className="flex items-stretch gap-6 px-2 py-4">
        {/* Market and Date */}
        <div className="flex items-center gap-3 basis-1/5">
          <div
            className={classNames(
              "hidden lg:flex items-center justify-center w-12 h-12 text-base font-bold text-white bg-gray-300 rounded-full dark:bg-gray-500",
              market ? "" : "animate-pulse"
            )}
          >
            {abbrev}
          </div>
          <div className="flex flex-col">
            {marketName ? (
              <div className="text-base font-semibold leading-6">
                {marketName}
              </div>
            ) : (
              <div className="w-24 h-5 text-base font-semibold leading-6 bg-gray-300 rounded-md animate-pulse"></div>
            )}
            <div className="text-sm font-semibold leading-5 text-primary-green whitespace-nowrap">
              Week of {weekFormat}
            </div>
            {forecast.submittedAt && (
              <div className="text-xs font-semibold leading-5 text-gray-400">
                Submitted {submittedFormat}
              </div>
            )}
          </div>
        </div>

        {/* Mateiral Planning */}
        <div className="pr-3 -mr-3 border-r">
          <MiniMaterialPlanning />
        </div>

        {/* Weeks */}
        <div className="flex flex-col grow">
          <div className="text-sm font-medium leading-5">
            {pluralize(
              "Week",
              Math.round(10 * forecast.weeksOfCapacity) / 10,
              true
            )}{" "}
            of Capacity - {jobCountDisplay} Input
          </div>
          <div className="min-w-[400px]">
            <BulletChart
              height={64}
              data={chartData}
              frontValueDataKey="inputOrAvailable"
              frontValueRedDataKey="remainder"
              backValueDataKey="toaValue"
              backBarStyle={{
                barSize: 13.5,
                radius: [2, 2, 2, 2],
              }}
              frontBarStyle={{
                barSize: 7,
                radius: [1, 1, 1, 1],
              }}
              noAxis
            />
          </div>
        </div>

        {/* Go to Forecast */}
        <div className="flex items-center">
          <Link to={forecast._id}>
            <Button variant="secondary" onClick={() => { }}>
              View
            </Button>
          </Link>
        </div>

        <div className="self-center">
          <IconDropdown
            options={[
              // { label: 'Comment', value: 'comment', onSelected: (item) => { addForecastComment(item) } },
              { label: 'Share', value: 'share', onSelected: (item) => { openShareForecastModal() } },
              { label: 'Delete', value: 'delete', onSelected: (item) => { deleteForecast() } }
            ]}
            onSelected={(item) => {
              if (item.onSelected) {
                item.onSelected()
              }
            }}
          />
        </div>

        {false && (
          <>
            {/* Chat */}
            <div className="items-center hidden gap-4 ml-auto xl:flex">
              {/* PFPs */}
              <div className="flex">
                <ProfilePicture />
                <ProfilePicture />
                <ProfilePicture />
                <ProfilePicture />
              </div>

              {/* Messages */}
              <button
                className="flex items-center gap-2 hover:text-gray-500"
                onClick={() => {
                  console.error("TODO: implement messages");
                }}
              >
                {/* TODO: functionality */}
                <div>
                  <ChatBubbleLeftRightIcon className="w-6 h-6 stroke-2" />
                </div>

                {/* TODO: implement */}
                <div>0</div>
              </button>
            </div>
          </>
        )}
      </li>
    </>
  );
}


/**
 * Icon with a profile picture and a hoverable name.
 */
function ProfilePicture(): JSX.Element {
  return (
    // TODO: implement fully plus dark mode
    <button
      className="relative w-8 h-8 -ml-2 bg-gray-400 dark:bg-gray-600 border-[3px] border-white dark:border-gray-800 rounded-full items-center justify-center flex group"
      onClick={() => {
        console.error("TODO: implement profile picture click");
      }}
    >
      <p className="text-white">JD</p>

      <div className="absolute hidden p-2 bg-white dark:bg-gray-800 border rounded-lg pointer-events-auto whitespace-nowrap group-hover:block bottom-[calc(100%+5px)] cursor-text">
        John Doe
      </div>
    </button>
  );
}


/**
 * If the name is >= 2 words, abbreviate it to first two initials.
 * If the name is 1 word, abbreviate it to first 2 letters.
 *
 * @param name - Name to abbreviate
 */
function calculateAbbreviation(name: string): string {
  if (!name) return null;

  const words = name.split(" ");

  if (words.length === 1) {
    var res = name.substring(0, 2);
  } else {
    var res = words[0].substring(0, 1) + words[1].substring(0, 1);
  }

  return res.toUpperCase();
}

/**
 * Chart data for the bullet chart.
 */
type ChartData = {
  label: string;
  startOfWeek: moment.Moment;
  toaValue: number;
  inputValue: number;
  inputOrAvailable: number;
  remainder: number;
}[];

/**
 * Formats the forecast data into a format that the BulletChart component can use.
 *
 * @param forecast - Forecast to format
 * @returns {ChartData} - Formatted chart data
 */
export function formatStaticForecastForBulletChart(
  forecast: ForecastType
): ChartData {
  if (!forecast) return null;

  let chartData: ChartData = [];

  // Generate available and remaining values
  let { available, remainder } = ForecastClass.generateAvailableAndRemainder(
    forecast.inputForecast,
    forecast.toaForecast
  );

  // Generate chart data
  for (let i = 0; i < forecast.toaForecast.length; i++) {
    let startOfWeek = moment(forecast.week).utc().add(i, "weeks");
    chartData.push({
      label: startOfWeek.format("M/D"),
      startOfWeek: startOfWeek,
      toaValue: forecast.toaForecast[i],
      inputValue: forecast.inputForecast[i],
      inputOrAvailable: Math.min(available[i], forecast.inputForecast[i]),
      remainder: remainder[i] < 0 ? -remainder[i] : 0,
    });
  }

  return chartData;
}
