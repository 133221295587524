import moment from "moment";
import React, { useEffect, useState } from "react";
import TopBar from "src/components/nav/TopBar";
import SearchBar from "../components/SearchBar";
import Dropdown from "../components/input/Dropdown";
import Modal from "../components/Modal";
import OrderModal from "../components/Orders/OrderModal.tsx";
import UserManager from "../tools/UserManager";
import MultiSelectListbox from "../components/input/MultiSelectListbox.tsx";
import SimpleBarCard from "../components/SimpleBarCard";
import Card from "../components/Card";
import Table from "../components/Table";
import Pagination from "../components/nav/Pagination";
import {
  formatJobsForTable,
  retrieveJobs,
} from "../tools/Jobs/jobHelpers";

const wideJobTableFields = [
  {
    label: "ID",
    field: "importedId",
  },
  {
    label: "Location",
    field: "cityState",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Size",
    field: "systemSize",
  },
  {
    label: "Stage",
    field: "stage",
  },
  {
    label: "Market",
    field: "market",
  },
];

/**
 * Orders Page, displays jobs in a specified market and stage, with the ability to place an order for each job.
 */
export default function OrdersPage() {
  const [selectedStageIndex, setSelectedStageIndex] = useState(-1);
  const [selectedMarketIds, setSelectedMarketIds] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
  });

  const [stages, setStages] = useState(null);
  const [markets, setMarkets] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [search, setSearch] = useState("");

  const [showOrderModal, setShowOrderModal] = useState(false);

  const [newOrderData, setNewOrderData] = useState({});

  // TODO: is this used?
  const [dateFilter] = useState(
    // Start of last 12 months (e.g. If today is 11/10/2023, should be 11/1/2022 inclusive)
    moment.utc().subtract(12, "months").startOf("month").toISOString()
  );

  const [cycleTimeStats, setCycleTimeStats] = useState(null);
  const [conversionStats, setConversionStats] = useState(null);
  const [installStats, setInstallStats] = useState(null);
  const [soldStats, setSoldStats] = useState(null);

  // On page load, get markets and jobs
  useEffect(() => {
    // Get markets
    UserManager.makeAuthenticatedRequest("/api/markets/installer/find", "GET")
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { markets } = res.data;
          setMarkets(markets);
          let newSelectedMarketIds = markets.map((m) => m._id);
          setSelectedMarketIds(newSelectedMarketIds)

          // Get jobs
          retrieveJobs(
            pagination.currentPage,
            null,
            newSelectedMarketIds,
            search,
            dateFilter,
            setJobs,
            setStages,
            setCycleTimeStats,
            setConversionStats,
            setInstallStats,
            setSoldStats,
            setPagination,
            pagination
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  /**
   * Handle page change, get specified page of jobs
   */
  function handlePageChange(page) {
    setPagination({
      ...pagination,
      currentPage: page,
    });

    retrieveJobs(
      page,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      selectedMarketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );
  }

  /**
   * Get jobs matching search query
   */
  function handleSearch(search) {

    search = search.target.value;

    // Go back to page 1 and update search
    setPagination({
      ...pagination,
      currentPage: 1,
    });
    setSearch(search);

    retrieveJobs(
      1,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      selectedMarketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );
  }

  /**
   * Get jobs matching selected stage
   */
  function handleSetSelectedStage(i, toggle = false) {
    if (toggle) {
      i = i === selectedStageIndex ? -1 : i;
    }

    // Update selected stage
    setSelectedStageIndex(i);

    // Filter jobs based on selected stage
    retrieveJobs(
      pagination.currentPage,
      i !== -1 && stages ? stages[i]._id : null,
      selectedMarketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );

    // TODO:  reset month select
  }

  /**
   * Get jobs matching selected market 
   */
  function handleMarketSelected(marketIds) {
    setSelectedMarketIds(marketIds);

    retrieveJobs(
      pagination.currentPage,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      marketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );

    // TODO: Reset month select
  }

  /**
   * get job data for the job that the user clicked Order on
   */
  function handleNewOrderButtonClick(item) {
    var jobId = item.link.href.split("/").pop();

    UserManager.makeAuthenticatedRequest(
      `/api/jobs/get?job=${jobId}`,
      "GET"
    )
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var job = res.data.job;

          setNewOrderData({
            address: job.address,
            id: job._id,
            name: job.name,
            size: job.systemSize,
            installerId: job.installer,
            marketId: job.market._id
          })

          setShowOrderModal(true);
        }
      })
  }


  // Generate markets options
  var allMarketsOption = { label: "All Markets", value: null };
  var marketsOptions = markets
    ? [
      [allMarketsOption],
      markets.map((m) => {
        return {
          label: m.name,
          value: m._id,
        };
      }),
    ]
    : [[allMarketsOption]];


  var maxJobs = Math.max(...(stages?.map((s) => s.jobCount) || []));

  // Generate cards for each stage
  var stagesCards = stages?.slice(1).map((s, i) => {
    return (
      <SimpleBarCard
        key={i}
        className="w-[180px]"
        title={`S${i + 1} - ${s.name}`}
        value={s.jobCount}
        percent={maxJobs ? (s.jobCount / maxJobs) * 100 : 0}
        grayed={selectedStageIndex !== -1 && selectedStageIndex !== i + 1} // +1 because first stage is not selectable
        onClick={() => handleSetSelectedStage(i + 1, true)} // +1 because first stage is not selectable
      />
    );
  });


  var content = (
    <div className="flex flex-col gap-4 mx-4">
      {/* Stages */}
      <div className="flex flex-wrap justify-center gap-4">{stagesCards}</div>

      {/* Jobs */}
      <div className="w-full">
        <Card>
          <Table
            noSelect
            // noEdit
            ellipsisOptions={[
              {
                label: "Edit",
                // icon: EditIcon,
                onClick: (item) => {
                  //TODO: Implement edit order
                  console.log("Edit", item);
                },
              },
              {
                label: "Delete",
                // icon: TrashIcon,
                onClick: (item) => {
                  //TODO: Implement delete order
                  console.log("Delete", item);
                },
              },
            ]}
            thinFirstColumn
            fields={wideJobTableFields.concat(
              stages?.slice(1).map((s, i) => {
                return {
                  label: s.name,
                  field: s._id,
                };
              })
            ).concat([
              {
                label: "New Order",
                field: "newOrder",
                type: "button",
                buttonLabel: "Order",
                onClick: (item) => {
                  handleNewOrderButtonClick(item);
                },
              }
            ])
          }
            data={formatJobsForTable(jobs, stages, markets)}
          />
          <Pagination {...pagination} onChange={handlePageChange} />
        </Card>
      </div>
    </div>
  );

  return (
    <div>
      <OrderModal
        showOrderModal={showOrderModal}
        setShowOrderModal={setShowOrderModal}
        data={newOrderData}
      />


      {/* Top Bar */}
      <TopBar>Orders</TopBar>

      {/* Actions */}
      <div className="flex flex-wrap items-center gap-4 p-2 mx-2" >
        <div>
          <MultiSelectListbox
            itemType="Market"
            options={marketsOptions[1]}
            selectedOptionsValues={selectedMarketIds}
            onChange={(values) => {
              handleMarketSelected(values);
            }}
          />
        </div>
        <div className="mr-auto sm:mr-0">
          <Dropdown
            options={
              stages
                ? [
                  [
                    {
                      label: "All Stages",
                      value: -1,
                    },
                  ],
                  stages.slice(1).map((s, i) => ({
                    label: s.name,
                    value: i + 1,
                  })),
                ]
                : [
                  [
                    {
                      label: "All Stages",
                      value: -1,
                    },
                  ],
                ]
            }
            placeholder="Select a Stage"
            bold
            justifyLeft
            onSelected={(item) => {
              handleSetSelectedStage(item.value);
            }}
            selectedValue={selectedStageIndex}
          />
        </div>

        <div className="flex flex-wrap items-center gap-4 grow">
          <div className="flex grow">
            <div className="shrink">
              <SearchBar value={search} onChange={handleSearch} />
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-auto">{content}</div>
    </div>
  );
}