import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SearchBar({ value, onChange = () => {} }) {
  return (
    <div
      className={classNames(
        "flex w-full px-2 border rounded-lg lg:ml-0",

        // Light Mode
        "bg-white border-gray-200 focus-within:border-gray-400",

        // Dark Mode
        "dark:bg-gray-700 dark:border-gray-400 dark:focus-within:border-gray-400"
      )}
    >
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <div
        className={classNames(
          "relative w-full",

          // Light Mode
          "text-gray-400 focus-within:text-gray-600",

          // Dark Mode
          "dark:text-gray-400 dark:focus-within:text-gray-200"
        )}
      >
        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
          <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
        </div>
        <input
          value={value}
          id="search-field"
          className={classNames(
            "block w-full h-full py-2 pl-8 pr-3 border-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm",

            // Light Mode
            "text-gray-900 focus:placeholder:text-gray-400",

            // Dark Mode
            "dark:text-gray-200 dark:bg-gray-700 dark:focus:placeholder:text-gray-400"
          )}
          placeholder="Search"
          type="search"
          name="search"
          onChange={onChange}
        />
      </div>
    </div>
  );
}
