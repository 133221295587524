import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  ReferenceLine,
  Cell,
  YAxis,
} from "recharts";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BulletChart({
  data,
  height = 150,
  frontValueDataKey = "",
  frontValueRedDataKey = "",
  backValueDataKey = "",
  tickValueDataKey = null,
  labelDataKey = "label",
  tickStyle = {
    baseStyle: { strokeWidth: 2 },
    aboveTickStyle: { stroke: "#E5E7EB" },
    belowTickStyle: { stroke: "#E5E7EB" },
  },
  backBarStyle = {
    barSize: 28,
    radius: [4, 4, 4, 4],
  },
  frontBarStyle = {
    barSize: 14,
    radius: [2, 2, 2, 2],
  },
  noAxis = false,
}) {
  if (data) {
    // Get weeks that are the first of the month
    var firstWeeks = data.filter((d, i) => {
      // If date minus a week is a different month, then it's the first week of the month
      // Note: d.startOfWeek is a moment

      // If it's the first week, return false so it doesn't show at far left
      return (
        d.startOfWeek &&
        moment(d.startOfWeek).subtract(1, "week").month() !==
          d.startOfWeek.month() &&
        i !== 0
      );
    });
  }

  const tickShape = (props) => {
    const { x, y, width, index, fill } = props;

    var aboveLine =
      data[index][frontValueDataKey] > data[index][tickValueDataKey];

    return (
      <line
        key={`tick-${index}`}
        x1={x}
        y1={y}
        x2={x + width}
        y2={y}
        strokeLinecap="round"
        style={{
          ...tickStyle.baseStyle,
          ...(aboveLine ? tickStyle.aboveTickStyle : tickStyle.belowTickStyle),
        }}
      />
    );
  };

  return (
    <div className="relative w-full" style={{ height: height }}>
      <div className="absolute inset-0 pl-[1px]">
        <ResponsiveContainer height={height} width={"99.9%"}>
          <BarChart data={data}>
            <Tooltip
              cursor={{ fill: "#e5e7eb" }} // fill-gray-200 // TODO: Improve for dark mode
              content={
                <CustomTooltip
                  frontValueDataKey={frontValueDataKey}
                  frontValueRedDataKey={frontValueRedDataKey}
                  backValueDataKey={backValueDataKey}
                  tickValueDataKey={tickValueDataKey}
                />
              }
              wrapperStyle={{ outline: "none", zIndex: 10 }}
            />
            <Bar
              id="back-bar"
              type="monotone"
              dataKey={backValueDataKey}
              xAxisId="back"
              {...backBarStyle}
            >
              {data?.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    className={
                      entry.highlight
                        ? "fill-primary-rose/30"
                        : "fill-gray-300 dark:fill-gray-500"
                    }
                  />
                );
              })}
            </Bar>
            <Bar
              id="front-bar"
              stackId="front-bar"
              className="fill-gray-600 dark:fill-gray-200"
              type="monotone"
              dataKey={frontValueDataKey}
              {...frontBarStyle}
            >
              {data?.map((entry, index) => {
                let fvValue = entry[frontValueDataKey];
                let fvrValue = entry[frontValueRedDataKey];

                let radius = fvrValue ? [0, 0, 2, 2] : [2, 2, 2, 2];

                return <Cell key={index} radius={radius} />;
              })}
            </Bar>
            <Bar
              id="front-bar-red"
              stackId="front-bar"
              barSize={14}
              className="fill-primary-rose"
              type="monotone"
              dataKey={frontValueRedDataKey}
              radius={[2, 2, 2, 2]}
            >
              {data?.map((entry, index) => {
                let fvValue = entry[frontValueDataKey];
                let fvrValue = entry[frontValueRedDataKey];

                let radius = fvValue ? [2, 2, 0, 0] : [2, 2, 2, 2];

                return <Cell key={index} radius={radius} />;
              })}
            </Bar>
            {tickValueDataKey && (
              <Bar
                id="tick-bar"
                barSize={28}
                type="monotone"
                dataKey={tickValueDataKey}
                xAxisId="tick"
                shape={tickShape}
              />
            )}
            <XAxis
              dataKey={labelDataKey}
              axisLine={false}
              tickLine={false}
              interval={0}
              // TODO: Improve for dark mode
              // className="text-sm font-medium fill-gray-300 " Using tick object instead. Classes don't seem to work
              tick={{ fontSize: "0.875 rem", fontWeight: 500, fill: "#6B7280" }}
              hide={noAxis}
            />

            {/* Need axes for chart to work right */}
            <XAxis xAxisId="tick" hide />
            <XAxis xAxisId="back" hide />

            {/* Reference lines between months */}
            {firstWeeks?.map((d, i) => {
              return (
                <ReferenceLine
                  key={i}
                  x={d.label}
                  stroke="#d1d5db"
                  strokeWidth={2}
                  position="start"
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

function CustomTooltip({
  active,
  payload,
  label,
  frontValueDataKey,
  frontValueRedDataKey,
  backValueDataKey,
  tickValueDataKey,
}) {
  if (active && payload && payload.length) {

    let startOfWeek = payload[0].payload.startOfWeek;
    let goal = payload[0].payload[tickValueDataKey];
    let input =
      payload[0].payload[frontValueDataKey] + payload[0].payload[frontValueRedDataKey];
    let potential = payload[0].payload[backValueDataKey];

    goal = goal && Math.round(goal);
    input = input && Math.round(input);
    potential = potential && Math.round(potential);

    var endOfWeek = moment(startOfWeek).endOf("week");
    return (
      <div className="flex flex-col gap-2 p-4 text-sm bg-white border border-gray-300 rounded-md dark:bg-gray-800">
        {/* Date */}
        {startOfWeek && (
          <>
            <div className="text-sm font-medium">
              Week of {startOfWeek.format("M/D")}
            </div>
            <div className="-mt-2 text-xs font-normal text-gray-400">
              {startOfWeek.format("M/D/YY")}-{endOfWeek.format("M/D/YY")}
            </div>
          </>
        )}

        {/* Table */}
        <table className="table table-fixed">
          <tbody>
            <tr>
              <td className="text-gray-500 dark:text-gray-300">Estimated</td>
              <td className="text-right">
                {potential != null ? potential : "-"}
              </td>
            </tr>
            <tr>
              <td className="text-gray-500 dark:text-gray-300">Input</td>
              <td className="text-right">{input != null ? input : "-"}</td>
            </tr>
            <tr>
              <td className="text-gray-500 dark:text-gray-300">Goal</td>
              <td className="text-right">{goal != null ? goal : "-"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
