import JobPipelineContext from "../../../contexts/pipeline/jobs/JobPipelineContext";

const { useState, useContext } = require("react");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Displays a grid breaking down the conversion rate of jobs over 12 months.
 * This expects each array to be of length 12.
 * 
 * If the selected stage (via JobPipelineContext) is -1, then the grid will display the conversion rate for all stages. Else, it will adjust texts accordingly.
 * 
 * The grid will display the following rows:
 * - Sold: Number of jobs sold or entered the stage
 * - Complete: Number of jobs completed or exited the stage
 * - Canceled: Number of jobs canceled or did not complete the stage
 * - Active: Number of jobs active or in the stage
 * - Complete %: Percentage of jobs completed or exited the stage
 * - Canceled %: Percentage of jobs canceled or did not complete the stage
 * 
 * @param {{
 * sold: number[],
 * installed: number[],
 * canceled: number[],
 * active: number[],
 * conversion: number[],
 * cancelRate: number[]
 * }} param
 * @returns 
 */
export default function ConversionBreakdownGrid({
  sold = [],
  installed = [],
  canceled = [],
  active = [],
  conversion = [],
  cancelRate = null,
}) {
  sold = sold ?? [];
  installed = installed ?? [];
  canceled = canceled ?? [];
  active = active ?? [];
  conversion = conversion ?? [];
  cancelRate =
    cancelRate ??
    sold.map((s, i) => {
      // Calculate cancel rate
      return s ? canceled[i] / s : 0;
    });

  const { selectedStageIndex } = useContext(JobPipelineContext);

  const isAllStages = selectedStageIndex === -1;

  const [hoveredRow, setHoveredRow] = useState(null);

  function onMouseEnter(e) {
    setHoveredRow(e);
  }

  function onMouseLeave(e) {
    setHoveredRow(null);
  }

  return (
    <div className="">
      <table className="w-full text-center">
        <tbody>
          {/* Sold Row */}
          <tr
            id="conversion-sold-row"
            className="relative border-b-2 border-gray-400 dark:border-gray-500"
            onMouseEnter={() => onMouseEnter("sold")}
            onMouseLeave={() => onMouseLeave("sold")}
          >
            <td className="relative">
              {isAllStages ? "Sold" : "Entered Stage"}
              <div className="absolute inset-x-0 px-1 leading-3  bg-white dark:bg-gray-800 -bottom-1.5">
                {"="}
              </div>
            </td>
            {sold.map((count, i) => (
              <Cell key={i}>{count}</Cell>
            ))}
            <td className="absolute bottom-0 right-0 px-1 text-gray-900 translate-x-1/2 translate-y-1/2 bg-white dark:bg-gray-800 dark:text-white">
              {"="}
            </td>
          </tr>

          {/* Installed Row */}
          <tr
            id="conversion-complete-row"
            className={classNames(
              "relative",
              ["cancelPercent"].includes(hoveredRow)
                ? "text-gray-400 dark:text-gray-500"
                : ""
            )}
            onMouseEnter={() => onMouseEnter("complete")}
            onMouseLeave={() => onMouseLeave("complete")}
          >
            <td className="relative ">
              {isAllStages ? "Complete" : "Exited Stage"}
              <div className="absolute inset-x-0 px-1 -bottom-3 ">{"+"}</div>
            </td>
            {installed.map((count, i) => (
              <Cell key={i}>{count}</Cell>
            ))}
            <td className="absolute bottom-0 right-0 px-1 text-gray-900 translate-x-1/2 translate-y-1/2 bg-white dark:bg-gray-800 dark:text-white">
              {"+"}
            </td>
          </tr>

          {/* Canceled Row */}
          <tr
            id="conversion-canceled-row"
            className={classNames(
              "relative",
              ["completePercent"].includes(hoveredRow)
                ? "text-gray-400 dark:text-gray-500"
                : ""
            )}
            onMouseEnter={() => onMouseEnter("canceled")}
            onMouseLeave={() => onMouseLeave("canceled")}
          >
            <td className="relative ">
              Canceled
              <div className="absolute inset-x-0 px-1 -bottom-3 ">{"+"}</div>
            </td>
            {canceled.map((count, i) => (
              <Cell key={i}>{count}</Cell>
            ))}
            <td className="absolute bottom-0 right-0 px-1 text-gray-900 translate-x-1/2 translate-y-1/2 bg-white dark:bg-gray-800 dark:text-white">
              {"+"}
            </td>
          </tr>

          {/* Active Row */}
          <tr
            id="conversion-active-row"
            className={classNames(
              "relative",
              ["completePercent", "cancelPercent"].includes(hoveredRow)
                ? "text-gray-400 dark:text-gray-500"
                : ""
            )}
            onMouseEnter={() => onMouseEnter("active")}
            onMouseLeave={() => onMouseLeave("active")}
          >
            <td className="">{isAllStages ? "Active" : "In Stage"}</td>
            {active.map((count, i) => (
              <Cell key={i}>{count}</Cell>
            ))}
          </tr>
        </tbody>
      </table>

      <table className="w-full mt-2 text-center">
        <tbody>
          {/* Conversion Rate Row */}
          <tr
            id="conversion-complete-percent-row"
            className={classNames(
              "relative",
              ["cancelPercent"].includes(hoveredRow)
                ? "text-gray-400 dark:text-gray-500"
                : ""
            )}
            onMouseEnter={() => onMouseEnter("completePercent")}
            onMouseLeave={() => onMouseLeave("completePercent")}
          >
            <td className="relative whitespace-nowrap">
              Complete %
              <div className="absolute inset-0 mx-4 border-t border-gray-400 dark:border-gray-500 -top-1"></div>
            </td>
            {conversion.map((val, i) => (
              <Cell key={i}>{Math.round(val * 100)}%</Cell>
            ))}
          </tr>

          {/* Cancel Rate Row */}
          <tr
            id="conversion-cancel-percent-row"
            className={classNames(
              "relative",
              hoveredRow === "completePercent"
                ? "text-gray-400 dark:text-gray-500"
                : ""
            )}
            onMouseEnter={() => onMouseEnter("cancelPercent")}
            onMouseLeave={() => onMouseLeave("cancelPercent")}
          >
            <td className="relative bg-white whitespace-nowrap dark:bg-gray-800">
              Canceled %
            </td>
            {cancelRate.map((val, i) => (
              <Cell key={i}>{Math.round(val * 100)}%</Cell>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Cell({ children }) {
  return (
    <td className="py-2 border border-gray-400 dark:border-gray-500 w-[7.14%]">
      {children}
    </td>
  );
}
