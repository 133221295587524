import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Modal from "../../Modal";
import SelectFieldList from "./SelectFieldList";

var example = {
  test: {
    test2: 1,
    test3: "test",
    test4: {
      test5: 2,
      test6: [],
      test7: [1],
    },
  },
  systemSize: 5200,
  panelQuantity: 13,
  panelType: "VSUN",
  city: "Henderson",
  zip: "89011",
  state: "Nevada",
  dateSold: "2023-07-11",
  marketFormula: "Nevada",
  invertersBrand: "SolarEdge",
  invertersSize: "",
  invertersQuantity: 1,
  status: "Active",
  tasks: [
    {
      status: "Not Started",
      task_type: "System Design",
      customer: "131117.0",
      status_date_time: "2023-07-11T03:59:19Z",
    },
  ],
  appointments: [],
  qtyCaulk: 1,
  qtyEndSleeves: 10,
  qtyMicroBolts: 13,
  qtyRails: 7,
  qtySoladecks: null,
  qtyStandoffs: 19,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SelectFieldInput({
  field,
  schema = {},
  allowNoField = false,
  onSelect = () => {},
}) {

  const [showSelectModal, setShowSelectModal] = useState(false);

  function handleSelect(path) {
    onSelect(path);
    setShowSelectModal(false);
  }

  // Build Label
  if (field) {
    var label = "";

    field.forEach((p) => {
      switch (p.type) {
        case "arrayItem":
          label += `[${p.key}]`;
          break;

        case "arrayObj":
          label += `[${p.key}].`;
          break;

        case "obj":
          label += `${p.key}.`;
          break;

        case "field":
          label += `${p.key}`;
          break;

        case "null":
          label += "No Field";
          break;

        default:
          break;
      }
    });
  }

  return (
    <>
      <div
        tabIndex={0}
        className={classNames(
          "bg-white border rounded-md px-3 py-2 h-9 cursor-pointer flex gap-2.5 items-center text-sm font-normal text-gray-900 whitespace-nowrap",
          field ? "border-gray-300" : "border-secondary-orange"
        )}
        onClick={() => {
          setShowSelectModal(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setShowSelectModal(true);
          }
        }}
      >
        <div className="inline-block overflow-hidden grow overflow-ellipsis">
          {label || "Select Field"}
        </div>
        <div>
          <ChevronDownIcon className="w-5 h-5 ml-auto text-gray-500" />
        </div>
      </div>
      <Modal open={showSelectModal} setOpen={setShowSelectModal}>
        <SelectFieldList allowNoField={allowNoField} schema={schema} onSelect={handleSelect} />
      </Modal>
    </>
  );
}
