import React, { Component } from 'react'
import { Pagination } from 'react-bootstrap'

class FloCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variant: props.variant ?? "default"
    };
  }

  render() {
    var card = <div className="flo-content flo-border p-3" style={{ "borderRadius": 10 }}>
      {this.props.children}
    </div>;

    if (this.state.variant === "good") {
      card = <div className="pl-3 pr-3 pt-1 pb-1" style={{ "borderRadius": 10, "background": "var(--flo-green)", "boxShadow": "0px 5px 10px 3px rgba(97, 199, 147, 0.25)", "color": "var(--flo-navy)" }}>
        {this.props.children}
      </div>;
    }

    if (this.state.variant === "bad") {
      card = <div className="pl-3 pr-3 pt-1 pb-1" style={{ "borderRadius": 10, "background": "var(--flo-orange)", "boxShadow": "0px 5px 10px 3px rgba(245, 85, 54, 0.25)" }}>
        {this.props.children}
      </div>;
    }


    return (<>{card}</>);
  }
}

class Title extends Component {
  render() {
    return (
      <div className="flo-header pb-3">
        {this.props.children}
      </div>
    );
  }
}

class Body extends Component {
  render() {
    return (
      <div style={{ "position": "relative", "minHeight": 30 }}>
        {this.props.children}
      </div>
    );
  }
}

function FloPagination({ pageCount, currentPage, onPageChange }) {

  var items = [];

  // If there are less than 8 pages, show all pages
  if (pageCount < 8) {
    for (let i = 1; i <= pageCount; i++) {
      items.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }
  } 
  
  // If there are more than 8 pages, show ellipses between current page and first/last page if necessary
  else {
    // Add first item
    items.push(
      <Pagination.Item key={1} active={1 === currentPage} onClick={() => onPageChange(1)}>
        {1}
      </Pagination.Item>,
    );

    // Add ellipsis if needed
    if (currentPage > 3) {
      items.push(
        <Pagination.Ellipsis key="ellipsisStart" disabled />
      );
    }

    // Add current page and adjacent pages
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      if (i > 1 && i < pageCount) {
        items.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
            {i}
          </Pagination.Item>,
        );
      }
    }

    // Add ellipsis if needed
    if (currentPage < pageCount - 2) {
      items.push(
        <Pagination.Ellipsis key="ellipsisEnd" disabled />
      );
    }

    // Add last item
    items.push(
      <Pagination.Item key={pageCount} active={pageCount === currentPage} onClick={() => onPageChange(pageCount)}>
        {pageCount}
      </Pagination.Item>,
    );

  }

  return (
    <Pagination size="sm">
      <Pagination.First onClick={() => onPageChange(1)} />
      <Pagination.Prev disabled={currentPage <= 1} onClick={() => onPageChange(currentPage - 1)} />
      {items}
      <Pagination.Next disabled={currentPage >= pageCount} onClick={() => onPageChange(currentPage + 1)} />
      <Pagination.Last onClick={() => onPageChange(pageCount)} />
    </Pagination>
  )
}


FloCard.Title = Title;
FloCard.Body = Body;
FloCard.Pagination = FloPagination;

export default FloCard;