import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  AdjustmentsVerticalIcon,
  Cog6ToothIcon,
  FunnelIcon,
} from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ForecastHistoryRow from "src/components/Forecast/history/ForecastHistoryRow.tsx";
import Dropdown from "src/components/input/Dropdown";
import TopBar from "src/components/nav/TopBar";
import UserManager from "src/tools/UserManager";

import ForecastHistoryContext, {
  MarketType,
  ForecastHistoryContextType,
  MaterialGroupType,
  MaterialType,
} from "src/contexts/forecast/history/ForecastHistoryContext.tsx";

import pluralize from "pluralize";

type Props = {};

/**
 * Forecast Type for Forecast History Page
 * 
 * Matches Forecast Schema in backend
 */
export type ForecastType = {
  _id: string;

  createdAt: string;
  updatedAt: string;
  submittedAt?: string;

  market: string;
  installer: string;

  numActiveJobs: number;
  numEstimatedJobs: number;
  numInputJobs: number;

  weeksOfCapacity: number;

  isCurrent: boolean;

  inputForecast?: number[];
  toaForecast?: number[];
  toaForecastWoConversion?: number[];
  toaMaterialForecast?: {
    [materialGroupId: string]: {
      [materialId: string]: number[];
    };
  };
  stageStats?: {
    ID: string;
    name: string;

    activeInStage: number;
    avgTimeToInstall: number;
    convRate: number; // Stage to install

    fromWeek: number;
    toWeek: number;

    order: number;
  }[];

  visibility?: [];

  week: string;

  last6WeeksActual?: number[];
  last6WeeksForecast?: number[];
};

type LabelValue = {
  label: string;
  value: string;
};

/**
 * Page for viewing forecast history
 * Lists all submitted forecasts based on market filter
 * 
 * @param {Props} props - Props for Forecast History Page
 * @returns 
 */
export default function ForecastHistoryPage({ }: Props) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [forecasts, setForecasts] = useState<ForecastType[]>();

  // connected distributors
  const [distributors, setDistributors] = useState<any[]>([]);

  const [markets, setMarkets] = useState<MarketType[]>([]);
  const [selectedMarketId, setSelectedMarketId] = useState<string>(
    searchParams.get("market") // Get market from search params initially
  );

  const [materialGroups, setMaterialGroups] =
    useState<MaterialGroupType[]>(null);
  const [materials, setMaterials] = useState<MaterialType[]>(null);

  // Get forecasts on market change
  useEffect(() => {
    getForecasts(selectedMarketId).then((data) => {
      let history = data.history;
      setForecasts(history);
    });
  }, [selectedMarketId]);

  // Get markets
  useEffect(() => {
    getMarkets().then((data) => {
      setMarkets(data.markets);
    });
    getDistributors();
  }, []);

  // Get materials
  useEffect(() => {
    getMaterials().then((data) => {
      setMaterials(data.materials);
      setMaterialGroups(data.materialGroups);
    });
  }, []);

  // Sync search params with selected market
  useEffect(() => {
    setSearchParams({
      ...(selectedMarketId && { market: selectedMarketId }),
    });
  }, [selectedMarketId]);

  const marketOptions: LabelValue[][] = [
    [
      {
        label: "All Markets",
        value: null,
      },
    ],
    markets.map((market) => {
      return {
        label: market.name,
        value: market._id,
      };
    }),
  ];


  /**
 * Get all connected distributors.
 */
  function getDistributors() {
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list",
      "GET"
    ).then((res) => {
      if (res.data.status === "ok") {
        setDistributors(
          res.data.connections.map((connection) => {
            if (connection.status === "active" || connection.status === "pending_request") {
              return connection;
            }
          })
        );
      } else {
        console.error(res.data.message);
      }
    });
  }


  /**
   * Deletes a forecast from the forecasts list, 
   * passed to ForecastHistoryRow to delete a ForecastHistoryRow when the forecast is successfully deleted
   * @param forecastId - Forecast ID to delete
   */
  function deleteForecast(forecastId: string) {
    setForecasts((forecasts) => {
      return forecasts.filter((forecast) => forecast._id !== forecastId);
    });
  }


  return (
    <ForecastHistoryContext.Provider
      value={{
        markets: markets,
        materials: materials,
        materialGroups: materialGroups,
      }}
    >
      <div className="flex flex-col max-h-screen min-h-full grow">
        <TopBar>
          {
            <div className="flex items-center gap-3 grow">
              <div>Forecast History</div>
              {/* Back button */}
              <button
                className="ml-auto"
                onClick={() => {
                  // Navigate to /app/forecast with same market selected
                  navigate(
                    `/app/forecast/job${selectedMarketId ? `?market=${selectedMarketId}` : ""
                    }`
                  );
                }}
              >
                <XMarkIcon className="w-6 h-6 stroke-2" />
              </button>
            </div>
          }
        </TopBar>
        {/* Body */}
        <main className="flex flex-col overflow-scroll bg-white grow dark:bg-gray-800">
          {/* Header */}
          <header className="flex flex-wrap items-center gap-3 px-8 py-4">
            <div className="flex flex-col basis-full sm:basis-auto">
              {/* Market Name */}
              <div className="text-lg font-bold leading-7">
                {pluralize("Total Forecast", forecasts?.length ?? 0, true)}
              </div>
              <div className="text-sm font-normal leading-5">
                Forecast by Market
              </div>
            </div>

            {/* Market Dropdown */}
            <div className="mr-auto sm:pl-6">
              <Dropdown
                placeholder="Select a Market"
                justifyLeft
                options={marketOptions}
                selectedValue={selectedMarketId}
                onSelected={(option) => {
                  setSelectedMarketId(option.value);
                }}
              />
            </div>

            {/* Actions */}
            {false && (<div className="flex items-center gap-3">
              {/* TODO: implement functionality */}
              <button className="">
                <AdjustmentsVerticalIcon className="w-6 h-6 text-gray-500 dark:text-gray-400 dark:hover:text-gray-200 hover:text-gray-700" />
              </button>
              {/* TODO: implement functionality */}
              <button>
                <FunnelIcon className="w-6 h-6 text-gray-500 dark:text-gray-400 dark:hover:text-gray-200 hover:text-gray-700" />
              </button>
              {/* TODO: implement functionality */}
              <button>
                <Cog6ToothIcon className="w-6 h-6 text-gray-500 dark:text-gray-400 dark:hover:text-gray-200 hover:text-gray-700" />
              </button>
            </div>)}
          </header>
          {/* List */}
          <ul className="px-8 py-4 overflow-x-scroll divide-y grow">
            {forecasts && forecasts.length > 0 ? (
              forecasts.map((forecast) => (
                <ForecastHistoryRow
                  key={forecast._id}
                  forecast={forecast}
                  onDeleteRow={deleteForecast}
                  distributors={distributors}
                />
              ))
            ) : (
              <li className="flex items-center justify-center pt-8 text-gray-500">
                No Forecasts Found
              </li>
            )}
          </ul>
        </main>
      </div>
    </ForecastHistoryContext.Provider>
  );
}

/**
 * Gets forecast history for a market
 * 
 * @param marketId - Market ID to get forecasts for
 */
async function getForecasts(marketId): Promise<{ history: ForecastType[] }> {
  let url = "/api/forecast/history";
  url += marketId ? `?market=${marketId}` : "";

  try {
    var response = await UserManager.makeAuthenticatedRequest(url, "GET");
  } catch (error) {
    console.error(error);
  }

  if (response.data.status === "ok") {
    return response.data;
  } else {
    console.error(response.data.message);
    return null;
  }
}

/**
 * Gets all of the installer's markets
 * 
 * @returns {Promise<{markets:MarketType[]}>} - Array of markets
 */
async function getMarkets(): Promise<{ markets: MarketType[] }> {
  try {
    var response = await UserManager.makeAuthenticatedRequest(
      "/api/markets/installer/find",
      "GET"
    );
  } catch (error) {
    console.error(error);
  }

  if (response.data.status === "ok") {
    return response.data;
  } else {
    console.error(response.data.message);
    return null;
  }
}

/**
 * Gets all materials and material groups
 * 
 * @returns {Promise<{materials:MaterialType[], materialGroups:MaterialGroupType[]}>} - Array of materials and material groups
 */
async function getMaterials(): Promise<{ materials: MaterialType[], materialGroups: MaterialGroupType[] }> {
  // Await both requests for materials and material groups
  try {
    var [materialsResponse, groupsResponse] = await Promise.all([
      UserManager.makeAuthenticatedRequest(
        "/api/materials/get-materials",
        "GET"
      ),
      UserManager.makeAuthenticatedRequest("/api/materials/get-groups", "GET"),
    ]);
  } catch (error) {
    console.error(error);
  }

  let materials = materialsResponse.data.materials;
  let groups = groupsResponse.data.groups;

  return { materials, materialGroups: groups };
}
