import { Fragment } from "react";
import { Dialog, Transition, DialogPanel, TransitionChild } from "@headlessui/react";
import useTheme from "../hooks/styling/useTheme";
import classNames from "src/tools/classNames";


export default function Modal({
  open,
  setOpen,
  children,
  wide,
  transparent = false,
  opacityClassNames = "bg-gray-500 bg-opacity-75",
}) {
  // Apply theme here as it appears outside of main App component
  const theme = useTheme();

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames("relative z-30", theme === "dark" ? "dark" : "")}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={classNames(
              "flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
            )}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={classNames(
                  "relative transform rounded-lg dark:text-white px-4 pt-5 pb-4 text-left transition-all sm:my-8 sm:p-6",
                  wide ? "max-w-[calc(100%-20px)]" : "sm:w-full sm:max-w-sm lg:max-w-2xl",
                  transparent ? "bg-transparent" : "bg-white dark:bg-gray-800 shadow-xl"
                )}
              >
                <div>{children}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
