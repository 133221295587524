    /**
    * Validate email address using regex
    * there are many regexes for email validation, this one is simple and should work for most cases
    *
    * @param {string} email - email address to validate
    * @returns {boolean} - true if email is valid, false otherwise
    */
    export default function validateEmail(email) {
        // source for email regex: https://www.abstractapi.com/guides/email-validation-regex-javascript
        var re = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/;
        return re.test(email);
    }