import { BoltIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../input/Button";
import moment from "moment";
import pluralize from "pluralize";
import UserManager from "../../../tools/UserManager";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MaterialInfoCard({
  material,
  group,
  onNewDefaultMaterial = () => {},
}) {
  const navigate = useNavigate();

  // Generate jobs bars
  var materialJobsBars = <></>;
  if (material.jobs) {
    // Get largest
    var largest = material.jobs.reduce((a, b) => Math.max(a, b));

    materialJobsBars = material.jobs.map((job, i) => {
      var percent = (100 * job) / largest;

      return (
        <div
          key={i}
          className={`w-2 rounded bg-primary-rose`}
          style={{ height: `${percent}%`, minHeight: "4px" }}
        ></div>
      );
    });
  }

  function handleMakeDefault() {
    UserManager.makeAuthenticatedRequest(
      "/api/materials/make-default",
      "POST",
      {
        materialId: material._id,
      }
    )
      .then((res) => {
        onNewDefaultMaterial(material._id);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className="shadow rounded-lg bg-white flex flex-col">
      {/* Header */}
      <div
        onClick={() => {
          navigate(`${material._id}?edit=false`);
        }}
        className="border-b rounded-t-lg py-5 px-6 bg-gray-50 flex items-center justify-between gap-4 hover:bg-gray-200 hover:cursor-pointer "
      >
        {/* Title */}
        <div className="flex flex-col text-base">
          <div className="font-semibold text-gray-900">{material.name}</div>
          <div className="font-normal text-gray-500 capitalize">
            {pluralize.singular(group)}
          </div>
        </div>
        {/* Icon */}
        <div>
          {material.isDefaultMaterial ? (
            <div className="p-2 border border-primary-rose-200 rounded-lg text-primary-rose text-sm font-medium bg-primary-rose-50">
              Default
            </div>
          ) : (
            <BoltIcon
              className={classNames(
                "w-5 h-5 mx-10",
                material.active ? "fill-primary-green" : "fill-gray-400"
              )}
            />
          )}
        </div>
        {/* Button */}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Button
            // variant="secondary"
            variant="dropdown"
            dropdownItems={[
              {
                label: "Make Default",
                action: handleMakeDefault,
              },
            ]}
            onClick={(e) => {
              e.stopPropagation();
              console.log("edit");
              navigate(`${material._id}?edit=true`);
            }}
          >
            Edit
          </Button>
        </div>
      </div>

      {/* Brand */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">Brand</div>
        <div className="font-normal text-gray-900 basis-1/2">
          {material.brand}
        </div>
      </div>

      {/* Model */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">Model</div>
        <div className="font-normal text-gray-900 basis-1/2">
          {material.model}
        </div>
      </div>

      {/* Created Date */}
      <div className="border-b py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">Created Date</div>
        <div className="font-normal text-gray-900 basis-1/2">
          {material.createdAt && formatDate(material.createdAt)}
        </div>
      </div>

      {/* Jobs */}
      <div className="py-5 px-6 flex text-sm gap-2">
        <div className="font-medium text-gray-500 basis-1/2">Total Jobs</div>
        <div className="font-normal text-gray-900 basis-1/2">
          {material.jobCount && formatNumber(material.jobCount)}
        </div>
        {/* <div className="font-normal text-gray-900 basis-1/2 flex items-end gap-1 relative -my-1.5">
          {materialJobsBars} // TODO: change to bars?
        </div> */}
      </div>
    </div>
  );
}

function formatDate(dateString) {
  if (!dateString) return null;

  return moment(dateString).format("MM / DD / YY");
}
function formatNumber(n) {
  if (n === 0) return 0;

  if (!n) return null;

  return n.toLocaleString();
}
