import React from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Card({ children, className="" }) {
  return (
    <div className={classNames(className, 'shadow rounded-lg bg-white dark:bg-gray-800 p-4 min-w-min')}>
      {children}
    </div>
  )
}
