import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const exampleSteps = [
  {
    id: "01",
    name: "Job Details",
    description: "Vitae sed mi luctus laoreet.",
    href: "#",
    status: "complete",
  },
  {
    id: "02",
    name: "Application form",
    description: "Cursus semper viverra.",
    href: "#",
    status: "current", // or "currentComplete"
  },
  {
    id: "03",
    name: "Preview",
    description: "Penatibus eu quis ante.",
    href: "#",
    status: "upcoming",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StepsPanel({
  steps = [],
  disconnected = false,
  focusOnIncomplete = false,
  boldTitle = false,
  selectedBorderColor = "bg-primary-green",
  onSelected = () => { },
}) {
  return (
    <div className="">
      <nav
        className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
        aria-label="Progress"
      >
        <ol
          role="list"
          className={classNames(
            "overflow-hidden rounded-md lg:flex lg:items-start whitespace-nowrap",
            disconnected
              ? "gap-6"
              : "lg:rounded lg:border lg:border-gray-200 bg-white dark:bg-gray-800"
          )}
        >
          {steps.map((step, stepIdx) => (
            <li
              key={step.id}
              className={classNames(
                "relative overflow-hidden bg-white dark:bg-gray-800 flex-1",
                disconnected ? "lg:rounded lg:border lg:border-gray-200" : ""
              )}
              onClick={() => {
                onSelected(step);
              }}
            >
              <div
                className={classNames(
                  stepIdx === 0 ? "rounded-t-md border-b-0" : "",
                  stepIdx === steps.length - 1 ? "rounded-b-md border-t-0" : "",
                  "overflow-hidden border border-gray-200 lg:border-0"
                )}
              >
                {step.status === "complete" ? (
                  <Link to={step.href} className="group">
                    <span
                      className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        {!focusOnIncomplete ? (
                          <span className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-green">
                            <CheckIcon
                              className="w-6 h-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        ) : (
                          <span className="flex items-center justify-center w-10 h-10 border-2 rounded-full border-primary-green">
                            <CheckIcon
                              className="w-6 h-6 text-primary-green"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col self-center">
                        <span
                          className={classNames(
                            !boldTitle
                              ? "text-sm font-semibold"
                              : "text-lg font-semibold",
                            "text-gray-500 dark:text-gray-300"
                          )}
                        >
                          {step.name}
                        </span>
                        {step.description ? (
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-300">
                          {step.description}
                        </span>
                        ) : null}
                      </span>
                      {/* step.icon */}
                      <span className="flex items-center self-center justify-center ml-3 h-9 w-9">
                        {step.icon}
                      </span>
                    </span>
                  </Link>
                ) : step.status === "current" ? (
                  <Link to={step.href} aria-current="step">
                    <span
                      className={`absolute left-0 top-0 h-full w-1 ${selectedBorderColor} lg:bottom-0 lg:top-auto lg:h-1 lg:w-full`}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        {!focusOnIncomplete ? (
                          <span className="flex items-center justify-center w-10 h-10 border-2 rounded-full border-primary-green">
                            <span className="text-primary-green">
                              {step.id}
                            </span>
                          </span>
                        ) : (
                          <span className="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-orange">
                            <span className="text-white">
                              <XMarkIcon className="w-6 h-6" />
                            </span>
                          </span>
                        )}
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col self-center justify-self-start">
                        <span
                          className={classNames(
                            !boldTitle
                              ? "text-sm font-semibold"
                              : "text-lg font-semibold",
                            !focusOnIncomplete
                              ? "text-primary-green"
                              : "text-secondary-orange"
                          )}
                        >
                          {step.name}
                        </span>
                        {step.description ? (
                        <span className="text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                        ) : null}
                      </span>
                      {/* step.icon */}
                      <span className="flex items-center self-center justify-center ml-3 h-9 w-9">
                        {step.icon}
                      </span>
                    </span>
                  </Link>
                ) : step.status === "currentComplete" ? (
                  <Link to={step.href} aria-current="step">
                    <span
                      className={`absolute left-0 top-0 h-full w-1 ${selectedBorderColor} lg:bottom-0 lg:top-auto lg:h-1 lg:w-full`}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        {!focusOnIncomplete ? (
                          <span className="flex items-center justify-center w-10 h-10 rounded-full bg-primary-green">
                            <CheckIcon
                              className="w-6 h-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        ) : (
                          <span className="flex items-center justify-center w-10 h-10 border-2 rounded-full border-primary-green">
                            <CheckIcon
                              className="w-6 h-6 text-primary-green"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col self-center">
                        <span
                          className={classNames(
                            !boldTitle
                              ? "text-sm font-semibold"
                              : "text-lg font-semibold",
                            "text-primary-green"
                          )}
                        >
                          {step.name}
                        </span>
                        {step.description ? (
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-300">
                          {step.description}
                        </span>
                        ) : null}
                      </span>
                      {/* step.icon */}
                      <span className="flex items-center self-center justify-center ml-3 h-9 w-9">
                        {step.icon}
                      </span>
                    </span>
                  </Link>
                ) : (
                  <Link to={step.href} className="group">
                    <span
                      className="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "flex items-start px-6 py-5 text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        {!focusOnIncomplete ? (
                          <span className="flex items-center justify-center w-10 h-10 border-2 border-gray-300 rounded-full">
                            <span className="text-gray-500 dark:text-gray-300">
                              {step.id}
                            </span>
                          </span>
                        ) : (
                          <span className="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-orange">
                            <span className="text-white">
                              <XMarkIcon className="w-6 h-6" />
                            </span>
                          </span>
                        )}
                      </span>
                      <span className="ml-4 mt-0.5 flex min-w-0 flex-col self-center">
                        <span
                          className={classNames(
                            !boldTitle
                              ? "text-sm font-semibold"
                              : "text-lg font-semibold",
                            "text-gray-500 dark:text-gray-300"
                          )}
                        >
                          {step.name}
                        </span>
                        {step.description ? (
                        <span className="text-sm font-medium text-gray-500 dark:text-gray-300">
                          {step.description}
                        </span>
                        ) : null}
                      </span>
                      {/* step.icon */}
                      <span className="flex items-center self-center justify-center ml-3 h-9 w-9">
                        {step.icon}
                      </span>
                    </span>
                  </Link>
                )}

                {stepIdx !== 0 && !disconnected ? (
                  <>
                    {/* Separator */}
                    <div
                      className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                      aria-hidden="true"
                    >
                      <svg
                        className="w-full h-full text-gray-300 "
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </div>
                  </>
                ) : null}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}
