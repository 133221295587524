import React, { useState } from "react";
import IconToggle from "../../input/IconToggle.tsx";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import Button from "../../input/Button";
import Dropdown from "../../input/Dropdown";
import SelectFieldInput from "./SelectFieldInput";
import Input from "../../input/Input";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function IdentifyingLogic({
  mapping,
  isArrayMap,
  subSchema = {},
  onChange = () => {},
}) {
  if (!isArrayMap) {
    return (
      <div className="flex items-center justify-center p-6 text-sm text-gray-500">
        Mapping does not require identification (no array)
      </div>
    );
  }

  // Get identifying query from mapping

  var arrayMap = mapping.mapping?.find((m) =>
    ["arrayObj", "arrayItem"].includes(m.type)
  );
  var filters, index;
  if (arrayMap) {
    // Setup filters if obj
    if (arrayMap.type === "arrayObj") {
      filters = arrayMap.identifierQuery;
      if (!filters || filters == {}) {
        filters = {
          $and: [],
        };
      }
    }
    // Setup index if item
    else {
      index = arrayMap.index ?? 0;
    }

    var isArrayObj = arrayMap.type === "arrayObj";
  }

  // TODO: array obj handling

  return isArrayObj ? (
    <FilterGroup
      filters={filters}
      onFilterChange={(f) => {
        arrayMap.identifierQuery = f;
        onChange(mapping);
      }}
      isEven
      isFirst
      subSchema={subSchema}
    />
  ) : (
    <div className="flex items-center gap-3">
      <div>Choose index of item (starts at 0, -1 for last item):</div>
      <Input
        type="number"
        value={index}
        onChange={(i) => {
          arrayMap.index = i;
          onChange(mapping);
        }}
      />
    </div>
  );
}

function FilterGroup({
  filters,
  onFilterChange = () => {},
  isEven,
  isFirst,
  subSchema,
}) {
  var type = Object.keys(filters)[0];

  function handleTypeChange(typeId) { // typeId is $and or $or
    let isAnd = typeId === "$and";
    if (isAnd) {
      var newFilters = { $and: filters[type] };
    } else {
      var newFilters = { $or: filters[type] };
    }

    onFilterChange(newFilters);
  }

  function handleFilterChange(newFilter, index) {
    var newFilters = filters;
    newFilters[type][index] = newFilter;
    onFilterChange(newFilters);
  }

  function handleAddFilter() {
    var newFilters = filters;
    newFilters[type].push({});
    onFilterChange(newFilters);
  }

  function handleAddFilterGroup() {
    var newFilters = filters;
    newFilters[type].push({ $and: [] });
    onFilterChange(newFilters);
  }

  function handleDeleteFilter(index) {
    var newFilters = filters;
    newFilters[type].splice(index, 1);
    onFilterChange(newFilters);
  }

  return (
    <div
      className={classNames(
        isEven ? "bg-gray-100" : "bg-gray-50",
        "px-6 py-3 border-gray-200",
        isFirst ? "-mx-9 border-y" : "rounded-md border",
        "flex flex-col gap-3"
      )}
    >
      {/* Header */}
      <div className="flex items-center gap-3">
        <div className="text-sm font-medium">
          Match{" "}
          <span className="font-bold text-primary-green">
            {type === "$and" ? "ALL" : "ANY"}
          </span>{" "}
          of the following:
        </div>
        <div className="ml-auto">
          <IconToggle
            selectedIconId={type} // $and or $or
            icons={[{
              id: "$and",
              name: "AND",
              content: {
                on: <div className="text-sm text-primary-green">AND</div>,
                off: <div className="text-sm text-gray-400">AND</div>,
              }
            },{
              id: "$or",
              name: "OR",
              content: {
                on: <div className="text-sm text-primary-green">OR</div>,
                off: <div className="text-sm text-gray-400">OR</div>,
              }

            }]}
            paddingClasses="px-1.5"
            onChange={handleTypeChange}
          />
        </div>
      </div>

      {/* Children */}
      <div className="flex flex-col gap-2">
        {filters[type].map((filter, index) => {
          if (
            Object.keys(filter)[0] === "$and" ||
            Object.keys(filter)[0] === "$or"
          ) {
            return (
              <div key={index} className="flex items-center gap-3">
                <div className="grow">
                  <FilterGroup
                    filters={filter}
                    isEven={!isEven}
                    onFilterChange={(filter) => {
                      handleFilterChange(filter, index);
                    }}
                    subSchema={subSchema}
                  />
                </div>
                <div
                  className="ml-auto cursor-pointer"
                  onClick={() => {
                    handleDeleteFilter(index);
                  }}
                >
                  <MinusCircleIcon className="w-5 h-5 text-primary-rose" />
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="flex items-center gap-3">
                <div className="grow">
                  <Filter
                    filter={filter}
                    onFilterChange={(filter) => {
                      handleFilterChange(filter, index);
                    }}
                    subSchema={subSchema}
                  />
                </div>
                <div
                  className="ml-auto cursor-pointer"
                  onClick={() => {
                    handleDeleteFilter(index);
                  }}
                >
                  <MinusCircleIcon className="w-5 h-5 text-primary-rose" />
                </div>
              </div>
            );
          }
        })}
      </div>

      {/* Add Buttons */}
      <div className="flex justify-end gap-2.5">
        <Button
          variant="secondary"
          className="!py-1.5 !px-2 text-primary-green"
          onClick={handleAddFilterGroup}
        >
          + Filter Group
        </Button>
        <Button
          variant="primary-green"
          className="!py-1.5 !px-2"
          onClick={handleAddFilter}
        >
          + Filter
        </Button>
      </div>
    </div>
  );
}

function Filter({ filter, onFilterChange = () => {}, subSchema }) {
  const operOptions = [
    [
      {
        label: "is",
        value: "$eq",
      },
      {
        label: "is not",
        value: "$ne",
      },
    ],
  ];

  // Get operation from filter
  var key = Object.keys(filter)[0];
  var val = filter[key];
  var oper = typeof val === "object" ? Object.keys(val)[0] : "$eq";

  function handleOperChange(item) {
    var newOper = item.value;

    if (typeof val === "object") {
      // If val is an object, we need to preserve the object (e.g. { $ne: ... })
      var newVal = {};
      newVal[newOper] = val[oper];

      // Set new object (e.g. { field: { $ne: ... } })
      var newFilter = {};
      newFilter[key] = newVal;
    } else {
      // If val is not an object, create one (e.g. { field: { $eq: ... } })
      var newFilter = {};
      newFilter[key] = {};
      newFilter[key][newOper] = val;
    }

    onFilterChange(newFilter);
  }

  function handleValueChange(value) {
    if (typeof val === "object") {
      var newFilter = {};
      newFilter[key] = {};
      newFilter[key][oper] = value;
    } else {
      var newFilter = {};
      newFilter[key] = value;
    }

    onFilterChange(newFilter);
  }

  return (
    <div className="flex items-center gap-8 p-2 bg-white border border-gray-200 rounded-md">
      <SelectFieldInput
        field={(function () {
          if (key === "undefined" && undefined in filter) return null;

          // Turn key into field path (e.g. "address.city" => [{ key: "address", type: "obj" }, { key: "city", type: "field" }]")
          var fieldPath = key
            ?.split(".")
            .map((k, i) => ({ key: k, type: "obj" }));

          if (!fieldPath) return null;

          fieldPath[fieldPath.length - 1].type = "field";
          return fieldPath;
        })()}
        schema={subSchema}
        onSelect={(path) => {
          // Get key from path
          var label = path.reduce((acc, p) => {
            acc += p.key + ".";

            return acc;
          }, "");
          label = label?.slice(0, -1) || undefined; // Remove last period

          // Set new filter
          var newFilter = {};
          newFilter[label] = val || "";

          onFilterChange(newFilter);
        }}
      />
      <Dropdown
        options={operOptions}
        selectFirst
        selectedValue={oper}
        onSelected={handleOperChange}
        justifyLeft
      />
      <Input
        onChange={handleValueChange}
        highlightOnEmpty
        placeholder="Enter Value"
        value={typeof val === "object" ? val[oper] : val}
      />
    </div>
  );
}
