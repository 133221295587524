/**
 * Concatenates an array of strings into a single string, separated by a space.
 * Intended for use with React components to conditionally apply CSS classes.
 * 
 * Usage:
 * ```javascript
 * const classes = classNames("class1", "class2", "class3");
 * // classes === "class1 class2 class3"
 * ```
 * 
 * @param  {...string} classes - Array of strings to be concatenated 
 * @returns 
 */
export default function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}