import React, { useEffect, useState } from "react";
import {
  Bars3Icon,
  BellAlertIcon,
  CalendarIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  ChatBubbleLeftRightIcon,
  Cog8ToothIcon,
  ExclamationTriangleIcon,
  HomeIcon,
  RectangleStackIcon,
  SparklesIcon,
  UserGroupIcon,
  Bars3BottomRightIcon,
  ShoppingCartIcon,
  ChatBubbleBottomCenterTextIcon,
  BugAntIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import {
  BellAlertIcon as BellAlertIconSolid,
  CalendarIcon as CalendarIconSolid,
  ChartBarSquareIcon as ChartBarSquareIconSolid,
  ChartPieIcon as ChartPieIconSolid,
  ChatBubbleLeftRightIcon as ChatBubbleLeftRightIconSolid,
  Cog8ToothIcon as Cog8ToothIconSolid,
  ExclamationTriangleIcon as ExclamationTriangleIconSolid,
  HomeIcon as HomeIconSolid,
  RectangleStackIcon as RectangleStackIconSolid,
  SparklesIcon as SparklesIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
  ChatBubbleBottomCenterTextIcon as ChatBubbleBottomCenterTextIconSolid,
} from "@heroicons/react/24/solid";
import Button from "../Button";
import Dropdown from "../Dropdown";
import Input from "../Input";
import Alert from "../../feedback/Alert";
import UserManager from "../../../tools/UserManager";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SubmitFeedback({
  wide = false,
  onToggleCollapsed = () => {},
}) {
  const goodAlert = {
    severity: "good",
    title: "Feedback Submitted",
    subtext: "Thank you for your feedback!",
  };

  const badAlert = {
    severity: "error",
    title: "Error",
    subtext: "There was an error submitting your feedback.",
  };

  const [collapsed, setCollapsed] = useState(true);
  const [feedbackType, setFeedbackType] = useState("general");
  const [feedback, setFeedback] = useState("");

  const [alertState, setAlertState] = useState({
    ...goodAlert,
    showing: false,
    setShowing: () => {},
  });

  const setShowing = (value) => {
    setAlertState({ ...alertState, showing: value });
  };

  function submitFeedback() {
    UserManager.makeAuthenticatedRequest("/api/feedback/submit", "POST", {
      feedbackType,
      feedback,
      fromPage: window.location.href,
    })
      .then((response) => {
        if (response.data.status === "ok") {
          setAlertState({ ...goodAlert, showing: true, setShowing });
          setFeedback(""); // Clear feedback
        } else {
          console.error(response);
          setAlertState({ ...badAlert, showing: true, setShowing });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlertState({ ...badAlert, showing: true, setShowing });
      });
  }

  useEffect(() => {
    if (!wide) {
      setCollapsed(true);
    }
  }, [wide]);

  const feedbackTypes = [
    [
      {
        label: "General Feedback",
        value: "general",
        icon: ChatBubbleLeftRightIcon,
      },
      {
        label: "Bug Report",
        value: "bug",
        icon: BugAntIcon,
      },
      {
        label: "Suggestion",
        value: "suggestion",
        icon: LightBulbIcon,
      },
      {
        label: "Compliment",
        value: "compliment",
        icon: HeartIcon,
      },
    ],
  ];

  return (
    <div className="">
      {/* //  TODO: close on right bar close */}
      <Alert {...alertState} />
      <div
        className={classNames(
          !collapsed
            ? "bg-gray-100 text-gray-900 hover:text-gray-900 dark:bg-gray-900 dark:text-white dark:hover:text-white"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 dark:hover:text-gray-100 dark:text-gray-300 dark:hover:bg-gray-700",
          "group flex gap-3 items-center rounded-md py-2 px-2 text-sm font-medium cursor-pointer"
        )}
        onClick={() => {
          setCollapsed(!collapsed);
          onToggleCollapsed(!collapsed);
        }}
      >
        {!collapsed && wide ? (
          <ChatBubbleBottomCenterTextIconSolid
            className={classNames(
              "text-gray-500 dark:text-white",
              "h-6 w-6 flex-shrink-0"
            )}
          />
        ) : (
          <ChatBubbleBottomCenterTextIcon
            className={classNames(
              "text-gray-400 group-hover:text-gray-500 dark:text-gray-300 dark:group-hover:text-gray-100",
              "h-6 w-6 flex-shrink-0"
            )}
          />
        )}
        {wide && "Feedback"}
      </div>

      {!collapsed && wide && (
        <div className="flex flex-col gap-2 pt-2">
          <Dropdown
            placeholder="Select a Feedback Type"
            wide
            label="Feedback Type"
            options={feedbackTypes}
            justifyLeft
            above
            selectedValue={feedbackType}
            onSelected={(option) => {
              setFeedbackType(option.value);
            }}
          />
          <Input
            placeholder="Enter your feedback here"
            type="textarea"
            label={"Feedback"}
            value={feedback}
            onChange={setFeedback}
          />
          <Button variant="primary-green" onClick={submitFeedback}>
            Submit Feedback
          </Button>
        </div>
      )}
    </div>
  );
}
