import React, { Component } from 'react'

export default class UserIcon extends Component {
    render() {
        return (
            <div style={{

                // Circle
                "width": 50,
                "height": 50,
                "borderRadius": "50%",

                // Background
                "backgroundColor": "var(--flo-primary)",
                
                // Shadow
                "boxShadow": "0px 5px 10px 3px rgba(var(--flo-primary-rgb), 0.25)",

                // Center text
                "textAlign": "center",
                "lineHeight": "50px",
            }}>
                Icon
            </div>
        )
    }
}
