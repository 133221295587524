import React, { useEffect, useState } from "react";
import classNames from "../../../tools/classNames";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Modal from "../../Modal";
import ListInput from "../../input/ListInput";

/**
 * Component for inputting a list of values for the cancel status values in an installer mapping.
 * Handles adding, updating, and deleting values.
 * Used in the EditMappingCard component within the OperationsSetupPage > DataMappingPage.
 * 
 * @param {Array} prop.values - Array of values
 * @param {Function} prop.setValues - Function to set values
 * @returns 
 */
export default function CancelStatusValueInputModal({
  values: prop_values = [],
  setValues: prop_setValues = () => {},
}) {
  const [values, setValues] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setValues(prop_values);
  }, [prop_values]);

  return (
    <>
      <div
        tabIndex={0}
        className={classNames(
          "bg-white border rounded-md px-3 py-2 h-9 cursor-pointer flex gap-2.5 items-center text-sm font-normal text-gray-900 whitespace-nowrap",
          values.length > 0 ? "border-gray-300" : "border-secondary-orange"
        )}
        onClick={() => {
          setShowModal(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setShowModal(true);
          }
        }}
      >
        <div className="inline-block overflow-hidden grow overflow-ellipsis">
          {values.length > 0
            ? values.map((v) => `"${v}"`).join(", ")
            : "No Values"}
        </div>
      </div>
      <Modal open={showModal} setOpen={setShowModal} wide>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <div className="text-lg font-semibold">Cancel Status Values</div>
            <div className="text-gray-500">
              Values where the <span className="font-bold">Cancel Status</span>{" "}
              field represents cancelled
            </div>
          </div>
          <div className="flex flex-col">
            <ListInput values={values} setValues={(values) => {
              setValues(values);
              prop_setValues(values);
            }} />
          </div>
        </div>
      </Modal>
    </>
  );
}
