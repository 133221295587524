import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import classNames from 'src/tools/classNames'

const sampleOptions = [
    {
        label: 'Edit',
        value: 'edit',
    },
    {
        label: 'Delete',
        value: 'delete',
    },
]


/**
 * @options - an array of objects that represent the options in the dropdown
 * @onSelected - a function that is called when an option is selected
 * @icon - an icon to display as the dropdown button
 * 
 * @returns - a dropdown component that allows users to select an option from a list, with a customizable icon
 */
export default function IconDropdown({
    options = [],
    onSelected = (item) => { },
    onClick = () => { },
    icon = null,
}) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    className="flex items-center text-gray-400 hover:text-gray-600"
                    onClick={onClick}
                >
                    <span className="sr-only">Open options</span>
                    {/* <EllipsisVerticalIcon className="w-5 h-5" aria-hidden="true" /> */}
                    {icon ? icon : <EllipsisVerticalIcon className="w-5 h-5 text-gray-600 hover:text-gray-400" aria-hidden="true" />}
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {options.map((item, itemIndex) => (
                            <Menu.Item key={itemIndex}>
                                {({ active }) => (
                                    <a
                                        href="#"
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'group flex items-center px-4 py-2 text-sm'
                                        )}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onSelected(item)
                                        }}
                                    >
                                        {item.icon && (
                                            <item.icon
                                                className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                        )}
                                        {item.label}
                                    </a>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
