import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BucketsDiagram({
  values,
  height = 45,
  connWidth = 12,
  // connHeight = 30,
  connHeight = 20,
  offset = 10,
  borderRadius = 6,
  selectedIndex = -1,
  onSelect,
  disabled = false,
  fill = "fill-gray-200",
}) {
  if (!values || !values.length) {
    return null;
  }

  const MAX_DIAGRAM_WIDTH = 250; // TODO: adjust?

  var widths = values.map((v) => v.width);
  var labels = values.map((v) => v.label);

  var strokeWidth = 3;

  var maxWidth = Math.max(...widths);

  // Adjust widths to be at least as wide as the connector between boxes
  // Fit within max diagram width if necessary
  var adjWidths = widths.map((w) => {
    var normalizedWidth = maxWidth > MAX_DIAGRAM_WIDTH ? MAX_DIAGRAM_WIDTH * w / maxWidth : w;

    return normalizedWidth + connWidth + 2 * borderRadius + 2;
  });

  var maxAdjWidth = adjWidths.reduce((a, b) => Math.max(a, b)) + connWidth;

  var totalHeight =
    widths.length * height + (widths.length - 1) * connHeight + offset * 2;
  var totalWidth = maxAdjWidth + offset * 2;

  function generateRects(classes = "", interactive = false) {
    var center = maxAdjWidth / 2.0;

    var upperInnerRad = (i) => (
      <path
        className={ fill }
        d={
          "M" +
          (center - connWidth / 2.0 + offset - borderRadius) +
          " " +
          (i * height + i * connHeight + offset + height) +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 1 " +
          borderRadius +
          " " +
          borderRadius +
          " h" +
          connWidth +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 1 " +
          borderRadius +
          " " +
          -borderRadius +
          " z"
        }
      />
    );

    var lowerInnerRad = (i) => (
      <path
        className={fill}
        d={
          "M" +
          (center - connWidth / 2.0 + offset - borderRadius) +
          " " +
          (i * height + i * connHeight + offset) +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 0 " +
          borderRadius +
          " " +
          -borderRadius +
          " h" +
          connWidth +
          " a" +
          borderRadius +
          " " +
          borderRadius +
          " 0 0 0 " +
          borderRadius +
          " " +
          borderRadius +
          " z"
        }
      />
    )

    return (
      <>
        {/* Rects */}
        {adjWidths.map((w, i) =>
          i === selectedIndex ? null : (
            <React.Fragment key={i}>
              <rect
                className={classes}
                x={
                  center -
                  w / 2.0 +
                  offset -
                  (i === selectedIndex ? strokeWidth : 0)
                }
                y={
                  i * height +
                  i * connHeight +
                  offset -
                  (i === selectedIndex ? strokeWidth : 0)
                }
                width={w + (i === selectedIndex ? strokeWidth * 2 : 0)}
                height={height + (i === selectedIndex ? strokeWidth * 2 : 0)}
                rx={borderRadius}
                onClick={() => interactive && onSelect(i)}
              />

              {i === adjWidths.length - 1 ? null : upperInnerRad(i)}
              {i === 0 ? null : lowerInnerRad(i)}
            </React.Fragment>
          )
        )}

        {/* Vertical Connector */}
        {!interactive && (
          <rect
            x={maxAdjWidth / 2.0 - connWidth / 2.0 + offset}
            y={offset}
            width={connWidth}
            height={widths.length * height + (widths.length - 1) * connHeight}
          />
        )}
      </>
    );
  }

  function generateOutlineRects() {
    var center = maxAdjWidth / 2.0;
    return (
      <>
        {adjWidths.map((w, i) =>
          i === selectedIndex ? null : (
            <rect
              key={i}
              x={center - w / 2.0 + offset - strokeWidth}
              y={i * height + i * connHeight + offset - strokeWidth}
              width={w + strokeWidth * 2}
              height={height + strokeWidth * 2}
              rx={borderRadius + strokeWidth}
            />
          )
        )}
        <rect
          x={maxAdjWidth / 2.0 - connWidth / 2.0 + offset - strokeWidth}
          y={offset}
          width={connWidth + strokeWidth * 2}
          height={widths.length * height + (widths.length - 1) * connHeight}
        />
      </>
    );
  }

  function generateSelectedRect() {
    if (selectedIndex < 0) {
      return null;
    }
    var w = adjWidths[selectedIndex];
    var center = maxAdjWidth / 2.0;
    
    return (
      <g className="drop-shadow-lg">
        <rect
          x={center - w / 2.0 + offset - strokeWidth}
          y={
            selectedIndex * height +
            selectedIndex * connHeight +
            offset -
            strokeWidth
          }
          width={w + strokeWidth * 2}
          height={height + strokeWidth * 2}
          rx={borderRadius + strokeWidth}
          className={classNames("fill-primary-rose cursor-pointer")}
          onClick={() => onSelect(selectedIndex)}
        />
      </g>
    );
  }

  function generateLabels() {
    var center = maxAdjWidth / 2.0;
    return (
      <>
        {labels.map((l, i) => (
          <text
            key={i}
            x={center + offset}
            y={i * height + i * connHeight + offset + height / 2.0}
            textAnchor="middle"
            alignmentBaseline="central"
            dominantBaseline="central"
            className={classNames(
              "text-lg font-bold",
              i === selectedIndex ? "fill-white" : "fill-gray-900"
            )}
          >
            {formatNumber(l)}
          </text>
        ))}
      </>
    );
  }

  return (
    <svg height={totalHeight} width={totalWidth} strokeWidth={strokeWidth}>
      <defs>
        <clipPath id="clip">{generateRects()}</clipPath>

        <clipPath id="clipOutline">{generateOutlineRects()}</clipPath>
      </defs>
      <g>
        {/* <rect
          width="100%"
          height="100%"
          clipPath="url(#clipOutline)"
          className={classNames("fill-gray-300")}
        /> */}

        {/* Main Shape */}
        <rect
          width="100%"
          height="100%"
          clipPath="url(#clip)"
          className={classNames(fill)}
        />

        {/* Hover Shapes */}
        {!disabled && generateRects(
          classNames(
            "opacity-0 hover:opacity-20 hover:cursor-pointer fill-primary-rose"
          ),
          true
        )}
        {generateSelectedRect()}
        {generateLabels()}
      </g>
    </svg>
  );
}


function formatNumber(n) {
  // Format number with commas
  return n?.toLocaleString("en-US");
}