import React, { Component } from 'react'
import { Nav, Navbar, Container, NavDropdown, Row, Col } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import UserManager from '../../tools/UserManager';
import FloNavItem from './FloNavItem';
import UserIcon from '../UserIcon';


export default class FloNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: props.page.toLowerCase(),
            user: {}
        }

        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        console.log('component mounted');

        UserManager.getUser()
            .then(user => {

                console.log(user);
                var userData = user.userData;
                var loggedIn = user.loggedIn;

                if (loggedIn && this.state.page === "login") {
                    window.location.pathname = "/app";
                }


                // Get markets
                console.log('making markets req');
                user.makeAuthenticatedRequest('/api/markets/installer/find', 'GET').then(res => {
                    console.log(res.data);

                    this.setState({
                        user: userData,
                        loggedIn: loggedIn,
                        page: this.state.page,
                        markets: res.data.markets
                    });

                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    console.log('done w/ req');
                });


                // Get installer
                console.log('making installer req');
                user.makeAuthenticatedRequest('/api/installers/findmy', 'GET').then(res => {
                    console.log('installer', res.data);

                    this.setState({ ...this.state, installer: res.data.installer });

                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    console.log('done w/ req');
                });


            });
    }

    isAppNav() {
        return this.state.page === "app";
    }

    isHomeNav() {
        return this.state.page === "home";
    }

    isLoginNav() {
        return this.state.page === "login";
    }

    render() {
        var leftNavItems = <></>;
        var rightNavItems = <></>;
        if (this.isAppNav() || this.state.loggedIn) {
            leftNavItems = <>
                <Nav.Link href="/app">Home</Nav.Link>
            </>
            rightNavItems = <>
                <Nav.Item>
                    {this.state.user && this.state.user.email}
                </Nav.Item>
                <Nav.Link href="/">Log Out</Nav.Link>
            </>
        } else if (this.isHomeNav()) {
            leftNavItems = <>
                <Nav.Link href="/">Home</Nav.Link>
            </>
            rightNavItems = <>
                <Nav.Link href="/login/login">Log In</Nav.Link>
                <Nav.Link href="/login/register">Register</Nav.Link>
            </>
        } else if (this.isLoginNav()) {
            leftNavItems = <>
                <Nav.Link href="/">Home</Nav.Link>
            </>
        }

        if (this.isAppNav()) {
            return (
                <>
                    <div className="flo-content flo-border-right h-100 w-100 pe-4">
                        <Navbar className="h-100">

                            <Nav defaultActiveKey="/app" className="flex-column h-100 w-100">

                                {/* Logo */}
                                <FloNavItem href="/app" contentType="logo">
                                    Flo
                                </FloNavItem>

                                {/* User Icon */}
                                {/* TODO: implement */}
                                <FloNavItem href="/app/user">
                                    <Row>
                                        <Col md="auto">
                                            <UserIcon />
                                        </Col>
                                        <Col>
                                            <Row style={{ "fontSize": 20, "lineHeight": "26px" }}>{this.state.user.firstName} {this.state.user.lastName}</Row>
                                            <Row>{this.state.installer && this.state.installer.name}</Row>
                                        </Col>
                                    </Row>
                                </FloNavItem>

                                {/* Main */}

                                {/* // TODO: implement */}
                                {/* <FloNavItem href="/app/issues">
                                    Issues
                                </FloNavItem> */}
                                <FloNavItem href="/app/forecasts" contentType="header">
                                    Forecasts
                                </FloNavItem>

                                {/* Markets */}
                                <FloNavItem href="/app/markets" contentType="header">
                                    Markets
                                </FloNavItem>
                                {this.state.markets && this.state.markets.map(market => {
                                    return (
                                        <FloNavItem key={market._id} href={"/app/markets/" + market._id} isSubItem>
                                            {market.name}
                                        </FloNavItem>
                                    )
                                })}

                                {/* Distributors */}
                                <FloNavItem href="/app/distributors" contentType="header">
                                    Distributors
                                </FloNavItem>
                                {this.state.distributors && this.state.distributors.map(distributor => {
                                    return (
                                        <FloNavItem key={distributor._id} href={"/app/distributor/" + distributor._id} isSubItem>
                                            {distributor.name}
                                        </FloNavItem>
                                    )
                                })}

                                {/* Jobs */}
                                <FloNavItem href="/app/jobs" contentType="header">
                                    Jobs
                                </FloNavItem>

                                {/* Crews */}
                                <FloNavItem href="/app/crews" contentType="header">
                                    Crews
                                </FloNavItem>

                                <div style={{ "position": "absolute", "bottom": 20 }}>
                                    {/* Settings */}
                                    <FloNavItem href="/app/settings">
                                        Settings
                                    </FloNavItem>
                                    <FloNavItem href="/app/support">
                                        Support
                                    </FloNavItem>
                                    <div onClick={this.logout}>
                                        <FloNavItem>
                                            Log Out
                                        </FloNavItem>
                                    </div>
                                </div>
                            </Nav>
                        </Navbar>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
                        <Container>
                            <Navbar.Brand href={this.isAppNav() ? "/app" : "/"}>
                                Flo
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="me-auto">
                                    {leftNavItems}
                                </Nav>
                                <Nav className="justify-content-end">
                                    {rightNavItems}
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </>
            )
        }
    }

    logout() {
        console.log('in logout in FloNav');
        UserManager.logout();
    }
}
