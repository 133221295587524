import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useEffect, useState } from "react";
import GridView from "../../components/Pipeline/Jobs/Views/GridView";
import ListView from "../../components/Pipeline/Jobs/Views/ListView";
import SearchBar from "../../components/SearchBar";
import Dropdown from "../../components/input/Dropdown";
import GridListToggle from "../../components/input/GridListToggle.tsx";
import Button from "../../components/input/Button";
import InputTable from "../../components/input/InputTable";
import Modal from "../../components/Modal";
import JobPipelineContext from "../../contexts/pipeline/jobs/JobPipelineContext";
import UserManager from "../../tools/UserManager";
import RowsView from "../../components/Pipeline/Jobs/Views/RowsView.tsx";
import MultiSelectListbox from "../../components/input/MultiSelectListbox.tsx";

import {
  formatJobsForTable,
  retrieveJobs,
} from "../../tools/Jobs/jobHelpers";

export default function PipelineJobsPage() {
  const [viewState, setViewState] = useState("grid");
  const [selectedStageIndex, setSelectedStageIndex] = useState(-1);
  const [selectedMarketIds, setSelectedMarketIds] = useState([]);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 15,
    totalItems: 0,
  });

  const [stages, setStages] = useState(null);
  const [markets, setMarkets] = useState(null);

  const [jobs, setJobs] = useState(null);

  const [search, setSearch] = useState("");

  const [showSharePipelineModal, setShowSharePipelineModal] = useState(false);

  const [distributors, setDistributors] = useState([]);

  const [selectedDistributorIds, setSelectedDistributorIds] = useState([]);

  const [sharedDistributors, setSharedDistributors] = useState([]);

  const [distributorOptions, setDistributorOptions] = useState([]);

  const tableColumns = [
    {
      Header: "Distributor",
      accessor: "distributor",
      placeholder: "",
      required: false,
      primary: true,
      columnWidth: 4,
      editable: false,
      editType: null,
      validate: (value) => { return true },
    },
  ]

  // TODO: is this used?
  const [dateFilter] = useState(
    // Start of last 12 months (e.g. If today is 11/10/2023, should be 11/1/2022 inclusive)
    moment.utc().subtract(12, "months").startOf("month").toISOString()
  );

  const [cycleTimeStats, setCycleTimeStats] = useState(null);
  const [conversionStats, setConversionStats] = useState(null);
  const [installStats, setInstallStats] = useState(null);
  const [soldStats, setSoldStats] = useState(null);

  const [combinedForecast, setCombinedForecast] = useState(null);

  // when sharedDistributors changes, update the distributorOptions to be all distributors minus the sharedDistributors
  useEffect(() => {
    setDistributorOptions(
      distributors.map((d) => {
        // if the distributor is already shared, don't show them in the multi select listbox
        if (sharedDistributors.find((distributor) => distributor.id === d.id)) {
          return null
        }
        return {
          label: d.name,
          value: d.id,
        };
      }).filter((d) => d !== null)
    )
  }, [sharedDistributors, distributors])

  useEffect(() => {
    // Get markets
    UserManager.makeAuthenticatedRequest("/api/markets/installer/find", "GET")
      .then((res) => {
        var status = res.data.status;
        if (status === "ok") {
          var { markets } = res.data;
          setMarkets(markets);
          let newSelectedMarketIds = markets.map((m) => m._id);
          setSelectedMarketIds(newSelectedMarketIds)

          // Get jobs
          retrieveJobs(
            pagination.currentPage,
            null,
            newSelectedMarketIds,
            search,
            dateFilter,
            setJobs,
            setStages,
            setCycleTimeStats,
            setConversionStats,
            setInstallStats,
            setSoldStats,
            setPagination,
            pagination
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });

    // Get shared distributors
    getSharedDistributors();

    // Get all connected distributors
    getDistributors();
  }, []);

  // Get current forecast on market change
  useEffect(() => {
    if (selectedMarketIds) {
      getCurrentForecasts(selectedMarketIds)
        .then((res) => {
          setCombinedForecast(res.data.combined);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setCombinedForecast(null);
    }
  }, [selectedMarketIds]);

  function handlePageChange(page) {
    setPagination({
      ...pagination,
      currentPage: page,
    });

    retrieveJobs(
      page,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      selectedMarketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );
  }

  function handleSearch(search) {
    // TODO: debounce?

    search = search.target.value;

    // Go back to page 1 and update search
    setPagination({
      ...pagination,
      currentPage: 1,
    });
    setSearch(search);

    retrieveJobs(
      1,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      selectedMarketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );
  }

  function handleSetSelectedStage(i, toggle = false) {
    if (toggle) {
      i = i === selectedStageIndex ? -1 : i;
    }

    // Update selected stage
    setSelectedStageIndex(i);

    // Filter jobs based on selected stage
    retrieveJobs(
      pagination.currentPage,
      i !== -1 && stages ? stages[i]._id : null,
      selectedMarketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );

    // TODO:  reset month select
  }


  function handleMarketSelected(marketIds) {
    setSelectedMarketIds(marketIds);

    retrieveJobs(
      pagination.currentPage,
      selectedStageIndex !== -1 && stages
        ? stages[selectedStageIndex]._id
        : null,
      marketIds,
      search,
      dateFilter,
      setJobs,
      setStages,
      setCycleTimeStats,
      setConversionStats,
      setInstallStats,
      setSoldStats,
      setPagination,
      pagination
    );

    // TODO: Reset month select
  }


  /**
   * Get shared distributors
   */
  function getSharedDistributors() {
    UserManager.makeAuthenticatedRequest(
      "/api/pipeline/get-shared",
      "GET"
    ).then((res) => {
      if (res.data.status === "ok") {
        console.debug(res.data.distributors)
        setSharedDistributors(structureDistributorTableData(res.data.distributors));
      } else {
        console.error(res.data.message);
      }
    }).catch((err) => {
      console.error(err);
    });
  }


  /**
 * Get all connected distributors.
 */
  function getDistributors() {
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list",
      "GET"
    ).then((res) => {
      if (res.data.status === "ok") {
        setDistributors(
          res.data.connections.map((connection) => {
            if (connection.status === "active" || connection.status === "pending_request") {
              return connection;
            }
          })
          .filter((connection) => connection)
        );
      } else {
        console.error(res.data.message);
      }
    });
  }

  /**
   * Handle distributor selection
   */
  function handleDistributorsSelected(distributorIds) {
    setSelectedDistributorIds(distributorIds);
  }


  /**
   * Stop sharing pipeline with distributor
   * @param distributorId - the id of the distributor to remove
   */
  function removeDistributor(distributorId) {
    UserManager.makeAuthenticatedRequest(
      "/api/pipeline/remove-shared",
      "POST",
      {
        distributorIds: [distributorId]
      }
    )
      .then((res) => {
        if (res.data.status === "ok") {
          // Update shared distributors
          setSharedDistributors(sharedDistributors.filter((d) => d.id !== distributorId));
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * 
   * @param newDistributors  
   * @returns structured data for the distributor table
   */
  function structureDistributorTableData(newDistributors) {
    return newDistributors.map((distributor) => {
      return {
        id: distributor._id ? distributor._id : distributor.id,
        distributor: {
          value: distributor.name,
          className: '',
        },
        rowStatus: 'readonly',
        menuOptions: [
          {
            label: 'Remove',
            value: 'remove',
            onSelected: (item) => {
              removeDistributor(item.id)
            }
          }
        ],
        inlineButtons: [],
      }
    })
  }


  /**
   * Add new distributors to the shared distributor table
   * @param distributorIds - the ids of the distributors to add
   */
  function addTableData(distributorIds) {
    let newDistributors = distributors.filter((d) => distributorIds.includes(d.id));
    let newTableData = structureDistributorTableData(newDistributors);
    setSharedDistributors([...sharedDistributors, ...newTableData]);
  }


  /**
   * Share pipeline with selected distributors
   */
  function sharePipeline() {
    UserManager.makeAuthenticatedRequest(
      "/api/pipeline/share",
      "POST",
      {
        distributorIds: selectedDistributorIds
      }
    )
      .then((res) => {
        if (res.data.status === "ok") {
          // Update shared distributors
          // setSharedDistributors(res.data.shared);
          addTableData(selectedDistributorIds);
          setSelectedDistributorIds([]);
        } else {
          console.error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }


  /**
   * close the share pipeline modal
   */
  function closeSharePipelineModal() {
    setShowSharePipelineModal(false);
    setSelectedDistributorIds([]);
  }


  // Generate markets options
  var allMarketsOption = { label: "All Markets", value: null };
  var marketsOptions = markets
    ? [
      [allMarketsOption],
      markets.map((m) => {
        return {
          label: m.name,
          value: m._id,
        };
      }),
    ]
    : [[allMarketsOption]];

  var content = {
    grid: (
      <GridView
        cycleTimeStats={cycleTimeStats}
        conversionStats={conversionStats}
        installStats={installStats}
        soldStats={soldStats}
      />
    ),
    columns: <ListView />,
    rows: <RowsView />,
    list: <RowsView compact />
  }[viewState];

  let contextValue = {
    stages,
    selectedStageIndex,
    markets,
    selectedMarketIds,
    setSelectedStageIndex: handleSetSelectedStage,
    setSelectedMarketId: handleMarketSelected,
    jobPagination: pagination,
    jobs,
    formattedJobs: formatJobsForTable(jobs, stages, markets),
    setJobListPage: handlePageChange,

    cycleTimeStats,
    conversionStats,
    installStats,
    soldStats,

    combinedForecast,

    setViewState
  }

  return (
    <JobPipelineContext.Provider value={contextValue}>
      <Modal
        open={showSharePipelineModal}
        setOpen={setShowSharePipelineModal}
        wide
      >
        <div className="flex flex-col w-[750px]">
          {/* Content */}
          <div className="flex flex-col divide-y">
            {/* Top Section */}
            <div className="flex flex-col gap-6 pb-6 mx-8">
              <div className="flex flex-col">
                <div className="text-base font-semibold">Share Pipeline</div>
                <div className="text-sm text-gray-500">
                  Share your pipeline with connected distributors.
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-medium">
                  Select Distributors
                </div>
                <div className="flex items-center justify-start gap-2 text-gray-500 dark:text-gray-400">
                  <MultiSelectListbox
                    itemType="Distributor"
                    options={distributorOptions}
                    selectedOptionsValues={selectedDistributorIds}
                    onChange={(values) => {
                      handleDistributorsSelected(values)
                    }}
                  />
                  <Button
                    variant="primary"
                    disabled={selectedDistributorIds.length === 0}
                    onClick={() => {
                      sharePipeline();
                    }}
                  >
                    Share Pipeline
                  </Button>
                </div>
              </div>
            </div>

            {/* Bottom Section */}
            <div className="">
              <div className='mx-8 mt-6 text-sm font-medium leading-5 text-left align-middle'>
                Distributors with access to your pipeline
              </div>
              {/* if the table overflows, scroll y */}
              <div className="overflow-y-auto max-h-[50vh]">
                <InputTable
                  columns={tableColumns}
                  tableData={sharedDistributors}
                  innerAddButton={false}
                  outerAddButton={false}
                  editableRows={false}
                  setTableData={setSharedDistributors}
                />
              </div>

            </div>
          </div>

          {/* Buttons */}
          <div className="relative flex justify-end gap-3 px-4 py-3 -mx-4 bg-gray-100 rounded-b-lg dark:bg-gray-700 sm:-mx-6 -bottom-4 sm:-bottom-6">
            <Button
              variant="primary"
              onClick={closeSharePipelineModal}
            >
              Done
            </Button>
          </div>

        </div>
      </Modal>

      {/* Actions */}
      < div className="flex flex-wrap items-center gap-4 p-2 mx-2" >
        <div>
          <MultiSelectListbox
            itemType="Market"
            options={marketsOptions[1]}
            selectedOptionsValues={selectedMarketIds}
            onChange={(values) => {
              handleMarketSelected(values);
            }}
          />
        </div>
        <div className="mr-auto sm:mr-0">
          <Dropdown
            options={
              stages
                ? [
                  [
                    {
                      label: "All Stages",
                      value: -1,
                    },
                  ],
                  stages.slice(1).map((s, i) => ({
                    label: s.name,
                    value: i + 1,
                  })),
                ]
                : [
                  [
                    {
                      label: "All Stages",
                      value: -1,
                    },
                  ],
                ]
            }
            placeholder="Select a Stage"
            bold
            justifyLeft
            onSelected={(item) => {
              handleSetSelectedStage(item.value);
            }}
            selectedValue={selectedStageIndex}
          />
        </div>

        <div className="flex flex-wrap items-center gap-4 grow">
          <div className="flex grow">
            <div className="shrink">
              <SearchBar value={search} onChange={handleSearch} />
            </div>
          </div>

          <div className="flex flex-row items-center justify-center gap-4">
            <Button
              variant="secondary"
              onClick={() => {
                setShowSharePipelineModal(true);
              }}
            >
              Share Pipeline
            </Button>

            <GridListToggle
              selectedOption={viewState}
              options={["grid", "rows", "list", "columns"]}
              onChange={(selected) => {
                setViewState(selected);
              }}
            />
          </div>
        </div>

        {/* TODO: implement add job */}
        {/* <div>
          <button className="px-4 py-2 text-sm font-semibold text-white rounded-md bg-primary-green">
            Add Job
          </button>
        </div> */}
      </div >

      <div className="overflow-auto">{content}</div>
    </JobPipelineContext.Provider >
  );
}

async function getCurrentForecasts(marketIds) {
  let url = "/api/forecast/installer/get-by-markets";
  url += `?markets=${marketIds}`;

  try {
    var res = await UserManager.makeAuthenticatedRequest(url);
  } catch (err) {
    console.error(err);
  }
  return res;
}
