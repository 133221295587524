import React, { useState, useEffect, useRef } from 'react'
import Modal from '../Modal'
import Dropdown from '../input/Dropdown'
import ComboboxSearchBar from '../ComboboxSearchBar';
import classNames from '../../tools/classNames'
import Button from '../input/Button'
import Input from '../input/Input'
import TimeRangeInput from '../input/TimeRangeInput.tsx';
import UserManager from '../../tools/UserManager'
import {
  XMarkIcon,
  UserPlusIcon,
  PaperClipIcon,
  CheckCircleIcon,
  XCircleIcon,
  PlusCircleIcon,
  MinusCircleIcon
} from '@heroicons/react/20/solid'
import {
  TrashIcon
} from '@heroicons/react/24/outline'
import {
  PencilIcon
} from '@heroicons/react/24/solid'
import moment from "moment";

/**
 * SharedWithBadge component
 */
function SharedWithBadge({
  user,
  removeUser,
  xIcon = true
}) {
  return (
    <div className='flex flex-row items-center gap-2 px-2 py-2 border border-gray-200 rounded-md w-fit bg-gray-50'>
      <div className='text-sm font-medium leading-5 text-center text-gray-700 align-middle'>
        {user.email}
      </div>
      {xIcon && (
        <XMarkIcon
          onClick={removeUser(user.id)}
          className="w-4 h-4 text-gray-700 cursor-pointer hover:text-gray-500"
        />
      )}
    </div>
  )
}

/**
 * Modal component for creating a new order,
 */
export default function OrderModal({
  showOrderModal,
  setShowOrderModal,
  data
}: {
  showOrderModal: boolean;
  setShowOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: {
    name: string,
    size: string,
    address: {
      line1: string,
      line2: string,
      city: string,
      state: string,
      postalCode: string
    },
    id: string,
    installerId: string,
    marketId: string,
  }
}) {

  const [jobData, setJobData] = useState(data)

  const [currentUser, setCurrentUser] = useState<any>({})

  const [poNumber, setPoNumber] = useState('')

  const [distributors, setDistributors] = useState([])

  const [selectedDistributor, setSelectedDistributor] = useState('')

  const [installerUsers, setInstallerUsers] = useState([])

  const [distributorUsers, setDistributorUsers] = useState([])

  const [userSharingSearchValue, setUserSharingSearchValue] = useState('')

  const [userSharingSearchResults, setUserSharingSearchResults] = useState([])

  const [primaryContact, setPrimaryContact] = useState('')

  const [installerOnSiteLead, setInstallerOnSiteLead] = useState('')

  const [quoteRequired, setQuoteRequired] = useState(true)

  const [selectedBOMFile, setSelectedBOMFile] = useState(null)

  const BOMInputRef = useRef(null)

  const additionalAttachmentsInputRef = useRef(null)

  const [editingAddress, setEditingAddress] = useState(false)

  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState('')

  const [deliveryTimeRangeStart, setDeliveryTimeRangeStart] = useState('')

  const [deliveryTimeRangeEnd, setDeliveryTimeRangeEnd] = useState('')

  const [sharedUsers, setSharedUsers] = useState([])

  const [additionalAttachments, setAdditionalAttachments] = useState([])

  const [notes, setNotes] = useState('')

  // when orderData changes, update the jobName, jobSize, jobAddress, jobId, installerId, and marketId
  // this happens when the user clicks on the "Order" button on the OrdersPage 
  useEffect(() => {
    setJobData(data)
  }, [data])

  // get connected distributors and installer users
  useEffect(() => {
    // get connected distributors
    UserManager.makeAuthenticatedRequest(
      "/api/company/connections-list",
      "GET"
    )
      .then((response) => {
        setDistributors(response.data.connections.map((connection) => ({
          value: connection.id,
          label: connection.name
        })))
      })

    UserManager.getUser()
      .then((user) => {
        setCurrentUser(user)
        UserManager.makeAuthenticatedRequest(
          "/api/company/users?companyId=" + user.userData.company.id,
          "GET"
        )
          .then((response) => {
            setInstallerUsers(response.data.users.map((user) => ({
              value: user._id,
              label: user.email
            })))
          })

          // add user to sharedUsers
          setSharedUsers([
            {
              email: user.userData.email,
              id: user.userData._id
            }
          ])
      })
  }, [])

  // get distributor users when selectedDistributor changes
  useEffect(() => {
    // remove all distributorUsers from sharedUsers
    setSharedUsers(sharedUsers.filter((user) => {
      return !distributorUsers.find((distributorUser) => distributorUser.value === user.id)
    }))

    if (selectedDistributor) {
      UserManager.makeAuthenticatedRequest(
        "/api/company/users?companyId=" + selectedDistributor,
        "GET"
      )
        .then((response) => {
          setDistributorUsers(response.data.users.map((user) => ({
            value: user._id,
            label: user.email
          })))
        })
    }

  }, [selectedDistributor])

  /**
   * Update userSharingSearchResults
   */
  function handleSearchValueChange(value) {
    setUserSharingSearchValue(value)

    // set userSharingSearchResults to all users in distributorUsers + installerUsers that match the search value
    // search results should have an _id and name
    // sort alphabetically by name
    setUserSharingSearchResults(
      distributorUsers.concat(installerUsers).map((user) => ({
        _id: user.value,
        name: user.label
      })).filter((user) =>
        user.name.toLowerCase().includes(value.toLowerCase())
        && !sharedUsers.find((sharedUser) => sharedUser.id === user._id)
      )
        .sort((a, b) => a.name.localeCompare(b.name))
    )
  }

  /**
   * Add user to sharedUsers
   */
  function handleAddSharedUser(user) {
    if (user) {
      // add user selected from search results to sharedUsers
      setSharedUsers([
        ...sharedUsers,
        {
          email: user.name,
          id: user._id
        }
      ])
    }
  }

  /**
   * Close the modal, clear all fields
   */
  function handleCloseModal(value) {
    // clear all fields
    setSelectedDistributor('')
    setDistributorUsers([])
    setUserSharingSearchValue('')
    setUserSharingSearchResults([])
    setPrimaryContact('')
    setQuoteRequired(true)
    setEditingAddress(false)
    setDeliveryTimeRangeStart('')
    setDeliveryTimeRangeEnd('')
    setSharedUsers([
        {
          email: currentUser.userData.email,
          id: currentUser.userData._id
        }
    ])
    setInstallerOnSiteLead('')
    setNotes('')
    setAdditionalAttachments([])
    setSelectedBOMFile(null)
    setPoNumber('')

    setShowOrderModal(value)
  }

  /**
   * Update selectedBOMFile when a file is selected
   */
  function handleOnBOMFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedBOMFile(event.target.files[0]);
    }
  }

  /**
   * Update additionalAttachments when files are selected
   */
  function handleOnAdditionalAttachmentsChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      setAdditionalAttachments([
        ...additionalAttachments,
        ...Array.from(event.target.files)
      ])
    }
  }

  /**
   * Open file dialog to choose BOM file
   */
  function onChooseBOMFile() {
    BOMInputRef.current.click();
  }

  /**
   * Open file dialog to choose additional attachments
   */
  function onChooseAdditionalAttachments() {
    additionalAttachmentsInputRef.current.click();
  }

  /**
   * Remove selected BOM file
   */
  function removeBOMFile() {
    setSelectedBOMFile(null);
    BOMInputRef.current.value = null;
  }

  /**
   * Remove additional attachment at index
   */
  function removeAdditionalAttachment(index) {
    setAdditionalAttachments(additionalAttachments.filter((_, i) => i !== index));
    additionalAttachmentsInputRef.current.value = null;
  }

  /**
   * Upload file to S3
   * 
   * @param {File} file - file to upload
   * @param {string} fileName - name of the file 
   */
  async function uploadFileToS3(file, fileName) {
    await UserManager.makeAuthenticatedRequest(
      `/api/s3/get-upload-url?bucket=toa-order-attachments&fileName=${fileName}`,
      'GET',
    )
      .then((response) => {
        if (response.data.status === 'ok') {
          const putObjectSignedUrl = response.data.putObjectSignedUrl

          // upload BOM to S3
          fetch(putObjectSignedUrl, {
            method: 'PUT',
            body: file
          })
            .then((response) => {

            })
            .catch((error) => {
              console.error(error)
            })
        }
      })
  }

  /**
   * Build a filepath for the BOM, upload it to S3, and return the filepath
   */
  async function uploadBOMToS3(): Promise<string> {
    // create a filepath for the BOM
    var bomFilePath = `${jobData.installerId}/${jobData.marketId}/${jobData.id}/${selectedBOMFile.name}`

    // upload BOM to S3
    await uploadFileToS3(selectedBOMFile, bomFilePath)

    return bomFilePath
  }

  /**
   * Upload additional attachments to S3 and return an array of filepaths
   */
  async function uploadAdditionalAttachmentsToS3(): Promise<string[]> {
    var attachmentFilePaths = []

    const promises = additionalAttachments.map(async (attachment) => {
      // create a filepath for the additional attachment
      var attachmentFilePath = `${jobData.installerId}/${jobData.marketId}/${jobData.id}/${attachment.name}`

      // upload additional attachment to S3
      await uploadFileToS3(attachment, attachmentFilePath)

      attachmentFilePaths.push(attachmentFilePath)
    });

    await Promise.all(promises);

    return attachmentFilePaths
  }

  /**
   * Upload BOM and additional attachments to S3, then submit the order via /api/orders/create API endpoint
   */
  async function handleSubmitOrder() {
    // upload BOM to S3
    var bomFilePath = await uploadBOMToS3()

    // upload additional attachments to S3
    var additionalAttachmentFilePaths = await uploadAdditionalAttachmentsToS3()

    UserManager.makeAuthenticatedRequest(
      '/api/orders/create',
      'POST',
      {
        jobId: jobData.id,
        marketId: jobData.marketId,
        distributorId: selectedDistributor,
        primaryContactId: primaryContact,
        installerOnSiteLeadId: installerOnSiteLead,
        sharedWithIds: sharedUsers.map((user) => user.id),
        poNumber: poNumber,
        quoteRequired: quoteRequired,
        requestedDeliveryDate: requestedDeliveryDate,
        requestedDeliveryTimeRange: {
          start: deliveryTimeRangeStart,
          end: deliveryTimeRangeEnd
        },
        orderAddress: {
          line1: jobData.address.line1,
          line2: jobData.address.line2,
          city: jobData.address.city,
          state: jobData.address.state,
          postalCode: jobData.address.postalCode
        },
        bomFilePath: bomFilePath,
        additionalAttachmentFilePaths: additionalAttachmentFilePaths,
        notes: notes
      }
    )
      .then((response) => {
        //TODO: show success message
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Modal
      open={showOrderModal}
      setOpen={handleCloseModal}
      wide
    >
      <div className="flex flex-col items-center justify-start w-[768px] gap-4 px-4 mb-12">
        <div className='flex flex-row justify-between w-full'>
          <div>
            <div className="text-base font-semibold leading-6 text-left align-middle">
              Purchase Order
            </div>
            <div className='text-sm font-normal leading-5 text-left text-gray-500 align-middle'>
              Request Material or Material Quote
            </div>
          </div>
          <div className='flex flex-row items-center gap-2'>
            <div className='text-sm font-medium leading-5 text-left align-middle'>
              PO#
            </div>
            <Input
              placeholder='Optional'
              name="poNumber"
              value={poNumber}
              onChange={(value) => {
                setPoNumber(value)
              }}
            />
          </div>
        </div>

        <div className='flex flex-row w-full gap-4'>
          <Dropdown
            label="Submit to Distributor"
            justifyLeft
            wide
            className='w-full'
            placeholder="Select a Distributor"
            options={[distributors]}
            onSelected={(selected) => {
              setSelectedDistributor(selected.value)
            }}
            selectedValue={selectedDistributor}
          />
          <Dropdown
            label="Distributor Contact"
            justifyLeft
            wide
            className='w-full'
            placeholder="Select a Primary Contact"
            options={[distributorUsers]}
            onSelected={(selected) => {
              setPrimaryContact(selected.value)
              // add primary contact to shared users
              if (!sharedUsers.find((user) => user.id === selected.value)) {
                setSharedUsers([
                  ...sharedUsers,
                  {
                    email: selected.label,
                    id: selected.value
                  }
                ])
              }
            }}
            selectedValue={primaryContact}
          />
          <div
            className="border-l border-gray-300"
          />
          <Dropdown
            label="Installer Onsite Lead"
            justifyLeft
            wide
            className='w-full'
            placeholder="Select an Onsite Lead"
            options={[installerUsers]}
            onSelected={(selected) => {
              setInstallerOnSiteLead(selected.value)
              // add installer onsite lead to shared users
              if (!sharedUsers.find((user) => user.id === selected.value)) {
                setSharedUsers([
                  ...sharedUsers,
                  {
                    email: selected.label,
                    id: selected.value
                  }
                ])
              }
            }}
            selectedValue={installerOnSiteLead}
          />
        </div>

        <div className='flex flex-col w-full gap-3 py-2'>
          <div className="flex flex-row items-center gap-4">
            <div className='text-sm font-medium leading-5 text-left align-middle'>
              Shared With:
            </div>
            <div className='relative -mt-1'>
              <ComboboxSearchBar
                placeholder="Search for a user"
                searchValue={userSharingSearchValue}
                searchResults={userSharingSearchResults}
                setSearchValue={handleSearchValueChange}
                onSelectItem={handleAddSharedUser}
              />
            </div>
          </div>
          <div className='flex flex-row flex-wrap items-center gap-2 overflow-y-auto max-h-[90px]'>
            {sharedUsers.map((user, index) => (
              <SharedWithBadge
                key={user.id}
                user={user}
                removeUser={(id) => () => {
                  setSharedUsers(sharedUsers.filter(user => user.id !== id))
                }}
                xIcon={user.id !== currentUser?.userData?._id}
              />
            ))}
          </div>
        </div>

        <div className='flex flex-row items-center w-full gap-3'>
          <div className="text-sm font-medium leading-5 text-left align-middle">
            Quote Required
          </div>
          <div>
            <input
              type="checkbox"
              className="w-4 h-4 border-gray-300 rounded text-primary-green focus:ring-primary-green"
              checked={quoteRequired}
              onChange={() => setQuoteRequired(!quoteRequired)}
            />
          </div>
        </div>

        <div className="w-full border-t border-gray-300" />

        <div className="flex flex-row w-full gap-8">
          <div className='flex flex-col w-full gap-4'>
            <div className="flex flex-col gap-2">
              <div className='text-sm font-medium leading-5 text-left align-middle'>
                Job Name
              </div>
              <div className="mx-2 text-sm font-normal leading-5 text-left text-gray-500 align-middle">
                {jobData.name}
              </div>
            </div>

            <div className='flex flex-col w-full gap-6'>
              <Input
                type="date"
                label="Requested Delivery Date"
                name="requestedDeliveryDate"
                min={""}
                max={""}
                step={""}
                value={""}
                onChange={(value) => {
                  // right now value is formatted as "YYYY-MM-DD", use moment to format it in date time string format, i.e. "YYYY-MM-DDTHH:mm:ss.sssZ"
                  setRequestedDeliveryDate(moment(value).format("YYYY-MM-DD"))
                }}
              />
              <TimeRangeInput
                enforceStartBeforeEnd
                onChange={({
                  startTime,
                  endTime,
                }) => {
                  // convert startTime and endTime to date time string f
                  // they are currently in the format "HH:MM", so we need to add the date and local timezone
                  // format it as "HH:mm:ss.sssZ" with moment
                  setDeliveryTimeRangeStart(moment(startTime, "HH:mm").format("HH:mm:ss.sssZ"))
                  setDeliveryTimeRangeEnd(moment(endTime, "HH:mm").format("HH:mm:ss.sssZ"))
                }}
                label="Requested Delivery Time Range"
              />
            </div>
          </div>

          <div className='flex flex-col w-full gap-4'>
            <div className="flex flex-col gap-2">
              <div className='text-sm font-medium leading-5 text-left align-middle'>
                Job Size
              </div>
              <div className="mx-2 text-sm font-normal leading-5 text-left text-gray-500 align-middle">
                {jobData.size} kW
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className='flex flex-row items-center gap-2'>
                <div className='text-sm font-medium leading-5 text-left align-middle'>
                  Address
                </div>
                {!editingAddress && (
                  <PencilIcon
                    onClick={() => setEditingAddress(true)}
                    className="w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-400"
                  />
                )}
              </div>
              {editingAddress
                ? (
                  <div className='flex flex-col items-center justify-start w-full gap-2'>
                    <div className='w-full'>
                      <Input
                        type="text"
                        placeholder='Street Address Line 1'
                        // label="Street Address"
                        name="streetAddress"
                        value={jobData.address.line1}
                        onChange={(value) => {
                          setJobData({
                            ...jobData,
                            address: {
                              ...jobData.address,
                              line1: value
                            }
                          })
                        }}
                      />
                    </div>
                    <div className='w-full'>
                      <Input
                        type="text"
                        placeholder='Street Address Line 2'
                        // label="Street Address Line 2"
                        name="streetAddress2"
                        value={jobData.address.line2 ? jobData.address.line2 : ''}
                        onChange={(value) => {
                          setJobData({
                            ...jobData,
                            address: {
                              ...jobData.address,
                              line2: value
                            }
                          })
                        }}
                      />
                    </div>
                    <div className="flex flex-row w-full gap-2">
                      <Input
                        type="text"
                        placeholder='City'
                        name="city"
                        value={jobData.address.city}
                        onChange={(value) => {
                          setJobData({
                            ...jobData,
                            address: {
                              ...jobData.address,
                              city: value
                            }
                          })
                        }}
                      />
                      <Input
                        type="text"
                        placeholder='State'
                        name="state"
                        value={jobData.address.state}
                        onChange={(value) => {
                          setJobData({
                            ...jobData,
                            address: {
                              ...jobData.address,
                              state: value
                            }
                          })
                        }}
                      />
                      <Input
                        type="text"
                        placeholder='Zip Code'
                        name="zipCode"
                        value={jobData.address.postalCode}
                        onChange={(value) => {
                          setJobData({
                            ...jobData,
                            address: {
                              ...jobData.address,
                              postalCode: value
                            }
                          })
                        }}
                      />
                    </div>
                  </div>
                )
                : (
                  <div className="mx-2 text-sm font-normal leading-5 text-left text-gray-500 align-middle">
                    {jobData.address?.line1}{jobData.address?.line2 && " " + jobData.address?.line2}, {jobData.address?.city}, {jobData.address?.state}, {jobData.address?.postalCode}
                  </div>
                )}
            </div>
          </div>
        </div>

        {/* hidden file input element for BOM */}
        <input
          ref={BOMInputRef}
          type="file"
          className="hidden"
          onChange={handleOnBOMFileChange}
        />

        {/* hidden file input element for additional attachments */}
        <input
          ref={additionalAttachmentsInputRef}
          type="file"
          className="hidden"
          onChange={handleOnAdditionalAttachmentsChange}
          multiple
        />


        {selectedBOMFile
          ? (
            <div className='flex flex-row items-center justify-between w-[816px] px-10 py-4 -mx-8 border-b border-gray-300'>
              <div className='flex flex-row items-center justify-start gap-2'>
                <CheckCircleIcon className="w-7 h-7 text-primary-green" />
                <div className='text-sm font-bold leading-5 text-gray-500'>
                  BOM
                </div>
              </div>

              <div className='flex flex-row items-center justify-center gap-2'>
                {/* overflow-hidden text-sm font-medium leading-5 text-left text-gray-500 align-middle text-ellipsis max-w-80 text-nowrap */}
                <div className='overflow-hidden text-sm font-normal leading-5 text-left text-gray-500 align-middle text-ellipsis text-nowrap max-w-72'>
                  {selectedBOMFile.name}
                </div>
                <div
                  className={classNames(
                    "text-sm leading-5 font-semibold align-middle text-left flex justify-center cursor-pointer",
                    "text-primary-green hover:text-primary-green-400"
                  )}
                  onClick={() => {
                    window.open(URL.createObjectURL(selectedBOMFile))
                  }}
                >
                  View
                </div>
              </div>

              <div className='flex justify-end pr-3'>
                <TrashIcon
                  className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-400"
                  onClick={() => {
                    removeBOMFile()
                  }}
                />
              </div>
            </div>
          )
          : (
            <div className='flex flex-row items-center w-[816px] px-10 py-3 -mx-8 border-b border-gray-300'>
              <Button
                onClick={() => {
                  onChooseBOMFile()
                }}
                variant='primary'
              >
                Upload Bill of Materials
              </Button>
            </div>
          )}

        <div 
          className={classNames(
            'flex flex-col items-center w-[816px] px-10 -mx-8 border-b border-gray-300',
            additionalAttachments.length > 0 ? 'pb-1' : 'pb-4'
          )}
        >
          <div className='flex flex-row items-center justify-between w-full gap-2'>
            <div className='flex flex-row items-center gap-2'>
              <div className='text-sm font-bold leading-5 text-gray-500'>
                Additional Attachments
              </div>
            </div>
            <div className='pr-2'>
              <PlusCircleIcon
                className="cursor-pointer w-7 h-7 text-primary-green hover:text-primary-green-400"
                onClick={() => {
                  onChooseAdditionalAttachments()
                }}
              />
            </div>
          </div>
          <div className='flex flex-col w-full gap-1 pl-6'>
            {additionalAttachments.map((attachment, index) => (
              <div
                key={index}
                className={classNames(
                  'flex flex-row items-center justify-between w-full gap-2 px-3 py-4',
                  index > 0 && 'border-t border-gray-300'
                )}
              >
                <div className='flex flex-row items-center justify-start gap-2'>
                  <div className='overflow-hidden text-sm font-medium leading-5 text-left text-gray-500 align-middle text-ellipsis max-w-80 text-nowrap'>
                    {attachment.name}
                  </div>
                  <div
                    className='text-sm font-semibold leading-5 text-left align-middle cursor-pointer hover:text-primary-green-400 text-primary-green'
                    onClick={() => {
                      window.open(URL.createObjectURL(attachment))
                    }}
                  >
                    View
                  </div>
                </div>

                <div>
                  <TrashIcon
                    className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-400"
                    onClick={() => {
                      removeAdditionalAttachment(index)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='flex items-center justify-center w-full -mx-6'>
          <div className='w-full'>
            <Input
              type="textarea"
              label="Notes"
              name="notes"
              placeholder='Add any additional notes here'
              value={notes}
              onChange={(value) => setNotes(value)}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-row justify-end gap-2 px-3 py-3 -mx-6 -my-6 bg-gray-50 rounded-b-md'>
        <Button
          onClick={() => {
            handleSubmitOrder()
            handleCloseModal(false)
          }}
          variant='primary'
          disabled={
            ![
              selectedDistributor,
              primaryContact,
              sharedUsers.length,
              jobData.id,
              jobData.address?.line1,
              jobData.address?.city,
              jobData.address?.state,
              jobData.address?.postalCode,
              requestedDeliveryDate,
              deliveryTimeRangeStart,
              deliveryTimeRangeEnd,
              selectedBOMFile
            ].every((field) => field)
          }
        >
          Submit Order
        </Button>
        <Button
          onClick={() => {
            handleCloseModal(false)
          }}
          variant='secondary'
        >
          Cancel
        </Button>
      </div>

    </Modal>
  )
}
