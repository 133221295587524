import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Card from "../components/Card";
import UserManager from "../tools/UserManager";
import moment from "moment";
import Button from "../components/input/Button";
import { MapIcon, SunIcon, FolderIcon } from "@heroicons/react/24/outline";
import TopBar from "../components/nav/TopBar";
// import {
//   ComposableMap,
//   ZoomableGroup,
//   Geographies,
//   Geography,
// } from "react-simple-maps";

// Map Info
// const usAlbers = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
// const geoUrl = usAlbers;
// "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json"; // Old map

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// TODO: fix text overlapping issues on smaller screens
export default function JobPage() {
  const [job, setJob] = React.useState(null);
  const [stages, setStages] = React.useState(null);

  const location = useLocation();

  // Update job when location changes
  useEffect(() => {
    var jobId = location.pathname.split("/").pop();

    UserManager.makeAuthenticatedRequest("/api/jobs/get?job=" + jobId, "GET")
      .then((res) => {
        setJob(res.data.job);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [location, location.pathname]);

  // Get stages
  useEffect(() => {
    if (stages == null) {
      UserManager.makeAuthenticatedRequest("/api/stages/installer/find", "GET")
        .then((res) => {
          setStages(res.data.stages);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  // Ghost loading if no job
  if (!job) {
    var output = <></>;

    for (let i = 0; i < 5; i++) {
      output = (
        <>
          {output}
          <Card>
            <div className="flex flex-col gap-3">
              <div
                className="bg-gray-200 rounded-lg animate-pulse"
                style={{
                  width: Math.floor(Math.random() * 100) + 100 + "px",
                  height: 20 + "px",
                }}
              ></div>
              <div
                className="bg-gray-200 rounded-lg animate-pulse"
                style={{
                  width: Math.floor(Math.random() * 100) + 100 + "px",
                  height: 20 + "px",
                }}
              ></div>
              <div
                className="bg-gray-200 rounded-lg animate-pulse"
                style={{
                  width: Math.floor(Math.random() * 100) + 100 + "px",
                  height: 20 + "px",
                }}
              ></div>
            </div>
          </Card>
        </>
      );
    }

    output = <div className="flex flex-wrap gap-3 p-6">{output}</div>;

    return (
      <div className="flex-col">
        {/* Top Bar */}
        <div className="inset-0 items-center hidden p-2 border-b h-14 bg-gray-50 lg:flex">
          <div className="pl-6 text-xl font-semibold leading-7">Job</div>
        </div>

        {/* Body */}
        {output}
      </div>
    );
  }

  // Calculate total days
  // If not installed, sold to now
  var totalDays = null;
  if (job.stageMap && stages) {
    var stage0 = stages[0];
    var date0 = job.stageMap[stage0._id];
    var currDate = job.stageMap[stages[stages.length - 1]._id];

    if (date0 && currDate) {
      totalDays = moment(currDate)
        .utc()
        .startOf("day")
        .diff(moment(date0).utc().startOf("day"), "days");
    } else if (date0) {
      totalDays = moment()
        .utc()
        .startOf("day")
        .diff(moment(date0).utc().startOf("day"), "days");
    }
  }

  var addressString = "";
  if (job.address) {
    if (job.address.line1) {
      addressString += job.address.line1;
    }
    if (job.address.line2) {
      if (addressString.length > 0) {
        addressString += ", ";
      }
      addressString += job.address.line2;
    }
    if (job.address.city) {
      if (addressString.length > 0) {
        addressString += ", ";
      }
      addressString += job.address.city;
    }
    if (job.address.state) {
      if (addressString.length > 0) {
        addressString += ", ";
      }
      addressString += job.address.state;
    }
    if (job.address.postalCode) {
      if (addressString.length > 0) {
        addressString += " ";
      }
      addressString += job.address.postalCode;
    }
  }

  var currentStage = null;

  if (job.stageMap && stages) {
    for (let i = 0; i < stages.length; i++) {
      var stage = stages[i];
      var currDate = job.stageMap[stage._id];
      var prevDate = job.stageMap[stages[i - 1]?._id];

      // Current stage is last stage where the previous stage is not null but the current stage is null
      if (!currDate && prevDate) {
        currentStage = stage;
      }

      // If last stage and has date, job is complete
      if (i == stages.length - 1 && currDate) {
        currentStage = {
          name: "Installed",
        };
      }
    }
  }

  var mainInfoItems = [
    {
      label: "Job ID",
      value: job.importedId,
    },
    {
      label: "Job Name",
      value: job.name,
    },
    {
      label: "Job Size",
      value: formatWatts(job.systemSize),
    },
    {
      label: "Name",
      value: job.contact?.name,
    },
    {
      label: "Phone",
      value: job.contact?.phone,
    },
    {
      label: "Email",
      value: job.contact?.email,
    },
    {
      label: "Address",
      value: addressString,
    },
    {
      label: "Created",
      value: formatDate(job.createdAt),
    },
    {
      label: "Modified",
      value: formatDate(job.updatedAt),
    },
  ];

  var stageInfoItems = [
    {
      label: "Current Stage",
      value: currentStage?.name,
    },
    {
      label: "Sold",
      value: stages ? formatDate(job.stageMap[stages[0]?._id]) : "-",
    },
    {
      label: "Total Days",
      value: totalDays,
    },
    {
      label: job.isCanceled ? "Canceled" : "Status",
      value: job.isCanceled ? (job.cancelDate ? formatDate(job.cancelDate) : "True") : job.cancelStatus,
    },
    // {
    //   label: "Est. Install",
    //   value: "(Feature Coming Soon)",
    //   // TODO: implement est install on job page
    // },
  ];

  return (
    <>
      <div className="flex-col">
        {/* Top Bar */}
        <TopBar>
          Job
        </TopBar>

        {/* Body */}
        <div className="inset-0 flex flex-col flex-1 gap-3 p-6">
          {/* Header */}
          <div className="flex gap-3">
            {/* Title */}
            <div className="text-2xl font-bold leading-8">
              {(job.importedId || job.name) && "Job "}
              {job.importedId}
              {job.importedId && job.name && "-"}
              {job.name}
            </div>

            {/* Actions */}
            {/* // TODO: implement actions for job page */}
            {/* <div className="flex items-center gap-2 ml-auto">
              <Button variant="secondary">Order Material</Button>
              <Button variant="secondary">Schedule Job</Button>
            </div> */}
          </div>
          <div className="flex flex-col gap-3 xl:flex-row">
            <div className="flex flex-col gap-3">
              {/* Main Info */}
              <Card>
                <div>
                  {/* Header */}
                  <div className="flex flex-wrap gap-4 px-8 pb-3 -mx-4 border-b">
                    {/* Title */}
                    <div className="flex flex-col grow">
                      <div className="text-xl font-semibold leading-7 whitespace-nowrap">
                        Job Information
                      </div>
                      <div className="text-base font-normal leading-6 whitespace-nowrap">
                        Main information about the job
                      </div>
                    </div>

                    {/* Size, Market, & Stage */}
                    <div className="flex items-center gap-4 text-gray-500 dark:text-gray-400 whitespace-nowrap">
                      {/* Market */}
                      <div className="flex gap-1">
                        <MapIcon className="w-6 h-6" />
                        {job.market?.name || "-"}
                      </div>

                      {/* Size */}
                      <div className="flex gap-1">
                        <SunIcon className="w-6 h-6" />
                        {formatWatts(job.systemSize) || "-"}
                      </div>

                      {/* Stage */}
                      <div className="flex gap-1">
                        <FolderIcon className="w-6 h-6" />
                        {currentStage?.name || "-"}
                      </div>
                    </div>
                  </div>

                  {/* Contents */}
                  <div className="grid px-6 py-6 md:grid-cols-2 gap-x-2">
                    {mainInfoItems.map((item, i) => {
                      return <LineItem key={i} {...item} index={i} />;
                    })}
                  </div>
                </div>
              </Card>

              {/* Material */}
              <Card>
                <div>
                  {/* Header */}
                  <div className="flex flex-wrap gap-4 px-8 pb-3 -mx-4 border-b">
                    {/* Title */}
                    <div className="flex flex-col grow">
                      <div className="text-xl font-semibold leading-7 whitespace-nowrap">
                        Material
                      </div>
                      <div className="text-base font-normal leading-6 whitespace-nowrap">
                        Material information about the job
                      </div>
                    </div>

                    {/* Size, Market, & Stage */}
                    <div className="flex items-center gap-4 text-gray-500 dark:text-gray-400 whitespace-nowrap">
                      {/* Size */}
                      <div className="flex gap-1">
                        <SunIcon className="w-6 h-6" />
                        {formatWatts(job.systemSize) || "-"}
                      </div>
                    </div>
                  </div>

                  {/* Contents */}
                  <div className="flex px-6 py-6">
                    <div className="flex pt-5 grow md:p-6">
                      {job.materialMap &&
                      Object.keys(job.materialMap).length > 0 ? (
                        <table className="text-left text-gray-500 table-auto dark:text-gray-300 grow">
                          <thead className="border-b">
                            <tr>
                              <th className="pb-3 font-semibold">Material</th>
                              <th className="px-6 pb-3 font-semibold">Group</th>
                              <th className="pb-3 font-semibold">Quantity</th>
                            </tr>
                          </thead>
                          <tbody className="space-y-2 border-separate divide-y border-spacing-2">
                            {Object.keys(job.materialMap).map((groupId, i) => {
                              let { material, group, quantity } =
                                job.materialMap[groupId];

                              // Use name
                              var displayName = material
                                ? material.name
                                : "-";
                              if (material) {
                                if (!displayName) {
                                  // If no name, use brand and model
                                  if (material.brand || material.model) {
                                    displayName =
                                      material.brand +
                                      (material.brand && material.model
                                        ? " "
                                        : "") +
                                      material.model;
                                  }
                                  // Else (no brand or model), say empty
                                  else {
                                    displayName = "Empty Material";
                                  }
                                }
                              }
                              return (
                                <tr key={i}>
                                  <td className="py-2">{displayName}</td>
                                  <td className="px-6 py-2">{group?.name}</td>
                                  <td className="py-2">
                                    {material?.quantity ?? "-"}
                                    {/* // TODO: default quantity */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="py-12 text-center text-gray-500 dark:text-gray-300">
                          No materials
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </div>

            <div className="">
              {/* Stages */}
              <Card>
                <div>
                  {/* Header */}
                  <div className="flex flex-wrap gap-4 px-8 pb-3 -mx-4 border-b xl:flex-nowrap grow">
                    {/* Title */}
                    <div className="flex flex-col grow">
                      <div className="text-xl font-semibold leading-7 whitespace-nowrap">
                        Stages
                      </div>
                      <div className="text-base font-normal leading-6 whitespace-nowrap">
                        Stage dates and times for the job
                      </div>
                    </div>
                    {/* Current Stage */}
                    <div className="flex items-center gap-4 text-gray-500 dark:text-gray-300">
                      <div className="flex gap-1 whitespace-nowrap">
                        <FolderIcon className="w-6 h-6" />
                        {currentStage?.name || "-"}
                      </div>
                    </div>
                  </div>
                  {/* Contents */}
                  <div className="flex flex-col gap-6 py-6 divide-y-2 divide-gray-300">
                    <div className="grid grid-cols-1 -mx-4">
                      {stageInfoItems.map((item, i) => {
                        return <LineItem key={i} {...item} index={i === 0 ? 0 : 2} />; // Add border top everything but first item. Workaround as LineItem assumes md: to affect column count
                      })}
                    </div>
                    <div className="flex pt-5 md:p-4">
                      <table className="text-left text-gray-500 table-auto dark:text-gray-300 grow">
                        <thead className="border-b">
                          <tr>
                            <th className="pb-3 font-semibold">Stage</th>
                            <th className="px-6 pb-3 font-semibold">
                              Exited Stage
                            </th>
                            <th className="pb-3 font-semibold">Days</th>
                          </tr>
                        </thead>
                        <tbody className="space-y-2 border-separate divide-y border-spacing-2">
                          {job.stageMap &&
                            stages?.slice(1)?.map((stage, i) => {
                              var days = null;
                              // If prev stage, calculate days
                              // If no current stage, calculate days from last stage to now
                              if (job.stageMap[stages[i]?._id]) {
                                var prevStage = stages[i];
                                var prevDate = job.stageMap[prevStage._id];
                                var currDate = job.stageMap[stage._id];
                                if (prevDate && currDate) {
                                  days = moment(currDate)
                                    .utc()
                                    .startOf("day")
                                    .diff(
                                      moment(prevDate).utc().startOf("day"),
                                      "days"
                                    );
                                } else if (prevDate) {
                                  days = moment()
                                    .utc()
                                    .startOf("day")
                                    .diff(
                                      moment(prevDate).utc().startOf("day"),
                                      "days"
                                    );
                                }
                              }
                              return (
                                <tr key={i}>
                                  <td className="py-2">{stage.name}</td>
                                  <td className="px-6 py-2">
                                    {formatDate(job.stageMap[stage._id])}
                                  </td>
                                  <td className="py-2">
                                    {days != null ? days : "-"}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          {/* Map */}
          {/* // TODO: map */}
          {/* To annotate map with location, need coords. Hook into react-geocode */}
          {/* https://www.npmjs.com/package/react-geocode */}
          {/* <div className="w-[500px]">
                <ComposableMap projection="geoAlbersUsa">
                  <ZoomableGroup>
                    <Geographies geography={geoUrl}>
                      {({ geographies }) =>
                        geographies.map((geo) => {
                          return (
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              className={"stroke-black fill-white"}
                            />
                          );
                        })
                      }
                      <Annotation>

                      </Annotation>
                    </Geographies>
                  </ZoomableGroup>
                </ComposableMap>
              </div> */}

          {/* TODO: custom job fields */}
        </div>
      </div>
    </>
  );
}

function formatWatts(watts) {
  if (!watts) return 0 + "W";
  return (
    Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(watts) + "W"
  );
}

function formatDate(d) {
  if (!d) return "-";
  return moment(d).utc().format("M/D/YYYY");
}

function LineItem({ label, value, index }) {
  
  return (
    <div
      className={classNames(
        "px-3 py-2 md:py-5 md:px-6 md:mx-6 grid grid-cols-2 gap-2 text-sm leading-5 font-medium text-gray-500 dark:text-gray-300",
        index > 1 ? "border-t" : index == 1 ? "border-t md:border-none" : ""
      )}
    >
      <div className="font-medium">{label}</div>
      <div className="font-normal">{value || "-"}</div>
    </div>
  );
}
