import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import RadioCard from './RadioCard'

import { HomeModernIcon } from '@heroicons/react/24/outline';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import { QrCodeIcon } from '@heroicons/react/24/solid';

const exampleCards = [
    {
        label: 'Team',
        description: 'Team or company bringing together material and labor.',
        icon: <HomeModernIcon className="h-24 text-gray-400" />,
    },
    {
        label: 'Distributor',
        description: 'Providing product and services to facilitate teams within TOA.',
        icon: <BuildingOffice2Icon className="h-24 text-gray-400" />

    },
    {
        label: 'Manufacturer',
        description: 'Creating products for different sales channels.',
        icon: <QrCodeIcon className="h-24 text-gray-400" />
    },
]

/**
 * RadioCards component to display a list of cards with radio buttons
 * 
 * @param {Object[]} cards - array of objects with label, description and icon
 * @param {function} onSelected - callback function when a card is selected
 * @param {string} defaultSelected - default selected card id, if any
 */
export default function RadioCards({ cards = [], onSelected = () => { }, defaultSelected = null, checkable = true}) {
    const [selected, setSelected] = useState(defaultSelected)

    return (
        <>
        {/* this entire div is used for larger screens when the cards can be displayed horizontally, it is hidden on smaller screens */}
        <div className="hidden md:block">
            <div className="w-full px-4">
                <div className="w-full mx-auto">
                    <RadioGroup value={selected} onChange={(value) => { setSelected(value); onSelected(value); }}>
                        <RadioGroup.Label className="sr-only">Type</RadioGroup.Label>
                        <div className="flex flex-row items-stretch justify-center gap-6">
                            {cards.map((card, i) => (
                                <RadioGroup.Option
                                    key={i}
                                    value={card.id}
                                >
                                    {({ checked }) => (
                                        <>
                                            <RadioCard
                                                label={card.label}
                                                description={card.description}
                                                icon={card.icon}
                                                checked={checked}
                                                checkable={checkable}
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                </div>
            </div>
        </div>

        {/* this entire div is used for smaller screens when the cards can be displayed vertically, it is hidden on larger screens */}
        <div className="block md:hidden">
            <div className="w-full px-4">
                <div className="w-full mx-auto">
                    <RadioGroup value={selected} onChange={(value) => { setSelected(value); onSelected(value); }}>
                        <RadioGroup.Label className="sr-only">Type</RadioGroup.Label>
                        <div className="flex flex-col items-stretch justify-center gap-3 md:gap-6">
                            {cards.map((card, i) => (
                                <RadioGroup.Option
                                    key={i}
                                    value={card.id}
                                >
                                    {({ checked }) => (
                                        <>
                                            <RadioCard
                                                label={card.label}
                                                description={card.description}
                                                icon={card.icon}
                                                checked={checked}
                                                checkable={checkable}
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                </div>
            </div>
        </div>
        </>
    )
}