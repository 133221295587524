import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import FloNav from '../components/deprecated/FloNav'

export default class HomeLayout extends Component {
  render() {
    return (
        <>
            {/* <FloNav page="Home" /> */}
            <div className="flo-style h-screen w-full">
              <Outlet />
            </div>
        </>
    )
  }
}
