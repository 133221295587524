import React from "react";
import {
  Bar,
  LabelList,
  BarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Cell,
  Legend,
  Label,
} from "recharts";
import useTheme from "../hooks/styling/useTheme";

export default function ToaBarChart({
  data = [],
  dataKeys = [],
  colors = [],
  names = [],
  height = 500,
  lines = [],
  customTooltip = null,
  hideTooltip = false,
  labelLists = [],
  hideZeros = [],
  xAxis = true,
  yAxis = true,
  xAxisSettings = {},
  yAxisSettings = {},
  selectedIndex = -1,
  layering = "none", // "none" | "layered" | "bullet" // None will put side by side, layered will put them on top of each other, bullet will put the first one wider and the rest on top of it
  legendSettings = null,
  margin = { top: 20 },
  barSize = undefined,
  handleClick = () => { },
}) {
  const theme = useTheme();

  const layered = layering === "layered" || layering === "bullet";
  // TODO: get primary-green dynamically (https://stackoverflow.com/questions/61118060/how-to-access-tailwind-colors-from-javascript)

  // TODO: adjust inputs to allow for multiple lines (i.e. datakeys, lines, colors) so that you can use multiple lines

  return (
    // Note: relative->absolute wrapper is to force size of responsive container to shrink fast when resizing window.
    //       Animations are slow when shrinking recharts and it affected the flex layout.
    // Note: ResponsiveContainer width is 99% b/c there is a bug where it won't shrink if it's 100%
    <div className="relative w-full" style={{ height: height }}>
      <div className="absolute inset-0">
        <ResponsiveContainer width="99.99%" height={height}>
          <BarChart
            cursor="pointer"
            data={data}
            margin={margin}
          >
            {/* Tooltip */}
            {!hideTooltip && (
              <Tooltip
                content={customTooltip}
                wrapperStyle={{ outline: "none" }}
                cursor={{
                  fill: theme === "dark" ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.05)",
                }}
              />
            )}

            {/* Bars */}
            {dataKeys.map((dataKey, i) => {
              let color = colors.length > i && colors[i];
              return (
                <Bar
                  isAnimationActive={false} // Forces label list to not disappear when resizing window
                  key={i}
                  dataKey={dataKey}
                  name={(names.length > i && names[i]) || dataKey}
                  radius={2}
                  barSize={layering === "bullet" ? (i === 0 ? 30 : 20) : barSize}
                  xAxisId={layered ? dataKey : undefined}
                  onClick={(data, index) => {
                    var payload = data.payload;

                    handleClick(payload, index);
                  }}
                >
                  {data.map((entry, index) => {
                    let highlighted =
                      selectedIndex === index &&
                      (!layered || i === dataKeys.length - 1);
                    return (
                      <Cell
                        key={`cell-${index}`}
                        className={
                          highlighted
                            ? "fill-primary-green"
                            : color || "fill-gray-400 dark:fill-gray-200"
                        }
                      />
                    );
                  })}
                  {(i >= labelLists.length || labelLists[i]) && (<LabelList
                    dataKey={dataKey}
                    position="top"
                    className="fill-gray-500"
                    formatter={(value) => {
                      // Hide zeros if specified
                      if (i < hideZeros.length && hideZeros[i] && value === 0) {
                        return "";
                      }
                      return value;
                    }}
                  />)}
                </Bar>
              );
            })}

            {/* { */}
            {/*   dataKeys.map((dataKey, i) => { */}
            {/*   } */}
            {/* } */}

            {/* Reference lines */}
            {lines.map((line, i) => (
              <ReferenceLine
                key={i}
                y={line.value}
                label={{
                  value: line.label,
                  position: line.position || "insideBottomRight",
                  fill: "#333",
                }}
                strokeDasharray="4 4"
                stroke="#333"
              />
            ))}

            {/* X Axis */}
            {xAxis &&
              dataKeys.map((k, i) => (
                <XAxis
                  key={i}
                  xAxisId={layered ? k : undefined}
                  hide={i > 0}
                  className="stroke-gray-600 fill-gray-600 dark:stroke-white dark:fill-white"
                  stroke=""
                  {...xAxisSettings}
                >
                  <Label />
                </XAxis>
              ))}

            {/* Y Axis */}
            {yAxis && (
              <YAxis
                className="stroke-gray-600 fill-gray-600 dark:stroke-white dark:fill-white"
                stroke=""
                {...yAxisSettings}
              >
              </YAxis>
            )}

            {/* Legend */}
            {legendSettings && (
              <Legend
                verticalAlign="bottom"
                height={36}
                {...legendSettings}
                content={(props) => {
                  let payload = props.payload;

                  return (
                    <ul className="flex flex-row items-center justify-center gap-3">
                      {payload.map((obj, i) => {
                        return (
                          <li key={i} className="flex items-center gap-1">
                            <svg width="16" height="16">
                              <rect
                                width="16"
                                height="16"
                                rx={4}
                                className={
                                  colors[i] ||
                                  "fill-gray-400 dark:fill-gray-200"
                                }
                              />
                            </svg>
                            <span className="">{obj.value}</span>
                          </li>
                        );
                      })}
                    </ul>
                  );
                }}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
