import classNames from "src/tools/classNames";
import React from "react";

type Prop = {
  value: number,
  diff: number,
  units?: string,
  negativeIsGood?: boolean,
}

/**
 * A small component that displays a value and a difference along with an up/down arrow.
 * Units can be provided as well.
 * `negativeIsGood` is a flag that will show the difference as green if negative. This is
 * useful for things like cycle times where a decrease is good. 
 *
 */
export default function MiniDiffStat({ value, diff, units = "", negativeIsGood = false }: Prop): JSX.Element {
  let isUp: boolean = diff > 0;
  let isDown = diff < 0;
  let isNeutral = diff == 0;

  let color = "text-gray-500";

  if (isUp) {
    color = negativeIsGood ? "text-red-500" : "text-green-500";
  } else if (isDown) {
    color = negativeIsGood ? "text-green-500" : "text-red-500";
  }

  return (
    <div className="flex items-center gap-2 text-sm whitespace-nowrap">
      <div>
        {value ?? "--"}
        {units}
      </div>
      <div className="flex items-center flex-gap-1">
        {/* Up/Down Triangle */}
        <div>
          {
            // If up
            (isUp || isNeutral) && (
              <svg
                className={classNames("h-4 w-4", color)}
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                {/* Simple triangle */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 15l7-7 7 7"
                />
              </svg>
            )
          }
          {
            // If down
            isDown && (
              <svg
                className={classNames("h-4 w-4", color)}
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                {/* Simple triangle */}
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )
          }
        </div>
        <div className={color}>
          {diff ?? "--"}
          {units}
        </div>
      </div>
    </div>
  );
}
