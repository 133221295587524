import { Bars2Icon } from "@heroicons/react/24/outline";
import { Reorder, useMotionValue } from "framer-motion";
import React, { useState } from "react";

export default function DraggableList({
  values = [],
  setValueOrder = () => {},
  content = {},
}) {
  return (
    <>
      <Reorder.Group
        axis="y"
        className="relative flex flex-col gap-0"
        values={values}
        onReorder={setValueOrder}
        style={{ listStyle: "none" }}
      >
        {values.map((id, index) => (
          <ListItem key={id} id={id} index={index} content={content[id]} />
        ))}
      </Reorder.Group>
    </>
  );
}

function ListItem({ id, index, content }) {
  const y = useMotionValue(0);
  const [draggable, setDraggable] = useState(false);

  return (
    <>
      <Reorder.Item
        value={id}
        key={id}
        style={{ y }}
        className="bg-white grow border flex items-stretch gap-3 -my-[1px] text-gray-500"
        dragListener={draggable}
        onDragEnd={() => setDraggable(false)}
      >
        {/* Grabber */}
        {/* <div className="flex items-center"> */}
          <div
            className="shrink flex items-center cursor-grab border-r px-1"
            onMouseEnter={() => setDraggable(true)}
            onMouseLeave={() => setDraggable(false)}
            onTouchStart={() => setDraggable(true)}
          >
            <Bars2Icon className="h-5 w-5 text-gray-400" />
          </div>
        {/* </div> */}
        
        {/* Line Content */}
        <div className="grow">{content}</div>
      </Reorder.Item>
    </>
  );
}
