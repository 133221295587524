import React, { useState, useEffect, useCallback } from 'react';
import SearchBar from '../SearchBar';
import ComboboxSearchBar from '../ComboboxSearchBar';
import Input from '../input/Input';
import Button from '../input/Button';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { CheckIcon } from "@heroicons/react/24/solid";
import UserManager from "../../tools/UserManager";
import debounce from '../../tools/debounce';
import validateEmail from '../../tools/validateEmail';
import { 
    requestCompanySearch,
    addCompanySearchResult
} from '../../tools/connectionManagement';


var sampleInvites = [
    { companyName: 'Elite Solar', name: 'John Smith', email: 'john@elitesolar.com' },
    { companyName: 'Rural Solar', name: 'Jane Peterson', email: 'jane@ruralsolar.com' }
]


/**
 * 
 * @userCompanyType - company type of the users company, Installer or Distributor
 * @setNextStepButtonText - function to set the text of the next step button
 * @setNextStepButtonDisabled - function to set the disabled state of the next step button
 * @setGoToToaButtonDisabled - function to set the disabled state of the go to toa button
 * @connectionRequestCompanies - list of companies that the user is about to send connection requests to
 * @setConnectionRequestCompanies - function to set the list of companies that the user is about to send connection requests to
 * @invites - list of emails/company names that the user is about to send invites to create a new company
 * @setInvites - function to set the list of emails/company names that the user is about to send invites to create a new company
 * 
 * @returns - a component that allows users to search for companies and send connection requests or invites
 */
export default function Connect({
    userCompanyType,
    setNextStepButtonText = () => { },
    setNextStepButtonDisabled = () => { },
    setGoToToaButtonDisabled = () => { },
    connectionRequestCompanies,
    setConnectionRequestCompanies,
    invites,
    setInvites
}) {
    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const [sendingInvites, setSendingInvites] = useState(false)             //TODO: use this to show a loading spinner on the send invites button

    const [inputCompanyName, setInputCompanyName] = useState('')
    const [inputContactName, setInputContactName] = useState('')
    const [inputEmail, setInputEmail] = useState('')

    const [error, setError] = useState('')

    const debouncedRequestCompanySearch = useCallback(debounce(requestCompanySearch, 500), []);

    useEffect(() => {
        if (connectionRequestCompanies.length > 0 || invites.length > 0) {
            if (userCompanyType === 'installer') {
                setNextStepButtonText('Send Invites - Next: Forecast Setup')
            } else {
                setNextStepButtonText('Send Invites - Next: Location Setup')
            }
            setNextStepButtonDisabled(false)
            setGoToToaButtonDisabled(true)
        } else {
            if (userCompanyType === 'installer') {
                setNextStepButtonText('Skip to Forecast Setup')
            } else {
                setNextStepButtonText('Skip to Locations Setup')
            }
            setNextStepButtonDisabled(false)
            setGoToToaButtonDisabled(false)
        }
    }, [connectionRequestCompanies, invites])

    useEffect(() => {
        let searchCompanyType = userCompanyType === 'installer' ? 'distributor' : 'installer'
        debouncedRequestCompanySearch(searchValue, searchCompanyType, setSearchResults, connectionRequestCompanies)
    }, [searchValue])
    

    let requestAndInvite = (
        <div className="flex flex-col items-center justify-start w-1/2">
            <div className="flex flex-col items-center justify-start mt-10">
                <div className="text-xl font-semibold leading-7 text-center text-gray-900 align-middle">
                    {userCompanyType === 'installer' ? 'Connect with Existing Distributors' : 'Connect with Existing Installers'}
                </div>
                <div className="text-base font-normal leading-6 text-center text-gray-500 align-middle">
                    {userCompanyType === 'installer' ? 'Find a distributor to start collaborating' : 'Find an installer to start collaborating'}
                </div>
                <div className="w-full mt-6">
                    <ComboboxSearchBar
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        searchResults={searchResults}
                        onSelectItem={(company) => {
                            addCompanySearchResult(company, searchResults, setSearchResults, connectionRequestCompanies, setConnectionRequestCompanies)
                        }}
                        placeholder={
                            userCompanyType === 'installer'
                                ? 'Search Distributors'
                                : 'Search Installers'
                        }
                    />
                </div>
            </div>
            <div className="w-3/4 border-b border-gray-300 my-7" />
            <div className='flex flex-col items-center justify-start'>
                <div className='text-xl font-semibold leading-7 text-left text-gray-900 align-middle'>
                    {
                        userCompanyType === 'installer' ?
                            'Send an Invite to Onboard New Distributor'
                            : 'Send an Invite to Onboard New Installer'
                    }
                </div>
                <div className='text-base font-normal leading-6 text-center text-gray-500 align-middle'>
                    {
                        userCompanyType === 'installer' ?
                            'Invite distributors to start collaborating'
                            : 'Invite installers to start collaborating'
                    }
                </div>
            </div>
            <div className='flex flex-col gap-4 mt-4 w-72'>
                <Input
                    label='Company Name'
                    name='companyName'
                    type='text'
                    placeholder='Company Name'
                    required
                    value={inputCompanyName}
                    onChange={(value) => {
                        setInputCompanyName(value)
                    }}
                />
                <div className='w-full border-b border-gray-300' />
                <Input
                    label='Contact Name'
                    name='contactName'
                    type='text'
                    placeholder='John Jensen'
                    required
                    value={inputContactName}
                    onChange={(value) => {
                        setInputContactName(value)
                    }}
                />
                <Input
                    label='Email Address'
                    name='email'
                    type='email'
                    placeholder='example@email.com'
                    required
                    value={inputEmail}
                    onChange={(value) => {
                        setInputEmail(value)
                    }}
                />
                {error ? <div className='w-full text-sm font-normal leading-6 text-center text-red-500 align-middle text-pretty'>{error}</div> : null}
                <Button
                    className='w-full mt-3'
                    variant='primary-green'
                    disabled={inputCompanyName === '' || inputContactName === '' || inputEmail === ''}
                    onClick={() => {
                        if (inputCompanyName !== '' && inputContactName !== '' && inputEmail !== '') {
                            // if an invite with the same email already exists, do not add it
                            if (invites.some((item) => item.email === inputEmail)) {
                                setError('A matching invite is already in the list.')
                                return
                            }
                            // if an invite with the same company name already exists, do not add it
                            if (invites.some((item) => item.companyName === inputCompanyName)) {
                                setError('A matching invite is already in the list.')
                                return
                            }
                            // if the email is not valid, do not add it
                            if (!validateEmail(inputEmail)) {
                                setError('Invalid email address.')
                                return
                            }
                            setInvites([...invites, {
                                companyName: inputCompanyName,
                                name: inputContactName,
                                email: inputEmail
                            }])
                            setInputCompanyName('')
                            setInputContactName('')
                            setInputEmail('')
                            setError('')
                        }
                    }}
                >
                    {
                        userCompanyType === 'installer' ?
                            'Add Distributor'
                            : 'Add Installer'
                    }
                </Button>
            </div>
        </div>
    )

    let connectionRequestTable = (
        <div className="w-full pl-4 sm:pl-6 lg:pl-12">
            <div className="flow-root mt-8">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full border-b border-gray-300 divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold leading-5 text-gray-900 align-middle">
                                        New Connections
                                    </th>
                                    {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold leading-5 text-gray-900 align-middle">
                                        Request Sharing
                                    </th> */}
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {connectionRequestCompanies.map((connectionRequestCompany) => (
                                    <tr key={connectionRequestCompany._id}>
                                        <td className="px-3 py-2.5 text-sm font-medium leading-5 text-gray-900 align-middle whitespace-nowrap">
                                            {connectionRequestCompany.name}
                                        </td>
                                        {/* <td className="flex flex-row items-center gap-2 px-3 py-2.5 text-sm font-normal leading-5 text-gray-500 align-middle whitespace-nowrap">
                                            <span className="flex items-center justify-center w-6 h-6 rounded-full bg-primary-green">
                                                <CheckIcon
                                                    className="w-[16px] h-auto text-white"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            Forecasting
                                        </td> */}
                                        <td className="relative py-2.5 px-2.5 text-sm font-medium text-right border border-gray-300 whitespace-nowrap w-[44px] h-[44px]">
                                            <XMarkIcon
                                                className='w-5 h-5 cursor-pointer text-primary-rose hover:text-primary-rose-400'
                                                onClick={() => {
                                                    setConnectionRequestCompanies(connectionRequestCompanies.filter((item) => item._id !== connectionRequestCompany._id))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    let inviteCompaniesTable = (
        <div className="w-full pl-4 sm:pl-6 lg:pl-12">
            <div className="flow-root mt-3">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full border-b border-gray-300 divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold leading-5 text-gray-900 align-middle">
                                        New Invites
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {invites.map((invite) => (
                                    <tr key={invite.email}>
                                        <td className="px-3 py-2.5 text-sm font-medium leading-5 text-gray-900 align-middle whitespace-nowrap">
                                            {invite.companyName}
                                        </td>
                                        <td className="px-3 py-2.5 text-sm font-normal leading-5 text-gray-500 align-middle whitespace-nowrap">
                                            {invite.name}
                                        </td>
                                        <td className="px-3 py-2.5 text-sm font-normal leading-5 text-gray-500 align-middle whitespace-nowrap">
                                            {invite.email}
                                        </td>
                                        <td className="relative py-2.5 px-2.5 text-sm font-medium text-right border border-gray-300 whitespace-nowrap w-[44px] h-[44px]">
                                            <XMarkIcon
                                                className='w-5 h-5 cursor-pointer text-primary-rose hover:text-primary-rose-400'
                                                onClick={() => {
                                                    setInvites(invites.filter((item) => item.email !== invite.email))
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )

    let displayInvites = (
        <div
            // have to use style and calc to make the table scrollable, otherwise it overflows the modal, h-full doesn't work, and neither will h-[calc(84vh-200px)]
            style={{ height: 'calc(84vh - 200px)' }}
            className='flex flex-col items-center justify-start w-1/2 mt-12 overflow-y-auto overflow-x-clip'
        >
            {connectionRequestTable}
            {inviteCompaniesTable}
        </div>
    )

    return (
        <div className='flex flex-row justify-around w-full px-6 grow'>
            {requestAndInvite}
            {displayInvites}
        </div>
    )
}
