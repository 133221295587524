import React, { useState } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/outline";
import Card from "../components/Card";
import CriticalIssuesCard from "../components/CriticalIssuesCard";
import BasicInstallerRect from "../components/BasicInstallerRect";
import IssuesByMarketCard from "../components/IssuesByMarketCard";
import MaterialSummaryCard from "../components/MaterialSummaryCard";
import BulletChart from "../components/BulletChart";
import ForecastInput from "../components/Forecast/ForecastInput";
import MaterialGroupTable from "../components/MaterialGroupTable";

// TODO: Implement this fully. Currently just a placeholder.
const exampleInstallers = [
  {
    logo: <></>,
    name: "Pure Solar",
    number: 625, // TODO: Refactor name
    issues: [
      {
        level: "critical",
        text: "",
        link: "",
      },
      {
        level: "critical",
        text: "",
        link: "",
      },
      {
        level: "critical",
        text: "",
        link: "",
      },
      {
        level: "critical",
        text: "",
        link: "",
      },
    ],
  },
  {
    logo: <></>,
    name: "Southwest",
    number: 350, // TODO: Refactor name
    issues: [
      {
        level: "warning",
        text: "",
      },
    ],
  },
  {
    logo: <></>,
    name: "G3 Solar",
    number: 245, // TODO: Refactor name
    issues: [
      {
        level: "warning",
        text: "",
      },
      {
        level: "critical",
        text: "",
        link: "",
      },
    ],
  },
];

// TODO: Implement this fully. Currently just a placeholder.
const exampleMaterialGroupSummary = [
  {
    name: "Panel",
    totalCount: 11875,
    items: [
      {
        name: "Unassigned",
        count: 2250,
        color: "bg-gray-200",
      },
      {
        name: "Sunpower 440",
        count: 2100,
        color: "bg-primary-rose",
      },
      {
        name: "Trina 410",
        count: 1880,
      },
      {
        name: "Panasonic 440",
        count: 1775,
        color: "bg-primary-rose",
      },
      {
        name: "Trina 425",
        count: 1470,
      },
    ],
  },
  {
    name: "Inverter",
    totalCount: 11875,
    items: [
      {
        name: "Unassigned",
        count: 6550,
        color: "bg-gray-200",
      },
      {
        name: "EnPhase IQ7",
        count: 3100,
        color: "bg-primary-rose",
      },
      {
        name: "Solar Edge 1",
        count: 1880,
      },
      {
        name: "Solar Edge 2",
        count: 950,
        color: "bg-primary-rose",
      },
    ],
  },
  {
    name: "Batteries",
    totalCount: 1250,
    items: [
      {
        name: "Unassigned",
        count: 800,
        color: "bg-gray-200",
      },
      {
        name: "EnPhase Bat",
        count: 436,
      },
      {
        name: "Tesla",
        count: 134,
      },
      {
        name: "Battery 3",
        count: 2,
      },
    ],
  },
];

// TODO: Implement this fully. Currently just a placeholder.
const exampleMaterialGroupDetails = [
  {
    name: "Panel",
    field1: {
      key: "brand",
      label: "Brand",
    },
    field2: {
      key: "watt",
      label: "Watt",
    },
    items: [
      {
        brand: "Jinko",
        watt: 480,
        data: [
          {
            month: 4,
            forecast: 400,
            fulfilled: 400,
          },
          {
            month: 5,
            forecast: 200,
            fulfilled: 200,
          },
          {
            month: 6,
            forecast: 100,
            fulfilled: 100,
          }
        ]
      },
      {
        brand: "SunPower",
        watt: 440,
        data: [
          {
            month: 5,
            forecast: 180,
            fulfilled: 180,
          },
          {
            month: 6,
            forecast: 250,
            fulfilled: 250,
          },
          {
            month: 7,
            forecast: 350,
            fulfilled: 350,
          },
          {
            month: 8,
            forecast: 350,
            fulfilled: 200,
          },
        ]
      },
      {
        brand: "Jinko",
        watt: 440,
        data: [
          {
            month: 5,
            forecast: 180,
            fulfilled: 180,
          },
          {
            month: 6,
            forecast: 250,
            fulfilled: 250,
          },
          {
            month: 7,
            forecast: 350,
            fulfilled: 350,
          },
          {
            month: 8,
            forecast: 350,
            fulfilled: 200,
          },
        ]
      },
      {
        brand: "Panasonic",
        watt: 440,
        data: [
          {
            month: 9,
            forecast: 320,
            fulfilled: 320,
          },
          {
            month: 10,
            forecast: 320,
            fulfilled: 320,
          },
          {
            month: 11,
            forecast: 320,
            fulfilled: 320,
          },
          {
            month: 0,
            forecast: 320,
            fulfilled: 320,
          },
          {
            month: 1,
            forecast: 320,
            fulfilled: 320,
          },
          {
            month: 2,
            forecast: 480,
            fulfilled: 320,
          },
          {
            month: 3,
            forecast: 480,
            fulfilled: 480,
          }
        ]
      },
      {
        brand: "Unassigned",
        data: [
          {
            month: 11,
            forecast: 800,
            fulfilled: 800,
          },
          {
            month: 0,
            forecast: 1200,
            fulfilled: 1200,
          },
          {
            month: 1,
            forecast: 1250,
            fulfilled: 1250,
          },
          {
            month: 2,
            forecast: 1200,
            fulfilled: 1200,
          },
          {
            month: 3,
            forecast: 1100,
            fulfilled: 1100,
          },
        ]
      }
    ],
  }
]

// TODO: Implement this fully. Currently just a placeholder.
const exampleJobSummaryData = [
  {
    label: "May",
    forecast: 20,
    predicted: 15,
  },
  {
    label: "Jun",
    forecast: 5,
    predicted: 10,
  },
  {
    label: "Jul",
    forecast: 10,
    predicted: 20,
  },
  {
    label: "Aug",
    forecast: 12,
    predicted: 8,
  },
  {
    label: "Sep",
    forecast: 9,
    predicted: 5,
  },
  {
    label: "Oct",
    forecast: 8,
    predicted: 11,
  },
  {
    label: "Nov",
    forecast: 10,
    predicted: 10,
  },
  {
    label: "Dec",
    forecast: 10,
    predicted: 10,
  },
  {
    label: "Jan",
    forecast: 10,
    predicted: 10,
  },
  {
    label: "Feb",
    forecast: 10,
    predicted: 10,
  },
  {
    label: "Mar",
    forecast: 10,
    predicted: 10,
  },
  {
    label: "Apr",
    forecast: 10,
    predicted: 10,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * A component that renders a divider with a collapse/expand button.
 *
 * @param {Object} props - The component props.
 * @param {boolean} [props.isVertical=false] - Whether the divider is vertical or horizontal.
 * @param {boolean} [props.isCollapsed=false] - Whether the divider is initially collapsed.
 * @param {function} [props.onCollapseToggle] - A callback function that is called when the collapse/expand button is clicked.
 * @returns {JSX.Element} - The rendered divider component.
 */
function Divider({
  isVertical = false,
  isCollapsed = false,
  onCollapseToggle,
}) {
  var iconClasses = "h-4 w-4 stroke-gray-900 stroke-[2]";
  var barClasses =
    "grow border-gray-300" + (isVertical ? " border-l" : " border-t");

  return (
    <div className="py-3 relative">
      <button
        className={classNames(
          "flex gap-6 items-center",
          isVertical ? "flex-col h-full" : "w-full"
        )}
        onClick={() => onCollapseToggle(!isCollapsed)}
      >
        {/* Bar */}
        <div className={barClasses}></div>

        {/* Button/Chevron */}
        <div>
          {isCollapsed ? (
            isVertical ? (
              <ChevronRightIcon className={iconClasses} />
            ) : (
              <ChevronDownIcon className={iconClasses} />
            )
          ) : isVertical ? (
            <ChevronLeftIcon className={iconClasses} />
          ) : (
            <ChevronUpIcon className={iconClasses} />
          )}
        </div>

        {/* Bar */}
        <div className={barClasses}></div>
      </button>
    </div>
  );
}

function LabelLine({
  textColor = "gray-900",
  lineColor = "gray-900",
  text,
  width = 10,
}) {
  return (
    <div className="relative">
      {/* Text */}
      <div
        className={classNames(
          "text-xs font-medium text-end",
          "text-" + textColor
        )}
      >
        {text}
      </div>

      {/* Line */}
      <div
        style={{ width: width, zIndex: 10 }}
        className={classNames(
          "absolute top-2 left-full ml-2 border-t",
          "border-" + lineColor
        )}
      >
        <div
          className={classNames(
            "absolute -right-1 -top-[3px] rounded-full h-1.5 w-1.5",
            "bg-" + lineColor
          )}
        ></div>
      </div>
    </div>
  );
}

export default function MaterialPlannerPage() {
  const [leftCollapsed, setLeftCollapsed] = useState(false);
  const [materialSummaryCollapsed, setMaterialSummaryCollapsed] =
    useState(false);
  const [jobSummaryCollapsed, setJobSummaryCollapsed] = useState(false);

  // TODO: Replace with real data
  const [installers, setInstallers] = useState(exampleInstallers);
  const [materialGroupSummary, setMaterialGroupSummary] = useState(
    exampleMaterialGroupSummary
  );

  return (
    <>
      <div className="flex flex-col gap-2">
        {/* Top Bar */}
        <div className="inset-0 h-14 p-2 bg-gray-50 border-b hidden lg:flex items-center">
          <div className="pl-6 text-xl leading-7 font-semibold">
            Material Planner
          </div>
        </div>

        {/* Body */}
        <div className="flex-1 flex flex-col p-6 pt-2 space-y-2 inset-0">
          {/* Columns */}
          <div className="flex flex-row flex-1">
            {/* Left */}
            <div
              className={classNames(
                "pr-3 flex-none flex flex-col gap-3",
                leftCollapsed ? "w-[150px]" : "w-[400px]"
              )}
            >
              {/* Critical Issues */}
              <CriticalIssuesCard collapsed={leftCollapsed} />

              {/* Installers */}
              <Card>
                <div className="flex flex-col gap-3">
                  <div className="text-gray-900 font-semibold text-base">
                    Installers
                  </div>
                  {installers.length ? (
                    installers.map((installer) => (
                      <BasicInstallerRect
                        installer={installer}
                        collapsed={leftCollapsed}
                      />
                    ))
                  ) : (
                    <>No installers found.</>
                  )}
                </div>
              </Card>

              {/* By Market */}
              <IssuesByMarketCard collapsed={leftCollapsed} />

              {/* Actual vs Forecast */}
              <Card>
                {!leftCollapsed ? (
                  // ----- Expanded ----- //
                  <div className="flex gap-3">
                    {/* Text */}
                    <div className="flex flex-col gap-1">
                      <div className="text-sm font-medium text-gray-500">
                        Installer Actual vs. Installer Forecast
                      </div>
                      <div className="text-xl font-semibold text-gray-900">
                        -360 Jobs | 20% Under
                      </div>
                      <div className="text-sm font-medium text-gray-500">
                        Last 12 Months
                      </div>
                    </div>

                    {/* Expand Btn */}
                    <div className="ml-auto">
                      <ArrowsPointingOutIcon
                        className={
                          "h-6 text-gray-300 hover:text-primary-rose cursor-pointer"
                        }
                      />
                    </div>
                  </div>
                ) : (
                  // ----- Collapsed ----- //
                  <div className="flex flex-col text-base font-semibold text-gray-900">
                    <div>-360 Jobs</div>
                    <div>20% Under</div>
                    <div className="text-sm text-gray-500">Last 12 Months</div>
                  </div>
                )}
              </Card>
            </div>

            {/* Divider */}
            <Divider
              isVertical
              isCollapsed={leftCollapsed}
              onCollapseToggle={setLeftCollapsed}
            />

            {/* Right */}
            <div className={classNames("pl-3 grow flex flex-col gap-1.5")}>
              {/* Material Summary */}
              <div>
                {/* Header & Collapse */}
                <div className="flex gap-4">
                  <div className="w-[130px] flex items-center text-sm leading-5 font-medium text-gray-900 pl-2">
                    Material Summary
                  </div>
                  <div className="grow">
                    <Divider
                      isCollapsed={materialSummaryCollapsed}
                      onCollapseToggle={setMaterialSummaryCollapsed}
                    />
                  </div>
                </div>

                {/* Content */}
                <div className="flex gap-2.5">
                  {!materialSummaryCollapsed && (
                    <>
                      {materialGroupSummary.map((materialGroup) => (
                        <MaterialSummaryCard materialGroup={materialGroup} />
                      ))}
                    </>
                  )}
                </div>
              </div>

              {/* Job Summary */}
              <div>
                {/* Header & Collapse */}
                <div className="flex gap-4">
                  <div className="w-[130px] flex items-center text-sm leading-5 font-medium text-gray-900 pl-2">
                    Job Summary
                  </div>
                  <div className="grow">
                    <Divider
                      isCollapsed={jobSummaryCollapsed}
                      onCollapseToggle={setJobSummaryCollapsed}
                    />
                  </div>
                </div>

                {/* Content */}
                <div className="flex gap-2.5 pl-5">
                  {!jobSummaryCollapsed && (
                    <div className="flex flex-col gap-6 grow">
                      {/* Chart */}
                      <div className="flex gap-8 grow">
                        <div className="flex flex-col gap-4 justify-center">
                          <LabelLine
                            textColor="gray-900"
                            lineColor="gray-900"
                            text="Installer Forecast"
                            width={44}
                          />
                          <LabelLine
                            textColor="gray-500"
                            lineColor="gray-600"
                            text="TOA Predicted"
                            width={37}
                          />
                          <div className="text-gray-600 border-gray-600 bg-gray-600"></div>
                        </div>
                        <div className="grow">
                          <BulletChart
                            data={exampleJobSummaryData}
                            frontValueDataKey="forecast"
                            backValueDataKey="predicted"
                          />
                        </div>
                      </div>

                      {/* Table */}
                      <div className="flex gap-2.5">
                        <div className="flex-none w-[120px] flex flex-col justify-center gap-4 text-right text-sm font-medium text-gray-900">
                          <div>TOA Predicted</div>
                          <div>Installer Forecast</div>
                        </div>
                        <div className="grow">
                          <div className="w-full">
                            <ForecastInput
                              data={exampleJobSummaryData}
                              toaField="predicted"
                              inputField="forecast"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Material Groups */}
              <div className="mt-7">
                {
                  exampleMaterialGroupDetails.map((materialGroup, index) => (
                    <MaterialGroupTable materialGroup={materialGroup} showMonths={index === 0} />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
