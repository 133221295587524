import React, { useState, useEffect } from 'react'
import { FolderPlusIcon } from '@heroicons/react/24/outline';
import Button from '../input/Button'
import InputTable from '../input/InputTable'
import {
    getTableColumns,
    getLocations,
    deleteLocation,
    updateLocation,
    addLocation
} from '../../tools/locationManagement'


/**
 * 
 * @nextStepButtonText - function to set the text of the next step button
 * @nextStepButtonDisabled - function to disable/enable the next step button
 * @goToToaButtonDisabled - function to disable/enable the go to toa button
 * @locations - list of locations that the company has created, each location is an object with the following properties:
 *      id - unique identifier for the location
 *      name - name of the location
 *      address - address of the location
 *      address2 - second line of the address
 *      city - city of the location
 *      state - state of the location
 *      zip - zip code of the location
 * @setLocations - function to set the list of locations
 * @setError - function to set an error message
 * 
 * @returns - a table of locations that the company has created, allowing the user to add, edit, and delete locations
 */
export default function Locations({
    setNextStepButtonText = () => { },
    setNextStepButtonDisabled = () => { },
    setGoToToaButtonDisabled = () => { },
    setError,
}) {

    // current locations in the table
    const [locations, setLocations] = useState([])

    const [addingLocations, setAddingLocations] = useState(false)

    // when the page loads, get all locations for the company and add them to the table
    useEffect(() => {
        getLocations(setError).then((locations) => {
            // use locations.map to add each location to the table
            setLocations(locations.map(location => {
                // if the location already exists in the table, don't add it again
                if (locations.some(prevLocation => prevLocation.id === location._id)) {
                    return location
                }
                return {
                    id: location._id,
                    name: { value: location.name, className: '' },
                    address: { value: location.address.line1, className: '' },
                    address2: { value: location.address.line2, className: '' },
                    city: { value: location.address.city, className: '' },
                    state: { value: location.address.state, className: '' },
                    zip: { value: location.address.postalCode, className: '' },
                    rowStatus: 'readonly',
                    menuOptions: [
                        { label: 'Delete', value: 'deleteLocation', onSelected: (item) => { deleteLocation(item, setError, setLocations) } },
                        { label: 'Edit', value: 'edit' }
                    ]
                }
            }))

        })
    }, [])


    // on 'locations' change:
    // - enforce unique location names
    // - set buttons enabled/disabled
    // - ensure menuOptions exist for each location
    useEffect(() => {
        // if length of locations is > 0, set addingLocations to true
        if (locations.length > 0) {
            setAddingLocations(true)
        }

        // if a location with duplicate name exists, set error
        const locationNames = locations.map(location => location.name)
        const uniqueLocationNames = new Set(locationNames)
        if (locationNames.length !== uniqueLocationNames.size) {
            setError('Location names must be unique.')
            setNextStepButtonDisabled(true)
        } else {
            setError('')
            setNextStepButtonDisabled(false)
        }
        // if a at least one location exists, change the next step button text
        if (locations.length > 0) {
            setNextStepButtonText('Next: Roles Setup')
        } else {
            setNextStepButtonText('Skip to Roles Setup')
        }

        // if at least one location is active, disable the next step button and the go to toa button
        if (locations.some(location => location.status === 'active')) {
            setNextStepButtonDisabled(true)
            setGoToToaButtonDisabled(true)
        } else {
            setNextStepButtonDisabled(false)
            setGoToToaButtonDisabled(false)
        }

        // run through all locations, if any are missing menuOptions, add them using setLocations
        for (let location of locations) {
            if (!location.menuOptions) {
                setLocations((prevLocations) => {
                    return prevLocations.map((prevLocation) => {
                        if (prevLocation.id === location.id) {
                            return {
                                ...prevLocation,
                                menuOptions: [
                                    { label: 'Delete', value: 'deleteLocation', onSelected: (item) => { deleteLocation(item, setError, setLocations) } },
                                    { label: 'Edit', value: 'edit' }
                                ]
                            }
                        } else {
                            return prevLocation
                        }
                    })
                })
            }
        }
    }, [locations])


    var addLocationButton = (
        <div className="flex flex-col items-center justify-center pt-24">
            <FolderPlusIcon className="h-12 mb-3 text-gray-400" />
            <div className="text-sm font-semibold leading-5 text-center text-gray-900 align-middle">
                New Location
            </div>
            <div className="text-sm font-normal leading-5 text-center text-gray-500 align-middle">
                Get started by creating a location.
            </div>
            <Button
                className="mt-4"
                onClick={() => setAddingLocations(true)}
                variant="primary-green"
            >
                Add Location
            </Button>
        </div>
    )


    var locationsTable = (
        <InputTable
            tableData={locations}
            setTableData={setLocations}
            columns={getTableColumns(setError)}
            addButtonText={"Add Location"}
            outerAddButton={true}
            innerAddButton={true}
            height={'calc(84vh - 300px)'}
            useOnBlur={false}
            onSaveRow={(newRow) => {
                if (newRow.rowStatus === 'new') {
                    return addLocation(newRow, setError)
                } else if (newRow.rowStatus === 'editing') {
                    return updateLocation(newRow, setError)
                }
            }}
            setError={setError}
        />
    )


    return (
        <div className="flex flex-col items-center justify-start w-full grow">
            <div className="flex flex-col items-center justify-start">
                <div className="mt-12 text-xl font-semibold leading-7 text-center text-gray-900 align-middle">
                    Create your Locations
                </div>
                <div className="text-base font-normal leading-6 text-center text-gray-500 align-middle">
                    Track demand forecasts and material orders by warehouse
                </div>
            </div>
            {addingLocations ? (
                locationsTable
            ) : (
                addLocationButton
            )}
        </div>
    )
}
