import React, { useState, useEffect } from "react";
import useLocalStorage from "../useLocalStorage.tsx"

/**
 * Returns the current theme, either "light" or "dark".
 * Updates when local storage changes.
 * If theme is set to system (i.e. local storage is null), it will return "light" or "dark" based on system preference.
 * 
 * @returns {string} "light" or "dark"
 */
export default function useTheme() {
  const [theme, setTheme] = useLocalStorage("theme");

  // Output only "light" or "dark". If system, output accordingly (not "system")
  let systemDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  let outputTheme = theme ?? (systemDark ? "dark" : "light");

  // TODO: remove light mode force when dark mode is fully implemented
  outputTheme = "light";

  return outputTheme;
}
