import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useContext } from "react";

import pluralize from "pluralize";
import ForecastHistoryContext, {
  ForecastHistoryContextType,
} from "src/contexts/forecast/history/ForecastHistoryContext.tsx";

type Props = {};

/**
 * A component that displays a mini material planning chart.
 * Shows issue count (under construction) and a chart for each material group.
 */
export default function MiniMaterialPlanning({}: Props): JSX.Element {
  // TODO: switch to actual data via prop/state/context
  let issueCount = 2;

  return (
    <div className="flex flex-col justify-center gap-3">
      {/* Lable & Issue count */}
      <div className="flex flex-col gap-3">
        <div className="text-sm font-medium leading-5">Material Planning</div>
        {/* <div className="flex items-center gap-1.5">
          <div className="p-0.5 rounded-full bg-primary-rose">
            <XMarkIcon className="w-4 h-4 text-white stroke-[3px]" />
          </div>
          {pluralize("Issue", issueCount, true)}
        </div> */}
      </div>

      {/* Chart */}
      <MiniMaterialChart />
    </div>
  );
}

type ChartProps = {};

/**
 * A component that displays a mini material planning chart.
 */
function MiniMaterialChart({}: ChartProps): JSX.Element {
  // Get groups from context
  const { materialGroups: groups } = useContext<ForecastHistoryContextType>(
    ForecastHistoryContext
  );

  return (
    <div className="flex flex-col gap-0.5">
      {groups ? (
        groups.map((group, i) => {
          return <MiniMaterialRow key={group.name} group={group} />;
        })
      ) : (
        <div className="flex flex-col gap-0.5">
          <div className="w-40 h-4 bg-gray-300 rounded-md dark:bg-gray-500 animate-pulse"></div>
          <div className="w-40 h-4 bg-gray-300 rounded-md dark:bg-gray-500 animate-pulse"></div>
        </div>
      )}
    </div>
  );
}

/**
 * Material Group type.
 */
type Group = {
  name: string;
  _id: string;
};

/**
 * A component that represents a row in the mini material planning chart.
 */
type RowProps = {
  group: Group;
};

/**
 * A component that represents a row in the mini material planning chart.
 * Each row represents a material group.
 */
function MiniMaterialRow({ group }: RowProps): JSX.Element {
  let initial = group.name.charAt(0).toUpperCase();

  return (
    <div className="flex items-center gap-2">
      {/* Group Name */}
      <div className="relative flex items-center justify-center w-5 h-5 leading-5 bg-gray-400 rounded-full cursor-default dark:bg-gray-600 group">
        <span className="text-sm font-semibold text-white">{initial}</span>

        {/* Hover */}
        <div className="hidden absolute group-hover:block p-2 bg-white rounded-lg border bottom-[calc(100%+2px)] dark:bg-gray-800">
          <div className="">{pluralize(group.name, 2, false)}</div>
        </div>
      </div>

      {/* Row */}
      <div className="flex items-center gap-0.5">
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
        <Cell></Cell>
      </div>
    </div>
  );
}

/**
 * A component that represents a cell in the mini material planning chart.
 * Will display fulfilment status (under construction).
 */
function Cell(): JSX.Element {
  return <div className="w-1.5 h-4 bg-gray-300 dark:bg-gray-400"></div>;
}
