import {
  CalendarIcon,
  HomeIcon,
  MapIcon,
  QueueListIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Badge from "src/components/Badge.tsx";
import Card from "src/components/Card";
import SubNav from "src/components/nav/SubNav";
import TopBar from "src/components/nav/TopBar";
import Dropdown from "src/components/input/Dropdown";
import {
  ForecastSnapshotViewContextType,
  StageType,
} from "src/contexts/forecast/history/snapshot/ForecastSnapshotViewContext";
import UserManager from "src/tools/UserManager";
import { ForecastType } from "./history/ForecastHistoryPage.tsx";
import { MarketType } from "src/contexts/forecast/history/ForecastHistoryContext";

type Props = {};

type InstallerType = {
  _id: string;
  name: string;
};

/**
 * Displays a submitted forecast snapshot for a distributor
 * Looks like the dynamic forecast page but is static.
 */
export default function ForecastSnapshotViewPage({ }: Props): JSX.Element {
  // marketId and installerId from URL
  const [searchParams, setSearchParams] = useSearchParams();

  // get installerId and marketId from URL
  const [installerId, setInstallerId] = useState(searchParams.get("installerId"));
  const [marketId, setMarketId] = useState(searchParams.get("marketId"));

  // Navigation and location const from react-router-dom
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const [forecast, setForecast] = useState<ForecastType>(null);
  const [market, setMarket] = useState<MarketType>(null);
  const [stages, setStages] = useState<StageType[]>([]);
  const [submitter, setSubmitter] = useState<SubmitterType>(null);
  const [materialGroups, setMaterialGroups] = useState<MaterialGroupType[]>([]);
  const [materials, setMaterials] = useState<MaterialType[]>([]);

  const [tabs, setTabs] = useState([
    {
      name: "Job Forecast",
      icon: <HomeIcon />,
      href: "job",
      current: true,
    },
    {
      name: "Material Forecast",
      icon: <QueueListIcon />,
      href: "material",
      current: false,
    },
  ]);

  const [installerOptions, setInstallerOptions] = useState<InstallerType[]>([]);
  const [marketOptions, setMarketOptions] = useState<MarketType[]>([]);

  const [loading, setLoading] = useState(false);

  /**
   * On page load, get shared forecast data for the selected installer and market
   */
  useEffect(() => {
    setLoading(true);
    // on page load, request forecast data to get all installers and markets for dropdowns
    getForecast(installerId, marketId)
      .then((res) => {

        setInstallerOptions(
          // only show installers that have forecasts
          res.installers.filter((installer) => res.forecasts.some((f) => f.installer === installer._id))
            .map((installer) => ({
              value: installer._id,
              label: installer.name,
            }))
        );

        let firstInstallerId = 
            res.installers.length > 0
            ? res.installers[0]._id
            : null;

        // if installerId is not set, set it to the first installer
        let iId = installerId ? installerId : firstInstallerId;

        // if we are loading the page without an installerId in the URL, set installerId so it displays in the dropdown
        if (!installerId && firstInstallerId) {
          setInstallerId(firstInstallerId);
        }

        // set the market options for the selected installer
        let newMarketOptions = res.installersMarkets.find(
          (im) => im._id === iId
        ).markets
          .map((market) => ({
            value: market._id,
            label: market.name,
          }))

        // set the market options for the first installer
        setMarketOptions(newMarketOptions);

        let mId = marketId
          ? marketId
          :
          newMarketOptions.length === 0
            ? null
            : newMarketOptions[0].value;

        // if we are loading the page without a marketId in the URL, set marketId so it displays in the dropdown
        if (!marketId && newMarketOptions.length > 0) {
          setMarketId(newMarketOptions[0].value);
        }

        // set search params with the current installer and market
        setSearchParams({
          ...(mId && { marketId: mId }),
          ...(iId && { installerId: iId }),
        });

        // if forecast market === marketId, set values for forecast, market, stages, submitter, materialGroups, materials
        let tempForecast = res.forecasts.find((f) => f.market === mId && f.installer === iId);

        // get the installersMaterialGroups for the selected installer
        let tempMaterialGroups = res.installersMaterialGroups.find((mg) => mg._id === iId).materialGroups;

        // get the installersMaterials for the selected installer
        let tempMaterials = res.installersMaterials[iId]

        if (tempForecast) {
          setForecast(tempForecast);
          setMarket(res.installersMarkets.find((m) => m._id === mId));
          setStages(tempForecast.stageStats);
          setSubmitter(tempForecast.submittedBy);
          setMaterialGroups(tempMaterialGroups);
          setMaterials(tempMaterials);
        } else {
          setForecast(null);
          setMarket(null);
          setStages([]);
          setSubmitter(null);
          setMaterialGroups([]);
          setMaterials([]);
        }
      })
      .catch((error) => {
        console.error("Error getting forecast data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [installerId, marketId]);


  /**
   * Adjust tabs based on the current location
   */
  useEffect(() => {
    setTabs((oldTabs) => {
      var newTabs = oldTabs.map((oldTab) => {
        if (location.pathname.includes(oldTab.href)) {
          return { ...oldTab, current: true };
        }
        return { ...oldTab, current: false };
      });
      return newTabs;
    });
  }, [location]);

  // Handle tab selection
  const handleTabSelected = (tabName: string, tabHref: string) => {
    setTabs((prev) =>
      prev.map((t) => {
        return { ...t, current: t.name === tabName };
      })
    );
  };

  /**
   * Update the selected installer when the user selects an installer from the dropdown.
   * @param selectedInstaller - the selected installer from the dropdown
   */
  const handleInstallerSelected = (selectedInstaller) => {
    // update the installerId in the URL
    setInstallerId(selectedInstaller.value);
  };

  /**
   * Update the selected market when the user selects a market from the dropdown.
   * @param selectedMarket - the selected market from the dropdown
   */
  const handleMarketSelected = (selectedMarket) => {
    // update the marketId in the URL
    setMarketId(selectedMarket.value);
  };

  const submitterName = submitter
    ? `${submitter.firstName} ${submitter.lastName}`
    : null;

  const contextValue: ForecastSnapshotViewContextType = {
    forecast,
    market,
    stages,
    submitter,
    materialGroups,
    materials,
  };

  return (
    <div className="flex flex-col gap-2">
      <TopBar>
        <div className="flex gap-3 grow">
          <h1>Installer Forecasts</h1>
        </div>
      </TopBar>

      {/* Body */}
      <div className="inset-0 flex flex-col flex-1 gap-y-4">
        <div className="flex flex-wrap items-center justify-between px-6 py-2">
          <div className="flex flex-wrap items-center gap-4">
            <div className="basis-full lg:basis-0">
              <Dropdown
                bold
                justifyLeft
                options={[installerOptions]}
                onSelected={handleInstallerSelected}
                placeholder="Select Installer"
                selectedValue={installerId}
              />
            </div>
            <div className="basis-full lg:basis-0">
              <Dropdown
                bold
                justifyLeft
                options={[marketOptions]}
                onSelected={handleMarketSelected}
                placeholder="Select Market"
                selectedValue={marketId}
              />
            </div>
          </div>
          <div className="justify-self-end">
            <SubNav
              tabs={tabs}
              onSelected={handleTabSelected}
              navClasses="!space-x-0 border border-gray-300 bg-gray-300 dark:bg-gray-500 gap-0.5"
              currentTabClasses="bg-white dark:bg-gray-800 !border-b-[3px]"
              tabClasses="!whitespace-nowrap border-b-[0px] bg-gray-200 dark:bg-gray-600"
            />
          </div>
        </div>


        {/* Main */}
        {/* Main */}
        <div className="relative flex justify-start overflow-x-auto">
          {loading ? (
            <div className="flex justify-center w-full">
              <SkeletonBar width={1200} height={700} />
            </div>
          ) :
            forecast ? (
              <Outlet context={contextValue} />
            ) :
              // TODO: add a button to request a forecast?
              <div className='w-full text-center'>No forecast has been shared.</div>
          }
        </div>
      </div>
    </div>
  );
}

/**
 * Response from the snapshot API endpoint.
 */
type SnapshotResponse = {
  status: string;
  forecast: ForecastType;
  market: MarketType;
  stages: StageType[];
  submitter: SubmitterType;
  materialGroups: MaterialGroupType[];
  materials: MaterialType[];
};

type ForecastResponse = {
  status: string;
  forecasts;
  installersMarkets;
  installers;
  installersMaterialGroups;
  installersMaterials;
};

type MaterialType = {
  _id: string;
  name: string;
  model: string;
  brand: string;
  isDefaultMaterial: boolean;
  materialGroup: string; // ID of the material group
};

type MaterialGroupType = {
  _id: string;
  name: string;
};

type SubmitterType = {
  _id: string;
  company: { type: string; name: string };
  firstName: string;
  lastName: string;
  email: string;
};

/**
 * Retrieves a forecast from the API.
 * @param installerId - ID of the installer
 * @param marketId - ID of the market
 * @returns - Forecast data
 */
async function getForecast(installerId: string, marketId: string): Promise<ForecastResponse> {
  var res = await UserManager.makeAuthenticatedRequest(
    "/api/forecast/get-shared-forecasts/",
    "GET",
    {
      installerId,
      marketId,
    }
  );

  if (res.data.status !== "ok") {
    throw new Error(res.data.message);
  }

  return res.data;
}

/**
 * Turns a date string into a formatted date string.
 * If invalid date, returns the original string.
 *
 * @param date - String representation of a date
 * @param format - Moment.js format string
 * @returns - Date formatted according to the format string
 */
function formatDate(date: string, format = "M/D/YY"): string {
  if (!date) return "";

  var m = moment(date);

  // Check if the date is valid
  if (!m.isValid()) return date;

  return m.format(format);
}

/**
 * A loading skeleton bar.
 * Can take a width and height prop.
 */
function SkeletonBar({ width = 40, height = 20 }) {
  return (
    <div
      style={{ width: width, height: height }}
      className="bg-gray-300 rounded-lg dark:bg-gray-500 animate-pulse"
    />
  );
}
