import React from "react";
import Input from "../input/Input";

var dataExample_hist = [
  {
    potential: 38,
    inputs: 50,
    week: -8,
  },
  {
    potential: 38,
    inputs: 50,
    week: -7,
  },
  {
    potential: 38,
    inputs: 50,
    week: -6,
  },
  {
    potential: 38,
    inputs: 50,
    week: -5,
  },
  {
    potential: 38,
    inputs: 50,
    week: -4,
  },
  {
    potential: 38,
    inputs: 50,
    week: -3,
  },
  {
    potential: 38,
    inputs: 50,
    week: -2,
  },
  {
    potential: 38,
    inputs: 50,
    week: -1,
  },
];

var dataExample_fore = [];
// Add more data to see how it looks
for (var i = 1; i <= 20; i++) {
  dataExample_fore.push({
    potential: 38,
    inputs: 50,
    week: i,
  });
}

const CELL_WIDTH = 1030 / 20;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Cell({
  value,
  editable,
  onChange = () => {},
  disabled = false,
  clickable = false,
  onClick = () => {},
  isTopRow = false,
  isBottomRow = false,
  borderedWeek = -1,
  index = -1,
}) {
  var border = editable
    ? "border-primary-green"
    : "border-gray-300 border-b-primary-green";
  // if (disabled) border = "border-gray-300";
  var grayedOut =
    disabled && !editable
      ? "text-gray-400 bg-gray-100 dark:bg-gray-700 dark:text-gray-300"
      : "";

  var highlight = "";
  if (borderedWeek !== -1) {
    if (index === borderedWeek) {
      highlight += "border-x-primary-rose border-x-2 bg-white text-gray-900";

      if (isTopRow) {
        highlight += " border-t-primary-rose border-t-2";
      }

      if (isBottomRow) {
        highlight += " border-b-primary-rose border-b-2";
      }
    }

    if (index === borderedWeek - 1) {
      highlight += " border-r-primary-rose border-r-2";
    }
  }

  return (
    <td
      className={classNames(
        "border text-center h-10 w-[5%] max-w-min", // w-[48px]
        editable && !disabled && "bg-white dark:bg-gray-800",
        !editable && "py-2",
        border,
        grayedOut,
        clickable && "hover:bg-primary-green-100 cursor-pointer",
        highlight
      )}
      onClick={onClick}
    >
      {disabled ? (
        value || (value == 0 ? "0" : "-")
      ) : (
        <input
          className="w-full px-0 py-2 text-center border-0 dark:bg-gray-800 basis-0 focus:ring-primary-green-500"
          value={value || ""}
          placeholder="-"
          size={1}
          onChange={onChange}
        />
      )}
    </td>
  );
}

export default function ForecastInput({
  data = [],
  toaField = "potential",
  inputField = "input",
  weekField = "week",
  disabled = false,
  onToaFieldClick = null, // If not null, hoverable
  showWeeks = false,
  onChange = () => {},
  borderedWeek = -1,
  fixedWidth = false,
  isStatic = false, // TODO: handle static forecasts
}) {
  var potential = data.map((d, i) => {
    return (
      <Cell
        key={i}
        disabled
        value={d[toaField]}
        clickable={onToaFieldClick !== null && !isStatic}
        onClick={() => {
          if (onToaFieldClick !== null) {
            onToaFieldClick(d, i);
          }
        }}
        borderedWeek={borderedWeek}
        isTopRow
        index={i}
      />
    );
  });

  var inputs = data.map((d, i) => {
    return (
      <Cell
        disabled={disabled || isStatic}
        key={i}
        value={!isStatic ? d[inputField] : d[inputField] || null}
        editable
        clickable={disabled && onToaFieldClick !== null && !isStatic}
        onChange={(e) => {
          if (!isStatic) {
            // Send to parent with index and value
            onChange(i, e.target.value);
          }
        }}
        borderedWeek={borderedWeek}
        isBottomRow
        index={i}
      />
    );
  });

  if (data.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <table
        className="w-full border-collapse"
        style={fixedWidth ? { width: 20 * CELL_WIDTH } : {}}
      >
        <tbody className="">
          {
            // Show weeks
            showWeeks && (
              <tr>
                {data.map((d, i) => {
                  return (
                    <td
                      key={i}
                      className="h-10 text-center bg-gray-100 border-gray-300 border-b-primary-green"
                    >
                      {d[weekField]}
                    </td>
                  );
                })}
              </tr>
            )
          }
          <tr>{potential}</tr>
          <tr className="z-10">{inputs}</tr>
        </tbody>
      </table>
    </>
  );
}
